import { Address } from '@one/api-models/lib/Address';

export const formatAddress = (address: Address | undefined, hideAddressLine?: boolean | undefined) => {
  if (address == null) return undefined;

  return [
    !hideAddressLine ? address.addressLine1 : undefined,
    !hideAddressLine ? address.addressLine2 : undefined,
    address.city,
    address.state ?? address.stateCode ?? address.county,
    address.country ?? address.countryCode,
  ]
    .filter((v) => v != null && v !== '')
    .join(', ');
};
