import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UseMutationResult } from 'react-query';

import UploadIcon from '@mui/icons-material/Upload';
import { Alert, Box, Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import { AddFilesBase } from '@one/api-models/lib/Admin/Files/AddFilesBase';
import { BulkEnrollmentIngestRequest } from '@one/api-models/lib/Feed/Files/Member/Enrollment/BulkEnrollmentIngestRequest';
import { BulkEnrollmentIngestResponse } from '@one/api-models/lib/Feed/Files/Member/Enrollment/BulkEnrollmentIngestResponse';

import { ApiError } from 'apiAccess/api-client';
import { useStyles } from 'components/_common//fileUploadDropZone/Styles';
import { ActionButton } from 'components/_common/ActionButton';
import { FileDetails } from 'components/_common/fileManager/FileDetails';
import { QueueListing, UploadStatus } from 'components/_common/fileManager/QueueListing';

import { FileConfiguration } from './FileConfiguration';

interface Props {
  disabled?: boolean;
  multiple?: boolean;
  uploadOnDrop?: boolean;
  requestBase: BulkEnrollmentIngestRequest;
  mutation: UseMutationResult<
    BulkEnrollmentIngestResponse,
    ApiError,
    { request: BulkEnrollmentIngestRequest },
    unknown
  >;
  buttonText?: string;
  helperText?: string;
  testId: string;
}

export const FileDropZone = ({
  disabled,
  multiple,
  uploadOnDrop,
  requestBase,
  mutation,
  buttonText,
  helperText,
  testId,
}: Props) => {
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.new);
  const classes = useStyles();
  const [hideList, setHideList] = useState(false);

  useEffect(() => {
    if (mutation) {
      if (mutation.isLoading) {
        setUploadStatus(UploadStatus.uploading);
      } else if (mutation.isSuccess) {
        setUploadStatus(UploadStatus.completed);
      } else if (mutation.isError) {
        setUploadStatus(UploadStatus.error);
      }
    } else {
      setFiles([]);
    }
  }, [mutation]);

  const onAddFiles = useCallback(
    (newFiles: FileDetails[]) => {
      const addActions = newFiles.map((f) => FileConfiguration.fileDetailsToAddFileRequest(f));
      setFiles(newFiles);
      Promise.all(addActions)
        .then((results) => {
          const files = results.filter((f) => f) as AddFilesBase[];

          let request: BulkEnrollmentIngestRequest = { ...requestBase } as BulkEnrollmentIngestRequest;

          if ('files' in request) request = { ...request, files } as BulkEnrollmentIngestRequest;

          mutation.mutate({
            request,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [mutation, requestBase],
  );

  const onHideListHandler = () => {
    setFiles([]);
    setUploadStatus(UploadStatus.new);
    setHideList(true);
  };

  const openFilePicker = () => {
    if (!disabled) {
      open();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const files = acceptedFiles.map(
          (x: any) =>
            ({
              name: x.name,
              size: x.size,
              type: `${x.type}`,
              file: x,
            } as FileDetails),
        );
        if (uploadOnDrop) onAddFiles(files);
        else setFiles(files);
        setUploadStatus(UploadStatus.new);

        setHideList(false);
      }
    },
    [onAddFiles, uploadOnDrop],
  );

  const { getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noDrag: disabled,
    onDrop,
    accept: '',
    multiple,
  });

  return (
    <Box
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
        className:
          classes.uploadDropZone +
          ' ' +
          (isDragActive && classes.uploadDropZoneHover) +
          ' ' +
          (isDragReject && classes.uploadDropZoneRejected),
      })}
    >
      <input {...getInputProps()} data-testid={`${testId}Input`} />
      <IconButton
        size="large"
        sx={{ mt: 2 }}
        className="uploadTextColor"
        onClick={openFilePicker}
        disabled={disabled}
        data-tesid={`${testId}UploadButton`}
      >
        <UploadIcon fontSize="large" />
      </IconButton>
      <Typography
        variant="h5"
        sx={{ mb: 2, cursor: disabled ? 'default' : 'pointer' }}
        className="uploadTextColor"
        onClick={openFilePicker}
      >
        Drop files here to upload
      </Typography>
      {isDragReject && <Alert severity="error">At least one of the files is not in the accepted format</Alert>}
      <Collapse in={files.length > 0 && !hideList}>
        <Box>
          <QueueListing files={files} onHide={onHideListHandler} status={uploadStatus} testId={testId} />
        </Box>
      </Collapse>
      {!uploadOnDrop && files.length > 0 && (
        <>
          <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
            <ActionButton
              type="button"
              onClick={() => onAddFiles(files)}
              disabled={uploadStatus !== UploadStatus.new}
              icon={<UploadIcon />}
              testId={`${testId}ProcessFile`}
            >
              {buttonText || 'Upload'}
            </ActionButton>
          </Box>
          {helperText && (
            <Typography component={Box} variant="caption" sx={{ textAlign: 'left' }}>
              {helperText}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export const MediaDropZoneSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height={158} />
    </Box>
  );
};
