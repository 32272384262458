import { Box, FormControlLabel, Radio, RadioGroup, useTheme } from '@mui/material';

import { Typography } from 'styled';

// Mocked component
export const SalesOrderDelivery = () => {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 7 }}>
      <Typography variant="h5">Delivery</Typography>
      <Box sx={{ mt: 2.5 }}>
        <RadioGroup defaultValue="send-email">
          <FormControlLabel
            value="send-email"
            control={<Radio size="small" />}
            label={<Typography variant="body2">Send Email Confirmation to Customer</Typography>}
          />
          <FormControlLabel
            value="do-not-notify"
            sx={{ mt: 2 }}
            control={<Radio size="small" />}
            label={<Typography variant="body2">Do not notify</Typography>}
          />
          <Typography variant="body1" sx={{ color: theme.palette.text.secondary, ml: 3.25 }}>
            Will not send a confirmation email.
          </Typography>
        </RadioGroup>
      </Box>
    </Box>
  );
};
