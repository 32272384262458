import { ReactElement } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

interface Props {
  label: string;
  labelStyle?: SxProps;
  value: ReactElement | string | number | null;
  direction?: 'column' | 'row';
}

export const Info = ({ label, value, direction = 'column', labelStyle }: Props) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, flexDirection: direction }}>
    <Typography variant="subtitle1" sx={{ ...labelStyle }}>
      {label}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </Box>
);
