import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CustomWidth } from 'models/CustomWidth';

interface DrawerProps extends MuiDrawerProps {
  drawerWidth: CustomWidth;
}

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'drawerWidth' })<DrawerProps>(
  ({ theme, drawerWidth }) => ({
    width: drawerWidth.fixedWidth ?? drawerWidth.medium,
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    '.MuiDrawer-paper': {
      border: 'none',
      position: 'initial',
      background: 'transparent',
      width: drawerWidth.fixedWidth ?? drawerWidth.medium,
    },

    [theme.breakpoints.up('xl')]: {
      width: drawerWidth.fixedWidth ?? drawerWidth.large,
      '.MuiDrawer-paper': {
        width: drawerWidth.fixedWidth ?? drawerWidth.large,
      },
    },
  }),
);
