import { styled } from '@mui/material/styles';
import { TreeItem as MuiTreeItem } from '@mui/x-tree-view/TreeItem';

export const TreeItem = styled(MuiTreeItem)(({ theme }) => ({
  '.MuiTreeItem-content': {
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
  },
  '.MuiTreeItem-groupTransition': {
    paddingLeft: 0,
  },
  '.MuiTreeItem-content .MuiTreeItem-iconContainer': {
    display: 'none',
  },
  '.MuiTreeItem-content.Mui-selected': {
    backgroundColor: 'transparent',
  },
}));
