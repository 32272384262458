import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { PromotionQualifier } from '@one/api-models/lib/Campaign/Promotion/PromotionQualifier';

import { ConfirmationDialog } from 'components/_common/ConfirmationDialog';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { useFormat } from 'components/hooks/useFormat';

import { AddPromotionQualifierDialog } from './AddPromotionQualifierDialog';
import { QualifierTypeValues } from './promotionHelpers';

interface PromotionQualifiersViewProps {
  promotion: Promotion;
  testId: string;
  qualifiers?: PromotionQualifier[];
  paddingTop?: number;
  handleRemoveQualifier: (qualifierId: number) => Promise<void>;
  handleAddQualifier: (item: PromotionQualifier) => Promise<void>;
}

export const PromotionQualifiersView = ({
  qualifiers,
  promotion,
  testId,
  paddingTop,
  handleRemoveQualifier,
  handleAddQualifier,
}: PromotionQualifiersViewProps) => {
  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState<boolean>(false);
  const [addQualifierOpen, setAddQualifierOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<PromotionQualifier | undefined>(undefined);
  const { formatDate } = useFormat();

  const handleRemove = (item: PromotionQualifier) => {
    setSelectedItem(item);
    setRemoveConfirmationOpen(true);
  };

  const handleConfirmRemoveQualifier = async (isConfirmed: boolean) => {
    if (selectedItem && isConfirmed) {
      await handleRemoveQualifier(selectedItem.id);
    }

    setRemoveConfirmationOpen(false);
    setSelectedItem(undefined);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Qualifier ID',
      sortable: false,
    },
    {
      field: 'qualifierType',
      headerName: 'Qualifier Type',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <>{QualifierTypeValues[params.row.qualifierType].label}</>,
    },
    {
      field: 'qualifierName',
      headerName: '',
      sortable: false,
      flex: 2,
    },
    {
      field: 'minQuantity',
      headerName: 'Min Qty',
      sortable: false,
    },
    {
      field: 'minAmount',
      headerName: 'Min Amount',
      sortable: false,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      sortable: false,
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: 'Update Date (UTC)',
      sortable: true,
      flex: 1,
      renderCell: (params: any) => <>{formatDate(params.row.updatedAt, true, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      align: 'right',
      renderCell: (params: any) => (
        <>
          {handleRemove && (
            <IconButton onClick={() => handleRemove(params.row)} data-testid={`${testId}ActionButton`}>
              <CloseIcon color="primary" />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  return (
    <Box>
      <Box mt={paddingTop} mb={2} display="flex" justifyContent="space-between">
        <Typography variant="h5">Qualifiers</Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => setAddQualifierOpen(true)}
          startIcon={<AddIcon />}
          data-testid={`${testId}QualifierButton`}
        >
          Qualifier
        </Button>
      </Box>

      {qualifiers && qualifiers.length > 0 ? (
        <DataGrid rows={qualifiers} columns={columns} hideFooter={true} rowHeight={40} />
      ) : (
        <Alert severity="info" variant="outlined">
          No promotion qualifiers available.
        </Alert>
      )}
      <AddPromotionQualifierDialog
        open={addQualifierOpen}
        promotion={promotion}
        handleAddQualifier={handleAddQualifier}
        handleClose={() => setAddQualifierOpen(false)}
        testId={`${testId}AddPromotionQualifier`}
      />

      <ConfirmationDialog
        open={removeConfirmationOpen}
        title={`Remove ${selectedItem?.qualifierName} Qualifier`}
        question="Are you sure you want to remove this qualifier?"
        confirmButton="Remove"
        abortButton="Cancel"
        onClose={handleConfirmRemoveQualifier}
        testId={`${testId}RemoveQualifierDialog`}
      />
    </Box>
  );
};
