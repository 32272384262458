import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { CreateDetails } from '@one/api-models/lib/Admin/Tags/Edit/CreateDetails';
import { Delete } from '@one/api-models/lib/Admin/Tags/Edit/Delete';
import { UpdateDetails } from '@one/api-models/lib/Admin/Tags/Edit/UpdateDetails';
import { UpdateRelation } from '@one/api-models/lib/Admin/Tags/Edit/UpdateRelation';
import { EditRequest } from '@one/api-models/lib/Admin/Tags/EditRequest';
import { EditResponse } from '@one/api-models/lib/Admin/Tags/EditResponse';
import { LoadSubtreeCriteria } from '@one/api-models/lib/Admin/Tags/LoadSubtreeCriteria';
import { LoadSubtreeResponse } from '@one/api-models/lib/Admin/Tags/LoadSubtreeResponse';
import { TagEditOperation } from '@one/api-models/lib/Admin/Tags/TagEditOperation';
import { Tag } from '@one/api-models/lib/Admin/Tags/View/Tag';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import {
  selectSelectedOnTreeComplete,
  selectTagTree,
  setIsSavingTag,
  setNewTag,
  setSelectedOnTreeComplete,
  setSelectedTag,
  setTagTree,
} from 'slices/tagsDataSlice';

export class ExtendedTag extends Tag {
  parent?: Tag;
}

export const useTagsHelpers = () => {
  const dispatch = useDispatch();
  const { api } = useApiHelpers();
  const { addApiError } = useToastMessage();

  const tagTree = useSelector(selectTagTree);
  const selectedOnTreeComplete = useSelector(selectSelectedOnTreeComplete);

  const onCreateComplete = (response: EditResponse, request: EditRequest) => {
    if (tagTree && request.createDetails?.parentKey && request.createDetails) {
      const parentKey = request.createDetails?.parentKey || '';
      const parentTag = getTag(tagTree, parentKey);
      if (parentTag && response.changedItems && response.changedItems.length > 0) {
        response.changedItems.forEach((t) => parentTag.descendants.push(t));
        fixTreeStructure(parentTag);
        selectTag(response.changedItems[0]);
      }
      dispatch(setNewTag(undefined));
    }
  };

  const onUpdateComplete = (response: EditResponse, request: EditRequest) => {
    if (tagTree && request.tagKey && request.updateDetails) {
      let selTag;
      response.changedItems?.forEach((t) => {
        const parent = (getTag(tagTree, t.key) as ExtendedTag).parent;
        if (parent) {
          const idx = parent.descendants.findIndex((x) => x.key === t.key);
          parent.descendants.splice(idx, 1, t);
          selTag = t;
          dispatch(setTagTree({ ...tagTree }));
          fixTreeStructure(parent);
        }
      });
      if (selTag) {
        selectTag(selTag);
      }
    }
  };

  const onDeleteComplete = (response: EditResponse, request: EditRequest) => {
    if (tagTree && request.tagKey && request.delete) {
      const parentTag = (getTag(tagTree, request.tagKey) as ExtendedTag).parent;
      if (parentTag) {
        if (parentTag.descendants.length === 1) {
          const parentOfParent = (parentTag as ExtendedTag).parent;
          if (parentOfParent) {
            parentOfParent.descendants = [];
            selectTag(parentOfParent);
          }
        } else {
          parentTag.descendants = [];
          selectTag(parentTag);
        }
      }
    }
  };

  const onEditComplete = (response: EditResponse, request: EditRequest) => {
    if (tagTree) {
      if (request.updateDetails) {
        onUpdateComplete(response, request);
      }

      if (request.createDetails) {
        onCreateComplete(response, request);
      }

      if (request.delete) {
        onDeleteComplete(response, request);
      }
    }
  };

  const requestEditMutation = useMutation<EditResponse, ApiError, EditRequest, unknown>(
    async (request: EditRequest) => {
      return await api.tags.edit(request);
    },
    {
      onSuccess: (response: EditResponse, request: EditRequest) => {
        onEditComplete(response, request);
      },
      onError: addApiError,
    },
  );

  const loadSubtree = useMutation<LoadSubtreeResponse, ApiError, Tag, unknown>(
    async (tag: Tag) => {
      return await api.tags.loadSubtree({
        tagKey: tag.key,
      } as LoadSubtreeCriteria);
    },
    {
      onSuccess: (value, tag) => {
        tag.descendants = value.tags;
        fixTreeStructure(tag);
        if (selectedOnTreeComplete) {
          dispatch(setSelectedTag(selectedOnTreeComplete));
          dispatch(setSelectedOnTreeComplete(undefined));
        }
      },
      onError: addApiError,
    },
  );

  const selectTag = (tag: Tag) => {
    if (tag.hasDescendants && (!Array.isArray(tag.descendants) || tag.descendants.length === 0)) {
      loadSubtree.mutate(tag);
    }
    dispatch(setSelectedTag({ ...tag }));
  };

  const getTag = (startTag: Tag, tagKey: string): Tag | undefined => {
    if (startTag.key === tagKey) {
      return startTag;
    }

    if (Array.isArray(startTag.descendants) && startTag.descendants.length > 0) {
      let found: Tag | undefined = undefined;
      for (let i = 0; i < startTag.descendants.length && !found; i += 1) {
        found = getTag(startTag.descendants[i], tagKey);
      }
      return found;
    }
    return undefined;
  };

  const fixTreeStructure = (tag: Tag): Tag | undefined => {
    if (!tag.descendants || !Array.isArray(tag.descendants) || tag.descendants.length === 0) {
      return;
    }

    tag.descendants.sort((tagA, tagB) => {
      const a = tagA.name.toLowerCase(),
        b = tagB.name.toLowerCase();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    for (let i = 0; i < tag.descendants.length; i += 1) {
      (tag.descendants[i] as ExtendedTag).parent = tag;
      fixTreeStructure(tag.descendants[i]);
    }
  };

  const createNewTag = () => {
    dispatch(setNewTag({ key: '' } as Tag));
  };

  const abortNewTag = () => {
    dispatch(setNewTag(undefined));
  };

  const saveHandler = (
    tagKey: string | undefined,
    data: UpdateDetails | CreateDetails | Delete | UpdateRelation,
    editOperation: TagEditOperation,
  ) => {
    const request: EditRequest = {
      tagKey: tagKey,
      createDetails: TagEditOperation.AddChild === editOperation ? data : undefined,
      updateDetails: TagEditOperation.Edit === editOperation ? data : undefined,
      delete: TagEditOperation.Delete === editOperation ? data : undefined,
      relation: TagEditOperation.Move === editOperation ? data : undefined,
    } as EditRequest;

    requestEditMutation.mutate(request);
  };

  useEffect(() => {
    dispatch(setIsSavingTag(requestEditMutation.isLoading));
  }, [dispatch, requestEditMutation.isLoading]);

  return {
    selectTag,
    createNewTag,
    abortNewTag,
    saveHandler,
    getTag,
    fixTreeStructure,
  };
};
