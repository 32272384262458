import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { endOfMonth, format, isAfter, parse } from 'date-fns';

import { Alert, Box, Button, FormHelperText, Grid, InputAdornment } from '@mui/material';
import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/CybersourceGatewayConfiguration';

import { SectionTitle } from 'components/_common/SectionTitle';
import { InputLabel, TextField } from 'styled';

import { CybersourceInput } from './CybersourceInput';
import { ExpiryInput } from './ExpiryDateComponent';

import 'paymentfont/css/paymentfont.min.css';
// Custom Form styling
import './styles/cybersource.css';

const STYLES = {
  input: {
    'font-size': '14px',
    'font-family': 'monospace',
    color: 'rgb(33, 33, 33)',
  },
  '::placeholder': {
    color: 'rgb(172, 172, 172)',
  },
};

const SCRIPT_TYPE = 'text/javascript';

const isScriptLoaded = (scriptSrc: string) => {
  const scripts = document.querySelectorAll('script');
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === scriptSrc) {
      return true;
    }
  }
  return false;
};

export interface CybersourceFormRefHandle {
  handleFormSubmit: (requireAdditionalFormValid?: boolean) => void;
}

export interface PaymentFormParams {
  title?: string;
  testId: string;
  updatePaymentData: () => void;
  paymentGatewayConfiguration: CybersourceGatewayConfiguration;
  submitPayment: (shift4CardToken: string, paymentMethodExpiration: Date) => void;
  setIsSubmittingPayment: (isSubmittingPayment: boolean) => void;
  isSubmittingPayment: boolean;
  submitForm: () => void;
  error?: string;
}

type inputType = 'cardNumber' | 'cvc' | 'expiryDate';

interface FlexInputState {
  loaded: boolean;
  focused: boolean;
  error: string;
  valid: boolean;
}

interface PaymentFormState {
  cardIcon: string;
  cardNumber: FlexInputState;
  cvc: FlexInputState;

  expiryDate: {
    error: string;
    focused: boolean;
    expMonthValue: string;
    expYearValue: string;
  };
}

export const CybersourceForm = forwardRef<CybersourceFormRefHandle, PaymentFormParams>(
  (
    {
      title,
      testId,
      updatePaymentData,
      submitPayment,
      setIsSubmittingPayment,
      isSubmittingPayment,
      submitForm,
      paymentGatewayConfiguration,
      error,
    },
    ref,
  ) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [cardIcon, setCardIcon] = useState('pf pf-credit-card');
    const [microform, setMicroform] = useState();

    const [formState, setFormState] = useState<PaymentFormState>({
      cardIcon: 'pf pf-credit-card',
      cardNumber: {
        loaded: false,
        focused: false,
        error: '',
        valid: false,
      },
      cvc: {
        loaded: false,
        focused: false,
        error: '',
        valid: false,
      },
      expiryDate: {
        expMonthValue: '',
        expYearValue: '',
        focused: false,
        error: '',
      },
    });

    useEffect(() => {
      if (isScriptLoaded(`${paymentGatewayConfiguration.clientLibraryUrl}`)) {
        setScriptLoaded(true);
        return;
      }

      // LOAD CYBERSOURCE SCRIPT
      const cybersourceScript = document.createElement('script');
      cybersourceScript.src = `${paymentGatewayConfiguration.clientLibraryUrl}`;
      cybersourceScript.type = SCRIPT_TYPE;
      cybersourceScript.addEventListener('load', () => setScriptLoaded(true));
      document.body.appendChild(cybersourceScript);

      return () => {
        if (cybersourceScript) {
          cybersourceScript.removeEventListener('load', () => setScriptLoaded(true));
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!scriptLoaded) return;

      const captureContext = paymentGatewayConfiguration.captureContext;

      // Setup Microform

      const flex = new Flex(captureContext);
      const microform = flex.microform({ styles: STYLES });
      setMicroform(microform);

      // Credit card number
      const number = microform.createField('number', { placeholder: '1234 1234 1234 1234' });
      number.on('change', (data: any) => {
        if (!data?.empty) {
          data?.card[0] && setCardIcon(mapCardIcon(data.card[0].name));
          handleFormInputState('cardNumber', 'error', data.valid ? '' : 'Card number is invalid.');
          handleFormInputState('cardNumber', 'valid', data.valid);
        } else {
          handleFormInputState('cardNumber', 'error', '');
          handleFormInputState('cardNumber', 'valid', false);
          setCardIcon('pf pf-credit-card');
        }
      });
      number.on('focus', () => {
        handleFormInputState('cardNumber', 'focused', true);
      });
      number.on('blur', () => {
        handleFormInputState('cardNumber', 'focused', false);
      });
      number.on('load', () => {
        handleFormInputState('cardNumber', 'loaded', true);
      });
      number.on('inputSubmitRequest', () => {
        submitForm();
      });

      // Security Code
      const securityCode = microform.createField('securityCode', { placeholder: 'CVC', maxLength: 4 });
      securityCode.on('change', (data: any) => {
        if (!data.empty) {
          handleFormInputState('cvc', 'error', data.valid ? '' : 'Security code is invalid.');
          handleFormInputState('cvc', 'valid', data.valid);
        } else {
          handleFormInputState('cvc', 'error', '');
          handleFormInputState('cvc', 'valid', false);
        }
      });
      securityCode.on('focus', () => {
        handleFormInputState('cvc', 'focused', true);
      });
      securityCode.on('blur', () => {
        handleFormInputState('cvc', 'focused', false);
      });
      securityCode.on('load', () => {
        handleFormInputState('cvc', 'loaded', true);
      });
      securityCode.on('inputSubmitRequest', () => {
        submitForm();
      });

      // Load inputs
      number.load('#number-container');
      securityCode.load('#securityCode-container');

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scriptLoaded]);

    const handleFormInputState = (inputName: inputType, eventType: string, value: boolean | string) => {
      setFormState((prevState) => ({ ...prevState, [inputName]: { ...prevState[inputName], [eventType]: value } }));
    };

    const handleExpiryDateChange = (value: string) => {
      const [month, year] = value.split('/');

      if (month && year) {
        handleFormInputState('expiryDate', 'expMonthValue', month);
        handleFormInputState('expiryDate', 'expYearValue', year);
      } else {
        handleFormInputState('expiryDate', 'expMonthValue', '');
        handleFormInputState('expiryDate', 'expYearValue', '');
      }
      if (month?.length !== 2 || year?.length !== 2) {
        handleFormInputState('expiryDate', 'error', 'Expiration date is invalid.');
      } else {
        handleFormInputState('expiryDate', 'error', '');
      }
    };

    useImperativeHandle(ref, () => ({
      handleFormSubmit(requireAdditionalFormValid) {
        const options = {
          expirationMonth: formState.expiryDate.expMonthValue,
          expirationYear: `20${formState.expiryDate.expYearValue}`,
        };
        if (validateOnSubmit() && !requireAdditionalFormValid && !isSubmittingPayment) {
          setIsSubmittingPayment(true);
          (microform as any).createToken(options, (err: any, token: any) => {
            if (err) {
              if (err.reason === 'CREATE_TOKEN_VALIDATION_SERVERSIDE') {
                updatePaymentData();
              }
              setIsSubmittingPayment(false);
            } else {
              const paymentMethodExpiration = endOfMonth(
                new Date(`${options.expirationYear}-${options.expirationMonth}-01`),
              );
              submitPayment(JSON.stringify(token), paymentMethodExpiration);
            }
          });
        }
      },
    }));

    const validateOnSubmit = () => {
      let isFormValid = true;
      if (!formState.cardNumber.valid) {
        handleFormInputState('cardNumber', 'error', 'Card number is required.');
        isFormValid = false;
      }
      if (!formState.cvc.valid) {
        handleFormInputState('cvc', 'error', 'Security code is required.');
        isFormValid = false;
      }
      if (formState.expiryDate.expYearValue.length !== 2) {
        handleFormInputState('expiryDate', 'error', 'Expiration date is required.');
        isFormValid = false;
      }
      if (isExpirationInvalid(formState.expiryDate.expMonthValue, formState.expiryDate.expYearValue)) {
        handleFormInputState('expiryDate', 'error', 'Expiration date is invalid.');
        isFormValid = false;
      }
      handleFormInputState('cardNumber', 'focused', false);
      handleFormInputState('cvc', 'focused', false);
      handleFormInputState('expiryDate', 'focused', false);

      return isFormValid;
    };

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        submitForm();
      }
    };

    return (
      <Box sx={{ pt: title ? 2 : 0, maxWidth: '425px' }}>
        {title && scriptLoaded && <SectionTitle sx={{ mb: 4 }} title={title} />}
        {error && (
          <Alert
            severity="error"
            sx={{ mb: 2 }}
            action={
              <Button
                onClick={() => {
                  updatePaymentData();
                }}
                data-testid={`${testId}TryAgainButton`}
              >
                Try Again
              </Button>
            }
          >
            {error}
          </Alert>
        )}
        <Grid container spacing={2} rowSpacing={2} display="flex" flexDirection="row" justifyContent="space-between">
          <Grid item xs={12}>
            <InputLabel>Card Number</InputLabel>
            <TextField
              name="card"
              error={!formState.cardNumber.focused && !!formState.cardNumber.error}
              InputLabelProps={{ shrink: true }}
              focused={formState.cardNumber.focused}
              InputProps={{
                inputComponent: CybersourceInput,
                inputProps: {
                  inputId: 'number-container',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <i className={cardIcon} />
                  </InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              inputProps={{
                'data-testid': `${testId}CardNumberInput`,
              }}
            />
            {!formState.cardNumber.focused && !!formState.cardNumber.error && (
              <FormHelperText error>{formState.cardNumber.error}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={6}>
              <InputLabel>Expiration Date</InputLabel>
              <TextField
                placeholder="MM / YY"
                error={!formState.expiryDate.focused && !!formState.expiryDate.error}
                focused={formState.expiryDate.focused}
                onFocus={() => handleFormInputState('expiryDate', 'focused', true)}
                onBlur={() => handleFormInputState('expiryDate', 'focused', false)}
                onKeyDown={handleEnterKeyPress}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& legend': {
                    fontSize: '0.5rem',
                    paddingRight: '2px',
                  },
                }}
                InputProps={{
                  inputComponent: ExpiryInput,
                  inputProps: {
                    onChange: (event: any) => handleExpiryDateChange(event.target.value),
                  },
                  style: {
                    fontFamily: 'monospace',
                    fontSize: '14px',
                    fontWeight: 'regular',
                    minWidth: '120px',
                  },
                }}
                size="small"
                fullWidth
                inputProps={{
                  'data-testid': `${testId}ExpirationDateInput`,
                }}
              />
            </Grid>
            {!formState.expiryDate.focused && !!formState.expiryDate.error && (
              <Grid item xs={12}>
                <FormHelperText error>{formState.expiryDate.error}</FormHelperText>
              </Grid>
            )}
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={7} />
            <Grid item xs={5}>
              <InputLabel>CVC</InputLabel>
              <TextField
                error={!formState.cvc.focused && !!formState.cvc.error}
                focused={formState.cvc.focused}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: CybersourceInput,
                  inputProps: {
                    inputId: 'securityCode-container',
                  },
                }}
                size="small"
                fullWidth
                inputProps={{
                  'data-testid': `${testId}CVCInput`,
                }}
              />
            </Grid>
            {!formState.cvc.focused && !!formState.cvc.error && (
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <FormHelperText error>{formState.cvc.error}</FormHelperText>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  },
);

const mapCardIcon = (brand: string) => {
  switch (brand) {
    case 'visa':
      return 'pf pf-visa';
    case 'mastercard':
      return 'pf pf-mastercard';
    case 'amex':
      return 'pf pf-american-express';
    case 'discover':
      return 'pf pf-discover';
    case 'diners':
      return 'pf pf-diners';
    case 'jcb':
      return 'pf pf-jcb';

    default:
      return 'pf pf-credit-card';
  }
};

const isExpirationInvalid = (expirationMonth: string, expirationYear: string) => {
  if (expirationMonth.length !== 2 || expirationYear.length !== 2) {
    return true;
  }

  const numericMonth = parseInt(expirationMonth, 10);
  const numericYear = parseInt(expirationYear, 10);

  const expirationDate = endOfMonth(parse(`20${numericYear}-${numericMonth}`, 'yyyy-MM', new Date()));
  const currentDate = new Date();

  const isValid = isAfter(expirationDate, currentDate);

  return !isValid;
};
