import { AddAwardRequest } from '@one/api-models/lib/Campaign/Promotion/Request/AddAwardRequest';
import { AddQualifierRequest } from '@one/api-models/lib/Campaign/Promotion/Request/AddQualifierRequest';
import { CreateRequest } from '@one/api-models/lib/Campaign/Promotion/Request/CreateRequest';
import { ListRequest } from '@one/api-models/lib/Campaign/Promotion/Request/ListRequest';
import { ListTargetsRequest } from '@one/api-models/lib/Campaign/Promotion/Request/ListTargetsRequest';
import { RemoveAwardRequest } from '@one/api-models/lib/Campaign/Promotion/Request/RemoveAwardRequest';
import { RemoveQualifierRequest } from '@one/api-models/lib/Campaign/Promotion/Request/RemoveQualifierRequest';
import { RetrieveRequest } from '@one/api-models/lib/Campaign/Promotion/Request/RetrieveRequest';
import { UpdateRequest } from '@one/api-models/lib/Campaign/Promotion/Request/UpdateRequest';
import { AddAwardResponse } from '@one/api-models/lib/Campaign/Promotion/Response/AddAwardResponse';
import { AddQualifierResponse } from '@one/api-models/lib/Campaign/Promotion/Response/AddQualifierResponse';
import { CreateResponse } from '@one/api-models/lib/Campaign/Promotion/Response/CreateResponse';
import { ListResponse } from '@one/api-models/lib/Campaign/Promotion/Response/ListResponse';
import { ListTargetsResponse } from '@one/api-models/lib/Campaign/Promotion/Response/ListTargetsResponse';
import { RemoveAwardResponse } from '@one/api-models/lib/Campaign/Promotion/Response/RemoveAwardResponse';
import { RemoveQualifierResponse } from '@one/api-models/lib/Campaign/Promotion/Response/RemoveQualifierResponse';
import { RetrieveResponse } from '@one/api-models/lib/Campaign/Promotion/Response/RetrieveResponse';
import { UpdateResponse } from '@one/api-models/lib/Campaign/Promotion/Response/UpdateResponse';

import { IApiClient } from './api-client';

export interface IPromotionsAccess {
  load(request: ListRequest): Promise<ListResponse>;
  retrieve(request: RetrieveRequest): Promise<RetrieveResponse>;
  create(request: CreateRequest): Promise<CreateResponse>;
  update(request: UpdateRequest): Promise<UpdateResponse>;
  addQualifier(request: AddQualifierRequest): Promise<AddQualifierResponse>;
  removeQualifier(request: RemoveQualifierRequest): Promise<RemoveQualifierResponse>;
  addAward(request: AddAwardRequest): Promise<AddAwardResponse>;
  removeAward(request: RemoveAwardRequest): Promise<RemoveAwardResponse>;
  listTargets(request: ListTargetsRequest): Promise<ListTargetsResponse>;
}

export class PromotionsAccess implements IPromotionsAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  load(request: ListRequest): Promise<ListResponse> {
    return this.apiClient.call('promotion/list', {
      ...request,
      $Type: ListRequest.$type,
      cultureCode: 'en-US',
    });
  }

  retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
    return this.apiClient.call('promotion/retrieve', {
      ...request,
      $Type: RetrieveRequest.$type,
      cultureCode: 'en-US',
    });
  }

  create(request: CreateRequest): Promise<CreateResponse> {
    return this.apiClient.call('promotion/create', {
      ...request,
      $Type: CreateRequest.$type,
      cultureCode: 'en-US',
    });
  }

  update(request: UpdateRequest): Promise<UpdateResponse> {
    return this.apiClient.call('promotion/update', {
      ...request,
      $Type: UpdateRequest.$type,
      cultureCode: 'en-US',
    });
  }

  addQualifier(request: AddQualifierRequest): Promise<AddQualifierResponse> {
    return this.apiClient.call('promotion/qualifier/add', {
      ...request,
      $Type: AddQualifierRequest.$type,
      cultureCode: 'en-US',
    });
  }

  removeQualifier(request: RemoveQualifierRequest): Promise<RemoveQualifierResponse> {
    return this.apiClient.call('promotion/qualifier/remove', {
      ...request,
      $Type: RemoveQualifierRequest.$type,
      cultureCode: 'en-US',
    });
  }

  addAward(request: AddAwardRequest): Promise<AddAwardResponse> {
    return this.apiClient.call('promotion/award/add', {
      ...request,
      $Type: AddAwardRequest.$type,
      cultureCode: 'en-US',
    });
  }

  removeAward(request: RemoveAwardRequest): Promise<RemoveAwardResponse> {
    return this.apiClient.call('promotion/award/remove', {
      ...request,
      $Type: RemoveAwardRequest.$type,
      cultureCode: 'en-US',
    });
  }

  listTargets(request: ListTargetsRequest): Promise<ListTargetsResponse> {
    return this.apiClient.call('promotion/targets/list', {
      ...request,
      $Type: ListTargetsRequest.$type,
      cultureCode: 'en-US',
    });
  }
}
