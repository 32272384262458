import React, { Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { Button, Menu, MenuItem, SxProps } from '@mui/material';
import { OnBehalfOfMemberRequest } from '@one/api-models/lib/Admin/Members/Request/OnBehalfOfMemberRequest';
import { OnBehalfOfMemberResponse } from '@one/api-models/lib/Admin/Members/Response/OnBehalfOfMemberResponse';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { CertificateProductTypes } from 'models/customers/CertificateProductTypes';
import { OBODestinations } from 'models/customers/OBODestinations';
import { selectActiveBrand } from 'slices/applicationDataSlice';

interface OBOWithCertificatesProps {
  options: string[];
  memberKey: string;
  testId: string;
  serialNumber: string;
  redeemedOn?: Date;
  setIsLoadingObo: Dispatch<SetStateAction<boolean>>;
}

const buttonStyle: SxProps = {
  textTransform: 'none',
  paddingTop: 0,
  paddingBottom: 0,
  borderRadius: '16px',
  lineHeight: '1.4rem',
};

const OBOWithCertificateButton = ({
  options,
  memberKey,
  serialNumber,
  redeemedOn,
  testId,
  setIsLoadingObo,
}: OBOWithCertificatesProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { showMessage, apiErrorHandler } = useToastMessage();
  const activeBrand = useSelector(selectActiveBrand);
  const { api } = useApiHelpers();

  const isOBODropdownOpen = Boolean(anchorEl);

  const handleOBOMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOBODropdownClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onBehalfOfMutation = useMutation<OnBehalfOfMemberResponse, ApiError, OnBehalfOfMemberRequest, unknown>(
    (request) => {
      return api.members.onBehalfOf(request);
    },
    {
      onError: apiErrorHandler,
    },
  );

  const handleObo = async (product: string, serialNumber: string, redeemedOn?: Date) => {
    try {
      setIsLoadingObo(true);
      const response = await onBehalfOfMutation.mutateAsync({
        brandKey: activeBrand?.key ?? '',
        memberKey,
        destinationUrl: redeemedOn != null ? product : `${product}?certificate=${serialNumber}`,
      });
      window.open(response.redirectUrl);
    } catch (e) {
      showMessage('Cannot start session on behalf of customer', 'error');
    } finally {
      setIsLoadingObo(false);
    }
  };

  const getLabel = (option: string) => {
    switch (option) {
      case CertificateProductTypes['Cruise.Ocean']:
        return 'Ocean Cruise';
      case CertificateProductTypes['Cruise.River']:
        return 'River Cruise';
      default:
        return option;
    }
  };

  return options.length > 1 ? (
    <div>
      <Button
        sx={buttonStyle}
        variant="outlined"
        color="primary"
        id="obo-button"
        aria-controls={isOBODropdownOpen ? 'obo-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOBODropdownOpen ? 'true' : undefined}
        onClick={handleOBOMenuClick}
        fullWidth
        data-testid={`${testId}OBOShopSelector`}
      >
        Shop
      </Button>
      <Menu
        id="obo-menu"
        anchorEl={anchorEl}
        open={isOBODropdownOpen}
        onClose={handleOBODropdownClose}
        MenuListProps={{
          'aria-labelledby': 'obo-button',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`${index}${option}`}
            onClick={(event) => {
              event.stopPropagation();

              handleObo(OBODestinations[option as keyof typeof OBODestinations], serialNumber, redeemedOn);
            }}
            data-testid={`${testId}OBOShopSelectorOption`}
          >
            {getLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  ) : (
    <Button
      sx={buttonStyle}
      variant="outlined"
      color="primary"
      onClick={(event) => {
        event.stopPropagation();
        handleObo(OBODestinations[options[0] as keyof typeof OBODestinations], serialNumber, redeemedOn);
      }}
      fullWidth
      data-testid={`${testId}OBOShopButton`}
    >
      Shop
    </Button>
  );
};

export default OBOWithCertificateButton;
