import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ProductContent as MerchandiseProductDetails } from '@one/api-models/lib/Admin/Products/Retail/Merchandise/ProductContent';

import { DetailsTabProps } from './DetailsBase';

export const MerchandiseDetailsTab = ({ product: productBase }: DetailsTabProps) => {
  const product = productBase as MerchandiseProductDetails;

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%' }}>
            <ListItem>
              <ListItemText
                primary="Name"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.name ?? 'N/A'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Display Name"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.displayName ? product.displayName : 'N/A'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Category"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.category?.value ?? 'N/A'}</Typography>}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%' }}>
            <ListItem>
              <ListItemText
                primary="Height"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.itemHeight ? product.itemHeight : 'N/A'}</Typography>}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Length"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.itemLength ? product.itemLength : 'N/A'}</Typography>}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Width"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.itemWidth ? product.itemWidth : 'N/A'}</Typography>}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Weight"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.itemWeight ? product.itemWeight : 'N/A'}</Typography>}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
};
