import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { DepositPlan } from '@one/api-models/lib/Admin/PaymentPlan/DepositPlan';
import { InstallmentPlan } from '@one/api-models/lib/Admin/PaymentPlan/InstallmentPlan';
import { CalculatedDepositPlan } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculatedDepositPlan';
import { CalculatedInstallmentPlan } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculatedInstallmentPlan';
import { CalculateExistingPlanRequest } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculateExistingPlanRequest';
import { CalculatePaymentPlanResponse } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculatePaymentPlanResponse';
import { UpdatePlanRequest } from '@one/api-models/lib/Sales/Payment/PaymentPlan/UpdatePlanRequest';
import { UpdatePlanResponse } from '@one/api-models/lib/Sales/Payment/PaymentPlan/UpdatePlanResponse';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { PAYMENT_AVS_MISMATCH_ERROR } from 'components/views/paymentPlans/constants/CybersourcePayment';
import {
  setCalculatedPaymentPlan,
  setIsLoadingCalculatePaymentPlan,
  setIsLoadingUpdatePaymentPlan,
  setUpdatedPaymentPlan,
} from 'slices/paymentDataSlice';

export const usePaymentPlan = () => {
  const dispatch = useDispatch();
  const { api } = useApiHelpers();
  const { apiErrorHandler, showMessage } = useToastMessage();

  // #region Calculate Payment Plan
  const calculatePaymentPlanMutation = useMutation<
    CalculatePaymentPlanResponse,
    ApiError,
    { request: CalculateExistingPlanRequest }
  >(
    async ({ request }) => {
      return await api.payment.calculatePaymentPlan({
        ...request,
      } as CalculateExistingPlanRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          dispatch(setCalculatedPaymentPlan(value.paymentPlan as CalculatedDepositPlan | CalculatedInstallmentPlan));
        }
      },
      onError: apiErrorHandler,
    },
  );

  const performCalculatePaymentPlan = async (request: CalculateExistingPlanRequest) => {
    await calculatePaymentPlanMutation.mutateAsync({ request });
  };
  // #endregion

  // #region Update Payment Plan
  const updatePaymentPlanMutation = useMutation<UpdatePlanResponse, ApiError, { request: UpdatePlanRequest }>(
    async ({ request }) => {
      const response = await api.payment.updatePaymentPlan({
        ...request,
      } as UpdatePlanRequest);
      return response;
    },
    {
      onSuccess: (value) => {
        showMessage('Payment plan updated successfully', 'success');
        if (value) {
          dispatch(setUpdatedPaymentPlan(value.paymentPlan as DepositPlan | InstallmentPlan));
        }
      },
      onError: (error) => apiErrorHandler(error, undefined, undefined, PAYMENT_AVS_MISMATCH_ERROR),
    },
  );

  const performUpdatePaymentPlan = async (request: UpdatePlanRequest) => {
    await updatePaymentPlanMutation.mutateAsync({ request });
  };
  // #endregion

  useEffect(() => {
    dispatch(setIsLoadingCalculatePaymentPlan(calculatePaymentPlanMutation.isLoading));
  }, [dispatch, calculatePaymentPlanMutation.isLoading]);

  useEffect(() => {
    dispatch(setIsLoadingUpdatePaymentPlan(updatePaymentPlanMutation.isLoading));
  }, [dispatch, updatePaymentPlanMutation.isLoading]);

  return {
    performCalculatePaymentPlan,
    performUpdatePaymentPlan,
  };
};
