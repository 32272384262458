import { forwardRef } from 'react';
import { Control } from 'react-hook-form';
import { Country } from 'react-phone-number-input';
import Input from 'react-phone-number-input/react-hook-form-input';

import { InputLabel, TextField } from 'styled';

import 'react-phone-number-input/style.css';

type ControlledPhoneNumberInputProps = {
  name: string;
  control: Control;
  testId: string;
  onChange?: (value?: string) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  label?: React.ReactNode;
  fullWidth?: boolean;
  country?: string;
  defaultCountry?: string;
  disabled?: boolean;
  isAutoFocused?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  placeholder?: string;
};

const MaterialTextField = forwardRef(({ initialValueFormat, countryCallingCodeEditable, ...props }: any, ref: any) => {
  return (
    <>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <TextField
        {...props}
        value={props.value}
        onChange={props.onChange}
        inputRef={ref}
        error={props.error}
        helperText={props.helperText}
        label={undefined}
        placeholder={props.placeholder}
        inputProps={{ maxLength: 20 }}
        fullWidth={props.fullWidth}
        disabled={props.disabled || false}
        autoFocus={props.isAutoFocused || false}
        variant={props.variant || 'outlined'}
        size={props.size || 'small'}
      />
    </>
  );
});

export const ControlledPhoneNumberInput = (props: ControlledPhoneNumberInputProps) => {
  const handleOnChange = (value: string) => {
    if (props.onChange) props.onChange(value);
  };

  return (
    <Input
      {...props}
      inputComponent={MaterialTextField as any}
      control={props.control}
      country={props?.country as Country}
      defaultCountry={props.defaultCountry as Country}
      countryCallingCodeEditable={false}
      international={false}
      initialValueFormat="national"
      onChange={handleOnChange}
      data-testid={`${props.testId}PhoneNumberInput`}
    />
  );
};
