import { CustomerCriteria } from '@one/api-models/lib/Admin/PaymentPlan/CustomerCriteria';
import { DetailsCriteria } from '@one/api-models/lib/Admin/PaymentPlan/DetailsCriteria';
import { DetailsResponse } from '@one/api-models/lib/Admin/PaymentPlan/DetailsResponse';
import { FilterResponse } from '@one/api-models/lib/Admin/PaymentPlan/FilterResponse';
import { CompleteAddPaymentMethodRequest } from '@one/api-models/lib/Sales/Payment/PaymentMethod/CompleteAddPaymentMethodRequest';
import { CompleteAddPaymentMethodResponse } from '@one/api-models/lib/Sales/Payment/PaymentMethod/CompleteAddPaymentMethodResponse';
import { InitAddPaymentMethodRequest } from '@one/api-models/lib/Sales/Payment/PaymentMethod/InitAddPaymentMethodRequest';
import { InitAddPaymentMethodResponse } from '@one/api-models/lib/Sales/Payment/PaymentMethod/InitAddPaymentMethodResponse';
import { ListPaymentMethodsCriteria } from '@one/api-models/lib/Sales/Payment/PaymentMethod/ListPaymentMethodsCriteria';
import { ListPaymentMethodsResponse } from '@one/api-models/lib/Sales/Payment/PaymentMethod/ListPaymentMethodsResponse';
import { CalculateExistingPlanRequest } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculateExistingPlanRequest';
import { CalculatePaymentPlanResponse } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculatePaymentPlanResponse';
import { UpdatePlanRequest } from '@one/api-models/lib/Sales/Payment/PaymentPlan/UpdatePlanRequest';
import { UpdatePlanResponse } from '@one/api-models/lib/Sales/Payment/PaymentPlan/UpdatePlanResponse';
import { SetupPaymentRequestBase } from '@one/api-models/lib/Sales/Payment/SetupPaymentRequestBase';
import { SetupPaymentResponse } from '@one/api-models/lib/Sales/Payment/SetupPaymentResponse';

import { ApiType, IApiClient } from 'apiAccess/api-client';

export interface IPaymentAccess {
  paymentSetup(request: SetupPaymentRequestBase): Promise<SetupPaymentResponse>;
  retrievePaymentPlanList(criteria: CustomerCriteria): Promise<FilterResponse>;
  retrievePaymentPlanDetails(criteria: DetailsCriteria): Promise<DetailsResponse>;
  calculatePaymentPlan(request: CalculateExistingPlanRequest): Promise<CalculatePaymentPlanResponse>;
  updatePaymentPlan(request: UpdatePlanRequest): Promise<UpdatePlanResponse>;
  retrievePaymentMethodList(criteria: ListPaymentMethodsCriteria): Promise<ListPaymentMethodsResponse>;
  initCreatePaymentMethod(request: InitAddPaymentMethodRequest): Promise<InitAddPaymentMethodResponse>;
  completeCreatePaymentMethod(request: CompleteAddPaymentMethodRequest): Promise<CompleteAddPaymentMethodResponse>;
}

export class PaymentAccess implements IPaymentAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  paymentSetup(request: SetupPaymentRequestBase): Promise<SetupPaymentResponse> {
    return this.apiClient.call('sales/payment/setup', request, ApiType.Sales);
  }

  retrievePaymentPlanList(criteria: CustomerCriteria): Promise<FilterResponse> {
    return this.apiClient.call('sales/payment-plans/list', {
      ...criteria,
      $type: CustomerCriteria.$type,
      cultureCode: 'en-US',
      statuses: [],
    } as CustomerCriteria);
  }

  retrievePaymentPlanDetails(criteria: DetailsCriteria): Promise<DetailsResponse> {
    return this.apiClient.call('sales/payment-plans/retrieve', {
      ...criteria,
      $type: DetailsCriteria.$type,
      cultureCode: 'en-US',
    } as DetailsCriteria);
  }

  calculatePaymentPlan(request: CalculateExistingPlanRequest): Promise<CalculatePaymentPlanResponse> {
    return this.apiClient.call(
      'sales/payment-plans/calculate',
      {
        ...request,
        $type: CalculateExistingPlanRequest.$type,
        cultureCode: 'en-US',
      } as CalculateExistingPlanRequest,
      ApiType.Sales,
    );
  }

  updatePaymentPlan(request: UpdatePlanRequest): Promise<UpdatePlanResponse> {
    return this.apiClient.call(
      'sales/payment-plans/update',
      {
        ...request,
        $type: UpdatePlanRequest.$type,
        cultureCode: 'en-US',
      } as UpdatePlanRequest,
      ApiType.Sales,
    );
  }

  retrievePaymentMethodList(criteria: ListPaymentMethodsCriteria): Promise<ListPaymentMethodsResponse> {
    return this.apiClient.call(
      'sales/payment-methods/list',
      {
        ...criteria,
        $type: ListPaymentMethodsCriteria.$type,
        cultureCode: 'en-US',
      } as ListPaymentMethodsCriteria,
      ApiType.Sales,
    );
  }

  initCreatePaymentMethod(request: InitAddPaymentMethodRequest): Promise<InitAddPaymentMethodResponse> {
    return this.apiClient.call(
      'sales/payment-methods/add',
      {
        ...request,
        $type: InitAddPaymentMethodRequest.$type,
        cultureCode: 'en-US',
      } as InitAddPaymentMethodRequest,
      ApiType.Sales,
    );
  }

  completeCreatePaymentMethod(request: CompleteAddPaymentMethodRequest): Promise<CompleteAddPaymentMethodResponse> {
    return this.apiClient.call(
      'sales/payment-methods/add',
      {
        ...request,
        $Type: CompleteAddPaymentMethodRequest.$type,
        cultureCode: 'en-US',
      } as CompleteAddPaymentMethodRequest,
      ApiType.Sales,
    );
  }
}
