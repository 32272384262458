import { Color } from '@mui/material';
import { blue, deepOrange, deepPurple, green, teal } from '@mui/material/colors';

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatarColors(colors: Color[], exactColor?: Color) {
  const selectedColor: Color = exactColor || colors[Math.floor(Math.random() * colors.length)];
  return {
    backgroundColor: selectedColor[100],
    color: selectedColor[500],
  };
}

export const availableAvatarColors: Color[] = [deepOrange, deepPurple, blue, teal, green];

export function stringAvatar(name: string) {
  return {
    sx: {
      ...stringAvatarColors(availableAvatarColors),
    },
    children: name.substring(0, 1),
  };
}

export function colorByInitial(name: string) {
  const initialChar = name.substring(0, 1).toUpperCase().charCodeAt(0) - 65;

  let colorIndex = 0;
  if (initialChar > 0 && initialChar <= 26) {
    colorIndex = Math.floor((initialChar / 26) * availableAvatarColors.length);
  }

  return {
    backgroundColor: availableAvatarColors[colorIndex][100],
    color: availableAvatarColors[colorIndex][500],
  };
}
