import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import applicationDataReducer from 'slices/applicationDataSlice';
import customersDataReducer from 'slices/customersDataSlice';
import paymentDataReducer from 'slices/paymentDataSlice';
import salesOrderDataSliceReducer from 'slices/salesOrderDataSlice';
import salesOrderListDataSlice from 'slices/salesOrderListDataSlice';
import tagsDataReducer from 'slices/tagsDataSlice';
import transactionsDataSlice from 'slices/transactionsDataSlice';
import userDataReducer from 'slices/userDataSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['applicationData'],
};

const applicationDataConfig = {
  key: 'applicationData',
  version: 1,
  storage,
  blacklist: ['activeAgency'],
};

const applicationDataPersistReducer = persistReducer(applicationDataConfig, applicationDataReducer);

const localStoredReducer = combineReducers({
  user: userDataReducer,
  applicationData: applicationDataPersistReducer,
});

const persistedReducer = persistReducer(persistConfig, localStoredReducer);

const rootReducer = combineReducers({
  localData: persistedReducer,
  customersData: customersDataReducer,
  paymentData: paymentDataReducer,
  salesOrderData: salesOrderDataSliceReducer,
  tagsData: tagsDataReducer,
  transactionsData: transactionsDataSlice,
  salesOrderListData: salesOrderListDataSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
