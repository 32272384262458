import { AwardType } from '@one/api-models/lib/Campaign/Promotion/AwardType';
import { PromotionType } from '@one/api-models/lib/Campaign/Promotion/PromotionType';
import { QualifierType } from '@one/api-models/lib/Campaign/Promotion/QualifierType';
import { TargetType } from '@one/api-models/lib/Campaign/Promotion/TargetType';

export const AudienceQualifiersTypes = [QualifierType.LeadSource, QualifierType.CustomerGroup, QualifierType.Referral];

export const PromotionTypeValues = Object.keys(PromotionType)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({ id: PromotionType[key as any] as any as PromotionType, label: key.split(/(?=[A-Z])/).join(' ') }));

export const QualifierTypeValues = Object.keys(QualifierType)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({ id: QualifierType[key as any] as any as QualifierType, label: key.split(/(?=[A-Z])/).join(' ') }));

export const AwardTypeValues = Object.keys(AwardType)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({ id: AwardType[key as any] as any as AwardType, label: key.split(/(?=[A-Z])/).join(' ') }));

export const AwardTargetTypeValues = Object.keys(TargetType)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({ id: TargetType[key as any] as any as TargetType, label: key.split(/(?=[A-Z])/).join(' ') }));
