import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

import { Add as AddIcon, Star as StarIcon } from '@mui/icons-material';
import { Box, Button, Divider, Paper } from '@mui/material';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';
import { SummaryLoadResponse } from '@one/api-models/lib/Membership/Account/SummaryLoadResponse';

import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { setSelectedCustomer } from 'slices/customersDataSlice';

import { SalesSection } from './components/profile/SalesSection';
import { ServiceMenu } from './components/profile/ServiceMenu';
import { CustomerActionsSkeleton } from './components/profile/skeletons/CustomerActionsSkeleton';
import { profileCtaStyle } from './components/profile/styles';

interface CustomerActionsProps {
  memberId: string;
  memberLoadData?: SummaryLoadResponse;
  isFetchingMemberLoadData: boolean;
  testId: string;
}

export const CustomerActions = (props: CustomerActionsProps) => {
  const { memberLoadData, isFetchingMemberLoadData, memberId, testId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasPermission } = useAppHelpers();

  const hasSellAProgramPermission = hasPermission(['Sales.ProgramSales:Manage']);
  const hasBookOnBehalfOfPermission = hasPermission(['Customers:OnBehalfOf']);
  const hasCustomerAccountPermission = hasPermission(['Customers:Manage']);

  const isExternalUser =
    memberLoadData?.accountSummary?.profileSummary?.holderAccountPartnerLoyaltyId ||
    memberLoadData?.accountSummary?.profileSummary?.holderAccountExternalMemberKey;

  const isInactiveUser =
    memberLoadData?.accountSummary?.memberStatus === MemberStatus.Inactive ||
    memberLoadData?.accountSummary?.memberStatus === MemberStatus.Suspended;

  const goToChooseProgram = () => {
    navigate({
      pathname: '/sales/new',
      search: stringify({
        memberKey: memberId,
      }),
    });
    dispatch(setSelectedCustomer(undefined));
  };

  return (
    <Paper
      elevation={2}
      sx={{
        py: 3,
        px: { xs: 3, sm: 10, md: 3 },
        width: '100%',
        minWidth: '250px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {!memberLoadData || isFetchingMemberLoadData ? (
        <CustomerActionsSkeleton />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {!hasSellAProgramPermission ? null : (
            <>
              <Button
                variant="text"
                startIcon={<StarIcon htmlColor="#ffd54f" />}
                onClick={() => goToChooseProgram()}
                sx={{ ...profileCtaStyle }}
                data-testid={`${testId}SellAProgramButton`}
              >
                Sell a program
              </Button>
              <Button
                variant="text"
                startIcon={<AddIcon htmlColor="black" />}
                onClick={() => goToChooseProgram()}
                sx={{ ...profileCtaStyle }}
                data-testid={`${testId}IncentiveButton`}
              >
                Incentive
              </Button>
            </>
          )}
          {isInactiveUser || !hasBookOnBehalfOfPermission ? null : (
            <>
              <Divider
                sx={{
                  my: 1,
                  width: '100%',
                  display: !hasSellAProgramPermission ? { xs: 'block', sm: 'none', lg: 'block' } : { xs: 'block' },
                }}
              />
              <SalesSection
                memberKey={memberId}
                productTypes={memberLoadData.accountSummary.accountSummaryProperty.productTypes}
                testId={testId}
              />
            </>
          )}
          {!hasCustomerAccountPermission ? null : (
            <>
              <Divider
                sx={{
                  my: 1,
                  width: '100%',
                  display:
                    !hasSellAProgramPermission && (isInactiveUser || !hasBookOnBehalfOfPermission)
                      ? { xs: 'block', sm: 'none', lg: 'block' }
                      : { xs: 'block' },
                }}
              />
              <ServiceMenu
                memberKey={memberId}
                memberStatus={memberLoadData.accountSummary.memberStatus}
                allowSendActivation={memberLoadData.accountSummary.allowSendActivation}
                allowResetPassword={!isExternalUser}
                testId={testId}
              />
            </>
          )}
        </Box>
      )}
    </Paper>
  );
};
