import { useEffect, useMemo, useRef, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, IconButton, InputAdornment, useTheme } from '@mui/material';
import { Money } from '@one/api-models/lib/Money';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';

import ControlledTextField from 'common/ControlledTextField';
import { AddPaymentMethodDialog } from 'components/_common/paymentPlan/AddPaymentMethodDialog';
import { useRetrievePaymentMethodList } from 'components/hooks/paymentHooks';
import { useFormat } from 'components/hooks/useFormat';
import { PaymentProviderList } from 'models/PaymentProvider';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SectionTitle,
} from 'styled';

import { CardSelector } from './components/CardSelector';
import { PaymentMethodOption } from './components/EditPaymentPlanDialog';

interface TakePaymentDialogProps {
  memberId: string;
  //  paymentMethodId: number;
  balance: Money;
  open: boolean;
  handleClose: () => void;
  testIdPrefix: string;
}
interface TakePaymentForm {
  amount: number;
  description?: string;
  notes?: string;
  paymentMethodReference?: string;
}

export const TakePaymentDialog = ({ memberId, balance, open, handleClose, testIdPrefix }: TakePaymentDialogProps) => {
  const theme = useTheme();
  const fromRef = useRef<HTMLFormElement>(null);
  const minPaymentAmount = 1;
  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };
  const [additionalPaymentMethodListOptions, setAdditionalPaymentMethodListOptions] = useState<PaymentMethodOption[]>(
    [],
  );
  const [selectedPaymentMethodReference, setSelectedPaymentMethodReference] = useState<PaymentMethodOption | undefined>(
    undefined,
  );
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);

  const validationSchema: yup.SchemaOf<TakePaymentForm> = yup.object().shape({
    amount: yup
      .number()
      .required('Amount is required.')
      .min(minPaymentAmount, `Minimum amount is ${formatCurrency(minPaymentAmount, balance?.currency)}.`)
      .max(balance?.amount, `Max amount is ${formatCurrency(balance?.amount, balance?.currency)}.`),
    paymentMethodReference: yup.string().required('Payment method is required.'),
    description: yup.string().trim(),
    notes: yup.string().trim(),
  });

  const defaultValues: TakePaymentForm = {
    amount: 0,
    description: '',
    notes: '',
  };
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TakePaymentForm>({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });

  // const { isFetching: isFetchingPaymentPlanDetailsData, data: paymentPlanDetailsData } =
  // useRetrievePaymentPlanDetails(paymentPlanId);

  const {
    isFetching: isFetchingAdditionalPaymentMethodList,
    data: additionalPaymentMethodListData,
    refetch: refetchAdditionalPaymentMethodList,
  } = useRetrievePaymentMethodList(memberId, true);

  useEffect(() => {
    if (additionalPaymentMethodListData) {
      const additionalFormattedPaymentMethods = additionalPaymentMethodListData?.paymentMethods?.map(
        (i: PaymentMethod) => ({
          code: i.paymentMethodId.toString(),
          label: `${mapGatewayIdentifierLabel(i.gatewayIdentifier)} \n **** **** **** ${i.description}`,
          isDefaultPaymentMethod: false, // TODO: check if this is correct
          expirationMonth: i.expirationMonth,
          expirationYear: i.expirationYear,
        }),
      );

      setAdditionalPaymentMethodListOptions(additionalFormattedPaymentMethods);
      const defaultPaymentMethod = additionalFormattedPaymentMethods[0];
      // const defaultPaymentMethod = additionalFormattedPaymentMethods.find(
      //   (i: PaymentMethodOption) => Number(i.code) === paymentMethodId,
      // );

      if (defaultPaymentMethod) {
        setValue('paymentMethodReference', defaultPaymentMethod?.code);
        setSelectedPaymentMethodReference(defaultPaymentMethod);
      }
    }
  }, [additionalPaymentMethodListData, setValue]);

  const mapGatewayIdentifierLabel = (gatewayIdentifier: string) => {
    const provider = PaymentProviderList.find((provider) => gatewayIdentifier.includes(provider));
    return provider ? provider : 'Unknown';
  };

  const refetchPaymentMethodList = () => {
    refetchAdditionalPaymentMethodList();
  };

  const handleChangePaymentMethod = (paymentMethodReference: string | undefined) => {
    const selectedPaymentMethod = additionalPaymentMethodListOptions.find(
      (i: PaymentMethodOption) => i.code === paymentMethodReference,
    );
    if (selectedPaymentMethod) {
      setSelectedPaymentMethodReference(selectedPaymentMethod);
    }
  };

  const handleTakePayment = (data: TakePaymentForm) => {
    console.log(data);
  };

  const mapCurrency = () => {
    switch (balance?.currency) {
      case 'USD':
        return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
      case 'GBP':
        return <CurrencyPoundIcon sx={{ fontSize: '1rem' }} />;
      default:
        return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '496px',
        },
      }}
    >
      <DialogTitle>
        Take a Payment
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testIdPrefix}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(handleTakePayment)} ref={fromRef}>
          <Grid container rowSpacing={3} columnSpacing={1}>
            <Grid item xs={12} md={4}>
              <ControlledTextField
                name="amount"
                control={control as unknown as Control<FieldValues, object>}
                fullWidth
                label="Amount"
                type="number"
                error={errors.amount?.message != null}
                helperText={errors.amount?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{mapCurrency()}</InputAdornment>,
                  inputProps: {
                    step: 0.01,
                  },
                }}
                testId={`${testIdPrefix}Amount`}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="description"
                fullWidth
                label="Description"
                description="Optional. This will appear on the Customer Receipt"
                error={errors?.description?.message != null}
                helperText={errors?.description?.message}
                testId={`${testIdPrefix}Description`}
              />
            </Grid>
            <Grid item xs={12}>
              <CardSelector
                isLoading={isFetchingAdditionalPaymentMethodList}
                control={control as unknown as Control<FieldValues, object>}
                name="paymentMethodReference"
                label="Payment Method"
                options={additionalPaymentMethodListOptions}
                error={errors.paymentMethodReference != null}
                helperText={errors.paymentMethodReference?.message}
                requiredMessage="Payment method is required"
                disableClearable
                popperWidth="300px"
                onChange={(value) => {
                  handleChangePaymentMethod(value);
                }}
                // getOptionDisabled={(option) => {
                //   const currentDate = new Date();
                //   return (
                //     option.expirationYear < currentDate.getFullYear() ||
                //     (option.expirationYear === currentDate.getFullYear() &&
                //       option.expirationMonth < currentDate.getMonth())
                //   );
                // }}
                testId={`${testIdPrefix}PaymentMethod`}
              />
              <Button
                onClick={() => setAddPaymentModalOpen(true)}
                variant="text"
                startIcon={<AddIcon />}
                data-testid={`${testIdPrefix}AddPaymentMethodButton`}
                sx={{ mt: 0.5 }}
              >
                Add New
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />} sx={{ px: 0 }}>
                  <SectionTitle>Notes</SectionTitle>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                  <ControlledTextField
                    control={control as unknown as Control<FieldValues, object>}
                    name="notes"
                    fullWidth
                    multiline
                    minRows="5"
                    label="Payment Notes"
                    error={errors?.notes?.message != null}
                    helperText={errors?.notes?.message}
                    testId={`${testIdPrefix}Notes`}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose} data-testid={`${testIdPrefix}CancelButton`}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            fromRef?.current?.requestSubmit();
          }}
          data-testid={`${testIdPrefix}SubmitButton`}
        >
          Submit Payment
        </Button>
      </DialogActions>

      {addPaymentModalOpen && (
        <AddPaymentMethodDialog
          memberId={memberId}
          open={addPaymentModalOpen}
          refetchPaymentMethodList={refetchPaymentMethodList}
          setAddPaymentModalOpen={setAddPaymentModalOpen}
          onClose={() => setAddPaymentModalOpen(false)}
          testId={`${testIdPrefix}Dialog`}
        />
      )}
    </Dialog>
  );
};
