import { EditRequest } from '@one/api-models/lib/Admin/Tags/EditRequest';
import { EditResponse } from '@one/api-models/lib/Admin/Tags/EditResponse';
import { LoadCriteria } from '@one/api-models/lib/Admin/Tags/LoadCriteria';
import { LoadProductsCriteria } from '@one/api-models/lib/Admin/Tags/LoadProductsCriteria';
import { LoadProductsResponse } from '@one/api-models/lib/Admin/Tags/LoadProductsResponse';
import { LoadResponse } from '@one/api-models/lib/Admin/Tags/LoadResponse';
import { LoadSubtreeCriteria } from '@one/api-models/lib/Admin/Tags/LoadSubtreeCriteria';
import { LoadSubtreeResponse } from '@one/api-models/lib/Admin/Tags/LoadSubtreeResponse';

import { IApiClient } from 'apiAccess/api-client';

export interface ITagsAccess {
  load(criteria: LoadCriteria): Promise<LoadResponse>;
  loadSubtree(criteria: LoadSubtreeCriteria): Promise<LoadSubtreeResponse>;
  loadProducts(criteria: LoadProductsCriteria): Promise<LoadProductsResponse>;
  edit(request: EditRequest): PromiseLike<EditResponse>;
}

export class TagsAccess implements ITagsAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  load(criteria: LoadCriteria): Promise<LoadResponse> {
    return this.apiClient.call('admin/tags/load', criteria);
  }

  loadSubtree(criteria: LoadSubtreeCriteria): Promise<LoadSubtreeResponse> {
    return this.apiClient.call('admin/tags/loadSubtree', criteria);
  }

  loadProducts(criteria: LoadProductsCriteria): Promise<LoadProductsResponse> {
    return this.apiClient.call('admin/tags/loadProducts', criteria);
  }

  edit(request: EditRequest): PromiseLike<EditResponse> {
    return this.apiClient.call('admin/tags/edit', request);
  }
}
