import { ApplicationContext } from '@one/api-models/lib/Admin/Common/ApplicationContext';

import { IApiClient } from 'apiAccess/api-client';

export interface IAdminAccess {
  contextLoad(criteria: any): Promise<ApplicationContext>;
}

export class AdminAccess implements IAdminAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  contextLoad(criteria: any): Promise<ApplicationContext> {
    return this.apiClient.call('context', criteria);
  }
}
