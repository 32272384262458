import { Box, Grid, Skeleton } from '@mui/material';

import { SectionTitle } from 'components/_common/SectionTitle';

export const CustomerInfoSkeleton = () => {
  return (
    <Box>
      <SectionTitle title="Customer Information" />
      <Grid container spacing={2} sx={{ mb: 4 }} className="customer-info">
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width={80} /> <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width={80} /> <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width={80} /> <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width={80} /> <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width={80} /> <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width={80} /> <Skeleton variant="text" width={120} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width={80} /> <Skeleton variant="text" width={100} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width={80} /> <Skeleton variant="text" width={120} />
        </Grid>
      </Grid>
    </Box>
  );
};
