import { AddImage } from '@one/api-models/lib/Admin/Media/AddImage';
import { AddMediaBase } from '@one/api-models/lib/Admin/Media/AddMediaBase';

import { FileDetails } from '../fileManager/FileDetails';
import { getFileContent } from '../fileManager/FileHelper';

export interface MediaConfigurationItem {
  typeName: string;
  isOfThisType: (mediaType: string) => boolean;
  fileDetailsToAddMediaRequest: (fileDetails: FileDetails) => Promise<AddMediaBase>;
}

const mediaConfigs: MediaConfigurationItem[] = [
  {
    typeName: 'image',
    isOfThisType: (mediaType: string) => {
      return mediaType.startsWith('image/');
    },

    fileDetailsToAddMediaRequest: async (fileDetails: FileDetails): Promise<AddMediaBase> =>
      new Promise(function (resolve, reject) {
        Promise.all([getImageDimensions(fileDetails.file), getFileContent(fileDetails.file)])
          .then(function ([fileDetailsResult, fileContentResult]) {
            resolve({
              $type: AddImage.$type,
              size: fileDetails.size,
              width: fileDetailsResult.width,
              height: fileDetailsResult.height,
              contentType: fileDetails.type,
              content: fileContentResult.content,
            } as AddImage);
          })
          .catch(function (err) {
            reject(err);
          });
      }),
  },
];

const getImageDimensions = (fileBlob: any) =>
  new Promise<{ width: number; height: number }>((resolve, reject) => {
    try {
      const image = new Image();
      image.addEventListener('load', (evt) => {
        resolve({ width: image.width, height: image.height });
      });
      image.src = URL.createObjectURL(fileBlob);
    } catch (e) {
      reject(e);
    }
  });

const getMediaConfiguration = (mediaType: string): MediaConfigurationItem | undefined => {
  return mediaConfigs.find((x) => x.isOfThisType(mediaType));
};

const fileDetailsToAddMediaRequest = (fileDetails: FileDetails): Promise<AddMediaBase> | undefined => {
  const config = mediaConfigs.find((x) => x.isOfThisType(fileDetails.type));
  if (config) {
    return config.fileDetailsToAddMediaRequest(fileDetails);
  }
  return undefined;
};

export const MediaConfiguration = {
  getMediaConfiguration,
  fileDetailsToAddMediaRequest,
};
