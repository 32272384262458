import { Box, Typography, useTheme } from '@mui/material';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';

import { useFormat } from 'components/hooks/useFormat';

interface MainOrderDetailsProps {
  order?: Order;
}
export const MainOrderDetails = ({ order }: MainOrderDetailsProps) => {
  const theme = useTheme();
  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  return (
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={{ xs: 1, sm: 4 }}>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
          Order Amount
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: theme.customPalette.text.primary, fontWeight: theme.typography.fontWeightRegular }}
        >
          {formatCurrency(order?.orderTotalPrice?.amount, order?.orderTotalPrice?.currency)}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
          Total Paid
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: theme.customPalette.text.primary, fontWeight: theme.typography.fontWeightRegular }}
        >
          {formatCurrency(order?.amountPaid?.amount, order?.amountPaid?.currency)}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
          Balance
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: theme.customPalette.text.primary, fontWeight: theme.typography.fontWeightRegular }}
        >
          {order?.balance ? formatCurrency(order?.balance?.amount, order?.balance?.currency) : '-'}
        </Typography>
      </Box>
    </Box>
  );
};
