import React from 'react';

import { Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

export const SideModalContentWrapper = ({ children }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        maxHeight: 'inherit',
        overflow: 'auto',
        '& form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      }}
    >
      {children}
    </Box>
  );
};
