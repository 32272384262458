import { GetContactRequest } from '@one/api-models/lib/Admin/Proxy/Princess/GetContactRequest';
import { GetContactResponse } from '@one/api-models/lib/Admin/Proxy/Princess/GetContactResponse';
import { GetContactsRequest } from '@one/api-models/lib/Admin/Proxy/Princess/GetContactsRequest';
import { GetContactsResponse } from '@one/api-models/lib/Admin/Proxy/Princess/GetContactsResponse';

import { IApiClient } from 'apiAccess/api-client';

export interface ISingleIdentityPrincessAccess {
  loadGuests(request: GetContactsRequest): Promise<GetContactsResponse>;
  loadGuest(ccn: string): Promise<GetContactResponse>;
}

export class SingleIdentityPrincessAccess implements ISingleIdentityPrincessAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  loadGuests(criteria: GetContactsRequest): Promise<GetContactsResponse> {
    return this.apiClient.call('princess/contacts', {
      ...criteria,
      $Type: GetContactsRequest.$type,
    } as GetContactsRequest);
  }

  loadGuest(ccn: string): Promise<GetContactResponse> {
    return this.apiClient.call('princess/contact', {
      ccn,
      $Type: GetContactRequest.$type,
    } as GetContactRequest);
  }
}
