import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ShipContent } from '@one/api-models/lib/Admin/Products/Travel/Cruise/ShipContent';
import { UpdateShip } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateShip';
import { UpdateShipDetails } from '@one/api-models/lib/Admin/Products/Travel/Cruise/UpdateShipDetails';

import { ActionButton } from 'components/_common/ActionButton';

import { FieldBox } from '../common/FieldBox';
import { ProductBaseFields } from '../common/ProductBaseFields';

import { DetailsTabProps, FormProps } from './DetailsBase';

type ShipDetailsForm = {
  name?: string;
  displayName?: string;
  supplierReferences?: { reference?: string }[];
};

const validationSchema: yup.SchemaOf<ShipDetailsForm> = yup.object().shape({
  name: yup.string().trim().matches(/.{3,}/, {
    excludeEmptyString: true,
    message: 'Use at least 3 characters.',
  }),
  displayName: yup.string().trim().matches(/.{3,}/, {
    excludeEmptyString: true,
    message: 'Use at least 3 characters.',
  }),
  supplierReferences: yup.array().of(
    yup.object().shape({
      reference: yup.string().trim(),
    }),
  ),
});

export const ShipDetailsTab = ({
  product: productBase,
  onSave,
  isLoading,
  onFormDirty,
  suppliers,
  testId,
}: DetailsTabProps): React.ReactNode => {
  const product = productBase as ShipContent;

  const defaultValues = (product: ShipContent) => ({
    name: product.name,
    displayName: product.displayName,
    supplierReferences: product.supplierReferences,
  });

  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<UpdateShipDetails>({
    mode: 'onBlur',
    defaultValues: defaultValues(product),
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    onFormDirty(isDirty);
  }, [onFormDirty, isDirty]);

  useEffect(() => {
    reset(defaultValues(product));
  }, [reset, product]);

  const saveHandler = (data: UpdateShipDetails) => {
    const details = data as any;
    delete details.$type;
    if (onSave) {
      const req = {
        $type: UpdateShip.$Type,
        productId: product.id,
        cultureCode: product.cultureCode,
        details: {
          $type: UpdateShipDetails.$Type,
          ...details,
        },
      } as UpdateShip;

      onSave(req);
    }
  };

  const formProps = { register, errors, control, getValues } as unknown as FormProps;

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <form onSubmit={handleSubmit(saveHandler)} autoComplete="off">
        <Grid container direction="row" alignItems="stretch">
          <Grid item xs={12} sx={{}}>
            <ProductBaseFields formProps={formProps} suppliers={suppliers} isLoading={isLoading} testId={testId} />
          </Grid>

          <Grid item xs={12} sx={{ pt: 2 }}>
            <FieldBox>
              <List sx={{ width: '100%' }}>
                <ListItem>
                  <ListItemText
                    primary="Cruise Line"
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondary={
                      <Typography variant="body1">
                        {product?.cruiseLineName ? product.cruiseLineName : 'N/A'}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </FieldBox>
          </Grid>

          <Grid
            item
            sx={{ pr: 3, pt: 6 }}
            xs={12}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Grid item>
              <ActionButton icon="edit" loading={isLoading} type="submit" disabled={!isDirty} testId={`${testId}Save`}>
                SAVE
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
