import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/CybersourceGatewayConfiguration';

import { useCybersourceCreatePayment } from 'components/hooks/useCybersourceCreatePayment';
import { CybersourceForm, CybersourceFormRefHandle } from 'components/views/paymentPlans/components/CybersourceForm';
import { PaymentBackdropLoading } from 'components/views/paymentPlans/components/PaymentBackdropLoading';
import { PaymentSkeleton } from 'components/views/paymentPlans/components/PaymentSkeleton';
import { selectCreatePaymentMethodError, selectIsLoadingCreatePaymentMethod } from 'slices/paymentDataSlice';

interface AddPaymentMethodComponentProps {
  testId: string;
  memberId: string;
  paymentGatewayConfiguration: CybersourceGatewayConfiguration;
  paymentGatewayIdentifier: string;
  onClose: () => void;
  isLoadingConfiguration: boolean;
  refetchConfiguration: () => void;
  refetchPaymentMethodList: () => void;
  resetCreatePaymentMethodError: () => void;
  setAddPaymentModalOpen: (value: boolean) => void;
}

export const AddPaymentMethodCybersourceComponent = (props: AddPaymentMethodComponentProps) => {
  const {
    testId,
    memberId,
    onClose,
    paymentGatewayConfiguration,
    refetchConfiguration,
    resetCreatePaymentMethodError,
    isLoadingConfiguration,
  } = props;

  const { performCreatePaymentMethod } = useCybersourceCreatePayment();
  const createPaymentMethodError = useSelector(selectCreatePaymentMethodError);
  const isLoadingCreatePaymentMethod = useSelector(selectIsLoadingCreatePaymentMethod);

  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const cybersourceFormRef = useRef<CybersourceFormRefHandle>(null);

  useEffect(() => {
    if (!isLoadingCreatePaymentMethod) {
      setIsSubmittingPayment(false);
    }
  }, [isLoadingCreatePaymentMethod]);

  const createPaymentMethodFormSubmitHandler = async (token: string, paymentMethodExpiration: Date) => {
    resetCreatePaymentMethodError();
    handleSubmitAddPaymentMethod(token, paymentMethodExpiration, false, memberId);
  };

  const handleSubmitAddPaymentMethod = async (
    token: string,
    paymentMethodExpiration: Date,
    isDefault: boolean,
    memberKey: string | undefined,
  ) => {
    await performCreatePaymentMethod(
      token,
      memberKey,
      paymentMethodExpiration,
      isDefault,
      paymentGatewayConfiguration.gatewayIdentifier,
      props.refetchPaymentMethodList,
      props.setAddPaymentModalOpen,
    );
  };

  const updatePaymentData = () => {
    setIsSubmittingPayment(false);
    resetCreatePaymentMethodError();
    refetchConfiguration();
  };

  const closeModal = () => {
    resetCreatePaymentMethodError();
    onClose();
  };

  const submitForm = () => {
    if (cybersourceFormRef.current) {
      cybersourceFormRef.current.handleFormSubmit();
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid item xs={12}>
        {!isLoadingCreatePaymentMethod || isSubmittingPayment ? (
          <>
            <DialogContent>
              <CybersourceForm
                ref={cybersourceFormRef}
                paymentGatewayConfiguration={paymentGatewayConfiguration}
                updatePaymentData={updatePaymentData}
                submitPayment={createPaymentMethodFormSubmitHandler}
                setIsSubmittingPayment={setIsSubmittingPayment}
                isSubmittingPayment={isSubmittingPayment}
                submitForm={submitForm}
                error={createPaymentMethodError}
                testId={testId}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                size="small"
                disableRipple
                onClick={closeModal}
                disabled={isLoadingCreatePaymentMethod}
                data-testid={`${testId}CancelButton`}
              >
                Cancel
              </Button>
              <LoadingButton
                disabled={isLoadingCreatePaymentMethod}
                disableRipple
                variant="contained"
                type="submit"
                onClick={submitForm}
                size="small"
                loading={isLoadingCreatePaymentMethod}
                data-testid={`${testId}SaveButton`}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>
        ) : (
          <PaymentSkeleton />
        )}
      </Grid>

      <PaymentBackdropLoading
        isCompleteWithNoPayment={false}
        isLoading={isLoadingCreatePaymentMethod}
        isLoadingConfiguration={isLoadingConfiguration}
        isSubmittingPayment={isSubmittingPayment}
        style={{ position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
      />
    </Box>
  );
};
