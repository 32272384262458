import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import { PromoCode } from '@one/api-models/lib/Campaign/PromoCode/PromoCode';
import { PromoCodeDefinition } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinition';
import { PromoCodeDefinitionType } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinitionType';

import { useFormat } from 'components/hooks/useFormat';

import { GeneratePromoCodeDialog } from './GeneratePromoCodeDialog';
import { PromoCodeDefinitionStatus } from './PromoCodeDefinitionStatus';
import { promoCodeDefinitionTypeOptions } from './promoCodeHelpers';
import { PromoCodesList } from './PromoCodesList';

type PromoCodeDefinitionDetailsProps = {
  promoCodes?: PromoCode[];
  handleCloseGeneratePromoCode: (shouldRefresh: boolean) => void;
  handleExportPromoCodes: () => void;
  isGeneratePromoCodeDialogOpen: boolean;
  promoCodesPage: number;
  promoCodesTotalRowsCount: number;
  setPromoCodesPage: React.Dispatch<React.SetStateAction<number>>;
  setIsGeneratePromoCodeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  promoCodeDefinitionDetails?: PromoCodeDefinition;
  testId: string;
};

export const PromoCodeDefinitionDetails = ({
  promoCodes,
  promoCodesPage,
  promoCodesTotalRowsCount,
  setPromoCodesPage,
  promoCodeDefinitionDetails,
  handleCloseGeneratePromoCode,
  handleExportPromoCodes,
  isGeneratePromoCodeDialogOpen,
  setIsGeneratePromoCodeDialogOpen,
  testId,
}: PromoCodeDefinitionDetailsProps) => {
  const theme = useTheme();
  const { formatDate } = useFormat();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isSinglePromoCode = promoCodeDefinitionDetails?.promoCodeDefinitionType === PromoCodeDefinitionType.Single_Code;

  return (
    <Box>
      <Box mb={5}>
        <Grid container spacing={1}>
          <Grid item container xs={12} md={9}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                Display Name:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1">{promoCodeDefinitionDetails?.name ?? ''}</Typography>
            </Grid>
          </Grid>
          {!smallScreen && (
            <Grid item container xs={12} md={3}>
              <Box width="100%" display="flex" justifyContent="flex-end">
                <Box flexBasis="20%">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Start:
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {promoCodeDefinitionDetails?.validFromDate
                    ? `${formatDate(promoCodeDefinitionDetails?.validFromDate, false, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                    : ''}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item container xs={12} md={9}>
            <Grid xs={12} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                Promotion:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1">{promoCodeDefinitionDetails?.promotionName}</Typography>
            </Grid>
          </Grid>
          {!smallScreen && (
            <Grid item container xs={12} md={3}>
              <Box width="100%" display="flex" justifyContent="flex-end">
                <Box flexBasis="18%">
                  <Typography variant="subtitle1" fontWeight="bold">
                    End:
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {promoCodeDefinitionDetails?.validToDate
                    ? `${formatDate(promoCodeDefinitionDetails?.validToDate, false, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                    : ''}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item container xs={12} md={9} spacing={1}>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                Description:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1">{promoCodeDefinitionDetails?.description ?? ''}</Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                Type:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1">
                {promoCodeDefinitionDetails
                  ? promoCodeDefinitionTypeOptions[promoCodeDefinitionDetails.promoCodeDefinitionType]?.label
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                Max Redemp./Code:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1">{promoCodeDefinitionDetails?.maxRedemptionsPerPromoCode ?? ''}</Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                Max Redemp./Customer:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1">{promoCodeDefinitionDetails?.maxRedemptionsPerCustomer ?? ''}</Typography>
            </Grid>
          </Grid>

          {smallScreen && (
            <>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Start:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="body1">
                  {promoCodeDefinitionDetails?.validFromDate
                    ? `${formatDate(promoCodeDefinitionDetails?.validFromDate, false, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                    : ''}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography variant="subtitle1" fontWeight="bold">
                  End:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="body1">
                  {promoCodeDefinitionDetails?.validToDate
                    ? `${formatDate(promoCodeDefinitionDetails?.validToDate, false, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                    : ''}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item container xs={12}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              mt={3}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Box display="flex" flexBasis={{ xs: '100%', md: '50%' }} flexDirection={{ xs: 'column', md: 'row' }}>
                <Box display="flex">
                  <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                    Created by:
                  </Typography>
                  <Typography variant="body1">{promoCodeDefinitionDetails?.createdBy ?? ''}</Typography>
                </Box>
                <Box display="flex" pl={{ xs: 0, md: 5 }}>
                  <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                    Created:
                  </Typography>
                  <Typography variant="body1">
                    {promoCodeDefinitionDetails?.createdAt
                      ? `${formatDate(promoCodeDefinitionDetails?.createdAt, true, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                      : ''}
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                flexBasis={{ xs: '100%', md: '50%' }}
                justifyContent="flex-end"
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                <Box display="flex">
                  <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                    Status:
                  </Typography>
                  <PromoCodeDefinitionStatus status={promoCodeDefinitionDetails?.status} />
                </Box>
                <Box display="flex" pl={{ xs: 0, md: 5 }}>
                  <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                    Last Updated:
                  </Typography>
                  <Typography variant="body1">
                    {promoCodeDefinitionDetails?.updatedAt
                      ? `${formatDate(promoCodeDefinitionDetails?.updatedAt, true, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                      : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box mb={3} display="flex" justifyContent={isSinglePromoCode ? 'flex-end' : 'space-between'}>
        {!isSinglePromoCode && (
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => {
              setIsGeneratePromoCodeDialogOpen(true);
            }}
            data-testid={`${testId}IssuePromoCodesButton`}
          >
            Issue Promo Codes
          </Button>
        )}
        <Button
          variant="text"
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={handleExportPromoCodes}
          data-testid={`${testId}ExportButton`}
        >
          Export
        </Button>
      </Box>
      <PromoCodesList
        promoCodes={promoCodes}
        promoCodesPage={promoCodesPage}
        setPromoCodesPage={setPromoCodesPage}
        promoCodesTotalRowsCount={promoCodesTotalRowsCount}
      />
      <GeneratePromoCodeDialog
        promoCodeDefinitionId={promoCodeDefinitionDetails?.id}
        open={isGeneratePromoCodeDialogOpen}
        onClose={handleCloseGeneratePromoCode}
        testId={testId}
      />
    </Box>
  );
};
