import { FC, Fragment } from 'react';

import { Chip } from '@mui/material';

import { Colors } from './constants/Colors';
import { StatusOption } from './constants/StatusOptions';

const getStatusDefaultOption = (status: string) => ({
  name: status,
  backgroundColor: Colors.lightGray,
  color: Colors.darkGrayBlue,
  icon: <Fragment />,
});

interface StatusChipProps {
  status: StatusOption | string;
  width?: string;
}
export const StatusChip: FC<StatusChipProps> = ({ status, width = '100%' }) => {
  const statusOption = typeof status === 'string' ? getStatusDefaultOption(status) : status;

  return (
    <Chip
      sx={{
        maxHeight: '20px',
        maxWidth: '200px',
        width,
        backgroundColor: statusOption.backgroundColor,
        color: statusOption.color,
        borderRadius: '4px',
        fontWeight: 500,
        flexDirection: 'row-reverse',
        '& .MuiChip-icon': {
          marginLeft: '0px',
          marginRight: '4px',
          fill: statusOption.color,
        },
      }}
      size="small"
      label={statusOption.name}
      icon={statusOption.icon}
    />
  );
};
