import { useIsMutating } from 'react-query';

import { Alert } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { PromoCode } from '@one/api-models/lib/Campaign/PromoCode/PromoCode';

import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { useFormat } from 'components/hooks/useFormat';

import { PromoCodeStatus } from './PromoCodeStatus';

const defaultPageSize = 25;

type PromoCodesListProps = {
  promoCodes?: PromoCode[];
  promoCodesPage: number;
  promoCodesTotalRowsCount: number;
  setPromoCodesPage: React.Dispatch<React.SetStateAction<number>>;
};

export const PromoCodesList = ({
  promoCodes,
  promoCodesPage,
  promoCodesTotalRowsCount,
  setPromoCodesPage,
}: PromoCodesListProps) => {
  const { formatDate } = useFormat();
  const isLoading = useIsMutating({ mutationKey: 'listPromoCodesMutation' }) > 0;

  //TODO: Update the field property when PromoCode type changes
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Promo Code ID',
      sortable: false,
      flex: 1,
    },
    {
      field: 'code',
      headerName: 'Promo Code',
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      sortable: false,
      flex: 1,
    },
    {
      field: 'redeemedBy',
      headerName: 'Redeemed By',
      sortable: false,
      flex: 1,
    },
    {
      field: 'redeemedAt',
      headerName: 'Redeemed (UTC)',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <>{formatDate(params.row?.redeemedAt, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'validFromDate',
      headerName: 'Valid From (UTC)',
      sortable: true,
      flex: 1.25,
      renderCell: (params: any) => <>{formatDate(params.row.validFromDate, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'validToDate',
      headerName: 'Valid Until (UTC)',
      sortable: true,
      flex: 1.25,
      renderCell: (params: any) => <>{formatDate(params.row.validToDate, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1.25,
      renderCell: (params: any) => <PromoCodeStatus status={params.row.status} />,
    },
    {
      field: 'createdBy',
      headerName: 'Issued By',
      sortable: true,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Issued (UTC)',
      sortable: true,
      flex: 1,
      renderCell: (params: any) => <>{formatDate(params.row?.createdAt, true, 'MMM dd, yyyy') || ''}</>,
    },
  ];

  return (
    <Box>
      {!isLoading ? (
        <>
          {promoCodes && promoCodes.length > 0 ? (
            <DataGrid
              rows={promoCodes}
              columns={columns}
              paginationMode="server"
              initialState={{
                pagination: {
                  paginationModel: {
                    page: promoCodesPage,
                    pageSize: defaultPageSize,
                  },
                },
              }}
              rowCount={promoCodesTotalRowsCount}
              onPaginationModelChange={(data: GridPaginationModel) => setPromoCodesPage(data.page)}
              hideFooter={promoCodesTotalRowsCount <= defaultPageSize}
            />
          ) : (
            <Alert severity="info" variant="outlined">
              No promo codes available
            </Alert>
          )}
        </>
      ) : null}
    </Box>
  );
};
