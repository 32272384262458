import { forwardRef } from 'react';

import { Box, Checkbox as MuiCheckbox } from '@mui/material';

const CheckboxIcon = forwardRef(function CustomCheckbox(props: any, ref) {
  return (
    <Box
      component="span"
      sx={{
        width: '20px',
        height: '20px',
        borderRadius: 1.5,
        border: '1.7px solid #D8DEE4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: props.checked ? '#D8DEE4' : 'transparent',
        boxShadow: props.checked ? 'none' : '0px 0px 4px rgba(0, 0, 0, 0.1)',
      }}
      {...props}
    >
      {props.checked && (
        <Box
          sx={{
            width: '20px',
            height: '20px',
            borderRadius: 1.5,
            backgroundColor: '#D8DEE4',
          }}
        />
      )}
    </Box>
  );
});

export const Checkbox = (props: any) => {
  return <MuiCheckbox icon={<CheckboxIcon />} checkedIcon={<CheckboxIcon checked />} {...props} />;
};
