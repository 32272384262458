import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, CircularProgress, MenuItem, Paper, TextField } from '@mui/material';

import { PageHeader } from 'components/_common/PageHeader';

import { ProductsTable } from './ProductsTable';
import { getProductType, ProductType, ProductTypeConfig, productTypes } from './ProductTypeConfig';

export const Products = () => {
  const firstProductType = productTypes[0].code;
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<ProductType>(firstProductType);
  const navigate = useNavigate();
  const testIdPrefix = 'Products';
  const { type } = useParams<{ type: string }>();

  useEffect(() => {
    if (type) setSelectedTypeFilter(getProductType(type, firstProductType)!);
    else if (selectedTypeFilter === undefined) setSelectedTypeFilter(firstProductType);
  }, [type, selectedTypeFilter, firstProductType]);

  const productsFilter = (
    <TextField
      select
      value={selectedTypeFilter}
      label="Type"
      name="type"
      onChange={(e: any) => navigate(`/products/${e.target.value}`)}
      size="small"
      inputProps={{
        'data-testid': `${testIdPrefix}TypeDropdown`,
      }}
    >
      {productTypes?.map(({ code, label }: ProductTypeConfig) => (
        <MenuItem key={code} value={code} data-testid={`${testIdPrefix}TypeDropdownItem`}>
          {label || code}
        </MenuItem>
      ))}
    </TextField>
  );

  return (
    <Box>
      <PageHeader title="Products" titleAside={productsFilter} testId={testIdPrefix} />
      <Paper sx={{ mt: 2, p: 3 }}>
        {selectedTypeFilter === undefined ? (
          <Box sx={{ minHeight: '50vh' }} justifyContent="center" alignItems="center" display="flex">
            <CircularProgress data-testid="LoadingSpinner" />
          </Box>
        ) : (
          <ProductsTable productType={selectedTypeFilter} />
        )}
      </Paper>
    </Box>
  );
};
