import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { getCustomToken, isSSOSession } from 'auth/auth';
import { Loading } from 'components/_common/Loading';
import { useToastMessage } from 'components/hooks/useToastMessage';

export const AgentLogin = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const { addApiError, addMessage } = useToastMessage();
  const isCustomAuth = isSSOSession();

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        addMessage({
          label: 'Missing 3rd party token.',
          severity: 'error',
        });
        setShowError(true);
      } else {
        setIsLoading(true);
        try {
          await getCustomToken(token, true);
          window.location.replace('/');
        } catch (error: any) {
          addApiError(error);
          setIsLoading(false);
          setShowError(true);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        p: 1,
      }}
    >
      {isLoading ? <Loading message="Hang on"></Loading> : null}
      {showError ? (
        <>
          <Typography variant="h4" align="center">
            Something went wrong. <br />
            Please contact your administrator or go to login.
          </Typography>
          {!isCustomAuth && (
            <Button variant="contained" sx={{ mt: 3 }} href="/" data-testid="AgentLoginButton">
              Login
            </Button>
          )}
        </>
      ) : null}
    </Box>
  );
};
