import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Typography } from '@mui/material';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { PromotionStatus } from '@one/api-models/lib/Campaign/Promotion/PromotionStatus';
import { FiatCurrencyDefinition } from '@one/api-models/lib/FiatCurrencyDefinition';

import ControlledSelect from 'components/_common/ControlledSelect';
import ControlledTextField from 'components/_common/ControlledTextField';
import { useFormat } from 'components/hooks/useFormat';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { PromotionTypeValues } from './promotionHelpers';

export interface PromotionSummary {
  languageCode: string;
  currencyCode: string;
  promotionName: string;
  description?: string | null;
  promotionAdvertisingMessage?: string;
  promotionAdvertisingDescription?: string;
  typeId: number;
  startDate: Date | null | string;
  endDate: Date | null | string;
  status: boolean;
  timeZone?: string | null;
}
interface PromotionSummaryFormProps {
  promotion?: Promotion;
  availableLanguageCodes: string[];
  availableCurrencies: FiatCurrencyDefinition[];
  testId: string;
  handleSavePromotion: (data: Promotion) => Promise<void>;
}

export const PromotionSummaryForm = ({
  promotion,
  handleSavePromotion,
  availableLanguageCodes,
  availableCurrencies,
  testId,
}: PromotionSummaryFormProps) => {
  const activeBrand = useSelector(selectActiveBrand);
  const { customZonedDateToISODate, formatDate } = useFormat();
  const labelStyle = 'original';
  const timezones = {
    ...allTimezones,
  };
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

  const validationSchema: yup.SchemaOf<PromotionSummary> = yup.object().shape(
    {
      languageCode: yup.string().trim().required('Language is required'),
      currencyCode: yup.string().trim().required('Currency is required'),
      promotionName: yup
        .string()
        .trim()
        .max(128, 'Maximum 128 characters allowed.')
        .required('Promotion name is required.'),
      description: yup.string().trim().max(1024, 'Maximum 1024 characters allowed.').nullable(),
      typeId: yup.number().required('Type is required.'),
      status: yup.boolean().required(),
      promotionAdvertisingMessage: yup.string().trim().max(1024, 'Maximum 1024 characters allowed.'),
      promotionAdvertisingDescription: yup.string().trim().max(1024, 'Maximum 1024 characters allowed.'),
      timeZone: yup.string().trim().nullable().required('Timezone is required.'),
      startDate: yup
        .date()
        .nullable()
        .required('Start Date is required.')
        .when('endDate', (endDate: Date, schema) => {
          if (endDate) {
            return schema.test('date-comparison', '', (startDate: Date) => {
              if (startDate && startDate > endDate) {
                setError('endDate', {
                  type: 'manual',
                  message: 'End Date must be equal to or greater than Start Date.',
                });
                return false;
              } else {
                clearErrors('endDate');
                return true;
              }
            });
          }
          return schema;
        })
        .typeError('Invalid date.'),
      endDate: yup
        .date()
        .nullable()
        .required('End Date is required.')
        .when('startDate', (startDate: Date, schema) => {
          if (startDate) {
            return schema.test(
              'date-comparison',
              'End Date must be equal to or greater than Start Date.',
              (endDate: Date) => {
                if (endDate && startDate > endDate) {
                  setError('startDate', {
                    type: 'manual',
                    message: '',
                  });
                  return false;
                } else {
                  clearErrors('startDate');
                  return true;
                }
              },
            );
          }
          return schema;
        })
        .typeError('Invalid date.'),
    },
    [['startDate', 'endDate']],
  );

  const { control, handleSubmit, formState, setError, clearErrors } = useForm<PromotionSummary>({
    mode: 'onChange',
    defaultValues: {
      languageCode: promotion?.promotionSummary.languageCode ?? '',
      currencyCode: promotion?.promotionSummary.currencyCode ?? '',
      promotionName: promotion?.promotionSummary.name ?? '',
      description: promotion?.promotionSummary.description ?? '',
      typeId: promotion?.promotionSummary.type,
      promotionAdvertisingMessage: promotion?.marketingDetails.advertisingMessage ?? '',
      promotionAdvertisingDescription: promotion?.marketingDetails.advertisingMessage ?? '',
      startDate: formatDate(promotion?.promotionSummary.startDate, false, "yyyy-MM-dd'T'HH:mm"),
      endDate: formatDate(promotion?.promotionSummary.endDate, false, "yyyy-MM-dd'T'HH:mm"),
      status: promotion?.promotionSummary.status === PromotionStatus.Active,
      timeZone: 'Etc/GMT',
    },
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  const handlePromotionSubmit = async (data: PromotionSummary) => {
    const newPromotionDetails: Promotion = {
      id: promotion ? promotion.id : 0,
      promotionSummary: {
        ...promotion?.promotionSummary,
        id: promotion?.promotionSummary ? promotion.promotionSummary.id : 0,
        name: data.promotionName ?? '',
        description: data.description ?? '',
        type: data.typeId,
        currencyCode: data.currencyCode ?? '',
        languageCode: data.languageCode ?? '',
        status: data.status ? PromotionStatus.Active : PromotionStatus.Draft,
        brandKey: activeBrand?.key || '',
        startDate: customZonedDateToISODate(data.startDate as Date, data.timeZone),
        endDate: customZonedDateToISODate(data.endDate as Date, data.timeZone),
        //these fields should be deleted
        redemptionCount: 0,
        combinable: false,
      },
      marketingDetails: {
        promotionId: promotion ? promotion.id : 0,
        advertisingMessage: data.promotionAdvertisingMessage ?? '',
        advertisingDescription: data.promotionAdvertisingDescription ?? '',
      },
      qualifiers: promotion?.qualifiers || [],
      awards: promotion?.awards || [],
    };
    await handleSavePromotion(newPromotionDetails);
  };

  return (
    <form id="promotion-summary-form" onSubmit={handleSubmit(handlePromotionSubmit)} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">General</Typography>
          <Divider sx={{ mt: 1 }} />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6} md={3}>
            <ControlledSelect
              control={control as unknown as Control<FieldValues, object>}
              name="currencyCode"
              label="Currency"
              fullWidth
              size="small"
              error={errors.currencyCode != null}
              helperText={errors.currencyCode?.message}
              testId={`${testId}Currency`}
            >
              {availableCurrencies.map((opt: FiatCurrencyDefinition) => (
                <MenuItem key={opt.code} value={opt.code} data-testid={`${testId}CurrencySelectItem`}>
                  {opt.name}
                </MenuItem>
              ))}
            </ControlledSelect>
          </Grid>
          <Grid item xs={6} md={3}>
            <ControlledSelect
              control={control as unknown as Control<FieldValues, object>}
              name="languageCode"
              label="Language"
              fullWidth
              size="small"
              error={errors.languageCode != null}
              helperText={errors.languageCode?.message}
              testId={`${testId}Language`}
            >
              {availableLanguageCodes.map((opt: string) => (
                <MenuItem key={opt} value={opt} data-testid={`${testId}LanguageSelectItem`}>
                  {opt}
                </MenuItem>
              ))}
            </ControlledSelect>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Information</Typography>
          <Divider sx={{ mt: 1 }} />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <ControlledSelect
              control={control as unknown as Control<FieldValues, object>}
              name="typeId"
              label="Type"
              fullWidth
              size="small"
              error={errors.typeId != null}
              helperText={errors.typeId?.message}
              testId={`${testId}Type`}
            >
              {PromotionTypeValues.map((opt: any) => (
                <MenuItem key={opt.id} value={opt.id} data-testid={`${testId}TypeSelectItem`}>
                  {opt.label}
                </MenuItem>
              ))}
            </ControlledSelect>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <ControlledTextField
            control={control as unknown as Control<FieldValues, object>}
            name="promotionName"
            fullWidth
            label="Promotion Name"
            placeholder="Promotion Name"
            error={errors.promotionName != null}
            helperText={errors.promotionName?.message}
            testId={`${testId}Name`}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control as unknown as Control<FieldValues, object>}
            name="description"
            fullWidth
            label="Promotion Description"
            placeholder="Promotion Description"
            multiline
            minRows="3"
            error={errors.description != null}
            helperText={errors.description?.message}
            testId={`${testId}Description`}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control as unknown as Control<FieldValues, object>}
            name="promotionAdvertisingMessage"
            fullWidth
            label="Promotion Advertising Message"
            placeholder="Promotion Advertising Message"
            error={errors.promotionAdvertisingMessage != null}
            helperText={errors.promotionAdvertisingMessage?.message}
            testId={`${testId}AdvertisingMessage`}
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledTextField
            control={control as unknown as Control<FieldValues, object>}
            name="promotionAdvertisingDescription"
            fullWidth
            label="Promotion Advertising Description"
            placeholder="Promotion Advertising Description"
            multiline
            minRows="3"
            error={errors.promotionAdvertisingDescription != null}
            helperText={errors.promotionAdvertisingDescription?.message}
            testId={`${testId}AdvertisingDescription`}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" mt={3}>
            Scheduling
          </Typography>
          <Divider sx={{ mt: 1 }} />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={3}>
            <ControlledSelect
              control={control as unknown as Control<FieldValues, object>}
              name="timeZone"
              label="Timezone"
              fullWidth
              size="small"
              onChange={(e) => parseTimezone(e as any)}
              error={errors.timeZone != null}
              helperText={errors.timeZone?.message}
              selectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  PaperProps: { sx: { maxHeight: 350 } },
                },
              }}
              testId={`${testId}Timezone`}
            >
              {options.map((opt: any) => (
                <MenuItem key={opt.value} value={opt.value} data-testid={`${testId}TimezoneSelectItem`}>
                  {opt.label}
                </MenuItem>
              ))}
            </ControlledSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              type="datetime-local"
              name="startDate"
              label="Valid from"
              placeholder="Valid from"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.startDate != null}
              helperText={errors.startDate?.message}
              testId={`${testId}ValidFrom`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              type="datetime-local"
              name="endDate"
              label="Valid until"
              placeholder="Valid until"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.endDate != null}
              helperText={errors.endDate?.message}
              testId={`${testId}ValidUntil`}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              control={control as unknown as Control<FieldValues, object>}
              name="status"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      inputProps={{
                        //eslint-disable-next-line
                        //@ts-ignore
                        'data-testid': `${testId}ActiveCheckboxInput`,
                      }}
                    />
                  }
                  label="Active"
                />
              )}
            />
          </Grid>
        </Grid>

        {!promotion && (
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button type="submit" size="medium" variant="contained" data-testid={`${testId}SaveButton`}>
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
