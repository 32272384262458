import { TransactionListCriteria } from '@one/api-models/lib/Admin/Transaction/TransactionListCriteria';
import { TransactionListResponse } from '@one/api-models/lib/Admin/Transaction/TransactionListResponse';

import { IApiClient } from './api-client';

export interface ITransactionAccess {
  loadTransactionList(criteria: TransactionListCriteria): Promise<TransactionListResponse>;
}

export class TransactionAccess implements ITransactionAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  loadTransactionList(criteria: TransactionListCriteria): Promise<TransactionListResponse> {
    return this.apiClient.call('sales/transactions/list', {
      ...criteria,
      $Type: TransactionListCriteria.$type,
      cultureCode: 'en-US',
    });
  }
}
