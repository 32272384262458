import { Fragment, useMemo } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { Wallet } from '@one/api-models/lib/Admin/Members/Response/Wallet';
import { Certificate } from '@one/api-models/lib/Membership/Account/Certificate';
import { Portfolio } from '@one/api-models/lib/Membership/Account/Portfolio';

import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';

import { Benefits } from './Assets/Benefits';
import { Certificates } from './Assets/Certificates';
import { ItemInfo } from './ItemInfo';

interface Props {
  memberKey: string;
  wallet: Wallet | undefined;
  portfolio: Portfolio | undefined;
  testId: string;
  onRedemptionSuccess: () => void;
  isLoading: boolean;
}

export const AssetsSection = ({ memberKey, wallet, portfolio, testId, onRedemptionSuccess, isLoading }: Props) => {
  const { formatNumber } = useFormat();
  const certificates: Certificate[] = useMemo(() => {
    if (portfolio && portfolio.certificates) {
      return portfolio.certificates.sort((a, b) => b.status - a.status) as Certificate[];
    }
    return [];
  }, [portfolio]);

  return (
    <Fragment>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <SectionTitle title="Wallet" />
            <Box sx={{ mb: { md: 4 } }}>
              {wallet && wallet.tenders && wallet.tenders.length ? (
                <Fragment>
                  {wallet.tenders.map((item) => (
                    <ItemInfo
                      key={item.id}
                      type={'deal'}
                      name={`${formatNumber(parseInt(item.value))} ${item.name}`}
                      activeFrom={item.activeFrom}
                      expiresOn={item.expiresOn}
                      testId={`${testId}ItemInfo`}
                    />
                  ))}
                </Fragment>
              ) : (
                <Typography variant="body1">The wallet is empty</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <SectionTitle title="Assets" />
            <Certificates
              isLoading={isLoading}
              onRedemptionSuccess={onRedemptionSuccess}
              memberKey={memberKey}
              certificates={certificates}
              testId={`${testId}Certificates`}
            />
            {portfolio?.benefits && portfolio?.benefits?.length > 0 && <Benefits benefits={portfolio?.benefits} />}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
