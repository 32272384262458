import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';

export interface ConfirmationDialogProps {
  open: boolean;
  isLoading?: boolean;
  title?: string;
  question: string | React.ReactNode;
  confirmButton: string;
  abortButton: string;
  testId: string;
  onClose?(isConfirmed: boolean): any;
  children?: React.ReactNode;
}

export const ConfirmationDialog = ({
  open,
  isLoading,
  title,
  question,
  confirmButton,
  abortButton,
  testId,
  onClose,
  children,
}: ConfirmationDialogProps) => {
  const handleClose = (isConfirmed: boolean): void => {
    if (onClose) {
      onClose(isConfirmed);
    }
  };

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      {title && (
        <DialogTitle variant="h6" id="alert-dialog-title">
          <Box>{title}</Box>
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{question}</DialogContentText>
        {children && children}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          variant="outlined"
          size="small"
          disableRipple
          disabled={isLoading}
          data-testid={`${testId}AbortButton`}
        >
          {abortButton}
        </Button>
        <Button
          onClick={() => handleClose(true)}
          variant="contained"
          size="small"
          autoFocus
          disableRipple
          disabled={isLoading}
          data-testid={`${testId}ConfirmButton`}
        >
          {confirmButton}
        </Button>
      </DialogActions>
      {isLoading && <LinearProgress />}
    </Dialog>
  );
};
