import { IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IconButton = styled(MuiIconButton)(() => ({
  borderRadius: 7,
}));

export const ActionIconButton = styled(IconButton)(({ theme }) => ({
  height: 28,
  width: 28,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  border: `1px solid ${theme.customPalette.input.border}`,
  boxShadow:
    '0px -1px 1px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 5px 0px rgba(60, 66, 87, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.08)',
}));
