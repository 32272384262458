export const getFileContent = (file: any) =>
  new Promise<{ content: string }>((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt && evt.target && evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result as ArrayBuffer;
          let binary = '';
          const bytes = new Uint8Array(arrayBuffer);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          resolve({ content: window.btoa(binary) });
        }
        reject('Cannot load file content!');
      };
    } catch (e) {
      reject(e);
    }
  });
