import { useState } from 'react';

import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { Box, IconButton, TextField } from '@mui/material';

export const InlineTextEditor = ({
  value,
  onChange,
  width,
  testId,
}: {
  value?: string;
  onChange: (value: string) => void;
  width: number;
  testId: string;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState(value || '');

  const startEdit = () => {
    setTextFieldValue(value || '');
    setEditMode(true);
  };

  return (
    <Box width={width}>
      {!editMode && (
        <Box sx={{ position: 'relative' }} onDoubleClick={() => startEdit()}>
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              top: '50%',
              right: '-10px',
              color: '#ddd',
              transform: 'translate(10px, -50%)',
            }}
            onClick={() => startEdit()}
            data-testid={`${testId}EditButton`}
          >
            <ModeEditOutlinedIcon fontSize="small" />
          </IconButton>
          {value}
        </Box>
      )}
      {editMode && (
        <Box style={{ position: 'relative' }}>
          <TextField
            style={{ position: 'absolute', width: '130px', left: '-20px', fontSize: '8px', top: '-25px' }}
            multiline
            value={textFieldValue}
            autoFocus
            onChange={(ev) => setTextFieldValue(ev.target.value)}
            onBlur={() => {
              onChange(textFieldValue);
              setEditMode(false);
            }}
            onKeyUp={(ev) => {
              if (ev.key === 'Escape') {
                setEditMode(false);
              }
            }}
            inputProps={{
              'data-testid': `${testId}EditorTextInput`,
            }}
          />
        </Box>
      )}
    </Box>
  );
};
