import { Box, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { AlternativeCurrency as AlternativeCurrencyModel } from '@one/api-models/lib/AlternativeCurrency';

import { useFormat } from 'components/hooks/useFormat';

import { AlternativeCurrenciesIcon } from './AlternativeCurrenciesIcon';

interface Props {
  data: AlternativeCurrencyModel;
  margin?: SystemStyleObject | string | number;
  currencyNameFontWeight?: 400 | 500 | 700;
}

export const AlternativeCurrency = ({ data, margin, currencyNameFontWeight }: Props) => {
  const { formatNumber } = useFormat();
  return (
    <Box display="flex" alignItems="center">
      <Box sx={{ pr: 0.5, display: 'flex', alignItems: 'center' }}>
        <AlternativeCurrenciesIcon size="0.9rem" alternativeCurrency={data.alternativeCurrencyDefinition.code} />
        <Typography sx={{ m: margin ?? '0 0 0 4px' }}>{formatNumber(data.total)}</Typography>
      </Box>
      <Typography
        variant="caption"
        sx={{
          lineHeight: 1.5,
          fontWeight: currencyNameFontWeight || 700,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {data.alternativeCurrencyDefinition.name}
      </Typography>
    </Box>
  );
};
