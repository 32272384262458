import React from 'react';

import { Box, useTheme } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

export const SideModalPreviewWrapper = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <Box
      px={2}
      py={2.5}
      sx={{
        width: '100%',
        minWidth: '350px',
        position: 'relative',

        [theme.breakpoints.up('md')]: {
          maxWidth: 'fit-content',
        },
      }}
    >
      {children}
    </Box>
  );
};
