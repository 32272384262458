import { useState } from 'react';

import { Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ImageRecord } from '@one/api-models/lib/Admin/Media/ImageRecord';
import { ImageVariant } from '@one/api-models/lib/Admin/Media/ImageVariant';
import { MediaRecord } from '@one/api-models/lib/Admin/Media/MediaRecord';

import { ImagePreviewPopup } from './ImagePreviewPopup';
import { MediaListingRow } from './MediaListingRow';

export const MediaListing = ({
  items,
  testId,
  onCaptionChanged,
  onNoteChanged,
  onDelete,
  onSetPrimary,
  onGenerateVariants,
}: {
  items: MediaRecord[];
  testId: string;
  onCaptionChanged: (id: string, newValue: string) => void;
  onNoteChanged: (id: string, newValue: string) => void;
  onDelete: (id: string) => void;
  onSetPrimary: (id: string) => void;
  onGenerateVariants: (id: string) => void;
}) => {
  const [altImagePreview, setAltImagePreview] = useState<ImageVariant | undefined>(undefined);
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 0 }}></TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Preview</TableCell>
              <TableCell>Caption</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Variant</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Primary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((x) => (
              <MediaListingRow
                item={x as ImageRecord}
                key={x.id}
                onSetImagePreview={setAltImagePreview}
                onCaptionChanged={(newValue) => onCaptionChanged(x.id, newValue)}
                onNoteChanged={(newValue) => onNoteChanged(x.id, newValue)}
                onDelete={() => onDelete(x.id)}
                onSetPrimary={() => onSetPrimary(x.id)}
                onGenerateVariants={() => onGenerateVariants(x.id)}
                testId={testId}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ImagePreviewPopup
        imageSrc={altImagePreview?.url}
        imageWidth={altImagePreview?.width}
        onClose={() => setAltImagePreview(undefined)}
        testId={testId}
      />
    </Box>
  );
};

export const MediaListingSkeleton = () => {
  const fh = 120;
  return (
    <Box>
      <Paper sx={{ p: 3, pt: 2 }}>
        <Skeleton variant="rectangular" height={50} sx={{ mb: 2.5 }} />
        <Skeleton variant="rectangular" height={fh} sx={{ mb: 2.5 }} />
        <Skeleton variant="rectangular" height={fh} sx={{ mb: 2.5 }} />
        <Skeleton variant="rectangular" height={fh} sx={{ mb: 2.5 }} />
      </Paper>
    </Box>
  );
};
