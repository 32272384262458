import NoMealsIcon from '@mui/icons-material/NoMeals';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Box, Grid, Typography } from '@mui/material';
import { ReservationItemSummary } from '@one/api-models/lib/Membership/Account/Travel/Accommodation/ReservationItemSummary';
import { RoomReservationInfo } from '@one/api-models/lib/Membership/Account/Travel/Accommodation/RoomReservationInfo';
import { BoardType } from '@one/api-models/lib/Sales/Travel/Accommodation/BoardType';
import { BoardTypeId } from '@one/api-models/lib/Sales/Travel/BoardTypeId';

import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';
import { formatAddress } from 'utils/address';

interface Props {
  orderItem: ReservationItemSummary;
}

export const ReservationOrderItemDetails = ({ orderItem }: Props) => {
  const { productSummary } = orderItem;
  const numberOfRooms = orderItem.roomReservationInfo.length;
  const { formatDate } = useFormat();

  const hasRoomOnly = (boardType: BoardType): boolean => {
    return boardType.configurations.find((config) => config.typeId === BoardTypeId.RoomOnly) != null;
  };

  const getBoardTypeName = (boardType: BoardType | undefined): string | undefined => {
    if (boardType == null) return undefined;
    if (hasRoomOnly(boardType) || boardType.name == null || boardType.name === '') return undefined;

    return boardType.name;
  };

  const boardTypeName = getBoardTypeName(orderItem?.boardType);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2">
          {productSummary?.type} {formatAddress(productSummary.address, true)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {orderItem.roomReservationInfo.map((info: RoomReservationInfo, index: number) => (
          <Box key={`traveler_${index}`}>
            <SectionTitle title={`Lead Traveler:`} variant="h6" />
            <Typography variant="subtitle1">{`${info.leadTraveler.firstName} ${info.leadTraveler.lastName}`}</Typography>
            <Typography variant="body1">{info.leadTraveler.email}</Typography>
            <Typography variant="body1">{info.leadTraveler.phone}</Typography>
          </Box>
        ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <SectionTitle title="Booking Details" variant="h6" />
        <Typography variant="subtitle1">
          {`${formatDate(orderItem.checkInDate, false, 'EEE MMM d yyyy')} - `}
          {formatDate(orderItem.checkoutDate, false, 'EEE MMM d yyyy')}
        </Typography>
        <Typography variant="body2">
          {numberOfRooms} x {orderItem.productSummary.unitDescription}
        </Typography>
        {hasRoomOnly(orderItem?.boardType) && (
          <Typography
            variant="body2"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              my: 0.5,
              color: theme.palette.common.black,
            })}
          >
            <NoMealsIcon fontSize="small" sx={{ mr: 0.5 }} />
            {orderItem?.boardType.name}
          </Typography>
        )}
        {!hasRoomOnly(orderItem?.boardType) && boardTypeName != null && (
          <Typography
            variant="body2"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              my: 0.5,
              color: theme.palette.common.black,
            })}
          >
            <RestaurantIcon fontSize="small" sx={{ mr: 0.5 }} />
            {boardTypeName}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
