import { ProductTag } from 'models/productTags/ProductTag';
import { Tag } from 'models/productTags/Tag';


export const assignedTags: ProductTag[] = [
  {tagKey:'1', name: 'Victoria', path:['Locations', 'World', 'Australia', 'Victoria']},
  {tagKey:'2', name: 'Cruise', path:['Product Type']},
  {tagKey:'311', name: 'International Cuisine', path:['Attributes', 'Dining']},
  {tagKey:'312', name: 'Specialty Restaurants', path:['Attributes', 'Dining']},
  {tagKey:'313', name: 'Room Service', path:['Attributes', 'Dining']},
  {tagKey:'3211', name: 'Casino', path:['Attributes', 'Entertainment', 'Nightlife at Sea']},
  {tagKey:'3212', name: 'Live music shows', path:['Attributes', 'Entertainment', 'Nightlife at Sea']},
  {tagKey:'3213', name: 'Other performances', path:['Attributes', 'Entertainment', 'Nightlife at Sea']},
  {tagKey:'3221', name: 'Mini Golf', path:['Attributes', 'Entertainment', 'Sport Facilities']},
  {tagKey:'3222', name: 'Jogging tracks', path:['Attributes', 'Entertainment', 'Sport Facilities']},
  {tagKey:'3223', name: 'Swimming pools', path:['Attributes', 'Entertainment', 'Sport Facilities']},
];

export const taggingTagTree =  [
    {
      key: 'iQ-#-iQoRuJruwkGoqnplD9kdyS',
      name: 'Product Type',
      hasDescendants: true,
      descendants: [
        {
          key: '0r-#-0rcg23G5jEqyqUlQgU5thw',
          name: 'Shopping',
          hasDescendants: false,
          descendants: [],
          allowAssign: true,
        },
        {
          key: '3i-#-3i6O0pb5N0CjYajzKsEjvw',
          name: 'Travel',
          hasDescendants: false,
          descendants: [],
          allowAssign: true,
        },
      ],
    },
    {
      key: 'Lo-#-LoqqeFKxIUeISqnj0Ly3uA',
      name: 'Locations',
      hasDescendants: false,
      descendants: [],
      allowAssign: true,
    },
    {
      key: 'Gk-#-Gkai7JbHA0qgf2BL5bFaaA',
      name: 'Attributes',
      hasDescendants: true,
      allowAssign: false,
      descendants: [
        {
          key: 'tH-#-tHD62WBQgkeNlwPezGrGAQ',
          name: 'Shopping',
          hasDescendants: false,
          descendants: [],
          allowAssign: true,
        },
        {
          key: 'bV-#-bVEdBV8uaEeNbRcsrdHqTA1',
          name: 'Entertainment',
          hasDescendants: true,
          descendants: [{
            key: 'bV-#-bVEdBV8uaEeNbRcsrdHqTA12',
            name: 'Sport Facilities',
            hasDescendants: true,
            descendants: [
              {
                key: 'bV-#-bVEdBV8uaEeNbRcsrdHqTAkx',
                name: 'Cycling',
                hasDescendants: false,
                descendants: [],
                allowAssign: true,
              },
              {
              key: 'bV-#-bVEdBV8uaEeNbRcsrdHqTAk',
              name: 'Ping-Pong',
              hasDescendants: false,
              descendants: [],
              allowAssign: true,
            },
            {
              key: '3223',
              name: 'Swimming pools',
              hasDescendants: false,
              descendants: [],
              allowAssign: true,
            },
            {
              key: 'bV-#-bVEdBV8uaEeNbRcsrdHqTAu',
              name: 'Trampoline',
              hasDescendants: false,
              descendants: [],
              allowAssign: true,
            }
          ],
            allowAssign: true,
          }],
          allowAssign: true,
        },
        {
          key: 'bV-#-bVEdBV8uaEeNbRcsrdHqTA',
          name: 'Accommodation',
          hasDescendants: false,
          descendants: [],
          allowAssign: true,
        },
        {
          key: '1V-#-1VEdBV8uaEeNbRcsrdHqTA',
          name: 'Cruise',
          hasDescendants: true,
          descendants: [
            {
              key: '24-#-24o02dVVfEGlkyIiaVsiRw',
              name: 'Sailing',
              hasDescendants: true,
              descendants: [
                {
                  key: '34-#-34o02dVVfEGlkyIiaVsiRw',
                  name: 'Cruise Length',
                  hasDescendants: false,
                  descendants: [],
                  enabled: true,
                  allowAssign: true,
                },
                {
                  key: 'at-#-atSuKFKXXEi3Moq24ADuuw',
                  name: 'Amenities',
                  hasDescendants: false,
                  descendants: [],
                  allowAssign: true,
                },
                {
                  key: '3t-#-3tS6KFKXXEi3Moq24ADuuw',
                  name: 'Month',
                  hasDescendants: false,
                  descendants: [],
                  enabled: true,
                  allowAssign: true,
                },
                {
                  key: '13-#-13cmwheltEGSb0LSwDnR0g',
                  name: 'Sailing Star rating',
                  hasDescendants: false,
                  descendants: [],
                  enabled: true,
                  allowAssign: true,
                },
              ],
              enabled: true,
              allowAssign: true,
            },
          ],
        },
      ],
    },
  ] as Tag[];



const findNode = (tagKey:string, startNodes:Tag[] = taggingTagTree): Tag | undefined => {
  if(startNodes.length === 0) return undefined;

  for(const tag of startNodes) {
    if(tag.key === tagKey)
      return tag;

    const res = findNode(tagKey, tag.descendants || [])
    if(res)
      return res;
  }

  return undefined;
}

  export const filterTreeTags = (term:string, tags: Tag[]) : Tag[] => {
    if(tags.length === 0 ) return tags;

    const result: Tag[] = tags.map(x => {
      const descendants: Tag[] = filterTreeTags(term, x.descendants || []);

      // return if found on descendants
      if(!!descendants && descendants.length > 0) {
        return {...x, hasDescendants: true, descendants: descendants} as Tag;
      }

      if(x.name.toLocaleLowerCase().indexOf(term) > -1) {
        return {...x};
      }

      return undefined;
    }).filter(x => x !== undefined).map(x => x || {} as Tag);

    return result.filter(x => (x.descendants && x.descendants.length > 0) || x.name.toLocaleLowerCase().indexOf(term) > -1) || [];
  }


  export const subtree = (tagKey? : string) : Tag[] => {
    if(!tagKey) { // initial
      return (taggingTagTree as Tag[])
        .map((firstLvl : Tag) => {

          const desc = (!firstLvl.descendants
              ? []
              :firstLvl.descendants
              .map((sLevel: Tag) => {return {...sLevel, descendants: []};})
              );

              return {...firstLvl, descendants : desc}
          }) as Tag[];
        }
    return findNode(tagKey)?.descendants || [];
  }
