import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ProductContent as WineProductDetails } from '@one/api-models/lib/Admin/Products/Retail/Wine/ProductContent';

import { DetailsTabProps } from './DetailsBase';

export const WineDetailsTab = ({ product: productBase }: DetailsTabProps) => {
  const product = productBase as WineProductDetails;
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <List sx={{ width: '100%' }}>
            <ListItem>
              <ListItemText
                primary="Name"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.name ?? 'N/A'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Display Name"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.displayName ? product.displayName : 'N/A'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Brand"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.brand ?? 'N/A'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Country/Region"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={
                  <Typography variant="body1">
                    {product.region?.country ?? ''} {product.region?.value ?? ''}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Appellation"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.appellation?.value ?? 'N/A'}</Typography>}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Varietal"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.varietal?.value ?? 'N/A'}</Typography>}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Winery"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.winery?.name ?? 'N/A'}</Typography>}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <List sx={{ width: '100%' }}>
            <ListItem>
              <ListItemText
                primary="Wine Type"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.wineType?.value ?? 'N/A'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Bottling Date"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={
                  <Typography variant="body1">{product.bottlingDate?.toLocaleDateString() ?? 'N/A'}</Typography>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Harvest Date"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={
                  <Typography variant="body1">{product.harvestDate?.toLocaleDateString() ?? 'N/A'}</Typography>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Vintage"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.vintage?.value ?? 'N/A'}</Typography>}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Volume"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.volumeInLiters ?? 'N/A'}l</Typography>}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primaryTypographyProps={{ variant: 'subtitle2' }}
                primary="Weight"
                secondary={<Typography variant="body1">{product.weightInKg ?? 'N/A'}kg</Typography>}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <List sx={{ width: '100%' }}>
            <ListItem>
              <ListItemText
                primary="Sparkling"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.sparkling ? 'yes' : 'no'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="PH"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.ph ?? 'N/A'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Residual Sugar"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={
                  <Typography variant="body1">{product.residualSugar ? product.residualSugar : 'N/A'}</Typography>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Sugar"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.sugar ? product.sugar : 'N/A'}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Fermentation"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={
                  <Typography variant="body1">{product.fermentation ? product.fermentation : 'N/A'}</Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <List sx={{ width: '100%' }}>
            <ListItem>
              <ListItemText
                primary="Teaser"
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondary={<Typography variant="body1">{product.teaser ?? 'N/A'}</Typography>}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
};
