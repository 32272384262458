import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { TranslationItem } from '@one/api-models/lib/Admin/Common/TranslationItem';
import { TagType } from '@one/api-models/lib/Admin/Tags/TagType';

export interface FormData {
  key?: string;
  name?: string;
  displayName: TranslationItem[];
  rank: string;
  enabled: boolean;
  type?: TagType;
}

interface Props {
  data: FormData;
  testId: string;
  disableEdit?: boolean;
  tagTypes?: TagType[];
  onSave?: (data: FormData) => void;
  setEditing?: (editing: boolean) => void;
}

export function DetailsForm({ data, testId, disableEdit, tagTypes, onSave, setEditing }: Props) {
  const [tagEnabled, setTagEnabled] = useState(data.key ? data.enabled : true);
  const [typeValue, setTypeValue] = useState(data.type);

  const { register, handleSubmit: handleFormSubmit, formState, reset } = useForm<FormData>({ mode: 'onChange' });

  const { errors } = formState;

  // *** load data to fields (when data changed)
  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = (data: FormData) => {
    if (data && onSave) {
      onSave({ ...data, enabled: tagEnabled, type: typeValue });
    }
    return true;
  };

  const resetForm = () => {
    reset({
      key: data.key,
      name: data.name,
      displayName: data.displayName,
      enabled: data.enabled,
      rank: data.rank,
      type: data.type,
    } as FormData);
    setTypeValue(data.type);
    setTagEnabled(data.key ? data.enabled : true);
  };

  const isFormDirty = Object.keys(formState.dirtyFields).length > 0 || data.enabled !== tagEnabled;
  const isWithErrors = Object.keys(formState.errors).length > 0;

  useEffect(() => {
    if (setEditing) {
      setEditing(!disableEdit && isFormDirty);
    }
  }, [disableEdit, isFormDirty, setEditing]);

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)} autoComplete="off">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <TextField
          fullWidth
          label="Id"
          {...register('key')}
          InputProps={{
            readOnly: true,
          }}
          disabled={true}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            'data-testid': `${testId}IdTextInput`,
          }}
        />

        <TextField
          //autoFocus
          fullWidth
          label="Name"
          {...register('name', {
            required: { value: true, message: 'Tag name is required' },
          })}
          error={errors.name?.message != null}
          helperText={errors.name?.message}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: disableEdit,
          }}
          inputProps={{
            'data-testid': `${testId}NameTextInput`,
          }}
        />

        <Divider />

        {data.displayName?.map((r: TranslationItem, index: number) => (
          <TextField
            {...register(`displayName.${index}.value`)}
            fullWidth={true}
            label={`Display Name - ${r.cultureCode}`}
            key={'display_name_' + r.cultureCode}
            error={
              !!errors?.displayName &&
              !!errors?.displayName?.length &&
              errors?.displayName?.length > index &&
              errors?.displayName[index]?.value != null
            }
            helperText={
              errors?.displayName != null && !!errors?.displayName?.length && errors?.displayName?.length > index
                ? errors?.displayName[index]?.value?.message
                : ''
            }
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: disableEdit,
            }}
            inputProps={{
              'data-testid': `${testId}DisplayNameTextInput`,
            }}
          />
        ))}

        <Divider />

        <TextField
          fullWidth
          label="Rank"
          {...register('rank', {
            required: { value: true, message: 'Rank is required' },
            validate: (x) =>
              !yup.number().min(-2147483647).max(2147483647).isValidSync(x)
                ? 'Invalid value. Please use values between -2,147,483,647 and 2,147,483,647'
                : true,
          })}
          error={errors.rank?.message != null}
          helperText={errors.rank?.message}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: disableEdit,
          }}
          inputProps={{
            'data-testid': `${testId}RankTextInput`,
          }}
        />

        <TextField
          select
          value={typeValue}
          label="Type"
          name="type"
          disabled={!!data.key}
          onChange={(e: any) => setTypeValue(e.target.value)}
          inputProps={{
            'data-testid': `${testId}TypeInput`,
          }}
        >
          {tagTypes?.map((tagType) => (
            <MenuItem key={tagType} value={tagType} data-testid={`${testId}TypeInputMenuItem`}>
              {tagType}
            </MenuItem>
          ))}
        </TextField>

        <FormControlLabel
          control={
            <Checkbox
              name="enabled"
              onChange={(e) => {
                if (!disableEdit) setTagEnabled(e.target.checked);
              }}
              checked={tagEnabled}
              inputProps={{
                //eslint-disable-next-line
                //@ts-ignore
                'data-testid': `${testId}EnabledCheckboxInput`,
              }}
            />
          }
          label="Enabled"
        />
        <Grid container direction="row" justifyContent="space-around" alignItems="flex-start">
          <Grid item xs={5}>
            <Button
              fullWidth
              type="submit"
              disabled={disableEdit || !isFormDirty || isWithErrors}
              variant="contained"
              data-testid={`${testId}SaveButton`}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              fullWidth
              type="reset"
              variant="text"
              disabled={disableEdit || !isFormDirty || isWithErrors}
              color="primary"
              onClick={resetForm}
              data-testid={`${testId}ClearQuitButton`}
            >
              {data.key ? 'Quit editing' : 'Clear form'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
