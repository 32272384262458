import { forwardRef, ReactNode, SyntheticEvent } from 'react';
import { Control, Controller } from 'react-hook-form';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import { Autocomplete, InputLabel, TextField } from 'styled';
import { InputDescription } from 'styled/InputDescription';

// Interface used for Google Place Autocomplete values used in form
export interface SelectedAddress {
  street: string;
  city: string;
  state: string;
  stateCode: string;
  zipCode: string;
  country: string;
  countryCode: string;
}

interface AddressAutocompleteProps {
  name: string;
  control: Control;
  testId: string;
  error?: boolean;
  helperText?: ReactNode;
  label?: ReactNode;
  description?: string;
  placeholder?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  noOptionsText?: string;
  disabled?: boolean;
  requiredMessage?: string;
  isAutoFocused?: boolean;
  setSelectedAddress: (address?: SelectedAddress | null | undefined) => void;
}

export const AddressAutocomplete = forwardRef(
  (
    {
      name,
      label,
      description,
      testId,
      placeholder,
      noOptionsText = 'Type in your address',
      control,
      error,
      helperText,
      disabled = false,
      requiredMessage,
      setSelectedAddress,
      isAutoFocused = false,
      variant = 'outlined',
      size = 'small',
    }: AddressAutocompleteProps,
    ref,
  ) => {
    const {
      ready,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      debounce: 300,
    });

    const extractAddress = (placeDetails: string | google.maps.places.PlaceResult) => {
      const address = {
        street: '',
        city: '',
        state: '',
        stateCode: '',
        zipCode: '',
        country: '',
        countryCode: '',
      };
      let streetNumber = '';
      let streetName = '';

      if (typeof placeDetails === 'string') {
        return address;
      }

      if (!Array.isArray(placeDetails?.address_components)) {
        return address;
      }

      placeDetails?.address_components.forEach((component: google.maps.GeocoderAddressComponent) => {
        const { types, long_name, short_name } = component;

        if (types.includes('street_number')) {
          streetNumber = long_name;
        }

        if (types.includes('route')) {
          streetName = long_name;
        }

        if (types.includes('locality')) {
          address.city = long_name;
        }

        if (types.includes('administrative_area_level_1')) {
          address.state = long_name;
          address.stateCode = short_name;
        }

        if (types.includes('postal_code')) {
          address.zipCode = long_name;
        }

        if (types.includes('country')) {
          address.country = long_name;
          address.countryCode = short_name;
        }
      });

      if (!!streetNumber) {
        address.street = `${streetNumber} ${streetName}`;
      } else {
        address.street = streetName;
      }

      return address;
    };

    const handleInput = (event: SyntheticEvent, value: string, reason: string) => {
      setValue(value);

      switch (reason) {
        case 'clear':
          clearSuggestions();
          break;
        default:
          break;
      }
    };

    const handleSelect = (event: SyntheticEvent, value: google.maps.GeocoderResult | null, reason: string) => {
      if (value === null) return;

      const params = {
        placeId: value?.place_id,
        fields: ['address_component'],
      };

      switch (reason) {
        case 'clear':
          clearSuggestions();
          break;

        default:
          getDetails(params).then((placeDetails: string | google.maps.places.PlaceResult) => {
            const extractedAddress = extractAddress(placeDetails) || null;
            setSelectedAddress(extractedAddress);
          });
          break;
      }
    };

    return (
      <Controller
        control={control}
        name={name}
        rules={requiredMessage ? { required: { value: true, message: requiredMessage } } : undefined}
        render={({ field }) => (
          <Autocomplete
            ref={ref}
            autoSelect
            fullWidth
            options={status === 'OK' ? data : []}
            value={field.value}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            onChange={handleSelect}
            onInputChange={handleInput}
            disabled={!ready || disabled}
            popupIcon={null}
            disableClearable
            noOptionsText={noOptionsText}
            renderInput={(params) => (
              <>
                {label && <InputLabel>{label}</InputLabel>}
                {description && <InputDescription>{description}</InputDescription>}
                <TextField
                  {...params}
                  {...field}
                  autoFocus={isAutoFocused}
                  placeholder={placeholder}
                  error={error}
                  helperText={helperText}
                  disabled={disabled}
                  variant={variant}
                  size={size}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': `${testId}AddressAutocompleteTextInput`,
                  }}
                />
              </>
            )}
          />
        )}
      />
    );
  },
);
