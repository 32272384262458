import { Control, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { PromotionQualifier } from '@one/api-models/lib/Campaign/Promotion/PromotionQualifier';

import ControlledSelect from 'components/_common/ControlledSelect';
import ControlledTextField from 'components/_common/ControlledTextField';

import { AudienceQualifiersTypes, QualifierTypeValues } from './promotionHelpers';

interface PromotionQualifierForm {
  type: number;
  qualifierId: string;
}

interface AddPromotionAudienceDialogProps {
  promotion: Promotion;
  open: boolean;
  testId: string;
  handleClose: () => void;
  handleAddQualifier: (item: PromotionQualifier) => Promise<void>;
}

const validationSchema: yup.SchemaOf<PromotionQualifierForm> = yup.object().shape({
  qualifierId: yup.string().trim().required('Audience is required.'),
  type: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .nullable()
    .max(64, 'Maximum 64 characters allowed.')
    .required('Audience type is required.')
    .typeError('Invalid number'),
});

export const AddPromotionAudienceDialog = ({
  open,
  promotion,
  testId,
  handleClose,
  handleAddQualifier,
}: AddPromotionAudienceDialogProps) => {
  const { control, handleSubmit, formState } = useForm<PromotionQualifierForm>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  const qualifierTypes = QualifierTypeValues.filter((item) => AudienceQualifiersTypes.includes(item.id));

  const handleAddQualifierToPromotion = async (data: PromotionQualifierForm) => {
    await handleAddQualifier({
      id: 0,
      promotionId: promotion.id,
      qualifierType: data.type,
      qualifierId: data.qualifierId,
      qualifierName: '',
      minAmount: 0,
      minQuantity: 0,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(handleAddQualifierToPromotion)} autoComplete="off">
        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {promotion.promotionSummary.name}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="small"
            disableRipple
            data-testid={`${testId}CloseButton`}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ my: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2} display="flex" alignItems="center">
              <Typography variant="subtitle1" fontWeight="bold">
                Audience:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <ControlledSelect
                control={control as unknown as Control<FieldValues, object>}
                name="type"
                label="Audience Type"
                fullWidth
                size="small"
                error={errors.type != null}
                helperText={errors.type?.message}
                testId={`${testId}AudienceType`}
              >
                {qualifierTypes.map((opt: any) => (
                  <MenuItem key={opt.id} value={opt.id} data-testid={`${testId}AudienceTypeSelectItem`}>
                    {opt.label}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </Grid>
            <Grid item xs={12} sm={5}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="qualifierId"
                fullWidth
                label="Audience ID"
                placeholder="Audience ID"
                error={errors.qualifierId != null}
                helperText={errors.qualifierId?.message}
                testId={`${testId}AudienceId`}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            size="small"
            disableRipple
            data-testid={`${testId}CancelButton`}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" size="small" data-testid={`${testId}AddButton`}>
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
