export enum ProductType {
  Hotel = 'Travel.Accommodation.Hotel',
  Resort = 'Travel.Accommodation.Resort',
  ResortCollection = 'Travel.Accommodation.Resort.Collection',
  Villa = 'Travel.Accommodation.Villa',
  OceanCruise = 'Travel.Cruise.Ocean',
  RiverCruise = 'Travel.Cruise.River',
  Wine = 'Retail.Wine',
  Merchandise = 'Retail.Merchandise',
  Car = 'Travel.Car',
  Experience = 'Travel.Experience',
  Weeks = 'Weeks',
}
