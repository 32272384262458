import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  DistributedTracingModes,
  IApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';

import appConfig from 'config/appConfig';

export const reactPlugin = new ReactPlugin();

const createTelemetryService = (): IApplicationInsights | undefined => {
  const instrumentationKey = appConfig.applicationInsightsKey;

  if (!instrumentationKey || instrumentationKey === '') {
    return undefined;
  }

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],

      disableFetchTracking: false,
      disableAjaxTracking: false,

      //changed to 'true' since React router v6
      enableAutoRouteTracking: true,
      distributedTracingMode: DistributedTracingModes.W3C,

      correlationHeaderDomains: [appConfig.apiDomain || ''],
      enableCorsCorrelation: true,
      disableCorrelationHeaders: false,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });

  appInsights.loadAppInsights();

  return appInsights;
};

export const appInsights = createTelemetryService();

const filteringFunction = (envelope: ITelemetryItem) => {
  if (envelope?.baseData?.responseCode === 0) {
    return false;
  }

  return true;
};

appInsights?.addTelemetryInitializer(filteringFunction);
