import { Typography as MuiTypography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Typography = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.MuiTypography-h1': {
    fontSize: '1.75rem', //28px
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.MuiTypography-h2': {
    fontSize: '1.625rem', //26px
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.MuiTypography-h3': {
    fontSize: '1.5rem', //24px
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.MuiTypography-h4': {
    fontSize: '1.375rem', //22px
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.MuiTypography-h5': {
    fontSize: '1.25rem', //20px
    lineHeight: '1.75rem', //28px
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.MuiTypography-h6': {
    fontSize: '1rem', //16px
    lineHeight: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.MuiTypography-subtitle1': {
    //subheading
    fontSize: '1rem', //16px
    lineHeight: '1.5rem',
    fontWeight: 500,
  },
  '&.MuiTypography-subtitle2': {
    //small subheading
    fontSize: '0.688rem', //11px
    lineHeight: '1.25rem',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.MuiTypography-body1': {
    //body
    fontSize: '0.875rem', //14px
    lineHeight: '1.25rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&.MuiTypography-body2': {
    //body emphasized
    fontSize: '0.875rem', //14px
    lineHeight: '1.25rem',
    fontWeight: 600,
  },
  '&.MuiTypography-caption': {
    //caption
    fontSize: '0.75rem', //12px
    lineHeight: '1rem',
  },
}));
