import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { FiatCurrencyDefinition } from '@one/api-models/lib/FiatCurrencyDefinition';

import { useFormat } from 'components/hooks/useFormat';

import { PromotionStatus } from '../PromotionStatus';

import { EditPromotionSummaryDialog } from './EditPromotionSummaryDialog';
import { PromotionTypeValues } from './promotionHelpers';

interface PromotionSummaryViewProps {
  promotion: Promotion;
  availableLanguageCodes: string[];
  availableCurrencies: FiatCurrencyDefinition[];
  testId: string;
  handleSavePromotion: (data: Promotion) => Promise<void>;
}

export const PromotionSummaryView = ({
  promotion,
  handleSavePromotion,
  availableCurrencies,
  testId,
  availableLanguageCodes,
}: PromotionSummaryViewProps) => {
  const [openEditSummaryDialog, setOpenEditSummaryDialog] = useState<boolean>(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { promotionSummary, marketingDetails } = promotion;
  const { formatDate } = useFormat();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button
          variant="text"
          onClick={() => setOpenEditSummaryDialog(true)}
          startIcon={<EditIcon />}
          data-testid={`${testId}EditButton`}
        >
          Edit
        </Button>
      </Grid>
      <Grid item container xs={12} md={9}>
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            Promotion Type:
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body1">{PromotionTypeValues[promotionSummary.type].label}</Typography>
        </Grid>
      </Grid>
      {!smallScreen && (
        <Grid item container xs={12} md={3}>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Box flexBasis="20%">
              <Typography variant="subtitle1" fontWeight="bold">
                Start:
              </Typography>
            </Box>
            <Typography variant="body1">
              {promotionSummary?.startDate
                ? `${formatDate(promotionSummary?.startDate, false, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                : ''}
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid item container xs={12} md={9} spacing={1}>
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            Promotion Name:
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body1">{promotionSummary.name}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            Promotion Description:
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body1">{promotionSummary.description}</Typography>
        </Grid>
      </Grid>

      {!smallScreen && (
        <Grid item container xs={12} md={3}>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Box flexBasis="18%">
              <Typography variant="subtitle1" fontWeight="bold">
                End:
              </Typography>
            </Box>
            <Typography variant="body1">
              {promotionSummary?.endDate
                ? `${formatDate(promotionSummary?.endDate, false, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                : ''}
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid item container xs={12} md={9} spacing={1}>
        <Grid item xs={12} md={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            Advertising Message:
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body1">{marketingDetails.advertisingMessage}</Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="subtitle1" fontWeight="bold">
            Advertising Description:
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body1">{marketingDetails.advertisingDescription}</Typography>
        </Grid>
      </Grid>

      {smallScreen && (
        <>
          <Grid item container xs={12} md={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              Start:
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="body1">
              {promotionSummary?.startDate
                ? `${formatDate(promotionSummary?.startDate, false, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                : ''}
            </Typography>
          </Grid>

          <Grid item container xs={12} md={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              End:
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="body1">
              {promotionSummary?.endDate
                ? `${formatDate(promotionSummary?.endDate, false, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                : ''}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          width="100%"
          justifyContent="space-between"
          mt={3}
        >
          <Box display="flex" flexBasis={{ xs: '100%', lg: '40%' }} flexDirection={{ xs: 'column', lg: 'row' }}>
            <Box display="flex">
              <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                Created by:
              </Typography>
              <Typography variant="body1">{promotionSummary?.createdBy}</Typography>
            </Box>
            <Box display="flex" pl={{ xs: 0, lg: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                Created:
              </Typography>
              <Typography variant="body1">
                {promotionSummary?.createdAt
                  ? `${formatDate(promotionSummary?.createdAt, true, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                  : ''}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            flexBasis={{ xs: '100%', lg: '60%' }}
            justifyContent="flex-end"
            flexDirection={{ xs: 'column', lg: 'row' }}
          >
            <Box display="flex">
              <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                Status:
              </Typography>
              {promotionSummary && <PromotionStatus status={promotionSummary?.status} />}
            </Box>
            <Box display="flex" pl={{ xs: 0, lg: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                Last Updated:
              </Typography>
              <Typography variant="body1">
                {promotionSummary?.updatedAt
                  ? `${formatDate(promotionSummary?.updatedAt, true, 'MMM dd, yyyy hh:mm aa')} (UTC)`
                  : ''}
              </Typography>
            </Box>
            <Box display="flex" pl={{ xs: 0, lg: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" pr={1}>
                Updated by:
              </Typography>
              <Typography variant="body1">{promotionSummary?.updatedBy}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <EditPromotionSummaryDialog
        promotion={promotion}
        open={openEditSummaryDialog}
        availableCurrencies={availableCurrencies}
        availableLanguageCodes={availableLanguageCodes}
        handleClose={() => setOpenEditSummaryDialog(false)}
        handleUpdatePromotion={handleSavePromotion}
        testId={`${testId}EditPromotionDialog`}
      />
    </Grid>
  );
};
