import { ReactElement, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import { Alert, Box, CircularProgress, Paper, Tab, Tabs } from '@mui/material';
import { EditRequest } from '@one/api-models/lib/Admin/Products/EditRequest';
import { EditResponse } from '@one/api-models/lib/Admin/Products/EditResponse';
import { LoadCriteria } from '@one/api-models/lib/Admin/Products/LoadCriteria';
import { LoadResponse } from '@one/api-models/lib/Admin/Products/LoadResponse';
import { ProductContentBase } from '@one/api-models/lib/Admin/Products/ProductContentBase';
import { ProductContentCriteriaBase } from '@one/api-models/lib/Admin/Products/ProductContentCriteriaBase';
import { Supplier } from '@one/api-models/lib/Admin/Products/Supplier';
import { UpdateProductBase } from '@one/api-models/lib/Admin/Products/UpdateProductBase';

import { ApiError } from 'apiAccess/api-client';
import { ConfirmationDialog } from 'components/_common/ConfirmationDialog';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';

import { ContentTab } from './components/ContentTab';
import { DescriptionsTab } from './components/DescriptionsTab';
import { DetailsTabProps } from './components/DetailsTab/DetailsBase';
import { MediaTab } from './components/MediaTab';
import { TagsTab } from './components/TagsTab';
import { getProductTypeConfig, ProductTypeConfig } from './ProductTypeConfig';

interface ProductDetailsTab {
  id: string;
  label: string;
  content: ReactElement;
  disabled?: boolean;
}

const a11yProps = (index: any) => {
  return {
    id: `product-details-tab-${index}`,
    'aria-controls': `product-details-tabpanel-${index}`,
  };
};

type TabPanelProps = {
  children: React.ReactNode;
  value: number;
  index: number;
};

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <Box
      sx={value === index ? { display: 'flex', flex: 1, p: 3, pt: 2 } : {}}
      role="tabpanel"
      hidden={value !== index}
      id={`details-tabpanel-${index}`}
      aria-labelledby={`details-tab-${index}`}
      {...other}
    >
      {value === index && <> {children} </>}
    </Box>
  );
};

export const Details = () => {
  const { apiErrorHandler, addMessage } = useToastMessage();
  const { api } = useApiHelpers();
  const [selectedTab, setSelectedTab] = useState(0);
  const { id, type: typeCode } = useParams<{ id: string; type: string }>();
  const query = new URLSearchParams(useLocation().search);
  const [product, setProduct] = useState<ProductContentBase | undefined | null>(undefined);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [formDirty, setFormDirty] = useState(false);
  const [tabChangeConfirmation, setTabChangeConfirmation] = useState<number | undefined>(undefined);
  const typeConfig = getProductTypeConfig(typeCode);
  const testId = 'Details';

  const buildProductDetailsCriteria = (type?: ProductTypeConfig) => {
    const criteria = {
      cultureCode: 'en-US',
      productId: id,
      partitionKey: query.get('partitionKey') ?? '',
    };

    return type
      ? ({
          $type: type.detailCriteriaApiType,
          ...criteria,
        } as ProductContentCriteriaBase)
      : null;
  };

  //#region Product LOAD

  const { data, isFetching } = useQuery<LoadResponse, ApiError>(
    ['productDetails', id, typeConfig],
    () => {
      const criteria = {
        tagIds: [],
        productDetailsCriteria: buildProductDetailsCriteria(typeConfig),
        cultureCode: 'en-US',
        $Type: 'One.Api.Models.Admin.Products.LoadCriteria',
      } as LoadCriteria;

      return api.products.load(criteria);
    },
    {
      enabled: true,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: apiErrorHandler,
    },
  );

  const setState = (product: ProductContentBase | undefined, suppliers: Supplier[] | undefined): void => {
    setProduct(product);
    if (suppliers) setSuppliers(suppliers);
  };

  const saveProductMutation = useMutation<
    EditResponse,
    ApiError,
    { data: UpdateProductBase; productConfig: ProductTypeConfig },
    unknown
  >(
    async ({ data, productConfig }: { data: UpdateProductBase; productConfig: ProductTypeConfig }) => {
      return await api.products.edit({
        $Type: EditRequest.$type,
        cultureCode: 'en-US',
        updateProduct: {
          $type: productConfig!.detailsUpdateApiType,
          ...data,
          productId: product!.id,
          cultureCode: 'en-US',
        } as UpdateProductBase,
      } as EditRequest);
    },
    {
      onSuccess: (value: EditResponse) => {
        if (value) {
          if (value.product) {
            setState({ ...value.product }, value.suppliers);
          } else {
            addMessage({ label: 'Error during product save', severity: 'error' });
          }
        }
      },
      onError: apiErrorHandler,
    },
  );

  useEffect(() => {
    if (data) setState(data.product, data.suppliers);
  }, [data]);

  //#endregion

  let tabs: ProductDetailsTab[] = [];

  if (product) {
    const productConfig = getProductTypeConfig(product.$type);
    const DetailsTab = productConfig?.detailsComponent as (props: DetailsTabProps) => JSX.Element;

    const saveHandler = (data: UpdateProductBase): void => {
      if (productConfig) saveProductMutation.mutate({ data, productConfig });
    };

    tabs = [
      {
        id: 'tags',
        label: 'Tags (MOCK)',
        disabled: false,
        content: <TagsTab product={product!} isLoading={saveProductMutation.isLoading} testId="TagsTab" />,
      },
      {
        id: 'details',
        label: 'Details',
        content: (
          <DetailsTab
            product={product}
            onSave={saveHandler}
            isLoading={saveProductMutation.isLoading}
            onFormDirty={setFormDirty}
            suppliers={suppliers}
            testId="DetailsTab"
          />
        ),
      },
      {
        id: 'description',
        label: 'Description',
        content: (
          <DescriptionsTab
            product={product!}
            onSave={saveHandler}
            isLoading={saveProductMutation.isLoading}
            onFormDirty={setFormDirty}
            disableEdit={productConfig?.disableEdit}
            testId="DescriptionTab"
          />
        ),
        disabled: Object.keys(product.descriptions).length === 0,
      },
      {
        id: 'contentItems',
        label: 'Content',
        content: (
          <ContentTab
            product={product!}
            onSave={saveHandler}
            isLoading={saveProductMutation.isLoading}
            onFormDirty={setFormDirty}
            disableEdit={productConfig?.disableEdit}
            testId="ContentTab"
          />
        ),
        disabled: Object.keys(product.contentItems).length === 0,
      },
      {
        id: 'media',
        label: 'Media',
        content: <MediaTab product={product!} isLoading={saveProductMutation.isLoading} testId="MediaTab" />,
      },
    ];
  }

  return (
    <Box>
      {product === null ? (
        <Paper>
          <Box>
            <Alert severity="warning" sx={{ mt: 3 }}>
              The selected product was not found.
            </Alert>
          </Box>
        </Paper>
      ) : isFetching || product === undefined ? (
        <Box sx={{ minHeight: '50vh' }} justifyContent="center" alignItems="center" display="flex">
          <CircularProgress data-testid="LoadingSpinner" />
        </Box>
      ) : (
        <>
          <PageHeader title={product?.name ?? 'Product'} testId="Product" />
          <Paper>
            <Tabs
              value={selectedTab}
              onChange={(e, v) => {
                if (formDirty) {
                  setTabChangeConfirmation(v);
                } else {
                  setSelectedTab(v);
                }
              }}
            >
              {tabs.map((tab: ProductDetailsTab, index: number) => (
                <Tab
                  label={tab.label}
                  {...a11yProps(index)}
                  key={tab.id}
                  disabled={tab.disabled || saveProductMutation.isLoading}
                  sx={{ textTransform: 'none', fontSize: '16px' }}
                  data-testid={`${tab.label.replace(' ', '')}TabButton`}
                />
              ))}
            </Tabs>
            {tabs.map((tab: ProductDetailsTab, index: number) => (
              <TabPanel value={selectedTab} index={index} key={tab.id}>
                {tab.content}
              </TabPanel>
            ))}
          </Paper>

          <ConfirmationDialog
            confirmButton="Discard"
            abortButton="Cancel"
            onClose={(result) => {
              if (result && tabChangeConfirmation !== undefined) {
                setSelectedTab(tabChangeConfirmation || 0);
                setFormDirty(false);
              }
              setTabChangeConfirmation(undefined);
            }}
            open={tabChangeConfirmation !== undefined}
            title="Discard the changes?"
            question="You are about to leave the form and loose the changes."
            testId={`${testId}DiscardChangesDialog`}
          />
        </>
      )}
    </Box>
  );
};
