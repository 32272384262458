import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

interface Props {
  open: boolean;
  testId: string;
  onClose: () => void;
}

export const NormalModalDialog = ({ open, testId, onClose }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle variant="h6" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>Normal Modal Dialog</Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
          disableRipple
          data-testid={`${testId}CloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item>
            <Box>
              <Typography variant="subtitle2" pb={1}>
                Text Field
              </Typography>
              <TextField placeholder="Text Field" fullWidth size="small" />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} size="small" disableRipple data-testid={`${testId}CancelButton`}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          size="small"
          disableRipple
          disabled
          data-testid={`${testId}Submit`}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
