import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Promotion } from '@one/api-models/lib/Campaign/Promotion/Promotion';
import { FiatCurrencyDefinition } from '@one/api-models/lib/FiatCurrencyDefinition';

import { PromotionSummaryForm } from './PromotionSummaryForm';

interface EditPromotionSummaryDialogProps {
  promotion: Promotion;
  availableLanguageCodes: string[];
  availableCurrencies: FiatCurrencyDefinition[];
  open: boolean;
  testId: string;
  handleClose: () => void;
  handleUpdatePromotion: (data: Promotion) => Promise<void>;
}

export const EditPromotionSummaryDialog = ({
  promotion,
  open,
  testId,
  handleClose,
  handleUpdatePromotion,
  availableCurrencies,
  availableLanguageCodes,
}: EditPromotionSummaryDialogProps) => {
  const handleSavePromotion = async (data: Promotion) => {
    await handleUpdatePromotion(data);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        Edit: {promotion.promotionSummary.name}
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="small"
          disableRipple
          data-testid={`${testId}CloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PromotionSummaryForm
          promotion={promotion}
          availableCurrencies={availableCurrencies}
          availableLanguageCodes={availableLanguageCodes}
          handleSavePromotion={handleSavePromotion}
          testId={testId}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          disableRipple
          data-testid={`${testId}CancelButton`}
        >
          Cancel
        </Button>
        <Button
          form="promotion-summary-form"
          type="submit"
          variant="contained"
          size="small"
          data-testid={`${testId}SaveButton`}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
