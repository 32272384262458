import { Box } from '@mui/material';
import { ProductDetailsBase } from '@one/api-models/lib/Admin/Products/ProductDetailsBase';

import { TaggingManager } from 'components/_common/TaggingManager';

type TagsTabProps = {
  product: ProductDetailsBase;
  isLoading: boolean;
  testId: string;
};

export const TagsTab = ({ product, testId }: TagsTabProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'flex-start',
        gap: 10,
        pt: 2,
      }}
    >
      <TaggingManager productId={product.id} testId={testId} />
    </Box>
  );
};
