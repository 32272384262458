import { Menu as MuiMenu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '.MuiMenu-paper': {
    minWidth: '200px',
    borderRadius: '8px',
    marginTop: '6px',
    padding: theme.spacing(1),
  },
}));
