import { lazy } from 'react';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

const AppAuthenticated = lazy(() => import('./AppAuthenticated'));
const AppUnauthenticated = lazy(() => import('./AppUnauthenticated'));

export function MsalAuthWrapper() {
  return (
    <>
      <AuthenticatedTemplate>
        <AppAuthenticated />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AppUnauthenticated />
      </UnauthenticatedTemplate>
    </>
  );
}
