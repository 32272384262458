import { useEffect, useMemo } from 'react';

import { Box, Button, Typography } from '@mui/material';
import {
  gridPageSelector,
  gridPaginationModelSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

interface PaginationProps {
  disablePaginationButtons?: boolean | null;
  resetPage?: boolean;
}

export const Pagination = ({ disablePaginationButtons, resetPage }: PaginationProps) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);

  const pageCount = useMemo(
    () => Math.ceil(rowCount / (paginationModel.pageSize || 1)),
    [rowCount, paginationModel.pageSize],
  );

  useEffect(() => {
    if (resetPage) {
      apiRef.current.setPage(0);
    }
    // eslint-disable-next-line
  }, [resetPage]);

  const pageSize = paginationModel.pageSize || 1;

  const startRow = page * pageSize + 1;
  const endRow = Math.min((page + 1) * pageSize, rowCount);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Typography>{`Viewing ${startRow}–${endRow} of ${rowCount} results`}</Typography>
      <Box>
        <Button
          variant="outlined"
          size="small"
          sx={{
            mr: 1,
            textTransform: 'none',
            width: '67px',
            height: '24px',
            padding: '8px 4px',
          }}
          disabled={page === 0}
          onClick={() => apiRef.current.setPage(page - 1)}
          data-testid="PaginationPreviousButton"
        >
          Previous
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ textTransform: 'none', width: '43px', height: '24px', padding: '8px 4px' }}
          disabled={disablePaginationButtons || page === pageCount - 1}
          onClick={() => apiRef.current.setPage(page + 1)}
          data-testid="PaginationNextButton"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
