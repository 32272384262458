import { ChangeEvent, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import * as XLSX from 'xlsx';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import PlaceIcon from '@mui/icons-material/Place';
import { Alert, Box, Button, Divider, Paper, Typography } from '@mui/material';

import { PageHeader } from 'components/_common/PageHeader';
import appConfig from 'config/appConfig';

export const MatchingHotels = () => {
  const [excelFile, setExcelFile] = useState<any>(null);
  const [selectFile, setSelectedFile] = useState<any>(null);
  const [excelData, setExcelData] = useState<any>(null);
  const [hotelDetails, setHotelDetails] = useState<any>(null);
  const [previousMatch, setPreviousMatch] = useState<any>(null);
  const testIdPrefix = 'MatchingHotels';
  const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;

    if (selectedFile) {
      setSelectedFile(selectedFile);
      if (selectedFile && fileType.includes(selectedFile.type)) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          if (e?.target?.result) setExcelFile(e.target.result);
        };
      } else {
        setExcelFile(null);
      }
    }
  };

  const handleSuccess = () => {
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: 'buffer' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data);
      setHotelDetails(
        data?.find((element: any) => !element.hasOwnProperty('Match.Status') || element['Match.Status'] === ''),
      );
    } else {
      setExcelData(null);
    }
  };

  const onMatch = () => {
    if (hotelDetails) {
      const details = { ...(hotelDetails as object), 'Match.Status': 'Matched' };
      const objIndex = excelData.findIndex(
        (obj: any) =>
          obj['Master.ProductId'] === hotelDetails['Master.ProductId'] &&
          obj['Supplier.Reference'] === hotelDetails['Supplier.Reference'] &&
          (obj['Match.Status'] === '' || !obj.hasOwnProperty('Match.Status')),
      );
      excelData[objIndex] = details;
      setPreviousMatch(objIndex);
      setExcelData(excelData);
      setHotelDetails(
        excelData?.find((element: any) => !element.hasOwnProperty('Match.Status') || element['Match.Status'] === ''),
      );
    }
  };

  const onSkip = () => {
    if (hotelDetails) {
      const details = { ...(hotelDetails as object), 'Match.Status': 'Unmatched' };
      const objIndex = excelData.findIndex(
        (obj: any) =>
          obj['Master.ProductId'] === hotelDetails['Master.ProductId'] &&
          obj['Supplier.Reference'] === hotelDetails['Supplier.Reference'] &&
          (obj['Match.Status'] === '' || !obj.hasOwnProperty('Match.Status')),
      );
      excelData[objIndex] = details;
      setPreviousMatch(objIndex);
      setExcelData(excelData);
      setHotelDetails(
        excelData?.find((element: any) => !element.hasOwnProperty('Match.Status') || element['Match.Status'] === ''),
      );
    }
  };

  const onPrevious = () => {
    if (previousMatch) setHotelDetails(excelData[previousMatch]);
  };

  const exportData = () => {
    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'TestSheet');
    XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });

    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
    XLSX.writeFile(workBook, `HotelBedsData-${new Date()}.xlsx`);
  };

  const clearData = () => {
    setExcelFile(null);
    setExcelData(null);
    setSelectedFile(null);
    setHotelDetails(null);
  };

  return (
    <Box>
      <PageHeader title="Matching Engine" testId={testIdPrefix} />
      <Paper sx={{ mb: 2, pt: 3, minHeight: '80vh' }}>
        {excelData == null && (
          <>
            <Typography fontSize="1.25rem" lineHeight={1.6} mb={2}>
              Upload .xlsx file here
            </Typography>
            <Box sx={{ display: 'flex', ml: 2 }}>
              <Button variant="contained" component="label" data-testid={`${testIdPrefix}ChooseFileButton`}>
                Choose file
                <input
                  type="file"
                  onChange={handleFile}
                  accept={'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                  required
                  hidden
                  data-testid={`${testIdPrefix}ChooseFileInput`}
                />
              </Button>

              <Typography sx={{ m: 'auto 0', ml: 2 }}>
                {excelFile == null ? 'No file chosen' : selectFile.name}
              </Typography>

              <Button onClick={handleSuccess} sx={{ ml: 5 }} data-testid={`${testIdPrefix}SubmitButton`}>
                Submit
              </Button>
            </Box>
          </>
        )}

        {hotelDetails && (
          <Box width="100%">
            <Box sx={{ display: 'flex', width: '100%', mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ flexBasis: '12%', backgroundColor: '#1976d2', height: 'fit-content' }}
                startIcon={<ArrowBackIosIcon />}
                onClick={onPrevious}
                disabled={previousMatch === undefined}
                data-testid={`${testIdPrefix}PreviousButton`}
              >
                Previous
              </Button>

              <Box sx={{ flexBases: '68%', width: '100%', mr: 4, mt: 5 }}>
                <Box sx={{ display: 'flex', my: 1 }}>
                  <Typography
                    variant="h5"
                    sx={{ mr: 2, flexBasis: '15%', display: 'flex', justifyContent: 'flex-end' }}
                  >
                    ProductId
                  </Typography>
                  <Typography fontSize="1.5rem" ml={12}>
                    {hotelDetails['Master.ProductId']}
                  </Typography>
                </Box>
                <TileDetails
                  title="Name"
                  masterDetails={hotelDetails['Master.Name']}
                  supplierDetails={hotelDetails['Supplier.Name']}
                  toLowerCase={true}
                />
                <Divider />
                <TileDetails
                  title="Address"
                  masterDetails={hotelDetails['Master.Address']}
                  supplierDetails={hotelDetails['Supplier.Address']}
                  toLowerCase={true}
                />
                <Divider />
                <TileDetails
                  title="City"
                  masterDetails={hotelDetails['Master.City']}
                  supplierDetails={hotelDetails['Supplier.City']}
                  toLowerCase={true}
                />
                <Divider />
                <TileDetails
                  title="Postal Code"
                  masterDetails={hotelDetails['Master.PostalCode']}
                  supplierDetails={hotelDetails['Supplier.PostalCode']}
                />
                <Divider />
                <TileDetails
                  title="Geocodes"
                  masterDetails={hotelDetails['Master.LatLong']}
                  supplierDetails={hotelDetails['Supplier.LatLong']}
                />

                <Box sx={{ display: 'flex', my: 1 }}>
                  <Typography
                    variant="h5"
                    sx={{ mr: 2, flexBasis: '15%', display: 'flex', justifyContent: 'flex-end' }}
                  >
                    Distance
                  </Typography>
                  <Typography fontSize="1.5rem" ml={12}>
                    {hotelDetails['LatLong.DistanceInMeters']}
                  </Typography>
                </Box>

                <EmbeddedMap
                  masterLatLong={hotelDetails['Master.LatLong']}
                  supplierLatLong={hotelDetails['Supplier.LatLong']}
                />
              </Box>
              <Box
                sx={{
                  flexBasis: '20%',
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Box sx={{ display: 'flex', mb: 6 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<CloseIcon />}
                      sx={{ backgroundColor: '#f44336', mr: 1.5 }}
                      onClick={onSkip}
                      data-testid={`${testIdPrefix}SkipButton`}
                    >
                      Skip
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<CheckCircleOutlineIcon />}
                      onClick={onMatch}
                      data-testid={`${testIdPrefix}MatchButton`}
                    >
                      Match
                    </Button>
                  </Box>
                  <Box sx={{ m: '0 auto ' }}>
                    <TileMatchScore title="Name" matchScore={hotelDetails['Name.MatchScore']} />
                    <TileMatchScore title="Address" matchScore={hotelDetails['Address.MatchScore']} />
                    <TileMatchScore title="Total" matchScore={hotelDetails['Total.MatchScore']} />
                  </Box>
                </Box>

                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  sx={{ backgroundColor: '#2196f3', mt: 3 }}
                  onClick={exportData}
                  data-testid={`${testIdPrefix}ExportButton`}
                >
                  Export
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {!hotelDetails && excelData && (
          <>
            <Box sx={{ display: 'flex', width: '100%', mb: 2 }}>
              <Alert severity="info" sx={{ width: '100%' }}>
                <Typography> All products were checked, please export your file!</Typography>
              </Alert>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                sx={{ backgroundColor: '#2196f3', ml: 3 }}
                onClick={() => {
                  exportData();
                  clearData();
                }}
                data-testid={`${testIdPrefix}ExportButton`}
              >
                Export
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

type TileProps = {
  title: string;
  masterDetails?: string;
  supplierDetails?: string;
  toLowerCase?: boolean;
};

const TileDetails: React.FC<TileProps> = ({ title, masterDetails, supplierDetails, toLowerCase = false }) => {
  let masterDet = masterDetails;
  let supplierDet = supplierDetails;
  let sameDetails = false;

  if (toLowerCase) {
    masterDet = masterDetails && masterDetails !== '' ? masterDetails?.toLowerCase() : undefined;
    supplierDet = supplierDetails && supplierDetails !== '' ? supplierDetails?.toLowerCase() : undefined;
  }
  sameDetails = masterDet && supplierDet ? masterDet === supplierDet : false;

  return (
    <>
      {(masterDet || supplierDet) && (
        <Box sx={{ display: 'flex', my: 1 }}>
          <Typography variant="h5" sx={{ mr: 2, flexBasis: '15%', display: 'flex', justifyContent: 'flex-end' }}>
            {title}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {masterDetails && (
              <Box sx={{ display: 'inline-flex' }}>
                <Typography variant="subtitle2" color="primary" align="right" sx={{ mr: 2, my: 'auto', width: '80px' }}>
                  MASTER
                </Typography>
                <Typography
                  fontSize="1.5rem"
                  textTransform="lowercase"
                  sx={{ color: sameDetails ? '#48AE50' : '#000000' }}
                >
                  {masterDetails}
                </Typography>
              </Box>
            )}

            {supplierDetails && (
              <Box sx={{ display: 'inline-flex' }}>
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  align="right"
                  sx={{ mr: 2, my: 'auto', width: '80px' }}
                >
                  SUPPLIER
                </Typography>
                <Typography
                  fontSize="1.5rem"
                  textTransform="lowercase"
                  sx={{ color: sameDetails ? '#48AE50' : '#000000' }}
                >
                  {supplierDetails}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

type TileMatchScoreProps = {
  title: string;
  matchScore: number;
};

const TileMatchScore: React.FC<TileMatchScoreProps> = ({ title, matchScore }) => {
  return (
    <Box sx={{ m: '0 auto ', py: 2 }}>
      <Typography textAlign="center" fontWeight="bold">
        {title}
      </Typography>
      <Typography color="#48AE50" fontSize="1.25rem" fontWeight="bold" textAlign="center">
        {matchScore}%
      </Typography>
    </Box>
  );
};

type MapCoordProps = {
  masterLatLong: string;
  supplierLatLong: string;
};

type MapProps = {
  lat: number;
  lng: number;
  color: 'primary' | 'secondary';
};

const EmbeddedMap: React.FC<MapCoordProps> = ({ masterLatLong, supplierLatLong }) => {
  const masterCoord = masterLatLong && masterLatLong !== '' ? masterLatLong.split('|') : null;
  const supplierCoord = supplierLatLong && supplierLatLong !== '' ? supplierLatLong.split('|') : null;

  const center = masterCoord
    ? { lat: +masterCoord[0], lng: +masterCoord[1] }
    : supplierCoord
    ? { lat: +supplierCoord[0], lng: +supplierCoord[1] }
    : undefined;

  return (
    <>
      {(masterCoord || supplierCoord) && (
        <Box sx={{ display: 'flex', width: '600px', height: '400px', m: '0 auto', mt: 3 }}>
          <GoogleMapReact bootstrapURLKeys={{ key: appConfig.googleMapsKey || '' }} center={center} defaultZoom={15}>
            {masterCoord && <Marker lat={+masterCoord[0]} lng={+masterCoord[1]} color="primary" />}
            {supplierCoord && <MarkerSupplier lat={+supplierCoord[0]} lng={+supplierCoord[1]} color="secondary" />}
          </GoogleMapReact>
        </Box>
      )}
    </>
  );
};

const Marker = ({ lat, lng, color }: MapProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        transform: 'translate(-50%, -100%)',
        width: '20px',
        height: '20px',
      }}
    >
      <PlaceIcon color={color} />
    </Box>
  );
};

const MarkerSupplier = ({ lat, lng, color }: MapProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        transform: 'translate(-50%, -100%)',
        width: '20px',
        height: '20px',
      }}
    >
      <AddCircleOutlineIcon color={color} />
    </Box>
  );
};
