import { ExportRequest } from '@one/api-models/lib/Campaign/PromoCode/Request/ExportRequest';
import { ListRequest } from '@one/api-models/lib/Campaign/PromoCode/Request/ListRequest';
import { RetrieveRequest } from '@one/api-models/lib/Campaign/PromoCode/Request/RetrieveRequest';
import { ListResponse } from '@one/api-models/lib/Campaign/PromoCode/Response/ListResponse';
import { RetrieveResponse } from '@one/api-models/lib/Campaign/PromoCode/Response/RetrieveResponse';

import { FileResponse, IApiClient } from './api-client';

export interface IPromoCodeAccess {
  load(criteria: ListRequest): Promise<ListResponse>;
  retrieve(request: RetrieveRequest): Promise<RetrieveResponse>;
  export(request: ExportRequest): Promise<FileResponse>;
}

export class PromoCodeAccess implements IPromoCodeAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  load(request: ListRequest): Promise<ListResponse> {
    return this.apiClient.call('promocode/list', {
      ...request,
      $Type: ListRequest.$type,
      cultureCode: 'en-US',
    });
  }

  retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
    return this.apiClient.call('promocode/retrieve', {
      ...request,
      $Type: RetrieveRequest.$type,
      cultureCode: 'en-US',
    });
  }

  export(request: ExportRequest): Promise<FileResponse> {
    return this.apiClient.call('promocode/export', {
      ...request,
      $Type: ExportRequest.$type,
      cultureCode: 'en-US',
    });
  }
}
