import { RouteKey } from 'models/RouteKey';

export enum StorageKeys {
  BREADCRUMBS = 'breadcrumbs',
  ACTIVE_PARTNER_KEY = 'activePartnerKey',
  ACTIVE_BRAND_KEY = 'activeBrandKey',
}

export function setBreadcrumbsInStorage(type: RouteKey, data: any) {
  const currentData = getBreadcrumbsFromStorage();
  currentData[type] = data;
  sessionStorage.setItem(StorageKeys.BREADCRUMBS, JSON.stringify(currentData));
}

export function getBreadcrumbsFromStorage() {
  const data = sessionStorage.getItem(StorageKeys.BREADCRUMBS);

  if (!data) return new Map<string, any>();

  return JSON.parse(data);
}

export function deleteBreadcrumbsFromStorage() {
  sessionStorage.removeItem(StorageKeys.BREADCRUMBS);
}

export function setActiveBrandAndPartnerInStorage(brandKey: string, partnerKey?: string) {
  sessionStorage.setItem(StorageKeys.ACTIVE_BRAND_KEY, brandKey);
  localStorage.setItem(StorageKeys.ACTIVE_BRAND_KEY, brandKey);
  if (!!partnerKey) {
    sessionStorage.setItem(StorageKeys.ACTIVE_PARTNER_KEY, partnerKey);
    localStorage.setItem(StorageKeys.ACTIVE_PARTNER_KEY, partnerKey);
  }
}

export function getActiveBrandFromStorage() {
  return sessionStorage.getItem(StorageKeys.ACTIVE_BRAND_KEY) || localStorage.getItem(StorageKeys.ACTIVE_BRAND_KEY);
}

export function getActivePartnerFromStorage() {
  return sessionStorage.getItem(StorageKeys.ACTIVE_PARTNER_KEY) || localStorage.getItem(StorageKeys.ACTIVE_PARTNER_KEY);
}
