import { forwardRef, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

import { FormHelperText, SelectProps, TextField } from '@mui/material';
import { SxProps } from '@mui/system';

interface ControlledSelectProps {
  name: string;
  control: Control;
  children: ReactNode;
  label?: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
  selectProps?: Partial<SelectProps<unknown>>;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  onChange?: (value?: string) => void;
  error?: boolean;
  helperText?: ReactNode;
  testId: string;
}

const ControlledSelect = forwardRef(
  (
    {
      name,
      label,
      control,
      disabled,
      sx,
      children,
      size = 'small',
      variant = 'outlined',
      fullWidth,
      onChange,
      error,
      helperText,
      testId,
      selectProps,
    }: ControlledSelectProps,
    ref,
  ) => {
    return (
      <>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <TextField
              {...field}
              select
              onChange={(ev) => {
                field.onChange(ev.target.value);
                if (onChange) onChange(ev.target.value);
              }}
              SelectProps={selectProps}
              label={label}
              disabled={disabled}
              fullWidth={fullWidth}
              sx={sx}
              size={size}
              variant={variant}
              error={error}
              inputProps={{ 'data-testid': `${testId}SelectInput` }}
            >
              {children}
            </TextField>
          )}
        />
        {!!helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </>
    );
  },
);

export default ControlledSelect;
