import { forwardRef, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface ControlledDatePickerProps {
  name: string;
  control: Control;
  testId: string;
  error?: boolean;
  helperText?: ReactNode;
  label?: ReactNode;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  openTo?: 'day' | 'year';
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  minDate?: Date;
  maxDate?: Date;
  onChange?: (value?: Date | null) => void;
}

const ControlledDatePicker = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      control,
      testId,
      error,
      helperText,
      fullWidth,
      disableFuture = false,
      disablePast = false,
      disabled = false,
      openTo = 'day',
      minDate,
      maxDate,
      variant = 'outlined',
      size = 'small',
      onChange,
    }: ControlledDatePickerProps,
    ref: any,
  ) => {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              {...field}
              inputRef={ref}
              onChange={(value) => {
                field.onChange(value);
                if (onChange) onChange(value);
              }}
              OpenPickerButtonProps={{
                tabIndex: -1,
                disableRipple: true,
                disableFocusRipple: true,
              }}
              disableFuture={disableFuture}
              disablePast={disablePast}
              disabled={disabled}
              openTo={openTo}
              minDate={minDate}
              maxDate={maxDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder,
                    'data-testid': `${testId}DatePickerInput`,
                  }}
                  label={label}
                  error={error}
                  helperText={helperText}
                  fullWidth={fullWidth}
                  size={size}
                  variant={variant}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    );
  },
);

export default ControlledDatePicker;
