import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DepositPlan } from '@one/api-models/lib/Admin/PaymentPlan/DepositPlan';
import { InstallmentPlan } from '@one/api-models/lib/Admin/PaymentPlan/InstallmentPlan';
import { PaymentPlanBase } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanBase';

import { CustomPagination } from 'components/_common/DataGrid/CustomPagination';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { useStyles } from 'components/_common/DataGrid/dataGridStyling';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';

interface Props {
  paymentPlanDetails: PaymentPlanBase | DepositPlan | InstallmentPlan | undefined;
}

const pageSize = 5;

export const PaymentPlanProductSection = (props: Props) => {
  const { paymentPlanDetails } = props;
  const productData = paymentPlanDetails ? [{ ...paymentPlanDetails, id: paymentPlanDetails.paymentPlanId }] : [];

  const dataGridClasses = useStyles();
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const columns: GridColDef[] = [
    {
      field: 'paymentPlanId',
      headerName: 'Product',
      sortable: false,
      flex: 3,
      cellClassName: 'noPL',
      headerClassName: 'noPL',
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {params.row.items[0].name}
          </Typography>
          {params.row.nextPaymentAmount && (
            <Typography variant="body2" color="#6A7383" sx={{ mt: 0.5 }}>
              Next invoice on {formatDate(new Date(params.row.nextPaymentDueDate), true, 'MMM dd')} for{' '}
              {formatCurrency(params.row.nextPaymentAmount.amount, params.row.nextPaymentAmount.currency)}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      headerAlign: 'left',
      align: 'left',
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => (
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {formatCurrency(params.row.total.amount, params.row.total.currency)}
        </Typography>
      ),
    },
    {
      field: 'paid',
      headerName: 'Paid',
      headerAlign: 'left',
      align: 'left',
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => (
        <>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {formatCurrency(params.row.totalPaid.amount, params.row.totalPaid.currency)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              ml: 0.5,
              mb: 0,
              fontWeight: 500,
              color: (theme) => {
                if (params.row.paidPercentage < 10) {
                  return theme.paymentPlan.paymentStatusError;
                } else if (params.row.paidPercentage >= 10 && params.row.paidPercentage < 50) {
                  return theme.paymentPlan.paymentStatusWarning;
                } else {
                  return theme.paymentPlan.paymentStatusSuccess;
                }
              },
            }}
          >
            {`(${params.row.paidPercentage}%)`}
          </Typography>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      align: 'right',
      flex: 1,
      renderCell: (params: any) => <div />,
    },
  ];

  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Product" variant="h4" />

      {productData && productData.length > 0 ? (
        <DataGrid
          rows={productData.map((item: any) => ({ ...item, id: item.id }))}
          columns={columns}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: pageSize,
              },
            },
          }}
          getRowHeight={() => 'auto'}
          columnHeaderHeight={30}
          hideFooter={productData?.length <= pageSize}
          disableColumnMenu
          components={{
            Pagination: CustomPagination,
          }}
          className={dataGridClasses.root}
        />
      ) : (
        <Typography variant="body1">No products available for this payment plan</Typography>
      )}
    </Box>
  );
};
