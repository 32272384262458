import { createApi } from 'apiAccess/api';
import { useAuthWrapperHelpers } from 'components/hooks/useAuthWrapperHelpers';
import { UserData } from 'models/UserData';

export const useApiHelpers = () => {
  const { requestToken } = useAuthWrapperHelpers();

  const loadUser = (): UserData | null => {
    const defaultUserData: UserData = {
      cultureCode: 'en-US',
    };

    return defaultUserData;
  };

  return {
    api: createApi(loadUser, requestToken),
  };
};
