import { Tooltip as MuiTooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

interface StyledTooltipProps extends TooltipProps {
  maxWidth?: string | number;
  fontSize?: string | number;
}

export const Tooltip = styled(({ className, fontSize = 11, maxWidth = 'auto', ...props }: StyledTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth, fontSize }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    fontSize,
    maxWidth,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));
