import { Box, Grid, Paper, Skeleton } from '@mui/material';
import { MemberSummary as CustomerSummaryModel } from '@one/api-models/lib/Admin/ProgramSales/Common/MemberSummary';

import { SectionTitle } from 'components/_common/SectionTitle';
import { Typography } from 'styled';

import { CustomerStatus } from '../../../_common/CustomerStatus';

interface CustomerSummaryProps {
  data: CustomerSummaryModel;
}

export const CustomerSummary = ({ data }: CustomerSummaryProps) => {
  return (
    <Paper sx={{ p: 3 }}>
      <SectionTitle title="Customer Details" />
      <Grid container>
        <Grid item xs={12} xl={4} sx={{ pt: 2, pb: { xs: 0, xl: 2 }, borderBottom: { xl: '1px solid #E0E0E0' } }}>
          <Typography variant="body2">Name</Typography>
        </Grid>
        <Grid item xs={12} xl={8} sx={{ pb: 2, pt: { xs: 1, xl: 2 }, borderBottom: '1px solid #E0E0E0' }}>
          <Typography variant="body1">{`${data.firstName} ${data.lastName}`}</Typography>
        </Grid>
        <Grid item xs={12} xl={4} sx={{ pt: 2, pb: { xs: 0, xl: 2 }, borderBottom: { xl: '1px solid #E0E0E0' } }}>
          <Typography variant="body2">Status</Typography>
        </Grid>
        <Grid item xs={12} xl={8} sx={{ pb: 2, pt: { xs: 1, lg: 2 }, borderBottom: '1px solid #E0E0E0' }}>
          <Box>
            <CustomerStatus status={data.status} />
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} sx={{ pt: 2, pb: { xs: 0, xl: 2 }, borderBottom: { xl: '1px solid #E0E0E0' } }}>
          <Typography variant="body2">Mobile Phone</Typography>
        </Grid>
        <Grid item xs={12} xl={8} sx={{ pb: 2, pt: { xs: 1, lg: 2 }, borderBottom: '1px solid #E0E0E0' }}>
          <Typography variant="body1">{data.mobilePhone}</Typography>
        </Grid>
        <Grid item xs={12} xl={4} sx={{ pt: 2, pb: { xs: 0, xl: 2 }, borderBottom: { xl: '1px solid #E0E0E0' } }}>
          <Typography variant="body2">Email</Typography>
        </Grid>
        <Grid item xs={12} xl={8} sx={{ pb: 2, pt: { xs: 1, lg: 2 }, borderBottom: '1px solid #E0E0E0' }}>
          <Typography variant="body1">{data.email}</Typography>
        </Grid>
        <Grid item xs={12} xl={4} sx={{ pt: 2, pb: { xs: 0, xl: 2 } }}>
          <Typography variant="body2">Member Key</Typography>
        </Grid>
        <Grid item xs={12} xl={8} sx={{ pb: 2, pt: { xs: 1, lg: 2 } }}>
          <Typography variant="body1">{data.memberKey}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const CustomerSummarySkeleton = () => {
  return (
    <Paper sx={{ p: 3 }}>
      <Skeleton variant="rectangular" height={35} width={200} />
      <Skeleton variant="rectangular" height={308} sx={{ mt: 4 }} />
    </Paper>
  );
};
