import { BreadcrumbMatch } from 'use-react-router-breadcrumbs';

import { getBreadcrumbsFromStorage } from 'config/sessionStorage';

interface DynamicBreadcrumbsProps {
  match: BreadcrumbMatch;
}

export const DynamicBreadcrumbs = ({ match }: DynamicBreadcrumbsProps) => {
  const routeKey = (match as any).route['key'];
  const defaultLabel = (match as any).route['label'];

  const breadcrumbsData = getBreadcrumbsFromStorage();
  const currentData = breadcrumbsData[routeKey];
  const label = match.params.id && currentData?.id.toString() === match.params.id ? currentData.label : defaultLabel;

  return <span>{label}</span>;
};
