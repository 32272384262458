import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box } from '@mui/material';

import appConfig from 'config/appConfig';
import { customerSpecificSearchBrands } from 'models/customers/constants';
import { Customer } from 'models/customers/Customer';
import { selectActiveBrand } from 'slices/applicationDataSlice';
import { Button, IconButton, Menu, MenuItem, Typography } from 'styled';

import { AddCustomerDialog } from './AddCustomerDialog';
import { CustomerSelectorAutocomplete } from './CustomerSelectorAutocomplete';
import { PrincessCustomerSelector } from './PrincessCustomerSelector';

interface CustomerSelectorProps {
  testId: string;
  disabled?: boolean;
  handleChange: (customer: Customer | null) => void;
  defaultValue: Customer | null;
}

export const CustomerSelector = ({ testId, disabled, defaultValue, handleChange }: CustomerSelectorProps) => {
  const [addCustomerOpen, setAddCustomerOpen] = useState<boolean>(false);
  const [showSelector, setShowSelector] = useState<boolean>(defaultValue === null);
  const activeBrand = useSelector(selectActiveBrand);
  const allowBrandSpecific = activeBrand
    ? customerSpecificSearchBrands.includes(activeBrand?.key) && !!appConfig.isMCMSingleIdentityEnabled
    : false;

  useEffect(() => {
    setShowSelector(defaultValue === null);
  }, [defaultValue]);

  const handleCloseAddCustomer = (customer: Customer) => {
    handleChange(customer);
    setAddCustomerOpen(false);
  };

  const handleCustomerChange = (customer: Customer | null) => {
    handleChange(customer);
    setShowSelector(!customer);
  };
  const handleOpenSwitchCustomer = () => {
    handleChange(null);
    setShowSelector(true);
  };
  return (
    <>
      {!allowBrandSpecific ? (
        <>
          {showSelector || !defaultValue ? (
            <CustomerSelectorAutocomplete
              defaultValue={defaultValue}
              handleChange={handleCustomerChange}
              testId={testId}
              disabled={disabled}
            />
          ) : (
            <Box display="flex">
              <Box mr={2}>
                <Typography variant="body2">{`${defaultValue?.firstName} ${defaultValue?.lastName}`}</Typography>
                <Typography variant="body1">{defaultValue?.email?.toLocaleLowerCase()}</Typography>
              </Box>
              <CustomerMenuButton
                onAddCustomer={() => setAddCustomerOpen(true)}
                onSwitchCustomer={handleOpenSwitchCustomer}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          {showSelector ? (
            <PrincessCustomerSelector
              handleChange={handleCustomerChange}
              testId={testId}
              open={showSelector}
              handleClose={() => {
                setShowSelector(false);
              }}
            />
          ) : (
            <>
              {!!defaultValue ? (
                <Box display="flex">
                  <Box mr={2}>
                    <Typography variant="body2">{`${defaultValue?.firstName} ${defaultValue?.lastName}`}</Typography>
                    <Typography variant="body1">{defaultValue?.email?.toLocaleLowerCase()}</Typography>
                  </Box>
                  <CustomerMenuButton
                    onAddCustomer={() => setAddCustomerOpen(true)}
                    onSwitchCustomer={handleOpenSwitchCustomer}
                  />
                </Box>
              ) : (
                <Button variant="outlined" onClick={() => setShowSelector(true)}>
                  Find Customer
                </Button>
              )}
            </>
          )}
        </>
      )}
      <AddCustomerDialog
        open={addCustomerOpen}
        callback={handleCloseAddCustomer}
        handleClose={() => setAddCustomerOpen(false)}
      />
    </>
  );
};

interface CustomerMenuButtonProps {
  onAddCustomer: () => void;
  onSwitchCustomer: () => void;
}

const CustomerMenuButton = ({ onAddCustomer, onSwitchCustomer }: CustomerMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onAddCustomer();
            handleClose();
          }}
        >
          Add Customer
        </MenuItem>
        <MenuItem
          onClick={() => {
            onSwitchCustomer();
            handleClose();
          }}
        >
          Switch Customer
        </MenuItem>
      </Menu>
    </>
  );
};
