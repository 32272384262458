import { LoadCriteria } from "./LoadCriteria";

export class LoadInitialCriteria extends LoadCriteria {
  public static $Type = "LoadInitialCriteria";

  constructor() {
    super();
    this.productId = '';
  }

  productId: string;
}
