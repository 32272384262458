import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ButtonProps extends MuiButtonProps {
  customColor?: string;
}

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ButtonProps>(({ theme, customColor }) => ({
  textTransform: 'initial',
  borderRadius: 7,

  '&:active': {
    boxShadow: `0 0 0 4px ${theme.customPalette.input.borderFocus}`,
  },
  '&.MuiButton-outlined': {
    color: customColor || theme.palette.text.primary,
    borderColor: customColor || theme.customPalette.input.border,
    boxShadow: '0px 2px 5px 0px #3C425714, 0px 1px 1px 0px #0000001F',

    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '0px 1px 1px 0px #0000001F, 0px 2px 5px 0px #3C425714, 0px 3px 9px 0px #3C425714',
    },
  },
  '&.MuiButton-contained': {
    backgroundColor: customColor || theme.palette.primary.main,
    color: customColor ? theme.palette.getContrastText(customColor) : undefined,
    '&:hover': {
      backgroundColor: customColor || theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  '&.MuiButton-sizeSmall': {
    height: '24px',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 600,
  },
  '&.MuiButton-sizeMedium': {
    height: '30px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
  },
  '&.MuiButton-sizeLarge': {
    height: '40px',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
  },
}));
