import { DialogTitle as MuiDialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: '#fff',
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'space-between',
  height: '48px',

  '.MuiIconButton-root': {
    height: '48px',
  },
}));
