import { Chip } from '@mui/material';
import { PromotionStatus as PromotionStatusEnum } from '@one/api-models/lib/Campaign/Promotion/PromotionStatus';

type StatusSettings = {
  type: number;
  name: string;
  backgroundColor: string;
  color: string;
  borderColor?: string;
};

const statusSettingsNew: StatusSettings[] = [
  {
    type: PromotionStatusEnum.Draft,
    name: 'Draft',
    backgroundColor: '#FDE5D9',
    color: '#B12D00',
  },
  {
    type: PromotionStatusEnum.Active,
    name: 'Active',
    backgroundColor: '#DDF6C7',
    color: '#2D681E',
  },
  {
    type: PromotionStatusEnum.Running,
    name: 'Running',
    backgroundColor: '#B0E2FF',
    color: '#00688B',
  },
  {
    type: PromotionStatusEnum.Paused,
    name: 'Paused',
    backgroundColor: '#fff',
    color: '#00688B',
    borderColor: '#B0E2FF',
  },
  {
    type: PromotionStatusEnum.Ended,
    name: 'Ended',
    backgroundColor: '#fff',
    color: '#2D681E',
    borderColor: '#2D681E',
  },
  {
    type: PromotionStatusEnum.Deleted,
    name: 'Deleted',
    backgroundColor: '#ffcdd2',
    color: '#c62828',
  },
  {
    type: PromotionStatusEnum.Ended,
    name: 'Archived',
    backgroundColor: '#f5f5f5',
    color: '#424242',
  },
];

export const PromotionStatus = ({ status }: { status: number }) => {
  const statusSetting = statusSettingsNew.find((el) => {
    return el.type === status;
  }) || {
    name: status,
    backgroundColor: '#f5f5f5',
    color: '#000',
    borderColor: '#fff',
  };
  return (
    <Chip
      sx={{
        maxHeight: '20px',
        backgroundColor: statusSetting.backgroundColor,
        color: statusSetting.color,
        borderRadius: '4px',
        fontWeight: 500,
        border: statusSetting.borderColor ? `1px solid ${statusSetting.borderColor}` : 'none',
      }}
      size="small"
      label={statusSetting.name}
    />
  );
};
