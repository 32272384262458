import CloseIcon from '@mui/icons-material/Close';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LayoutConfig } from 'components/layout/LayoutConfig';
import { Drawer, IconButton, SwipeableDrawer } from 'styled';

import { SideTreeMenu } from './SideTreeMenu';

interface AppSidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
  testId: string;
  config?: LayoutConfig;
  disablePartnerSelect: boolean;
}

export const AppMenuSidebar = ({ open, handleDrawerClose, testId, config, disablePartnerSelect }: AppSidebarProps) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'sticky',
        top: 0,
        justifyContent: 'flex-end',
      }}
    >
      {smallScreen ? (
        <>
          <SwipeableDrawer onClose={handleDrawerClose} onOpen={() => {}} open={open}>
            <Box>
              <IconButton sx={{ px: 0 }} onClick={handleDrawerClose} size="medium">
                <CloseIcon />
              </IconButton>
            </Box>
            <SideTreeMenu
              testId={testId}
              config={config}
              disablePartnerSelect={disablePartnerSelect}
              handleDrawerClose={handleDrawerClose}
            />
          </SwipeableDrawer>
        </>
      ) : (
        <Drawer
          variant="permanent"
          drawerWidth={{
            fixedWidth: '228px',
          }}
          open={true}
        >
          <SideTreeMenu
            testId={testId}
            config={config}
            disablePartnerSelect={disablePartnerSelect}
            handleDrawerClose={handleDrawerClose}
          />
        </Drawer>
      )}
    </Box>
  );
};
