import { useMutation } from 'react-query';

import { Alert, Box, CircularProgress } from '@mui/material';
import { EditOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/EditOrderRequest';
import { EditOrderResponse } from '@one/api-models/lib/Admin/Orders/Common/EditOrderResponse';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Membership/Account/Travel/Cruise/CruiseOrderItemSummary';
import { EditTraveler } from '@one/api-models/lib/Membership/Account/Travel/Cruise/EditTraveler';

import { ApiError } from 'apiAccess/api-client';
import { AccessControlGuard } from 'components/_common/AccessControlGuard';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';

import { EditTravelersForm } from './Assets/EditTravelersForm';

interface Props {
  orderNumber: string;
  cruiseOrderItem?: CruiseOrderItemSummary;
  testId: string;
  handleOnTravelersEditFinished: () => void;
  handleCloseEditTravelersForm: () => void;
  isEditTravelersOpen: boolean;
  editTravelerSupportedEditOperation?: EditTraveler;
}

export const EditTravelersSection = ({
  cruiseOrderItem,
  orderNumber,
  testId,
  handleOnTravelersEditFinished,
  handleCloseEditTravelersForm,
  isEditTravelersOpen,
  editTravelerSupportedEditOperation,
}: Props) => {
  const { api } = useApiHelpers();
  const { apiErrorHandler, addMessage } = useToastMessage();

  const {
    isLoading,
    data: editTravelerResponse,
    mutate,
  } = useMutation<any, ApiError, EditOrderRequest, unknown>((request) => api.sales.editOrder(request), {
    mutationKey: 'editTravelersMutation',
    onSuccess: (value: EditOrderResponse) => {
      if (value.isSuccessful) {
        addMessage({
          label: 'Traveler names changed successfully.',
          severity: 'success',
        });
      }
      handleOnTravelersEditFinished();
    },
    onError: (error) => {
      apiErrorHandler(error);
      handleOnTravelersEditFinished();
    },
  });

  if (!cruiseOrderItem && !editTravelerSupportedEditOperation) return null;

  const handleSubmitForm = (request: EditOrderRequest): void => {
    mutate(request);
  };

  return (
    <AccessControlGuard permission={['Sales.Orders:Edit']}>
      {isEditTravelersOpen && (
        <>
          {isLoading ? (
            <Box sx={{ height: 150 }} justifyContent="center" alignItems="center" display="flex">
              <CircularProgress data-testid="LoadingSpinner" />
            </Box>
          ) : (
            <Box mt={4}>
              {editTravelerResponse != null ? (
                <Box mb={2}>
                  <Alert
                    variant="standard"
                    sx={{ mb: 2 }}
                    severity={editTravelerResponse.isSuccessful ? 'success' : 'error'}
                  >
                    {editTravelerResponse.isSuccessful ? (
                      'Traveler names changed successfully.'
                    ) : (
                      <>
                        <strong>An error has occurred!</strong> The traveler names are not updated.
                      </>
                    )}
                  </Alert>

                  {editTravelerResponse.messages != null && (
                    <ul>
                      {editTravelerResponse.messages.map((message: string) => (
                        <li key={message}>{message}</li>
                      ))}
                    </ul>
                  )}
                </Box>
              ) : (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={0}>
                    <SectionTitle title="Change Traveler Names" />
                  </Box>

                  <Box mb={2}>
                    <EditTravelersForm
                      cruiseOrderItem={cruiseOrderItem}
                      orderNumber={orderNumber}
                      onCloseForm={handleCloseEditTravelersForm}
                      onSubmitForm={handleSubmitForm}
                      editTravelerSupportedEditOperation={editTravelerSupportedEditOperation}
                      testId={testId}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}
        </>
      )}
    </AccessControlGuard>
  );
};
