import { createElement } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import UndoIcon from '@mui/icons-material/Undo';
import { OrderItemStatus } from '@one/api-models/lib/Admin/Sales/OrderItemStatus';
import { TransactionStatus } from '@one/api-models/lib/Admin/Sales/TransactionStatus';
import { OrderFulfillmentStatus } from '@one/api-models/lib/Membership/Account/OrderFulfillmentStatus';
import { OrderPaymentStatus } from '@one/api-models/lib/Membership/Account/OrderPaymentStatus';
import { OrderStatus } from '@one/api-models/lib/Membership/Account/OrderStatus';

import { StatusColors } from './StatusColors';

export type StatusOption = {
  name: string;
  backgroundColor: string;
  color: string;
  icon?: JSX.Element;
};

export type StatusOptions = {
  [key: string]: StatusOption;
};

export const orderStatusOptions: StatusOptions = {
  [OrderStatus.Unknown]: {
    name: 'Unknown',
    backgroundColor: StatusColors.Unknown.background,
    color: StatusColors.Unknown.label,
  },
  [OrderStatus.Draft]: {
    name: 'Draft',
    backgroundColor: StatusColors.Draft.background,
    color: StatusColors.Draft.label,
  },
  [OrderStatus.OrderCreated]: {
    name: 'Created',
    backgroundColor: StatusColors.OrderCreated.background,
    color: StatusColors.OrderCreated.label,
  },
  [OrderStatus.OrderConfirmed]: {
    name: 'Confirmed',
    backgroundColor: StatusColors.OrderConfirmed.background,
    color: StatusColors.OrderConfirmed.label,
  },
  [OrderStatus.OrderCompleted]: {
    name: 'Completed',
    backgroundColor: StatusColors.OrderCompleted.background,
    color: StatusColors.OrderCompleted.label,
    icon: createElement(DoneIcon),
  },
  [OrderStatus.OrderCancelled]: {
    name: 'Canceled',
    backgroundColor: StatusColors.OrderCancelled.background,
    color: StatusColors.OrderCancelled.label,
  },
  [OrderStatus.OrderRefunded]: {
    name: 'Refunded',
    backgroundColor: StatusColors.OrderRefunded.background,
    color: StatusColors.OrderRefunded.label,
  },
  [OrderStatus.OrderArchived]: {
    name: 'Archived',
    backgroundColor: StatusColors.OrderArchived.background,
    color: StatusColors.OrderArchived.label,
  },
  [OrderStatus.Failed]: {
    name: 'Failed',
    backgroundColor: StatusColors.Failed.background,
    color: StatusColors.Failed.label,
  },
  [OrderStatus.CancellationRequested]: {
    name: 'Cancellation Requested',
    backgroundColor: StatusColors.CancellationRequested.background,
    color: StatusColors.CancellationRequested.label,
  },
  [OrderStatus.CancellationInProgress]: {
    name: 'Cancellation In Progress',
    backgroundColor: StatusColors.CancellationInProgress.background,
    color: StatusColors.CancellationInProgress.label,
  },
};

export const orderFulfillmentStatusOptions: StatusOptions = {
  [OrderFulfillmentStatus.Unknown]: {
    name: 'Unknown',
    backgroundColor: StatusColors.Unknown.background,
    color: StatusColors.Unknown.label,
  },
  [OrderFulfillmentStatus.FulfillmentInitiated]: {
    name: 'Initiated',
    backgroundColor: StatusColors.FulfillmentInitiated.background,
    color: StatusColors.FulfillmentInitiated.label,
  },
  [OrderFulfillmentStatus.FulfillmentCompleted]: {
    name: 'Fulfilled',
    backgroundColor: StatusColors.FulfillmentCompleted.background,
    color: StatusColors.FulfillmentCompleted.label,
    icon: createElement(DoneIcon),
  },
};

export const orderPaymentStatusOptions: StatusOptions = {
  [OrderPaymentStatus.Unknown]: {
    name: 'Unknown',
    backgroundColor: StatusColors.Unknown.background,
    color: StatusColors.Unknown.label,
  },
  [OrderPaymentStatus.Unpaid]: {
    name: 'Unpaid',
    backgroundColor: StatusColors.Unpaid.background,
    color: StatusColors.Unpaid.label,
  },
  [OrderPaymentStatus.PartiallyPaid]: {
    name: 'Partially Paid',
    backgroundColor: StatusColors.PartiallyPaid.background,
    color: StatusColors.PartiallyPaid.label,
  },
  [OrderPaymentStatus.FullyPaid]: {
    name: 'Fully Paid',
    backgroundColor: StatusColors.FullyPaid.background,
    color: StatusColors.FullyPaid.label,
    icon: createElement(DoneIcon),
  },
  [OrderPaymentStatus.PartiallyRefunded]: {
    name: 'Partially Refunded',
    backgroundColor: StatusColors.PartiallyRefunded.background,
    color: StatusColors.PartiallyRefunded.label,
  },
  [OrderPaymentStatus.FullyRefunded]: {
    name: 'Fully Refunded',
    backgroundColor: StatusColors.FullyRefunded.background,
    color: StatusColors.FullyRefunded.label,
  },
  [OrderPaymentStatus.Cancelled]: {
    name: 'Cancelled',
    backgroundColor: StatusColors.Cancelled.background,
    color: StatusColors.Cancelled.label,
  },
  [OrderPaymentStatus.AwaitingPayment]: {
    name: 'Awaiting Payment Authorization',
    backgroundColor: StatusColors.AwaitingPayment.background,
    color: StatusColors.AwaitingPayment.label,
  },
  [OrderPaymentStatus.PendingPaymentAuthorization]: {
    name: 'Pending Payment Authorization',
    backgroundColor: StatusColors.PendingPaymentAuthorization.background,
    color: StatusColors.PendingPaymentAuthorization.label,
  },
};
export const transactionStatusOptions: StatusOptions = {
  [TransactionStatus.New]: {
    name: 'New',
    backgroundColor: StatusColors.New.background,
    color: StatusColors.New.label,
  },
  [TransactionStatus.Succeeded]: {
    name: 'Succeeded',
    backgroundColor: StatusColors.Succeeded.background,
    color: StatusColors.Succeeded.label,
    icon: createElement(DoneIcon),
  },
  [TransactionStatus.Rejected]: {
    name: 'Rejected',
    backgroundColor: StatusColors.Rejected.background,
    color: StatusColors.Rejected.label,
    icon: createElement(ClearIcon),
  },
  [TransactionStatus.Failed]: {
    name: 'Failed',
    backgroundColor: StatusColors.Failed.background,
    color: StatusColors.Failed.label,
  },
  [TransactionStatus.Pending]: {
    name: 'Pending',
    backgroundColor: StatusColors.Pending.background,
    color: StatusColors.Pending.label,
  },
  [TransactionStatus.PendingCapture]: {
    name: 'Pending Capture',
    backgroundColor: StatusColors.PendingCapture.background,
    color: StatusColors.PendingCapture.label,
  },
  [TransactionStatus.Cancelled]: {
    name: 'Cancelled',
    backgroundColor: StatusColors.Cancelled.background,
    color: StatusColors.Cancelled.label,
    icon: createElement(UndoIcon),
  },
  [TransactionStatus.Expired]: {
    name: 'Expired',
    backgroundColor: StatusColors.Expired.background,
    color: StatusColors.Expired.label,
  },
  [TransactionStatus.PendingRefund]: {
    name: 'Pending Refund',
    backgroundColor: StatusColors.PendingRefund.background,
    color: StatusColors.PendingRefund.label,
  },
  [TransactionStatus.Refunded]: {
    name: 'Refunded',
    backgroundColor: StatusColors.Refunded.background,
    color: StatusColors.Refunded.label,
    icon: createElement(UndoIcon),
  },
  [TransactionStatus.Blocked]: {
    name: 'Blocked',
    backgroundColor: StatusColors.Blocked.background,
    color: StatusColors.Blocked.label,
  },
  [TransactionStatus.Disputed]: {
    name: 'Disputed',
    backgroundColor: StatusColors.Disputed.background,
    color: StatusColors.Disputed.label,
  },
  [TransactionStatus.EarlyFraudWarning]: {
    name: 'Early Fraud Warning',
    backgroundColor: StatusColors.EarlyFraudWarning.background,
    color: StatusColors.EarlyFraudWarning.label,
  },
  [TransactionStatus.Incomplete]: {
    name: 'Incomplete',
    backgroundColor: StatusColors.Incomplete.background,
    color: StatusColors.Incomplete.label,
  },
  [TransactionStatus.PartiallyPaid]: {
    name: 'Partially Paid',
    backgroundColor: StatusColors.PartiallyPaid.background,
    color: StatusColors.PartiallyPaid.label,
  },
  [TransactionStatus.PartiallyRefunded]: {
    name: 'Partially Refunded',
    backgroundColor: StatusColors.PartiallyRefunded.background,
    color: StatusColors.PartiallyRefunded.label,
  },
  [TransactionStatus.Uncaptured]: {
    name: 'Uncaptured',
    backgroundColor: StatusColors.Uncaptured.background,
    color: StatusColors.Uncaptured.label,
  },
  [TransactionStatus.WaitingOnFunding]: {
    name: 'Waiting On Funding',
    backgroundColor: StatusColors.WaitingOnFunding.background,
    color: StatusColors.WaitingOnFunding.label,
  },
  [TransactionStatus.Deleted]: {
    name: 'Deleted',
    backgroundColor: StatusColors.Deleted.background,
    color: StatusColors.Deleted.label,
  },
};

export const orderItemStatusOptions: StatusOptions = {
  [OrderItemStatus.Unknown]: {
    name: 'Unknown',
    backgroundColor: StatusColors.Unknown.background,
    color: StatusColors.Unknown.label,
  },
  [OrderItemStatus.New]: {
    name: 'New',
    backgroundColor: StatusColors.New.background,
    color: StatusColors.New.label,
  },
  [OrderItemStatus.Reserved]: {
    name: 'Reserved',
    backgroundColor: StatusColors.Pending.background,
    color: StatusColors.Pending.label,
  },
  [OrderItemStatus.Ordered]: {
    name: 'Ordered',
    backgroundColor: StatusColors.OrderConfirmed.background,
    color: StatusColors.OrderConfirmed.label,
  },
  [OrderItemStatus.Paid]: {
    name: 'Paid',
    backgroundColor: StatusColors.FullyPaid.background,
    color: StatusColors.FullyPaid.label,
    icon: createElement(DoneIcon),
  },
  [OrderItemStatus.Cancelled]: {
    name: 'Canceled',
    backgroundColor: StatusColors.OrderCancelled.background,
    color: StatusColors.OrderCancelled.label,
  },
  [OrderItemStatus.Failed]: {
    name: 'Failed',
    backgroundColor: StatusColors.Failed.background,
    color: StatusColors.Failed.label,
  },
  [OrderItemStatus.Fulfilled]: {
    name: 'Fulfilled',
    backgroundColor: StatusColors.FulfillmentCompleted.background,
    color: StatusColors.FulfillmentCompleted.label,
  },
  [OrderItemStatus.PreparingShipping]: {
    name: 'Preparing Shipping',
    backgroundColor: StatusColors.Pending.background,
    color: StatusColors.Pending.label,
  },
  [OrderItemStatus.Shipped]: {
    name: 'Shipped',
    backgroundColor: StatusColors.OrderCompleted.background,
    color: StatusColors.OrderCompleted.label,
  },
};
