import { Control, FieldValues } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { Address } from '@one/api-models/lib/Admin/Products/Address';

import ControlledAutocomplete from 'components/_common/ControlledAutocomplete';
import ControlledTextField from 'components/_common/ControlledTextField';
import { selectCountryList } from 'slices/customersDataSlice';

import { FormProps } from '../DetailsTab/DetailsBase';

import { FieldBox } from './FieldBox';

interface AddressFieldsProps {
  formProps: FormProps;
  testId: string;
  address?: Address;
  isLoading?: boolean;
}

export const AddressFields = ({ address, formProps, testId, isLoading }: AddressFieldsProps) => {
  const countryList = useSelector(selectCountryList);

  const { control } = formProps;

  if (!address) {
    address = {} as Address;
  }

  const errors = formProps?.errors?.address;

  return (
    <FieldBox title="Address">
      <Grid container direction="row" alignItems="stretch">
        <Grid item xs={12} sx={{ mb: 3 }}>
          <ControlledTextField
            name="address.line1"
            control={control as unknown as Control<FieldValues, object>}
            fullWidth
            label="Address line 1"
            disabled={isLoading}
            error={errors?.line1?.message != null}
            helperText={errors?.line1?.message}
            testId={`${testId}AddressLine1`}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <ControlledTextField
            name="address.line2"
            control={control as unknown as Control<FieldValues, object>}
            fullWidth
            label="Address line 2"
            disabled={isLoading}
            error={errors?.line2?.message != null}
            helperText={errors?.line2?.message}
            testId={`${testId}AddressLine2`}
          />
        </Grid>

        <Grid item xs={6} sx={{ pr: 1, mb: 3 }}>
          <ControlledTextField
            name="address.city"
            control={control as unknown as Control<FieldValues, object>}
            fullWidth
            label="City"
            disabled={isLoading}
            error={errors?.city?.message != null}
            helperText={errors?.city?.message}
            testId={`${testId}City`}
          />
        </Grid>
        <Grid item xs={6} sx={{ pl: 1, mb: 3 }}>
          <ControlledTextField
            name="address.stateProvinceName"
            control={control as unknown as Control<FieldValues, object>}
            fullWidth
            label="State"
            disabled={isLoading}
            error={errors?.stateProvinceName?.message != null}
            helperText={errors?.stateProvinceName?.message}
            testId={`${testId}State`}
          />
        </Grid>

        <Grid item xs={6} sx={{ pr: 1, mb: 3 }}>
          <ControlledTextField
            name="address.postalCode"
            control={control as unknown as Control<FieldValues, object>}
            size="small"
            fullWidth
            label="Zip Code"
            disabled={isLoading}
            error={errors?.postalCode?.message != null}
            helperText={errors?.postalCode?.message}
            testId={`${testId}PostalCode`}
          />
        </Grid>
        <Grid item xs={6} sx={{ pl: 1 }}>
          <ControlledAutocomplete
            name="address.countryCode"
            label="Country/Region"
            placeholder="Country/Region"
            options={countryList || []}
            error={errors?.countryCode?.message != null}
            helperText={errors?.countryCode?.message}
            testId={`${testId}Country`}
            control={control as unknown as Control<FieldValues, object>}
            disabled={isLoading}
          />
        </Grid>
      </Grid>
    </FieldBox>
  );
};
