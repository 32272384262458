import { Link, Typography, useTheme } from '@mui/material';

import { ReactComponent as FeedbackIcon } from 'assets/icons/feedback.svg';

interface SendFeedbackProps {
  testId: string;
}

export const SendFeedback = ({ testId }: SendFeedbackProps) => {
  const theme = useTheme();
  return (
    <Link
      display="flex"
      alignItems="center"
      underline="none"
      color={theme.palette.text.primary}
      mr={1}
      href="mailto:salestoolfeedback@onecompany.com"
      target="_blank"
      data-testid={`${testId}SendFeedbackButton`}
    >
      <FeedbackIcon fill={theme.palette.text.primary} />
      <Typography variant="body1" ml={1} sx={{ display: { xs: 'none', lg: 'flex' } }}>
        Send feedback
      </Typography>
    </Link>
  );
};
