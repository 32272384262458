export enum StorageKeys {
  AUTH_TOKEN = 'token',
  USER = 'user',
  SSO_SESSION = 'sso_session',
}

export function setTokenInStorage(token: string) {
  localStorage.setItem(StorageKeys.AUTH_TOKEN, token);
}

export function getTokenFromStorage() {
  const data = localStorage.getItem(StorageKeys.AUTH_TOKEN);

  if (!data) return undefined;

  return data;
}

export function deleteTokenFromStorage() {
  localStorage.removeItem(StorageKeys.AUTH_TOKEN);
}

export function setSSOSessionInStorage(isSsoSession: boolean) {
  localStorage.setItem(StorageKeys.SSO_SESSION, JSON.stringify(isSsoSession));
}

export function getSSOSessionFromStorage() {
  const isSSOSession = localStorage.getItem(StorageKeys.SSO_SESSION);

  return !!isSSOSession ? Boolean(JSON.parse(isSSOSession)) : false;
}

export function deleteSSOSessionFromStorage() {
  localStorage.removeItem(StorageKeys.SSO_SESSION);
}
