import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import UploadIcon from '@mui/icons-material/Upload';
import { Alert, Box, Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import { AddMediaBase } from '@one/api-models/lib/Admin/Media/AddMediaBase';
import { AddMediaRequest } from '@one/api-models/lib/Admin/Media/AddMediaRequest';
import { StoreRequestBase } from '@one/api-models/lib/Admin/Media/StoreRequestBase';

import { FileDetails } from '../fileManager/FileDetails';
import { QueueListing, UploadStatus } from '../fileManager/QueueListing';
import { useStyles } from '../fileUploadDropZone/Styles';

import { MediaConfiguration } from './MediaConfiguration';

export const MediaDropZone = ({
  disabled,
  storeRecordMutation,
  storeRequestBase,
  testId,
}: {
  disabled?: boolean;
  storeRecordMutation: any;
  storeRequestBase: StoreRequestBase;
  testId: string;
}) => {
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.new);
  const classes = useStyles();
  const [hideList, setHideList] = useState(false);
  const acceptedFiles = 'image/*';

  useEffect(() => {
    if (
      storeRecordMutation &&
      storeRecordMutation.variables &&
      storeRecordMutation.variables.storeRequest &&
      storeRecordMutation.variables.storeRequest.$Type === AddMediaRequest.$type
    ) {
      if (storeRecordMutation.isLoading) {
        setUploadStatus(UploadStatus.uploading);
      } else if (storeRecordMutation.isSuccess) {
        setUploadStatus(UploadStatus.completed);
      } else if (storeRecordMutation.isError) {
        setUploadStatus(UploadStatus.error);
      }
    } else {
      setFiles([]);
    }
  }, [storeRecordMutation]);

  const onAddFiles = useCallback(
    (newFiles: FileDetails[]) => {
      const addActions = newFiles.map((f) => MediaConfiguration.fileDetailsToAddMediaRequest(f));
      setFiles(newFiles);
      Promise.all(addActions)
        .then((results) => {
          const mediaFiles = results.filter((f) => f) as AddMediaBase[];
          storeRecordMutation.mutate({
            storeRequest: {
              $Type: AddMediaRequest.$type,
              mediaFiles: mediaFiles,
              ...storeRequestBase,
            } as AddMediaRequest,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [storeRecordMutation, storeRequestBase],
  );

  const onHideListHandler = () => {
    setHideList(true);
  };

  const openFilePicker = () => {
    if (!disabled) {
      open();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onAddFiles(
          acceptedFiles.map(
            (x: any) =>
              ({
                name: x.name,
                size: x.size,
                type: `${x.type}`,
                file: x,
              } as FileDetails),
          ),
        );

        setHideList(false);
      }
    },
    [onAddFiles],
  );

  const { getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noDrag: disabled,
    onDrop,
    accept: acceptedFiles,
  });

  return (
    <Box>
      <Box
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
          className:
            classes.uploadDropZone +
            ' ' +
            (isDragActive && classes.uploadDropZoneHover) +
            ' ' +
            (isDragReject && classes.uploadDropZoneRejected),
        })}
      >
        <input {...getInputProps()} data-testid={`${testId}Input`} />
        <IconButton
          size="large"
          sx={{ mt: 2 }}
          className="uploadTextColor"
          onClick={openFilePicker}
          disabled={disabled}
          data-testid={`${testId}UploadButton`}
        >
          <UploadIcon fontSize="large" />
        </IconButton>
        <Typography
          variant="h5"
          sx={{ mb: 2, cursor: disabled ? 'default' : 'pointer' }}
          className="uploadTextColor"
          onClick={openFilePicker}
        >
          Drop files here to upload
        </Typography>
        {isDragReject && <Alert severity="error">At least one of the files is not in the accepted format</Alert>}
        <Collapse in={files.length > 0 && !hideList}>
          <Box>
            <QueueListing files={files} onHide={onHideListHandler} status={uploadStatus} testId={testId} />
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export const MediaDropZoneSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height={158} />
    </Box>
  );
};
