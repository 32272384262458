import React from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';
import { EditMemberRequest } from '@one/api-models/lib/Admin/Members/Request/EditMemberRequest';
import { EditMemberResponse } from '@one/api-models/lib/Admin/Members/Response/EditMemberResponse';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { CustomerDetailsForm, EditCustomerForm } from './EditCustomerForm';

interface Props {
  isOpen: boolean;
  customer: EditMemberRequest;
  testId: string;
  handleClose: () => void;
}

export const AdditionalCustomerDetails: React.FC<Props> = ({ isOpen, customer, testId, handleClose }: Props) => {
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();
  const { currentZonedDateToISODate, isoDateToCurrentZonedDate } = useFormat();
  const navigate = useNavigate();
  const activeBrand = useSelector(selectActiveBrand);

  const defaultValues: CustomerDetailsForm = {
    memberKey: customer.memberKey,
    salutation: customer.salutation ?? '',
    firstName: customer.firstName ?? '',
    lastName: customer.lastName ?? '',
    email: customer.emailAddress ?? '',
    phoneNumber: customer.phoneNumber ?? '',
    dateOfBirth: isoDateToCurrentZonedDate(customer.dateOfBirth) ?? null,
    countryCode: customer.country ?? 'US',
    address: customer.address ?? '',
    address2: customer.address2 ?? '',
    city: customer.city ?? '',
    stateCode: customer.state ?? '',
    zipCode: customer.zipCode ?? '',
    externalMemberKey: customer.externalMemberKey ?? '',
    partnerLoyaltyId: customer.partnerLoyaltyId ?? '',
    notes: customer.notes ?? '',
  };

  const createCustomerMutation = useMutation<EditMemberResponse, ApiError, EditMemberRequest, unknown>(
    async (request) => api.members.edit(request),
    {
      onSuccess: (value: EditMemberResponse) => {
        if (value.memberKey) {
          navigate(`/customers/${value.memberKey}`);
        }
      },
      onError: apiErrorHandler,
    },
  );

  const handleCustomerSubmit = (data: CustomerDetailsForm) => {
    const request: EditMemberRequest = {
      $Type: EditMemberRequest.$type,
      memberKey: data.memberKey || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      emailAddress: data.email || '',
      phoneNumber: data.phoneNumber,
      dateOfBirth: data.dateOfBirth ? currentZonedDateToISODate(data.dateOfBirth) : undefined,
      brandKey: activeBrand?.key ?? '',
      status: MemberStatus.Inactive,
      country: data.countryCode,
      city: data.city,
      zipCode: data.zipCode,
      state: data.stateCode,
      address: data.address,
      address2: data.address2,
      salutation: data.salutation,
      externalMemberKey: data.externalMemberKey,
      leadSource: '',
      partnerLoyaltyId: data.partnerLoyaltyId,
      notes: data.notes,
    };

    createCustomerMutation.mutate(request);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle variant="h6" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>Additional Customer Information</Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="small"
          data-testid={`${testId}AdditionalInformationCloseButton`}
          disableRipple
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <EditCustomerForm
        country={customer.cultureCode}
        defaultValues={defaultValues}
        isLoading={createCustomerMutation.isLoading}
        handleCustomerSubmit={handleCustomerSubmit}
        handleCancel={handleClose}
        key={customer.memberKey}
        testId={`${testId}EditCustomerForm`}
      />
    </Dialog>
  );
};
