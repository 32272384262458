import { AddFile } from '@one/api-models/lib/Admin/Files/AddFile';
import { AddFilesBase } from '@one/api-models/lib/Admin/Files/AddFilesBase';

import { FileDetails } from '../fileManager/FileDetails';

import { getFileContent } from './FileHelper';

export interface FileConfigurationItem {
  typeName: string;
  isOfThisType: (fileType: string) => boolean;
  fileDetailsToAddFileRequest: (fileDetails: FileDetails) => Promise<AddFilesBase>;
}

const fileConfigs: FileConfigurationItem[] = [
  {
    typeName: 'application/',
    isOfThisType: (fileType: string) => {
      return fileType.startsWith('application/');
    },

    fileDetailsToAddFileRequest: async (fileDetails: FileDetails): Promise<AddFilesBase> =>
      new Promise(function (resolve, reject) {
        getFileContent(fileDetails.file)
          .then(function (fileContentResult) {
            resolve({
              $type: AddFile.$type,
              size: fileDetails.size,
              contentType: fileDetails.type,
              content: fileContentResult.content,
            } as AddFile);
          })
          .catch(function (err) {
            reject(err);
          });
      }),
  },
];

const getFileConfiguration = (fileType: string): FileConfigurationItem | undefined => {
  return fileConfigs.find((x) => x.isOfThisType(fileType));
};

const fileDetailsToAddFileRequest = (fileDetails: FileDetails): Promise<AddFilesBase> | undefined => {
  const config = fileConfigs.find((x) => x.isOfThisType(fileDetails.type));
  if (config) {
    return config.fileDetailsToAddFileRequest(fileDetails);
  }
  return undefined;
};

export const FileConfiguration = {
  getFileConfiguration,
  fileDetailsToAddFileRequest,
};
