import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';

interface ActionMenuProps {
  onDetailsClick?: () => void;
  onViewCustomerClick?: () => void;
  onCopyIdClick?: () => void;
  tableType: 'transactions' | 'salesOrders' | 'orderDetails' | 'orderTransactions';
}

export const ActionMenu = ({ onDetailsClick, onViewCustomerClick, onCopyIdClick, tableType }: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  type TableType = 'transactions' | 'salesOrders' | 'orderDetails' | 'orderTransactions';

  const tableText: { [key in TableType]: { text1?: string; text2?: string; text3?: string } } = {
    transactions: {
      text1: 'Copy transaction ID',
      text2: 'View customer',
      text3: 'View payment details',
    },
    salesOrders: {
      text1: 'Copy order number',
      text2: 'View customer',
    },
    orderDetails: {
      text1: 'Copy supplier reference',
    },
    orderTransactions: {
      text1: 'Copy transaction ID',
      text2: 'View customer',
      text3: 'View transaction details',
    },
  };

  return (
    <Box
      sx={{
        height: '28px',
        '&:hover': {
          border: '1px solid #808080',
          borderRadius: 2,
        },
        display: 'flex',
        alignItems: 'center',
      }}
      className="menuOptionButton"
    >
      <IconButton
        onClick={handleClick}
        sx={{
          rotate: '90deg',
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 2 },
        }}
      >
        {tableText[tableType].text1 && (
          <Box>
            <Typography sx={{ fontSize: '12px', fontWeight: 700, ml: 2, color: '#596171' }}>Actions</Typography>
            <MenuItem onClick={onCopyIdClick} sx={{ color: '#0071E3', fontWeight: 600 }}>
              {tableText[tableType].text1}
            </MenuItem>
            {(tableText[tableType].text2 || tableText[tableType].text3) && <Divider />}
          </Box>
        )}
        {(tableText[tableType].text2 || tableText[tableType].text3) && (
          <Typography sx={{ fontSize: '12px', fontWeight: 700, ml: 2, color: '#596171' }}>Connections</Typography>
        )}
        {tableText[tableType].text2 && (
          <MenuItem onClick={onViewCustomerClick} sx={{ color: '#0071E3', fontWeight: 600 }}>
            {tableText[tableType].text2}
          </MenuItem>
        )}
        {tableText[tableType].text3 && (
          <MenuItem onClick={onDetailsClick} sx={{ color: '#0071E3', fontWeight: 600 }}>
            {tableText[tableType].text3}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
