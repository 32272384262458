export const Colors = {
  lightBlue: 'hsl(200, 60%, 85%)',
  grayishBlue: 'hsl(205, 26%, 61%)',
  softBlue: 'hsl(207, 53%, 86%)',
  vividBlue: 'hsl(207, 90%, 54%)',
  brightYellow: 'hsl(40, 100%, 73%)',
  mutedBrown: 'hsl(30, 30%, 38%)',
  paleYellow: 'hsl(48, 100%, 83%)',
  lightGreen: 'hsl(91, 77%, 80%)',
  darkGreen: 'hsl(106, 43%, 35%)',
  lightGray: 'hsl(0, 0%, 96%)',
  darkGrayBlue: 'hsl(210, 8%, 38%)',
  softRed: 'hsl(0, 83%, 90%)',
  vividRed: 'hsl(0, 67%, 46%)',
  brightOrange: 'hsl(36, 100%, 60%)',
  white: 'hsl(0, 0%, 100%)',
  gray: 'hsl(0, 0%, 50%)',
  paleRed: 'hsl(0, 76%, 87%)',
  darkRed: 'hsl(0, 67%, 38%)',
  paleOrange: 'hsl(45, 100%, 86%)',
  lightGrayBlue: 'hsl(215, 12%, 92%)',
  mediumGray: 'hsl(0, 0%, 62%)',
  softOrange: 'hsl(32, 100%, 73%)',
  vividOrange: 'hsl(32, 96%, 50%)',
  lightTeal: 'hsl(168, 40%, 82%)',
  darkTeal: 'hsl(168, 75%, 29%)',
  palePurple: 'hsl(231, 33%, 85%)',
  darkPurple: 'hsl(231, 61%, 36%)',
  palePink: 'hsl(340, 64%, 86%)',
  vividPink: 'hsl(340, 73%, 47%)',
  softPeach: 'hsl(14, 100%, 75%)',
  darkPeach: 'hsl(14, 67%, 42%)',
  vividYellow: 'hsl(38, 100%, 65%)',
  softGreen: 'hsl(91, 65%, 85%)',
  oliveGreen: 'hsl(91, 46%, 42%)',
  paleMint: 'hsl(123, 50%, 83%)',
  darkMint: 'hsl(123, 53%, 40%)',
  paleLavender: 'hsl(288, 45%, 85%)',
  darkLavender: 'hsl(288, 65%, 40%)',
};
