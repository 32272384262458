import { AuthenticateResponse } from '@one/api-models/lib/Admin/Security/AuthenticateResponse';
import { B2CTokenAuthentication } from '@one/api-models/lib/Admin/Security/B2CTokenAuthentication';
import { PrincessTokenAuthentication } from '@one/api-models/lib/Admin/Security/PrincessTokenAuthentication';

import appConfig from 'config/appConfig';
import {
  getSSOSessionFromStorage,
  getTokenFromStorage,
  setSSOSessionInStorage,
  setTokenInStorage,
} from 'config/localStorage';
import { ApiResponse } from 'models/Response';

export const isSSOSession = () => {
  const isAgentLoginRoute = window.location.pathname.includes('agent-login');
  return isAgentLoginRoute || getSSOSessionFromStorage();
};

export const isTravelAgentPortal = () => {
  const domain = window.location.href.split('/').slice(0, 3).join('/');
  return appConfig.agentPortalAppUrl && appConfig.agentPortalAppUrl.includes(domain);
};

export const handleTravelAgentToken = async (idToken: string, refreshToken: boolean) => {
  const customToken = await getTokenFromStorage();

  if (!customToken || refreshToken) {
    return await getCustomToken(idToken);
  }
  return customToken;
};

export const getCustomToken = async (baseToken: string, ssoSession?: boolean) => {
  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const body = {
    $Type: ssoSession ? PrincessTokenAuthentication.$type : B2CTokenAuthentication.$type,
    token: baseToken,
    cultureCode: 'en-US',
  };

  const config: RequestInit = {
    method: 'POST',
    headers: {
      ...headers,
    },
    body: JSON.stringify(body),
  };
  const result = await window.fetch(`${appConfig.adminApiUrl}/security/authenticate`, config);
  const data: ApiResponse<AuthenticateResponse> = await result.json();
  const newToken = data?.value?.token;
  if (!newToken) {
    if (data?.errors && data?.errors.length > 0) {
      throw new Error(data?.errors[0]?.message);
    } else {
      throw new Error('Unexpected error.');
    }
  } else {
    setTokenInStorage(newToken);
    setSSOSessionInStorage(ssoSession || false);
  }
  return newToken;
};
