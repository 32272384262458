import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import { MemberResponse as Customer } from '@one/api-models/lib/Admin/Members/Response/MemberResponse';

import { CustomerStatus } from 'components/_common/CustomerStatus';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { useFormat } from 'components/hooks/useFormat';

const pageSize = 25;

export const CustomerList = ({
  items,
  testId,
  onItemSelect,
}: {
  items?: Customer[];
  testId: string;
  onItemSelect: (customer: Customer) => void;
}) => {
  const { formatDate } = useFormat();
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Name',
      sortable: true,
      flex: 1.5,
      filterable: false,
      valueGetter: (params: any) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      renderCell: (params: any) => (
        <Box
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          title={`${params.row.firstName || ''} ${params.row.lastName || ''}`}
        >
          <Typography variant="subtitle2">{`${params.row.firstName || ''} ${params.row.lastName || ''}`}</Typography>
        </Box>
      ),
    },
    {
      field: 'dateOfBirth',
      sortable: false,
      headerName: 'Date Of Birth',
      flex: 1,
      renderCell: (params: any) => {
        const formattedDate = formatDate(params.row.dateOfBirth, false, 'MMM dd, yyyy');
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }} title={formattedDate || ''}>
            {formattedDate}
          </Box>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2.5,
      renderCell: (params: any) => (
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={params.row.email || ''}>
          {params.row.email.toLowerCase()}
        </Box>
      ),
    },
    {
      field: 'mobilePhone',
      headerName: 'Mobile Phone',
      sortable: false,
      filterable: false,
      flex: 1.2,
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }} title={params.row.mobilePhone || ''}>
          {params.row.mobilePhone}
        </Box>
      ),
    },
    {
      field: 'partnerLoyaltyId',
      headerName: 'PARTNER Loyalty ID',
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }} title={params.row.partnerLoyaltyId || ''}>
          {params.row.partnerLoyaltyId}
        </Box>
      ),
    },
    {
      field: 'externalMemberKey',
      headerName: 'PARTNER Customer ID',
      width: 180,
      flex: 3,
      renderCell: (params: any) => (
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={params.row.externalMemberKey || ''}>
          {params.row.externalMemberKey}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      filterable: false,
      renderCell: (params: any) => <CustomerStatus status={params.row.status} />,
      flex: 1,
    },
  ];

  return items ? (
    <Box sx={{ pt: 6, height: '100%', width: '100%', overflow: { xs: 'scroll', lg: 'auto' } }}>
      <DataGrid
        rows={items.map((item: Customer) => ({
          ...item,
          id: item.memberKey || item.externalMemberKey || item.partnerLoyaltyId,
        }))}
        columns={columns}
        onRowClick={(param: any, event: any) => onItemSelect(param.row as Customer)}
        hideFooter={items.length <= pageSize}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: pageSize,
            },
          },
        }}
        sx={{
          minWidth: { xs: 1300, lg: '100%' },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  ) : (
    <Box>Invalid customer list</Box>
  );
};
