import React, { useState } from 'react';

import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface UncontrolledDatePickerProps {
  error?: boolean;
  helperText?: React.ReactNode;
  label?: React.ReactNode;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  disableFuture?: boolean;
  openTo?: 'day' | 'year';
  position?: 'start' | 'end';
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  value?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  testId: string;
  onChange?: (value?: Date | null) => void;
}

const UncontrolledDatePicker = ({
  label,
  placeholder,
  error,
  helperText,
  fullWidth,
  disableFuture = false,
  disabled = false,
  position = 'end',
  openTo = 'day',
  size = 'small',
  variant = 'outlined',
  value,
  minDate,
  maxDate,
  testId,
  onChange,
}: UncontrolledDatePickerProps) => {
  const [pickerValue, setPickerValue] = useState<Date | null>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        onChange={(newValue) => {
          setPickerValue(newValue);
          if (onChange) onChange(newValue);
        }}
        value={value || pickerValue}
        OpenPickerButtonProps={{
          tabIndex: -1,
          disableRipple: true,
          disableFocusRipple: true,
          sx: {
            marginLeft: position === 'start' ? '-13px' : '0px',
          },
        }}
        InputAdornmentProps={{
          position: position,
        }}
        disableFuture={disableFuture}
        disabled={disabled}
        openTo={openTo}
        maxDate={maxDate}
        minDate={minDate}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder,
              'data-testid': `${testId}DatePickerInput`,
            }}
            label={label}
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
            size={size}
            variant={variant}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default UncontrolledDatePicker;
