import { useMemo, useState } from 'react';
import { Control, DeepRequired, FieldErrorsImpl, FieldValues } from 'react-hook-form';
import { debounce } from 'lodash';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { Alert, Box, Button, Grid, InputAdornment, Typography } from '@mui/material';
import { Money } from '@one/api-models/lib/Money';

import ControlledTextField from 'components/_common/ControlledTextField';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';
import {
  minAdHocAmount,
  PaymentMethodOption,
  UpdatePaymentPlanForm,
} from 'components/views/paymentPlans/components/EditPaymentPlanDialog';

import { AddPaymentMethodDialog } from './AddPaymentMethodDialog';
import { PaymentPlanControlledCardSelector } from './PaymentPlanControlledCardSelector';

export interface Props {
  control: Control<UpdatePaymentPlanForm, object>;
  errors: FieldErrorsImpl<DeepRequired<UpdatePaymentPlanForm>>;
  isInstallmentPlan?: boolean;
  isPaymentPlanEditable?: boolean;
  adhocPaymentAmount: string | undefined;
  balance?: Money;
  selectedPaymentMethod: string | undefined;
  paymentMethodListOptions?: PaymentMethodOption[];
  isFetchingPaymentMethodList?: boolean;
  isLoadingCalculatePaymentPlan?: boolean;
  testId: string;
  memberId: string;
  handleCalculatePaymentPlan: () => void;
  handleChangePaymentMethod: (paymentMethodReference: string | undefined) => void;
  refetchPaymentMethodList: () => void;
}

export const PaymentPlanPaymentsSection = (props: Props) => {
  const {
    control,
    errors,
    isInstallmentPlan,
    isPaymentPlanEditable,
    balance,
    selectedPaymentMethod,
    adhocPaymentAmount,
    paymentMethodListOptions,
    isFetchingPaymentMethodList,
    isLoadingCalculatePaymentPlan,
    testId,
    memberId,
    handleCalculatePaymentPlan,
    handleChangePaymentMethod,
    refetchPaymentMethodList,
  } = props;

  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);

  const handleAddPaymentMethodModalOpen = () => {
    setAddPaymentModalOpen(true);
  };

  const handleAddPaymentMethodModalClose = () => {
    setAddPaymentModalOpen(false);
  };

  const mapCurrency = () => {
    switch (balance?.currency) {
      case 'USD':
        return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
      case 'GBP':
        return <CurrencyPoundIcon sx={{ fontSize: '1rem' }} />;
      default:
        return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
    }
  };

  const debouncedHandleAdhocPaymentAmountChange = debounce(() => {
    handleCalculatePaymentPlan();
  }, 800);

  const isAdhocPaymentAmountValid = useMemo(() => {
    if (
      adhocPaymentAmount === '' ||
      (adhocPaymentAmount &&
        balance?.amount &&
        parseFloat(adhocPaymentAmount) >= minAdHocAmount &&
        parseFloat(adhocPaymentAmount) <= balance?.amount)
    ) {
      return true;
    }

    return false;
  }, [balance, adhocPaymentAmount]);
  const isAdhocPaymentAmountValidMessage = `Then entered amount must be between ${formatCurrency(
    minAdHocAmount,
    balance?.currency,
  )} and ${formatCurrency(balance?.amount, balance?.currency)}`;

  const isPaymentMethodValid = useMemo(() => {
    if (adhocPaymentAmount === '' || (adhocPaymentAmount && !!selectedPaymentMethod)) {
      return true;
    }

    return false;
  }, [selectedPaymentMethod, adhocPaymentAmount]);
  const isPaymentMethodValidMessage = 'Payment method is required';

  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Payments" variant="h4" />

      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Add Additional Payments to Payment Plan:
      </Typography>

      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={3} md={4} lg={3}>
          <ControlledTextField
            name="adhocPaymentAmount"
            control={control as unknown as Control<FieldValues, object>}
            fullWidth
            label="Amount"
            type="number"
            error={errors.adhocPaymentAmount?.message != null || !isAdhocPaymentAmountValid}
            helperText={
              errors.adhocPaymentAmount?.message || (!isAdhocPaymentAmountValid && isAdhocPaymentAmountValidMessage)
            }
            InputProps={{
              startAdornment: <InputAdornment position="start">{mapCurrency()}</InputAdornment>,
              inputProps: {
                min: minAdHocAmount,
                max: balance?.amount,
                step: 0.01,
              },
            }}
            onChange={() => {
              debouncedHandleAdhocPaymentAmountChange();
            }}
            disabled={!isInstallmentPlan || !isPaymentPlanEditable || isLoadingCalculatePaymentPlan}
            testId={`${testId}Amount`}
          />
        </Grid>
        <Grid item container xs={12} sm={8} md={8} lg={5}>
          <Grid item xs={12} md={12}>
            <PaymentPlanControlledCardSelector
              isLoading={isFetchingPaymentMethodList}
              control={control as unknown as Control<FieldValues, object>}
              name="adhocPaymentMethodReference"
              label="Payment Method"
              options={paymentMethodListOptions}
              error={errors.paymentMethodReference != null || !isPaymentMethodValid}
              helperText={
                errors.paymentMethodReference?.message || (!isPaymentMethodValid && isPaymentMethodValidMessage)
              }
              requiredMessage="Payment method is required"
              disableClearable
              popperWidth="300px"
              onChange={(value) => {
                handleChangePaymentMethod(value);
              }}
              disabled={!isInstallmentPlan || !isPaymentPlanEditable}
              getOptionDisabled={(option) => {
                const currentDate = new Date();
                return (
                  option.expirationYear < currentDate.getFullYear() ||
                  (option.expirationYear === currentDate.getFullYear() &&
                    option.expirationMonth < currentDate.getMonth())
                );
              }}
              testId={`${testId}PaymentMethod`}
            />
            <Button
              onClick={handleAddPaymentMethodModalOpen}
              variant="text"
              disableRipple
              size="small"
              className="noXPadding"
              tabIndex={-1}
              disabled={!isInstallmentPlan || !isPaymentPlanEditable}
              data-testid={`${testId}AddPaymentMethodButton`}
            >
              Add payment method
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">The card will be charged only when you press Update Payment Plan</Alert>
        </Grid>
      </Grid>

      {addPaymentModalOpen && (
        <AddPaymentMethodDialog
          memberId={memberId}
          open={addPaymentModalOpen}
          refetchPaymentMethodList={refetchPaymentMethodList}
          setAddPaymentModalOpen={setAddPaymentModalOpen}
          onClose={handleAddPaymentMethodModalClose}
          testId={`${testId}Dialog`}
        />
      )}
    </Box>
  );
};
