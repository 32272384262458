import { Brand } from '@one/api-models/lib/Admin/Common/Brand';
import { Partner } from '@one/api-models/lib/Admin/Common/Partner';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

interface AppData {
  permissions: string[] | undefined;
  partners: Partner[] | undefined;
  activePartner: Partner | undefined;
  activeBrand: Brand | undefined;
  agencies: any;
  activeAgency: any;
  isLoading: boolean;
}

const initialState: AppData = {
  permissions: undefined,
  partners: undefined,
  activePartner: undefined,
  activeBrand: undefined,
  agencies: undefined,
  activeAgency: undefined,
  isLoading: false,
};

const applicationDataSlice = createSlice({
  name: 'applicationData',
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<string[] | undefined>) => {
      state.permissions = action.payload;
    },
    setPartners: (state, action: PayloadAction<Partner[] | undefined>) => {
      state.partners = action.payload;
    },
    setActivePartner: (state, action: PayloadAction<Partner | undefined>) => {
      state.activePartner = action.payload;
    },
    setActiveBrand: (state, action: PayloadAction<Brand | undefined>) => {
      state.activeBrand = action.payload;
    },
    setAgencies: (state, action: PayloadAction<any[] | undefined>) => {
      state.agencies = action.payload;
    },
    setActiveAgency: (state, action: PayloadAction<any | undefined>) => {
      state.activeAgency = action.payload;
    },
    setApplicationDataIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setPermissions,
  setPartners,
  setActivePartner,
  setActiveBrand,
  setAgencies,
  setActiveAgency,
  setApplicationDataIsLoading,
} = applicationDataSlice.actions;

export const selectPermissions = (state: RootState) => state.localData.applicationData.permissions;
export const selectPartners = (state: RootState) => state.localData.applicationData.partners;
export const selectActivePartner = (state: RootState) => state.localData.applicationData.activePartner;
export const selectActiveBrand = (state: RootState) => state.localData.applicationData.activeBrand;
export const selectAgencies = (state: RootState) => state.localData.applicationData.agencies;
export const selectActiveAgency = (state: RootState) => state.localData.applicationData.activeAgency;
export const selectApplicationDataIsLoading = (state: RootState) => state.localData.applicationData.isLoading;

export default applicationDataSlice.reducer;
