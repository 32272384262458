import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextField = styled(MuiTextField)(({ theme }) => ({
  '.Mui-error': {
    marginLeft: 0,
  },
  '.MuiInputBase-root': {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    marginTop: theme.spacing(0.5),

    '&.MuiInputBase-sizeSmall': {
      height: '30px',
      svg: {
        height: '18px',
      },
    },
    '&.MuiInputBase-sizeMedium': {
      height: '38px',
    },

    '&.MuiInputBase-multiline': {
      height: 'auto',
      padding: 0,
    },

    '.MuiInputBase-input': {
      '&.MuiOutlinedInput-input': {
        padding: '4px 8px',
      },
    },

    '&.Mui-disabled': {
      backgroundColor: '#F6F8FA',
    },

    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },

    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: 6,
      borderColor: theme.customPalette.input.border,
    },

    '&.Mui-focused': {
      boxShadow: `0 0 0 4px ${theme.customPalette.input.borderFocus}`,
    },
    '&:hover fieldset': {
      border: `1px solid ${theme.customPalette.input.border}`,
    },
    'input::placeholder': {
      opacity: 1,
      color: theme.customPalette.input.placeholder,
    },

    '.MuiSelect-icon': {
      top: 'calc(50% - 9px)',
    },
  },
}));
