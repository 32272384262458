import { forwardRef, ReactElement, Ref } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return (
    <Slide
      easing={{
        enter: 'linear',
        exit: 'linear',
      }}
      direction="left"
      ref={ref}
      {...props}
    />
  );
});

interface Props {
  open: boolean;
  testId: string;
  onClose: () => void;
}

export const SideModalDialog = ({ open, testId, onClose }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      className="MuiSideDialog"
    >
      <DialogTitle variant="h6" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>Side Modal Dialog</Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
          disableRipple
          data-testid={`${testId}CloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Box>
              <Typography variant="subtitle2" pb={1}>
                Text Field
              </Typography>
              <TextField placeholder="Text Field" fullWidth size="small" />
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <Typography variant="subtitle2" pb={1}>
                Text Field
              </Typography>
              <TextField placeholder="Text Field" fullWidth size="small" />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} size="small" disableRipple disabled>
          Cancel
        </Button>
        <Button variant="contained" onClick={onClose} size="small" disableRipple disabled>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
