import { useRef } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { addMinutes, format } from 'date-fns';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Grid, Link, Paper } from '@mui/material';
import { ExportCriteria } from '@one/api-models/lib/Admin/Reports/CustomerReport/ExportCriteria';

import { ApiError, FileResponse } from 'apiAccess/api-client';
import { ActionButton } from 'components/_common/ActionButton';
import { Loading } from 'components/_common/Loading';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';

export const CustomerReport = () => {
  const { api } = useApiHelpers();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { addApiError } = useToastMessage();
  const testIdPrefix = 'CustomerReport';

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);

  const exportMutation = useMutation<FileResponse, ApiError, ExportCriteria, unknown>(
    async (request: ExportCriteria) => {
      return await api.reports.customerReportExport(request);
    },
    {
      onSuccess: async (response: FileResponse, request: ExportCriteria) => {
        if (linkRef?.current == null) return;

        const fileName =
          response.fileName ??
          `CustomerReport_UTC${format(addMinutes(new Date(), new Date().getTimezoneOffset()), 'yyyy-MM-dd_HH;mm')}.csv`;

        linkRef.current.href = window.URL.createObjectURL(response.blob);
        linkRef.current.setAttribute('download', fileName);
        linkRef.current.click();
      },
      onError: addApiError,
    },
  );

  const handleExportButtonClick = () => {
    exportMutation.mutate({
      partnerKey: activePartner?.key,
      brandKey: activeBrand?.key ?? '',
    });
  };

  return (
    <Box>
      {exportMutation.isLoading ? <Loading message="Please wait..." /> : null}
      <PageHeader title="Customer Report" testId={testIdPrefix} />
      <Paper sx={{ mb: 2, p: 3 }}>
        <Grid container sx={{ justifyContent: { xs: 'center', md: 'space-between' } }} alignItems="center">
          <Grid item>
            <ActionButton
              icon={<FileDownloadOutlinedIcon />}
              onClick={handleExportButtonClick}
              testId={`${testIdPrefix}Export`}
            >
              Export
            </ActionButton>
            <Link ref={linkRef} style={{ display: 'none' }} data-testid={`${testIdPrefix}DownloadLink`}>
              download link
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
