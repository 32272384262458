import { Box, Grid, Typography, useTheme } from '@mui/material';

import { BackButton } from './BackButton';
import { RouterBreadcrumbs } from './RouterBreadcrumbs';

type Props = {
  testId: string;
  title?: string;
  titleAside?: any;
  hideBreadcrumbs?: boolean;
  backButtonUrl?: string;
};

export const PageHeader = ({ title, titleAside, hideBreadcrumbs = false, backButtonUrl, testId }: Props) => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: title ? 3 : 0 }}>
      {!hideBreadcrumbs && <RouterBreadcrumbs />}
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            {backButtonUrl && (
              <BackButton redirectUrl={backButtonUrl} color={`${theme.palette.primary.main}`} testId={testId} />
            )}
            {title && (
              <Typography sx={{ mt: 1 }} variant="h1">
                {title}
              </Typography>
            )}
          </Box>
        </Grid>
        {titleAside && <Grid item>{titleAside}</Grid>}
      </Grid>
    </Box>
  );
};
