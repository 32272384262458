import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Grid, Paper } from '@mui/material';
import { CompleteOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderResponse';

import { useFormat } from 'components/hooks/useFormat';
import { selectActiveAgency, selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import {
  PaymentOptions,
  selectBillingDetails,
  selectCalculatedInstallmentPlan,
  selectInstallmentsPaymentPlan,
  selectPurchaseData,
  selectSelectedCustomer,
  selectSelectedPaymentOption,
  selectSelectedPrograms,
  setSelectedCustomer,
} from 'slices/salesOrderDataSlice';
import { Button, Typography } from 'styled';

import { CustomerSummary, CustomerSummarySkeleton } from '../paymentPlans/components/CustomerSummary';

import { ProgramItemsList } from './components/ProgramItemsList';

interface SalesOrderCompleteProps {
  testId: string;
  confirmation?: CompleteOrderResponse;
  onNewSaleCallback: () => void;
  onNewSaleForCustomerCallback: () => void;
}

export const SalesOrderComplete = ({
  testId,
  confirmation,
  onNewSaleCallback,
  onNewSaleForCustomerCallback,
}: SalesOrderCompleteProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatCurrency, formatDate, getRecurringIntervalTypeLabel, formatRecurringInterval } = useFormat();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const activeAgency = useSelector(selectActiveAgency);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const billingDetails = useSelector(selectBillingDetails);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const purchaseData = useSelector(selectPurchaseData);
  const installmentsPaymentPlan = useSelector(selectInstallmentsPaymentPlan);
  const calculatedInstallmentPlan = useSelector(selectCalculatedInstallmentPlan);
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);

  const navigateToMemberProfile = () => {
    if (selectedCustomer) {
      navigate(`/customers/${selectedCustomer.memberKey}`, { state: { refetch: true } });
    }
  };

  const handleNewSaleClick = () => {
    dispatch(setSelectedCustomer(null));
    onNewSaleCallback();
    navigate('/sales/new');
  };

  const handleNewSaleForCustomerClick = () => {
    onNewSaleForCustomerCallback();
  };

  return confirmation !== undefined ? (
    <Grid container spacing={{ xs: 3, sm: 3, md: 0, lg: 0 }} sx={{ background: '#fff' }}>
      <Grid item xs={12}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Purchase confirmation
          </Typography>
          <Box>
            <Typography variant="body1">Purchase process finalized successfully.</Typography>
            <Typography variant="body1">
              Your confirmation number is: <b>{confirmation.orderConfirmationNumber}</b>
            </Typography>
          </Box>
          <Grid container spacing={1} xs={12} justifyContent="space-between" sx={{ pt: 2 }}>
            <Grid item xs={12} lg="auto">
              <Button
                variant="contained"
                color="success"
                startIcon={<PersonIcon />}
                onClick={navigateToMemberProfile}
                fullWidth
                data-testid={`${testId}ReturnToCustomerProfileButton`}
              >
                Return to customer profile
              </Button>
            </Grid>
            <Grid item xs={12} lg="auto">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} lg="auto">
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={handleNewSaleForCustomerClick}
                    sx={{
                      mr: { xs: 0, md: 3 },
                    }}
                    startIcon={<AddIcon />}
                    data-testid={`${testId}NewSaleForCustomerButton`}
                  >
                    New Sale For Customer
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} lg="auto">
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={handleNewSaleClick}
                    startIcon={<AddIcon />}
                    data-testid={`${testId}NewSaleButton`}
                  >
                    New Sale
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={7.5}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Purchase details
          </Typography>
          <Grid container alignItems="center" rowSpacing={1}>
            <Grid item xs={4} sm={2}>
              <Typography variant="body2">Partner:</Typography>
            </Grid>
            <Grid item xs={8} md={10}>
              <Typography variant="body1">{activePartner?.name}</Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="body2">Brand:</Typography>
            </Grid>
            <Grid item xs={8} md={10}>
              <Typography variant="body1">{activeBrand?.name}</Typography>
            </Grid>
            {activeAgency && (
              <>
                <Grid item xs={2}>
                  <Typography variant="body2">Agency:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1">{activeAgency?.name}</Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ width: '100%', mt: 5 }}>
            <ProgramItemsList programs={selectedPrograms} />
          </Box>
        </Paper>

        <Paper sx={{ p: 3, mt: 3 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Billing Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} lg={6}>
              <Typography variant="body2">First Name</Typography>
              <Typography variant="body1">{billingDetails?.firstName}</Typography>
            </Grid>
            <Grid item xs={12} sm={5} lg={6}>
              <Typography variant="body2">Last Name</Typography>
              <Typography variant="body1">{billingDetails?.lastName}</Typography>
            </Grid>
            <Grid item xs={12} sm={7} lg={6}>
              <Typography variant="body2">E-mail address</Typography>
              <Typography variant="body1">{billingDetails?.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={5} lg={6}>
              <Typography variant="body2">Street Address</Typography>
              <Typography variant="body1">
                {billingDetails?.streetAddress}
                {billingDetails?.aptNumber ? ', ' + billingDetails?.aptNumber : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} lg={6}>
              <Typography variant="body2">City</Typography>
              <Typography variant="body1">{billingDetails?.city}</Typography>
            </Grid>
            <Grid item xs={12} sm={5} lg={6}>
              <Typography variant="body2">Country</Typography>
              <Typography variant="body1">{billingDetails?.country}</Typography>
            </Grid>
            <Grid item xs={12} sm={7} lg={6}>
              <Typography variant="body2">State/Region</Typography>
              <Typography variant="body1">{billingDetails?.state || billingDetails?.stateCode}</Typography>
            </Grid>
            <Grid item xs={12} sm={5} lg={6}>
              <Typography variant="body2">Zip Code</Typography>
              <Typography variant="body1">{billingDetails?.zipCode}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={4.5}>
        <Paper sx={{ p: 3 }}>
          <Box>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Payment Details
            </Typography>
            <Grid container alignItems="center" rowSpacing={1}>
              <Grid item xs={4}>
                <Typography variant="body2">Total Paid:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {formatCurrency(selectedPrograms[0]?.price?.amount, selectedPrograms[0]?.price?.currency)}
                </Typography>
              </Grid>

              {installmentsPaymentPlan && selectedPaymentOption === PaymentOptions.PaymentPlan ? (
                <>
                  <Grid item xs={4}>
                    <Typography variant="body2">Down Payment:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {formatCurrency(
                        installmentsPaymentPlan?.downPaymentAmounts[0]?.amount,
                        installmentsPaymentPlan?.downPaymentAmounts[0]?.currency,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">Balance:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {formatCurrency(
                        calculatedInstallmentPlan?.balance.amount,
                        calculatedInstallmentPlan?.balance.currency,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">Term:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {formatRecurringInterval(
                        installmentsPaymentPlan.intervalCount,
                        installmentsPaymentPlan.recurringInterval,
                        true,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">
                      {getRecurringIntervalTypeLabel(installmentsPaymentPlan.recurringInterval)} Payment:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {!calculatedInstallmentPlan?.installments[0] ? (
                        'N/A'
                      ) : (
                        <>
                          {formatCurrency(
                            calculatedInstallmentPlan?.installments[0].amount.amount,
                            calculatedInstallmentPlan?.installments[0].amount.currency,
                            2,
                          )}
                          /{formatRecurringInterval(1, installmentsPaymentPlan.recurringInterval)}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">Next Payment:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {!calculatedInstallmentPlan?.installments[0]
                        ? 'N/A'
                        : formatDate(calculatedInstallmentPlan?.installments[0].billingCycleBegin, true)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">End Term:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {!calculatedInstallmentPlan?.installments[0]
                        ? 'N/A'
                        : formatDate(calculatedInstallmentPlan?.termEnd, true)}
                    </Typography>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </Paper>
        {purchaseData?.memberSummary ? (
          <Box sx={{ mt: 3 }}>
            <CustomerSummary data={purchaseData.memberSummary} />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            <CustomerSummarySkeleton />
          </Box>
        )}
      </Grid>
    </Grid>
  ) : (
    <Box>Invalid purchase confirmation</Box>
  );
};
