import { useState } from 'react';

import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { GridColDef, GridToolbarQuickFilter, GridToolbarQuickFilterProps } from '@mui/x-data-grid';
import { Order } from '@one/api-models/lib/Membership/Account/Order';
import { GenericOrderItemSummaryBase } from '@one/api-models/lib/Sales/GenericOrderItemSummaryBase';
import { RetailProductSummaryBase } from '@one/api-models/lib/Sales/Retail/RetailProductSummaryBase';

import { orderStatusOptions } from 'components/_common/constants/StatusOptions';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { SectionTitle } from 'components/_common/SectionTitle';
import { StatusChip } from 'components/_common/StatusChip';
import { useFormat } from 'components/hooks/useFormat';
import { Tooltip } from 'styled/Tooltip';

import { OrderDetailsDialog } from './OrderDetailsDialog';

interface Props {
  memberKey: string;
  isFetchingMemberLoadData?: boolean;
  orders: Order[] | undefined;
  testId: string;
  handleMemberDataReload: () => void;
}

const pageSize = 10;

export const PurchasesSection = (props: Props) => {
  const { memberKey, orders, isFetchingMemberLoadData, testId, handleMemberDataReload } = props;
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const handleOrderClick = (order: Order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const handleModalClose = (shouldReloadMemberData: boolean) => {
    setSelectedOrder(null);
    setModalOpen(false);

    if (shouldReloadMemberData) {
      handleMemberDataReload();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'totalPrice',
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      minWidth: 80,
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => params.row.totalPrice.amount,
      renderCell: (params: any) => <>{formatCurrency(params.row.totalPrice.amount, params.row.totalPrice.currency)}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      minWidth: 126,
      flex: 1.5,
      renderCell: (params: any) =>
        params.row.status ? (
          <Tooltip arrow title={orderStatusOptions[params.row.status]?.name ?? ''} fontSize="0.0775" maxWidth="900px">
            <Box width="100%">
              <StatusChip status={orderStatusOptions[params.row.status] || params.row.status} />
            </Box>
          </Tooltip>
        ) : (
          <StatusChip status={'-'} />
        ),
    },
    {
      field: 'product',
      headerName: 'Product',
      sortable: true,
      flex: 3,
      valueGetter: (params: any) => params.row.orderItems[0]?.productSummary?.name,
      renderCell: (params: any) => {
        const item = params.row.orderItems[0] as GenericOrderItemSummaryBase<RetailProductSummaryBase>;
        return (
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.productSummary.name}>
            {item.productSummary.name}
          </Box>
        );
      },
    },
    {
      field: 'orderDate',
      headerName: 'Date',
      sortable: true,
      minWidth: 105,
      flex: 2,
      renderCell: (params: any) => <>{formatDate(new Date(params.row.orderDate), true, 'MMM dd, yyyy')}</>,
    },
    {
      field: 'orderNumber',
      headerName: 'Order Number',
      flex: 4,
      minWidth: 210,
      sortable: true,
      renderCell: (params: any) => (
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={params.row.id}>
          {params.row.id}
        </Box>
      ),
    },
  ];
  const StyledGridToolbarQuickFilter = (props: GridToolbarQuickFilterProps) => (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'end',
        pb: 1,
      }}
    >
      <GridToolbarQuickFilter {...props} sx={{ width: { xs: '100%', md: '50%' } }} />
    </Box>
  );

  const renderPurchasesList = () => {
    let renderItem = null;
    if (isFetchingMemberLoadData) {
      renderItem = <Skeleton variant="rectangular" width={300} height={25} />;
    } else if (orders && orders.length > 0) {
      renderItem = (
        <DataGrid
          rows={orders.map((item: Order) => ({ ...item, id: item.orderNumber }))}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: pageSize,
              },
            },
          }}
          rowHeight={45}
          onRowClick={(param: any, event: any) => handleOrderClick(param.row as Order)}
          hideFooter={orders.length <= pageSize}
          sx={{
            width: { xs: 800, lg: '100%' },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
          slots={{ toolbar: StyledGridToolbarQuickFilter }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              placeholder: 'Search by product or order number',
            },
          }}
        />
      );
    } else {
      <Typography variant="body1">No purchases available for this customer</Typography>;
    }

    return renderItem;
  };

  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Orders" />
      <Grid container sx={{ width: '100%', overflow: 'auto' }}>
        <Grid item xs={12}>
          {renderPurchasesList()}
        </Grid>
      </Grid>
      <OrderDetailsDialog
        open={modalOpen}
        onClose={handleModalClose}
        order={selectedOrder}
        memberKey={memberKey}
        testId={`${testId}OrderDetailsDialog`}
      />
    </Box>
  );
};
