import { SxProps } from '@mui/material';

export const assetCtaStyle: SxProps = {
  textTransform: 'none',
  pt: 0,
  pb: 0,
  borderRadius: '16px',
  fontWeight: 'bold',
  lineHeight: '1.4rem',
  borderColor: '#ccc',
};

export const profileCtaStyle: SxProps = {
  py: 0.75,
  width: 'fit-content',
  gap: '8px',
  textAlign: 'left',
  lineHeight: '20px',
};
