import { Settings } from '@one/api-models/lib/Admin/Tags/Settings';
import { Tag } from '@one/api-models/lib/Admin/Tags/View/Tag';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

interface TagsData {
  settings: Settings | undefined;
  selectedTag: Tag | undefined;
  newTag: Tag | undefined;
  tagTree: Tag | undefined;
  isSavingTag: boolean;
  selectedOnTreeComplete: Tag | undefined;
}

const initialState: TagsData = {
  settings: undefined,
  selectedTag: undefined,
  newTag: undefined,
  tagTree: undefined,
  isSavingTag: false,
  selectedOnTreeComplete: undefined,
};

const tagsDataSlice = createSlice({
  name: 'tagsData',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<Settings | undefined>) => {
      state.settings = action.payload;
    },
    setSelectedTag: (state, action: PayloadAction<Tag | undefined>) => {
      state.selectedTag = action.payload;
    },
    setNewTag: (state, action: PayloadAction<Tag | undefined>) => {
      state.newTag = action.payload;
    },
    setTagTree: (state, action: PayloadAction<Tag | undefined>) => {
      state.tagTree = action.payload;
    },
    setIsSavingTag: (state, action: PayloadAction<boolean>) => {
      state.isSavingTag = action.payload;
    },
    setSelectedOnTreeComplete: (state, action: PayloadAction<Tag | undefined>) => {
      state.selectedOnTreeComplete = action.payload;
    },
  },
});

export const { setSettings, setSelectedTag, setNewTag, setTagTree, setIsSavingTag, setSelectedOnTreeComplete } =
  tagsDataSlice.actions;

export const selectSettings = (state: RootState) => state.tagsData.settings;
export const selectSelectedTag = (state: RootState) => state.tagsData.selectedTag;
export const selectNewTag = (state: RootState) => state.tagsData.newTag;
export const selectTagTree = (state: RootState) => state.tagsData.tagTree;
export const selectIsSavingTag = (state: RootState) => state.tagsData.isSavingTag;
export const selectSelectedOnTreeComplete = (state: RootState) => state.tagsData.selectedOnTreeComplete;

export default tagsDataSlice.reducer;
