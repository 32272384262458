import { EditRequest } from "./EditRequest";

export class RemoveEditRequest extends EditRequest {
  static $Type = "RemoveEditRequest";

  productId: string;
  tags?: string[];

  constructor() {
    super();
    this.productId = '';
  }
}
