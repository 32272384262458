import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Box, Paper, PaperProps } from '@mui/material';
import { ListingData } from '@one/api-models/lib/Admin/Common/ListingData';
import { PredictiveSearchRequest } from '@one/api-models/lib/Admin/Members/Request/PredictiveSearchRequest';
import { MemberProfileItem } from '@one/api-models/lib/Admin/Members/Response/MemberProfileItem';
import { PredictiveSearchResponse } from '@one/api-models/lib/Admin/Members/Response/PredictiveSearchResponse';
import { SearchItemType } from '@one/api-models/lib/Admin/Members/SearchItemType';

import { ApiError } from 'apiAccess/api-client';
import { CustomerStatus } from 'components/_common/CustomerStatus';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useDebouncedValue } from 'components/hooks/useDebounce';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { Customer } from 'models/customers/Customer';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import { Autocomplete, Button, TextField, Typography } from 'styled';

import { AddCustomerDialog } from './AddCustomerDialog';

interface CustomerSelectorAutocompleteProps {
  testId: string;
  disabled?: boolean;
  handleChange: (customer: Customer | null) => void;
  defaultValue: Customer | null;
}

export const CustomerSelectorAutocomplete = ({
  testId,
  disabled,
  defaultValue,
  handleChange,
}: CustomerSelectorAutocompleteProps) => {
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const [addCustomerOpen, setAddCustomerOpen] = useState<boolean>(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState<Customer[] | undefined>([]);
  const [selectedOption, setSelectedOption] = useState<Customer | null>(defaultValue);
  const [inputValue, setInputValue] = useState<string>('');

  const inputValueDebounced = useDebouncedValue(inputValue, 300);
  const isLoading = useIsMutating({ mutationKey: 'findCustomerMutation' }) > 0;

  useEffect(() => {
    setInputValue(defaultValue ? `${defaultValue.firstName} ${defaultValue.lastName}` : '');
  }, [defaultValue]);

  const customerSearchMutation = useMutation<PredictiveSearchResponse, ApiError, PredictiveSearchRequest, unknown>(
    (request) => {
      return api.members.predictiveSearch({
        ...request,
      });
    },
    {
      mutationKey: 'findCustomerMutation',
      onSuccess: (value: PredictiveSearchResponse) => {
        if (value) {
          const members = (value.listingData as ListingData<MemberProfileItem>).items;
          const customerOptions = members.map((member) => ({
            firstName: member?.firstName,
            lastName: member?.lastName,
            email: member?.emailAddress,
            phoneNumber: member?.mobilePhoneNumber,
            dateOfBirth: member?.dateOfBirth,
            accountStatus: member?.accountStatus,
            memberKey: member?.memberKey,
          }));
          setAutocompleteOptions(customerOptions);
        }
      },
      onError: (error) => {
        setAutocompleteOptions([]);
        addApiError(error);
      },
    },
  );

  const handleOnChange = (customer: Customer | null) => {
    setSelectedOption(customer);
    handleChange(customer);
  };

  const getCustomerSuggestions = async () => {
    if (inputValueDebounced == null || inputValueDebounced === '' || inputValueDebounced.length < 2) return;

    customerSearchMutation.mutate({
      searchTerm: inputValueDebounced,
      itemTypes: [SearchItemType.MemberProfile],
      partnerKey: activePartner?.key ?? '',
      brandKey: activeBrand?.key ?? '',
      listHandling: { take: 1000, skip: 0, ascending: true },
    });
  };

  useEffect(() => {
    getCustomerSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueDebounced]);

  const addNewCustomer = (
    <Box display="flex" flexDirection="column">
      {inputValueDebounced && inputValueDebounced.length >= 2 ? (
        <>
          <Typography variant="caption">No results matching your criteria.</Typography>
          <Typography variant="caption" mb={1}>
            Adjust your search or add a new customer.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="caption">Enter at least 2 characters to start searching for a customer.</Typography>
          <Typography variant="caption" mb={1}>
            Or add a new customer.
          </Typography>
        </>
      )}
    </Box>
  );

  const handleCloseAddCustomer = (customer: Customer) => {
    handleOnChange(customer);
    setAddCustomerOpen(false);
  };

  return (
    <>
      <Autocomplete
        data-testId={`${testId}FindOrAddCustomerAutocomplete`}
        options={autocompleteOptions || []}
        noOptionsText={addNewCustomer}
        disabled={disabled || !activeBrand}
        value={selectedOption}
        clearOnBlur={false}
        onChange={(_, value) => handleOnChange(value as Customer)}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue.trim());
          setAutocompleteOptions([]);
        }}
        loading={isLoading}
        isOptionEqualToValue={(option, value) => option?.memberKey === value?.memberKey}
        filterOptions={(x) => x}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        renderOption={(props, option) => <CustomerSelectorOption props={props} option={option} />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Find or Add Customer"
            autoComplete="off"
            autoFocus={true}
            size="small"
            value={inputValue}
            onChange={(e) => {
              e.preventDefault();
            }}
            inputProps={{
              ...params.inputProps,
              'data-testid': `${testId}CustomerInput`,
            }}
          />
        )}
        PaperComponent={(props) => (
          <FilterPopperComponent {...props} onAddCustomer={() => setAddCustomerOpen(true)} testId={testId} />
        )}
      />
      <AddCustomerDialog
        open={addCustomerOpen}
        callback={handleCloseAddCustomer}
        handleClose={() => setAddCustomerOpen(false)}
      />
    </>
  );
};

const CustomerSelectorOption = ({ props, option }: any) => {
  const { formatDate } = useFormat();
  const formattedDateOfBirth = formatDate(option.dateOfBirth, false, 'MMM dd, yyyy');

  return (
    <Box
      {...props}
      sx={{
        '&.MuiAutocomplete-option': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          py: 1,
        },
      }}
      key={option.memberKey}
    >
      <Box display="flex" justifyContent="space-between" width="100%" pb={0.25}>
        <Typography variant="subtitle1">{`${option.firstName} ${option.lastName}`}</Typography>
        <CustomerStatus status={option.accountStatus} />
      </Box>

      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" width="100%">
        <Typography variant="caption">{option.email}</Typography>
        <Typography variant="caption">{option.phoneNumber}</Typography>
      </Box>

      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" width="100%">
        <Typography variant="caption">{option.memberKey}</Typography>
        <Typography variant="caption">{formattedDateOfBirth}</Typography>
      </Box>
    </Box>
  );
};

type FilterProps = {
  testId: string;
  onAddCustomer: () => void;
} & PaperProps;

const FilterPopperComponent = ({ testId, onAddCustomer, ...props }: FilterProps) => {
  return (
    <Paper
      sx={{ mt: 1, minWidth: { xs: '100%', md: '500px' } }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <Box py={2}>
        <Box display="flex" px={1}>
          <Button variant="text" onClick={onAddCustomer} startIcon={<AddIcon />}>
            Add new customer
          </Button>
        </Box>
        {props.children}
      </Box>
    </Paper>
  );
};
