import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Menu, MenuItem, Typography } from '@mui/material';
import { Brand } from '@one/api-models/lib/Admin/Common/Brand';
import { Partner } from '@one/api-models/lib/Admin/Common/Partner';

import { ReactComponent as ChevronDown } from 'assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevronUp.svg';
import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { selectActiveBrand, selectActivePartner, selectPartners } from 'slices/applicationDataSlice';
import { Button } from 'styled';

interface Props {
  disabled?: boolean;
  testId: string;
}

export const PartnerHeaderSelect = ({ disabled, testId }: Props) => {
  const { setActivePartner } = useAppHelpers();

  const partners = useSelector(selectPartners);
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const testIdPrefix = `${testId}PartnersDropdown`;

  const changeHandler = (selectedPartner: Partner, selectedBrand: Brand) => {
    if (selectedPartner) setActivePartner(selectedPartner, selectedBrand);
  };

  let logoSrc;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [partnerBrandsVisibility, setPartnerBrandsVisibility] = useState<{ [key: string]: boolean }>({});

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

    const togglePartnerBrandsVisibility = { ...partnerBrandsVisibility };
    togglePartnerBrandsVisibility[activePartner?.name as string] =
      !partnerBrandsVisibility[activePartner?.name as string];
    const togglePartnerBrandsVisibilityArray = Object.keys(togglePartnerBrandsVisibility);
    togglePartnerBrandsVisibilityArray.forEach((element: string) => {
      if (element === activePartner?.name) {
        togglePartnerBrandsVisibility[element] = true;
      }
      if (element !== activePartner?.name) togglePartnerBrandsVisibility[element] = false;
    });
    setPartnerBrandsVisibility(togglePartnerBrandsVisibility);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getLogo = (key: string | undefined) => {
    try {
      logoSrc = require(`../../../public/images/logos/logo-${key}.png`);
    } catch (err) {
      logoSrc = '/images/logos/logo-ONE.png';
    }
    return logoSrc;
  };

  const handlePartnerClick = (partnerName: string) => {
    const togglePartnerBrandsVisibility = { ...partnerBrandsVisibility };
    togglePartnerBrandsVisibility[partnerName] = !partnerBrandsVisibility[partnerName];
    const togglePartnerBrandsVisibilityArray = Object.keys(togglePartnerBrandsVisibility);
    togglePartnerBrandsVisibilityArray.forEach((element: string) => {
      if (element !== partnerName) {
        togglePartnerBrandsVisibility[element] = false;
      }
    });
    setPartnerBrandsVisibility(togglePartnerBrandsVisibility);
  };

  return (
    <Box p={1}>
      <Button
        id="partner-button"
        aria-controls={open ? 'partner-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        sx={(theme) => ({
          textTransform: 'none',
          width: '100%',
          color: 'black',
          lineHeight: '1.5em',
          justifyContent: 'left',
          ':focus': {
            color: theme.palette.text.primary,
          },
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        })}
        startIcon={<img src={getLogo(activePartner?.key)} width="35" alt="logo" />}
        endIcon={disabled ? null : open ? <ChevronUp height="14px" /> : <ChevronDown height="14px" />}
        disabled={disabled}
        data-testid={`${testIdPrefix}Button`}
      >
        <Typography
          sx={(theme) => ({
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '0.875rem',
            color: disabled ? theme.palette.text.secondary : theme.palette.text.primary,
          })}
        >
          {`${activeBrand?.name}` || 'Select Partner'}
        </Typography>
      </Button>
      <Menu
        id="partner-menu"
        aria-labelledby="partner-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {partners?.map((partner) => {
          return (
            <Box key={partner.key}>
              <MenuItem
                sx={{
                  maxHeight: '45px',
                  overflow: 'hidden',
                  minWidth: '320px',
                  py: '20px',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                  borderTop: '1px solid #e0e0e0',
                }}
                onClick={() => {
                  handlePartnerClick(partner.name);
                }}
                data-testid={`${testIdPrefix}MenuItem`}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pr: 2,
                      }}
                    >
                      <img src={getLogo(partner.key)} alt="logo" width="40" />
                    </Box>
                    <Box>{partner.name}</Box>
                  </Box>
                  <Box sx={{ alignItems: 'center' }}>
                    {partnerBrandsVisibility[partner.name] ? (
                      <ExpandLess sx={{ marginLeft: '5px' }} />
                    ) : (
                      <ExpandMore sx={{ marginLeft: '5px' }} />
                    )}
                  </Box>
                </Box>
              </MenuItem>

              <Collapse in={partnerBrandsVisibility[partner.name]}>
                {partner.brands.map((brand) => (
                  <MenuItem
                    key={brand.key}
                    onClick={() => {
                      changeHandler(partner, brand);
                      handleClose();
                    }}
                    sx={{
                      background: '#F0F0F0',
                      fontWeight: activeBrand?.name === brand.name ? '700' : 'normal',
                      color: activeBrand?.name === brand.name ? '#06c' : '#000',
                      pt: 5,
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                    data-testid={`${testIdPrefix}SubmenuItem`}
                  >
                    <Box>{brand.name}</Box>
                  </MenuItem>
                ))}
              </Collapse>
            </Box>
          );
        })}
      </Menu>
    </Box>
  );
};
