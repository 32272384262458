import { Box, Divider, Typography } from '@mui/material';
import { CertificatePayment } from '@one/api-models/lib/Membership/Account/CertificatePayment';
import { PaymentOptionBase } from '@one/api-models/lib/Membership/Account/PaymentOptionBase';
import { PurchaseSummary } from '@one/api-models/lib/Membership/Account/PurchaseSummary';
import { PurchaseSummaryItem } from '@one/api-models/lib/Membership/Account/PurchaseSummaryItem';
import { PurchaseSummaryItemComponent } from '@one/api-models/lib/Membership/Account/PurchaseSummaryItemComponent';
import { PurchaseSummaryItemComponentType } from '@one/api-models/lib/Membership/Account/PurchaseSummaryItemComponentType';
import { PurchaseSummaryItemType } from '@one/api-models/lib/Membership/Account/PurchaseSummaryItemType';

import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';

import { EarnOptionsDisplay } from './EarnOptionsDisplay';
import { PaymentOptionsDisplay } from './PaymentOptionsDisplay';

interface Props {
  purchaseSummary: PurchaseSummary;
}

const getTravelerPrices = (purchaseSummary: PurchaseSummary): PurchaseSummaryItemComponent[] => {
  const payDuringPurchaseFlow = purchaseSummary.summaryItems.find(
    (i) => i.type === PurchaseSummaryItemType.PayDuringPurchaseFlow,
  );

  return payDuringPurchaseFlow
    ? payDuringPurchaseFlow.components.filter(
        (component: PurchaseSummaryItemComponent) =>
          component.itemType === PurchaseSummaryItemComponentType.PricePerPassenger,
      )
    : [];
};

export const CruisePricing = ({ purchaseSummary }: Props) => {
  const { formatCurrency: formatCurrencyFunc } = useFormat();

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const subtotal = purchaseSummary.summaryItems?.find(
    (item: PurchaseSummaryItem) => item.type === PurchaseSummaryItemType.Subtotal,
  )?.totals.fiatAmount;

  const payDuringPurchaseFlow = purchaseSummary.summaryItems.find(
    (i: any) => i.type === PurchaseSummaryItemType.PayDuringPurchaseFlow,
  );

  const travelerPrices: PurchaseSummaryItemComponent[] = getTravelerPrices(purchaseSummary);

  const paymentOptions: PaymentOptionBase[] = payDuringPurchaseFlow
    ? payDuringPurchaseFlow.totals.paymentOptions.filter(
        (paymentOption: PaymentOptionBase) => paymentOption.$type === CertificatePayment.$type,
      )
    : [];

  const taxes = purchaseSummary.summaryItems.find((i: any) => i.type === PurchaseSummaryItemType.Taxes);

  return (
    <>
      <SectionTitle title="Pricing details" variant="h6" />

      {paymentOptions.length > 0 && subtotal != null ? (
        <>
          {paymentOptions.map((paymentOption: PaymentOptionBase) => (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }} key={paymentOption.displayName}>
              <Typography>{paymentOption.displayName} certificate</Typography>
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>+</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>Additional charge</Typography>
            <Typography>{formatCurrency(subtotal.amount, subtotal.currency)}</Typography>
          </Box>
        </>
      ) : (
        <>
          {travelerPrices.map((item: PurchaseSummaryItemComponent) => (
            <Box key={item.name} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography>{item.name}</Typography>
              <Typography>{formatCurrency(item.value.amount, item.value.currency)}</Typography>
            </Box>
          ))}
        </>
      )}

      {taxes && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography>Taxes, fees and port expenses</Typography>
          <Typography>{formatCurrency(taxes.totals.fiatAmount.amount, taxes.totals.fiatAmount.currency)}</Typography>
        </Box>
      )}

      {purchaseSummary?.totals?.earn?.length > 0 && (
        <Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Rewards</Typography>
            <Box>
              <EarnOptionsDisplay options={purchaseSummary.totals.earn} />
            </Box>
          </Box>
          <Divider sx={{ mt: 1, mb: 1 }} />
        </Box>
      )}

      <Divider sx={{ my: 1 }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Typography fontWeight={700} fontSize="1.2rem">
          Total
        </Typography>
        <Box>
          <Typography fontWeight={700} fontSize="1.2rem" textAlign="right">
            {formatCurrency(purchaseSummary.totals.fiatAmount.amount, purchaseSummary.totals.fiatAmount.currency)}
          </Typography>
          <PaymentOptionsDisplay options={purchaseSummary.totals.paymentOptions} />
        </Box>
      </Box>
    </>
  );
};
