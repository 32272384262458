import { ReactNode } from 'react';

import { Box, useTheme } from '@mui/material';

import { Typography } from 'styled';

interface DataDisplayProps {
  label: string;
  value: ReactNode;
  onClick?: () => void;
}

export const DataDisplay = ({ label, value, onClick }: DataDisplayProps) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
        {label}
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: theme.customPalette.text.primary, cursor: onClick ? 'pointer' : 'default' }}
        onClick={onClick}
      >
        {value}
      </Typography>
    </Box>
  );
};
