import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Grid, TextField } from '@mui/material';
import { ContentType } from '@one/api-models/lib/Admin/Products/ContentType';
import { ProductContentBase } from '@one/api-models/lib/Admin/Products/ProductContentBase';
import { UpdateProductBase } from '@one/api-models/lib/Admin/Products/UpdateProductBase';

import { ActionButton } from 'components/_common/ActionButton';

interface Props {
  product: ProductContentBase;
  onSave: (data: UpdateProductBase) => void;
  isLoading: boolean;
  onFormDirty: (isDirty: boolean) => void;
  disableEdit?: boolean;
  testId: string;
}

export const ContentTab = ({ product, onSave, isLoading, onFormDirty, disableEdit, testId }: Props) => {
  const defaultValues = (product: ProductContentBase) => ({
    contentItems: product.contentItems,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<UpdateProductBase>({
    mode: 'onBlur',
    defaultValues: defaultValues(product),
  });

  useEffect(() => {
    onFormDirty(isDirty);
  }, [onFormDirty, isDirty]);

  useEffect(() => {
    reset(defaultValues(product));
  }, [reset, product]);

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <form onSubmit={handleSubmit(onSave)} autoComplete="off">
        <Grid container>
          {Object.keys(product.contentItems).map((key) => (
            <Grid item xs={12} sx={{ pr: 3 }} key={key}>
              <TextField
                fullWidth
                multiline
                size="small"
                label={key.replace(/([a-z])([A-Z])/g, (_, g1, g2) => `${g1} ${g2}`)}
                sx={{ mb: 3 }}
                inputProps={{
                  readOnly: disableEdit,
                  'data-testid': `${testId}TextInput`,
                }}
                variant="standard"
                disabled={isLoading}
                {...register(`contentItems.${key}` as any, {
                  validate: (value) =>
                    typeof value === 'string' && value.trim() !== ''
                      ? value.trim().length < 3
                        ? 'Use at least 3 characters.'
                        : undefined
                      : undefined,
                })}
                error={errors?.contentItems !== undefined && errors.contentItems[key as ContentType] != null}
                helperText={
                  errors?.contentItems !== undefined ? errors.contentItems[key as ContentType]?.message : undefined
                }
              />
            </Grid>
          ))}
          {!disableEdit && (
            <Grid item sx={{ pr: 3 }} xs={12} container direction="row" justifyContent="flex-end" alignItems="baseline">
              <Grid item>
                <ActionButton
                  icon="edit"
                  loading={isLoading}
                  type="submit"
                  disabled={!isDirty}
                  testId={`${testId}Save`}
                >
                  SAVE
                </ActionButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
};
