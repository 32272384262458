import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Box, Divider, Grid, Skeleton, useTheme } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';
import { EditOrderMetadataCriteria } from '@one/api-models/lib/Admin/Orders/Common/EditOrderMetadataCriteria';
import { EditOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/EditOrderRequest';
import { EditOrderResponse } from '@one/api-models/lib/Admin/Orders/Common/EditOrderResponse';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';
import { TransactionListResponse } from '@one/api-models/lib/Admin/Transaction/TransactionListResponse';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';

import { ApiError } from 'apiAccess/api-client';
import { ReactComponent as OrderIcon } from 'assets/icons/charge.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { Notes } from 'common/Notes';
import { orderStatusOptions } from 'components/_common/constants/StatusOptions';
import { StatusChip } from 'components/_common/StatusChip';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useSwitchActiveBrand } from 'components/hooks/useSwitchActiveBrand';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { Typography } from 'styled';
import { ActionIconButton } from 'styled/IconButton';

import { PaymentPlansList } from '../paymentPlans/PaymentPlansList';
import { EditMetadataDialog } from '../sales/components/EditMetadataDialog';
import { TransactionsDataGrid } from '../transactions/components/TransactionsDataGrid';

import { HeaderActions } from './components/HeaderActions';
import { MainOrderDetails } from './components/MainOrderDetails';
import { OrderItems } from './components/OrderItems';
import { OrderPricingSummary } from './components/OrderPricingSummary';
import { OrderSummary } from './components/OrderSummary';
import { PageHeader } from './components/PageHeader';
import { SecondaryOrderDetails } from './components/SecondaryOrderDetails';
import { Section } from './components/Section';

export const OrderDetails = () => {
  const testId = 'OrderDetails';
  const theme = useTheme();
  const { api } = useApiHelpers();
  const { addApiError, apiErrorHandler, addMessage } = useToastMessage();
  const { switchActiveBrand } = useSwitchActiveBrand();

  const defaultTransactionsPageSize = 25;
  const [transactionsPage, setTransactionsPage] = useState(0);
  const [transactionsPageSize, setTransactionsPageSize] = useState(defaultTransactionsPageSize);
  const { orderNumber } = useParams<{ orderNumber: string }>() as { orderNumber: string };
  const [openEditMetadata, setOpenEditMetadata] = useState(false);

  useEffect(() => {
    switchActiveBrand(orderNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumber]);

  const {
    data: transactionData,
    isFetching: isTransactionFetching,
    refetch: refetchTransactions,
  } = useQuery<TransactionListResponse, ApiError>(
    ['transactionData', orderNumber],
    () =>
      api.transaction.loadTransactionList({
        partnerKeys: [],
        brandKeys: [],
        statuses: [],
        transactionIds: [],
        orderNumber: orderNumber,
        listingCriteria: {
          skip: transactionsPage * transactionsPageSize,
          take: transactionsPageSize,
        },
      }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      keepPreviousData: false,
      onError: (error: ApiError) => addApiError(error),
    },
  );

  const {
    data: orderData,
    isFetching: isOrderFetching,
    refetch: refetchOrder,
  } = useQuery<Order, ApiError>(
    ['orderDetailsData', orderNumber],
    () =>
      api.order.loadOrderDetails({
        orderNumber: orderNumber,
      }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      keepPreviousData: false,
      onError: (error: ApiError) => addApiError(error),
    },
  );
  const { isLoading: isMetadataLoading, mutate: mutateMetadata } = useMutation<
    any,
    ApiError,
    EditOrderRequest,
    unknown
  >((request) => api.sales.editOrder(request), {
    mutationKey: 'editMetadataMutation',
    onSuccess: (value: EditOrderResponse) => {
      if (value.isSuccessful) {
        addMessage({
          label: 'Metadata updated successfully.',
          severity: 'success',
        });
        refetchOrder();
      }
    },
    onError: (error) => {
      apiErrorHandler(error);
    },
  });

  const handleTransactionsPaginationChanges = (data: GridPaginationModel) => {
    setTransactionsPageSize(data.pageSize);
    setTransactionsPage(data.page);
  };

  const handleRefetchOrder = (refetchAllData?: boolean): void => {
    refetchOrder();
    if (refetchAllData) {
      refetchTransactions();
    }
  };

  const handleSaveMetadata = (metadata: Metadata[]) => {
    const request: EditOrderRequest = {
      orderNumber: orderData?.orderNumber ?? '',
      edits: [
        {
          $type: EditOrderMetadataCriteria.$type,
          metadata: metadata,
        } as EditOrderMetadataCriteria,
      ],
    };
    mutateMetadata(request);
  };

  return (
    <Box sx={{ p: { xs: 1, md: 3 } }}>
      {(isOrderFetching || isMetadataLoading) && <OrderDetailsSkeleton />}
      {!isOrderFetching && !isMetadataLoading && orderData && (
        <>
          <PageHeader
            label="Sale Order"
            icon={<OrderIcon />}
            title={`${orderData?.orderNumber || orderNumber}`}
            titleBadge={
              orderData?.orderStatus ? (
                <StatusChip
                  status={orderStatusOptions[orderData?.orderStatus] || orderData?.orderStatus}
                  width="auto"
                />
              ) : null
            }
            actions={<HeaderActions orderData={orderData} testId={testId} handleRefetchOrder={handleRefetchOrder} />}
          />
          <Divider sx={{ my: 2 }} />
          <MainOrderDetails order={orderData} />
          <Divider sx={{ my: 2 }} />
          <SecondaryOrderDetails order={orderData} />

          <Grid container columnSpacing={5}>
            <Grid item xs={12} xl={9}>
              <Section title="Items" showDivider={false}>
                <>
                  <OrderItems order={orderData} />
                  <OrderPricingSummary order={orderData} />
                </>
              </Section>

              <Section title="Order Summary" showDivider={true}>
                <OrderSummary order={orderData} />
              </Section>

              {orderData?.paymentPlan && (
                <Section title="Payment Plans">
                  <PaymentPlansList
                    isFetchingPaymentPlanList={false}
                    hideFooter={true}
                    paymentPlans={orderData?.paymentPlan ? [orderData.paymentPlan] : []}
                    handleUpdatePaymentPlanSuccess={() => handleRefetchOrder(true)}
                    memberId={orderData?.customer?.memberKey || ''}
                    testId={`${testId}PaymentPlans`}
                  />
                </Section>
              )}

              <Section title="Transactions" showDivider={false}>
                <TransactionsDataGrid
                  transactions={transactionData?.transactions}
                  isLoading={isTransactionFetching}
                  allBrandsEnabled={false}
                  page={transactionsPage}
                  pageSize={transactionsPageSize}
                  handlePaginationChanges={handleTransactionsPaginationChanges}
                />
              </Section>
            </Grid>
            <Grid item xs={12} xl={3}>
              <Notes testId={testId} />
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                <Typography variant="h4">Metadata</Typography>
                {orderData?.metadataKeys && orderData?.metadataKeys.length > 0 && (
                  <ActionIconButton
                    onClick={() => setOpenEditMetadata(true)}
                    data-testid="EditSalesOrderMetadataButton"
                  >
                    <EditIcon />
                  </ActionIconButton>
                )}
              </Box>
              <Divider sx={{ my: 1 }} />
              {orderData?.metadata && orderData?.metadata.length > 0 ? (
                orderData?.metadata.map((metadata) => (
                  <Box key={metadata.metadataKey} mb={1}>
                    <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
                      {metadata.metadataKey}
                    </Typography>
                    <Typography variant="body1" sx={{ color: theme.customPalette.text.primary }}>
                      {metadata.metadataValue}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
                  No metadata.
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}

      {openEditMetadata && (
        <EditMetadataDialog
          open={openEditMetadata}
          handleClose={() => setOpenEditMetadata(false)}
          handleSave={handleSaveMetadata}
          testId="SalesOrderMetadata"
          metadata={orderData?.metadata ?? []}
          metadataKeys={orderData?.metadataKeys ?? []}
          entityId={orderData?.orderNumber}
        />
      )}
    </Box>
  );
};

const OrderDetailsSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" sx={{ height: 50 }} />
      <Divider sx={{ my: 2 }} />
      <Skeleton variant="rectangular" sx={{ height: 100 }} />
      <Divider sx={{ my: 2 }} />
      <Skeleton variant="rectangular" sx={{ height: 100, mb: 2 }} />
      <Skeleton variant="rectangular" sx={{ height: 200, mb: 2 }} />
      <Skeleton variant="rectangular" sx={{ height: 300, mb: 2 }} />
      <Skeleton variant="rectangular" sx={{ height: 100, mb: 2 }} />
    </Box>
  );
};
