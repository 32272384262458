import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { Box, Grid, Typography } from '@mui/material';
import { Benefit } from '@one/api-models/lib/Membership/Account/Benefit';

interface BenefitsProps {
  benefits?: Benefit[];
}

export const Benefits = ({ benefits }: BenefitsProps) => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        Benefits
      </Typography>
      <Grid container spacing={2}>
        {benefits?.map((benefit) => (
          <Grid item xs={12} display="flex" key={benefit.id}>
            <LoyaltyIcon sx={{ opacity: 0.6 }} />
            <Typography pl={1}>{benefit.name}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
