import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Alert, Box, Button, Link, Paper } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { PromoCodeDefinition } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinition';
import { ListRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/ListRequest';
import { ListResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/ListResponse';

import { ApiError } from 'apiAccess/api-client';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand } from 'slices/applicationDataSlice';

import { PromoCodeDefinitionStatus } from './PromoCodeDefinitionStatus';
import { promoCodeDefinitionTypeOptions } from './promoCodeHelpers';

const defaultPageSize = 25;

export const PromoCodeDefinitionListView = () => {
  const navigate = useNavigate();
  const { formatDate } = useFormat();
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();
  const [page, setPage] = useState(0);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [promoCodeDefinitions, setPromoCodeDefinitions] = useState<PromoCodeDefinition[]>([]);
  const isLoading = useIsMutating({ mutationKey: 'listPromoCodeDefMutation' }) > 0;
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key || '';
  const testIdPrefix = 'PromoCodes';
  const handleOnRowClick = (group: PromoCodeDefinition) => {
    navigate(`/marketing/promo-codes/${group.id}`);
  };

  useEffect(() => {
    listPromoCodeDefMutation.mutateAsync({
      brandKey,
      listHandling: {
        skip: page * defaultPageSize,
        take: defaultPageSize,
        ascending: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activeBrand]);

  const listPromoCodeDefMutation = useMutation<ListResponse, ApiError, ListRequest, unknown>(
    (request) => api.promoCodeDefinition.load(request),
    {
      mutationKey: 'listPromoCodeDefMutation',
      onSuccess: (value: ListResponse) => {
        if (value) {
          setPromoCodeDefinitions(value.promoCodeDefinitions.items);
          setTotalRowsCount(value.promoCodeDefinitions.itemCount);
        }
      },
      onError: apiErrorHandler,
    },
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'promoCode',
      headerName: 'Promo Code',
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'promoCodeDefinitionType',
      headerName: 'Promo Code Type',
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => (
        <>{promoCodeDefinitionTypeOptions[params.row.promoCodeDefinitionType]?.label ?? ''}</>
      ),
    },
    {
      field: 'name',
      headerName: 'Customer Display Name',
      flex: 2,
      sortable: false,
      renderCell: (params: any) => (
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={params.row.name ?? ''}>
          {params.row.name}
        </Box>
      ),
    },
    {
      field: 'issuedPromoCodesCount',
      headerName: 'Issued',
      sortable: false,
      flex: 1,
    },
    {
      field: 'redeemedPromoCodesCount',
      headerName: 'Used',
      sortable: false,
      flex: 1,
    },
    {
      field: 'validFromDate',
      headerName: 'Valid From (UTC)',
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <>{formatDate(params.row.validFromDate, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'validToDate',
      headerName: 'Valid Until (UTC)',
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <>{formatDate(params.row.validToDate, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      sortable: false,
      flex: 1.2,
      renderCell: (params: any) => <>{params.row.createdBy}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <PromoCodeDefinitionStatus status={params.row.status} />,
    },
    {
      field: 'updatedAt',
      headerName: 'Date Updated (UTC)',
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <>{formatDate(params.row.updatedAt, true, 'MMM dd, yyyy') || ''}</>,
    },
  ];

  return (
    <Box>
      <LoadingScreen open={isLoading} message={'Loading...'} />
      <PageHeader
        title="Promo Codes - Definitions"
        testId={testIdPrefix}
        titleAside={
          <Link
            component={RouterLink}
            to="/marketing/promo-codes/add"
            underline="none"
            data-testid={`${testIdPrefix}NewPromoCodeLink`}
          >
            <Button
              type="submit"
              variant="contained"
              size="medium"
              disableRipple
              startIcon={<AddIcon />}
              data-testid={`${testIdPrefix}NewPromoCodeButton`}
            >
              New Promo Code Definition
            </Button>
          </Link>
        }
      />
      <Paper sx={{ p: 3 }}>
        {/* V1 - no filters available */}
        {/* <PromoCodeDefinitionSearchForm /> */}
        {!isLoading ? (
          <>
            {promoCodeDefinitions && promoCodeDefinitions.length > 0 ? (
              <DataGrid
                rows={promoCodeDefinitions}
                columns={columns}
                paginationMode="server"
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: page,
                      pageSize: defaultPageSize,
                    },
                  },
                }}
                rowCount={totalRowsCount}
                onPaginationModelChange={(data: GridPaginationModel) => setPage(data.page)}
                hideFooter={totalRowsCount <= defaultPageSize}
                onRowClick={(param: any, event: any) => handleOnRowClick(param.row as PromoCodeDefinition)}
                sx={{
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                  },
                }}
              />
            ) : (
              <Alert severity="info" variant="outlined">
                No promo codes available.
              </Alert>
            )}
          </>
        ) : null}
      </Paper>
    </Box>
  );
};
