import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Box, Grid, Paper, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SummaryLoadResponse } from '@one/api-models/lib/Membership/Account/SummaryLoadResponse';

import { ApiError } from 'apiAccess/api-client';
import { PageHeader } from 'components/_common/PageHeader';
import { a11yProps, TabPanel } from 'components/_common/tabs/TabPanel';
import { useRetrievePaymentPlanList } from 'components/hooks/paymentHooks';
import { useAgentAccess } from 'components/hooks/useAgentAccess';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useSwitchActiveBrand } from 'components/hooks/useSwitchActiveBrand';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { setBreadcrumbsInStorage } from 'config/sessionStorage';
import { RouteKey } from 'models/RouteKey';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';

import { CustomerActions } from '../CustomerActions';
import { CustomerSummary } from '../CustomerSummary';

import { AssetsSection } from './profile/AssetsSection';
import { CustomerInfo } from './profile/CustomerInfo';
import { NotesSection } from './profile/NotesSection';
import { PaymentPlansSection } from './profile/PaymentPlansSection';
import { PaymentsSection } from './profile/PaymentsSection';
import { PurchasesSection } from './profile/PurchasesSection';
import { CustomerInfoSkeleton } from './profile/skeletons/CustomerInfoSkeleton';
import { EditCustomer } from './EditCustomer';

interface LocationState {
  state: {
    refetch: boolean;
  };
}

export const CustomerDetailView = () => {
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const location = useLocation() as LocationState;
  const theme = useTheme();
  const { id: memberId } = useParams<{ id: string }>() as { id: string };
  const { agentAccessRequiredBrandConfiguration } = useAgentAccess();
  const { switchActiveBrand } = useSwitchActiveBrand();
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const testIdPrefix = 'CustomerDetails';

  const [isEditCustomerOpen, setIsEditCustomerOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleMemberDataReload = (): void => {
    refetchMemberLoad();
    refetchPaymentPlanList();
  };

  /** Load */
  useEffect(() => {
    if (location && location.state && location.state.refetch) {
      handleMemberDataReload();
      location.state.refetch = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    switchActiveBrand(memberId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  const {
    data: memberLoadData,
    isFetching: isFetchingMemberLoadData,
    refetch: refetchMemberLoad,
  } = useQuery<SummaryLoadResponse, ApiError>(
    ['memberLoad', memberId],
    () =>
      api.members.load({
        memberKey: memberId,
        brandKey: activeBrand?.key || '',
        partnerKey: activePartner?.key || '',
        listHandling: { skip: 0, take: -1, ascending: true },
      }),
    {
      enabled: !!memberId,
      keepPreviousData: false,
      retry: false,
      // Due to the default cache time of 5 minutes, onSuccess is not always triggered
      cacheTime: 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (value) => {
        setBreadcrumbsInStorage(RouteKey.CustomersDetails, {
          id: value.accountSummary.memberKey,
          label: `${value.accountSummary.profileSummary.firstName} ${value.accountSummary.profileSummary.lastName}`,
        });
      },
      onError: (error) => addApiError(error),
    },
  );

  const {
    isFetching: isFetchingPaymentPlanList,
    data: paymentPlanListData,
    refetch: refetchPaymentPlanList,
  } = useRetrievePaymentPlanList(memberId);

  const handleRedemptionSuccess = () => {
    refetchMemberLoad();
    refetchPaymentPlanList();
  };

  const handleUpdatePaymentPlanSuccess = () => {
    refetchMemberLoad();
    refetchPaymentPlanList();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: { md: 1 } }}>
      <Box
        sx={{
          maxWidth: '860px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PageHeader testId={testIdPrefix} />
      </Box>
      <Grid container spacing={{ xs: 1, lg: 2 }} alignItems={'flex-start'}>
        <Grid
          item
          xs={12}
          xl={3}
          sx={{
            position: { xs: 'relative', lg: 'sticky' },
            top: 0,
            right: 0,
            height: 'fit-content',
          }}
        >
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            {/* Summary view */}
            <CustomerSummary
              memberLoadData={memberLoadData}
              isFetchingMemberLoadData={isFetchingMemberLoadData}
              testId={testIdPrefix}
            />
            {useMediaQuery(theme.breakpoints.between('xs', 'xl')) && (
              <Box mt={{ xs: 2, md: 0 }} ml={{ md: 2 }}>
                <CustomerActions
                  memberId={memberId}
                  memberLoadData={memberLoadData}
                  isFetchingMemberLoadData={isFetchingMemberLoadData}
                  testId={testIdPrefix}
                />
              </Box>
            )}
          </Box>
          {agentAccessRequiredBrandConfiguration('restrictNotes') && (
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                mt: 3,
                p: 1,
                border: 1,
                borderColor: '#F5E6BE',
                backgroundColor: memberLoadData?.accountSummary.profileSummary.holderAccountNotes ? '#FDF9DD' : 'none',
              }}
            >
              <NotesSection
                isLoading={!memberLoadData || isFetchingMemberLoadData}
                onViewAllNotesClick={() => setSelectedTab(2)}
                accountSummary={memberLoadData?.accountSummary}
                testId={`${testIdPrefix}Notes`}
              />
            </Paper>
          )}
        </Grid>

        {/* Tabbed view */}
        <Grid item xs={12} xl={6.5}>
          <Paper
            sx={{
              width: '100%',
              display: 'flex',
              mt: { xs: 3, sm: 0 },
            }}
          >
            <Box sx={{ width: '100%', px: 3 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="profile tabs">
                  <Tab
                    label="Summary"
                    {...a11yProps(0)}
                    sx={{ textTransform: 'none', fontSize: '16px' }}
                    data-testid={`${testIdPrefix}SummaryTabButton`}
                  />

                  <Tab
                    label="Wallet"
                    {...a11yProps(1)}
                    sx={{ textTransform: 'none', fontSize: '16px' }}
                    data-testid={`${testIdPrefix}WalletTabButton`}
                  />

                  {agentAccessRequiredBrandConfiguration('restrictNotes') && (
                    <Tab
                      label="Notes"
                      {...a11yProps(2)}
                      sx={{ textTransform: 'none', fontSize: '16px' }}
                      data-testid={`${testIdPrefix}NotesTabButton`}
                    />
                  )}

                  {agentAccessRequiredBrandConfiguration('restrictHistory') && (
                    <Tab
                      label="History"
                      {...a11yProps(3)}
                      sx={{ textTransform: 'none', fontSize: '16px' }}
                      data-testid={`${testIdPrefix}HistoryTabButton`}
                    />
                  )}
                </Tabs>
              </Box>
              <TabPanel value={selectedTab} index={0}>
                <PaymentPlansSection
                  isFetchingPaymentPlanList={isFetchingPaymentPlanList}
                  paymentPlans={paymentPlanListData?.paymentPlans || []}
                  handleUpdatePaymentPlanSuccess={handleUpdatePaymentPlanSuccess}
                  memberId={memberId}
                  testId={`${testIdPrefix}PaymentPlans`}
                />
                <PurchasesSection
                  isFetchingMemberLoadData={isFetchingMemberLoadData}
                  orders={memberLoadData?.accountSummary.orders}
                  memberKey={memberId}
                  handleMemberDataReload={handleMemberDataReload}
                  testId={`${testIdPrefix}Purchases`}
                />
                <PaymentsSection />
                {!memberLoadData || isFetchingMemberLoadData ? (
                  <CustomerInfoSkeleton />
                ) : (
                  <>
                    <CustomerInfo
                      accountSummary={memberLoadData.accountSummary}
                      handleEditCustomer={() => setIsEditCustomerOpen(true)}
                      testId={`${testIdPrefix}CustomerInfo`}
                    />
                    <EditCustomer
                      isOpen={isEditCustomerOpen}
                      accountSummary={memberLoadData.accountSummary}
                      handleClose={() => setIsEditCustomerOpen(false)}
                      testId={`${testIdPrefix}EditCustomerForm`}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <AssetsSection
                  memberKey={memberId}
                  wallet={memberLoadData?.accountSummary.wallet}
                  portfolio={memberLoadData?.accountSummary.portfolio}
                  onRedemptionSuccess={() => handleRedemptionSuccess()}
                  isLoading={isFetchingMemberLoadData}
                  testId={`${testIdPrefix}Assets`}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={2}>
                <Box sx={{ py: 2, width: '100%' }}>
                  <Typography
                    style={{
                      whiteSpace: 'pre-line',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {memberLoadData?.accountSummary.profileSummary.holderAccountNotes || 'No notes available'}
                  </Typography>
                </Box>
              </TabPanel>
              <TabPanel value={selectedTab} index={3}>
                <Box sx={{ py: 2, width: '100%' }}>
                  <Typography>No history available</Typography>
                </Box>
              </TabPanel>
            </Box>
          </Paper>
        </Grid>
        {useMediaQuery(theme.breakpoints.up('xl')) && (
          <Grid
            item
            lg={3}
            xl={2.5}
            sx={{
              position: { xs: 'relative', lg: 'sticky' },
              top: 0,
              right: 0,
              height: 'fit-content',
            }}
          >
            <CustomerActions
              memberId={memberId}
              memberLoadData={memberLoadData}
              isFetchingMemberLoadData={isFetchingMemberLoadData}
              testId={testIdPrefix}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
