import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { EditOrderMetadataCriteria } from '@one/api-models/lib/Admin/Orders/Common/EditOrderMetadataCriteria';
import { EditOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/EditOrderRequest';
import { EditOrderResponse } from '@one/api-models/lib/Admin/Orders/Common/EditOrderResponse';
import { EarnAlternativeCurrency } from '@one/api-models/lib/Membership/Account/Earn/EarnAlternativeCurrency';
import { Order } from '@one/api-models/lib/Membership/Account/Order';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Membership/Account/Travel/Cruise/CruiseOrderItemSummary';
import { EditTraveler } from '@one/api-models/lib/Membership/Account/Travel/Cruise/EditTraveler';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';
import { OrderItemSummaryBase } from '@one/api-models/lib/Sales/OrderItemSummaryBase';

import { ApiError } from 'apiAccess/api-client';
import { AlternativeCurrency } from 'components/_common/alternativeCurrency/AlternativeCurrency';
import {
  orderFulfillmentStatusOptions,
  orderPaymentStatusOptions,
  orderStatusOptions,
} from 'components/_common/constants/StatusOptions';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { SectionTitle } from 'components/_common/SectionTitle';
import { StatusChip } from 'components/_common/StatusChip';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { SalesOrderMetadata } from 'components/views/sales/components/SalesOrderMetadata';

import { Info } from './Assets/OrderDetails/Info';
import { PaymentDetails } from './Assets/OrderDetails/PaymentDetails';
import { CancellationSection } from './CancellationSection';
import { EditTravelersSection } from './EditTravelersSection';
import { OrderItemDetailsSection } from './OrderItemDetailsSection';

interface Props {
  open: boolean;
  order: Order | null;
  memberKey: string;
  testId: string;
  onClose: (shouldReloadMemberData: boolean) => void;
}

export const OrderDetailsDialog = ({ open, order, testId, memberKey, onClose }: Props) => {
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();
  const [shouldReloadMemberData, setShouldReloadMemberData] = useState<boolean>(false);
  const [isCancellationOpen, setIsCancellationOpen] = useState<boolean>(false);
  const [isEditTravelersOpen, setIsEditTravelersOpen] = useState<boolean>(false);
  const [selectedOrderItemId, setSelectedOrderItemId] = useState<number | undefined>(undefined);
  const isCancellationLoading = useIsMutating({ mutationKey: 'cancelOrderMutation' }) > 0;
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };
  const cruiseOrderItem = order?.orderItems.find((orderItem) => orderItem.$type === CruiseOrderItemSummary.$type) as
    | CruiseOrderItemSummary
    | undefined;
  const supportedEditOperations =
    cruiseOrderItem && cruiseOrderItem?.supportedEditOperations ? cruiseOrderItem?.supportedEditOperations : [];
  const { hasPermission } = useAppHelpers();
  const editTravelerSupportedEditOperation = supportedEditOperations.find((op) => op.$type === EditTraveler.$type);
  const { api } = useApiHelpers();
  const navigate = useNavigate();
  const { apiErrorHandler, addMessage } = useToastMessage();
  const [metadata, setMetadata] = useState<Metadata[]>(order?.metadata ?? []);

  useEffect(() => {
    setMetadata(order?.metadata ?? []);
  }, [order]);

  const { isLoading, mutate: mutateMetadata } = useMutation<any, ApiError, EditOrderRequest, unknown>(
    (request) => api.sales.editOrder(request),
    {
      mutationKey: 'editMetadataMutation',
      onSuccess: (value: EditOrderResponse) => {
        if (value.isSuccessful) {
          addMessage({
            label: 'Metadata updated successfully.',
            severity: 'success',
          });
          setMetadata(value.metadata);
          navigate(`/customers/${memberKey}`, { replace: true, state: { refetch: true } });
        }
      },
      onError: (error) => {
        apiErrorHandler(error);
      },
    },
  );

  const handleOrderCancellationFinished = (): void => {
    setShouldReloadMemberData(true);
  };

  const handleOnTravelersEditFinished = (): void => {
    setShouldReloadMemberData(true);
  };

  const handleCloseEditTravelersForm = (): void => {
    setIsEditTravelersOpen(false);
  };

  const handleCloseCancellationForm = (): void => {
    setIsCancellationOpen(false);
  };

  const handleModalClose = () => {
    if (!isCancellationLoading) {
      onClose(shouldReloadMemberData);
      setShouldReloadMemberData(false);
      setIsCancellationOpen(false);
      setIsEditTravelersOpen(false);
    }
  };

  const handleSelectOrderItem = (id: number | undefined) => {
    if (id === selectedOrderItemId) {
      setSelectedOrderItemId(undefined);
    } else {
      setSelectedOrderItemId(id);
    }
  };

  const handleSaveMetadata = (metadata: Metadata[]) => {
    const request: EditOrderRequest = {
      orderNumber: order?.orderNumber ?? '',
      edits: [
        {
          $type: EditOrderMetadataCriteria.$type,
          metadata: metadata,
        } as EditOrderMetadataCriteria,
      ],
    };
    mutateMetadata(request);
  };

  let columns: GridColDef[] = [
    {
      field: 'productSummary.name',
      headerName: 'Item name',
      flex: 4,
      sortable: false,
      align: 'left',
      filterable: false,
      renderCell: (params: any) => (
        <Tooltip title={params.row.productSummary.name ?? ''}>
          <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.row.productSummary.name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'supplierIdentifier',
      headerName: 'Supplier',
      flex: 2,
      sortable: false,
      align: 'left',
      filterable: false,
      renderCell: (params: any) => (
        <Tooltip title={params.row.supplierIdentifier ?? ''}>
          <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.row.supplierIdentifier}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'supplierOrderReference',
      headerName: 'Booking reference',
      flex: 2,
      sortable: false,
      align: 'left',
      filterable: false,
      renderCell: (params: any) => (
        <Tooltip title={params.row.supplierOrderReference ?? ''}>
          <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.row.supplierOrderReference}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 2,
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Tooltip title={params.row.quantity ?? ''}>
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body1">
            {params.row.quantity}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'purchaseSummary.totals.earn',
      headerName: 'Earned Amount',
      flex: 3,
      sortable: false,
      filterable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: any) => {
        const earn = params.row.purchaseSummary.totals.earn as EarnAlternativeCurrency[];

        return earn.length > 0 ? (
          <Tooltip
            title={
              <Typography variant="body1" component="span">
                {earn.map((item, index) => (
                  <AlternativeCurrency
                    key={index}
                    currencyNameFontWeight={400}
                    data={{
                      alternativeCurrencyDefinition: item.alternativeCurrencyDefinition,
                      total: item.alternativeCurrencyValue,
                    }}
                  />
                ))}
              </Typography>
            }
          >
            <Typography variant="body1" component="span" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {earn.map((item, index) => (
                <AlternativeCurrency
                  key={index}
                  currencyNameFontWeight={400}
                  data={{
                    alternativeCurrencyDefinition: item.alternativeCurrencyDefinition,
                    total: item.alternativeCurrencyValue,
                  }}
                />
              ))}
            </Typography>
          </Tooltip>
        ) : (
          '0'
        );
      },
    },
    {
      field: 'total-price',
      headerName: 'Total Price',
      flex: 2,
      sortable: false,
      filterable: false,
      headerAlign: 'right',
      align: 'right',

      renderCell: (params: any) => (
        <Tooltip
          title={
            <Typography variant="body1">
              {formatCurrency(
                params.row.purchaseSummary.totals?.fiatAmount?.amount,
                params.row.purchaseSummary.totals?.fiatAmount?.currency,
              )}
            </Typography>
          }
        >
          <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {formatCurrency(
              params.row.purchaseSummary.totals?.fiatAmount?.amount,
              params.row.purchaseSummary.totals?.fiatAmount?.currency,
            )}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'operations',
      headerName: '',
      flex: 1.5,
      sortable: false,
      filterable: false,
      align: 'right',
      renderCell: (params: any) => <Button onClick={() => handleSelectOrderItem(params.row.id)}>Details</Button>,
    },
  ];

  const earn = order != null ? (order.purchaseSummary.totals.earn as EarnAlternativeCurrency[]) : [];
  columns = earn.length > 0 ? columns : columns.filter((column) => column.field !== 'purchaseSummary.totals.earn');

  return (
    <Dialog open={open} onClose={handleModalClose} fullWidth={true} maxWidth="lg">
      <LoadingScreen open={isLoading} message="Please wait..." />
      {order && (
        <Box mb={2}>
          <DialogTitle variant="h6" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box> Order {order.orderNumber}</Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleModalClose}
              aria-label="close"
              size="small"
              data-testid={`${testId}CloseButton`}
              disableRipple
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {!isCancellationOpen && !isEditTravelersOpen && (
              <Grid container spacing={0.1} sx={{ backgroundColor: '#d3d3d3', py: 0.5, px: 2 }}>
                {hasPermission(['Sales.Orders:Cancel']) && order?.isCancellationEnabled === true && (
                  <Grid item xs={12} sm={2}>
                    <Button
                      sx={(theme) => ({ color: theme.palette.error.main, pl: 0 })}
                      onClick={() => setIsCancellationOpen(true)}
                      data-testid={`${testId}CancelOrderButton`}
                    >
                      Cancel order
                    </Button>
                  </Grid>
                )}
                {hasPermission(['Sales.Orders:Edit']) &&
                  editTravelerSupportedEditOperation != null &&
                  cruiseOrderItem != null && (
                    <Grid item xs={12} sm={2}>
                      <Button onClick={() => setIsEditTravelersOpen(true)} data-testid={`${testId}EditTravelersButton`}>
                        Edit Travelers
                      </Button>
                    </Grid>
                  )}
              </Grid>
            )}
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <Info label="Order placed" value={formatDate(new Date(order.orderDate), true, 'MMM dd, yyyy')} />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Info
                    label="Order Status"
                    value={
                      order.status != null ? (
                        <StatusChip status={orderStatusOptions[order.status] || order.status} width="auto" />
                      ) : (
                        <StatusChip status={'-'} />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Info
                    label="Fulfillment Status"
                    value={
                      order.fulfillmentStatus != null ? (
                        <StatusChip
                          status={orderFulfillmentStatusOptions[order.fulfillmentStatus] || order.fulfillmentStatus}
                          width="auto"
                        />
                      ) : (
                        <StatusChip width="100px" status={'-'} />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Info
                    label="Payment Status"
                    value={
                      order.paymentStatus != null ? (
                        <StatusChip
                          status={orderPaymentStatusOptions[order.paymentStatus] || order.paymentStatus}
                          width="auto"
                        />
                      ) : (
                        <StatusChip width="100px" status={'-'} />
                      )
                    }
                  />
                </Grid>
                {order?.soldByEmail != null && (
                  <Grid item xs={12} sm={3}>
                    <Info label="Sold by" value={order.soldByEmail} />
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={2} mt={2}>
                {earn.length > 0 && (
                  <Grid item xs={12} sm={3}>
                    <Info
                      label="Total Earned Amount"
                      value={
                        <Box>
                          {earn.map((item, index) => (
                            <AlternativeCurrency
                              key={index}
                              margin={0}
                              data={{
                                alternativeCurrencyDefinition: item.alternativeCurrencyDefinition,
                                total: item.alternativeCurrencyValue,
                              }}
                            />
                          ))}
                        </Box>
                      }
                    />
                  </Grid>
                )}
              </Grid>

              <Box mt={4}>
                <SectionTitle title="Order items" />
                <DataGrid
                  rows={order.orderItems.map((item: OrderItemSummaryBase, index: number) => ({ ...item }))}
                  columns={columns}
                  rowHeight={45}
                />
              </Box>
              {selectedOrderItemId != null && (
                <OrderItemDetailsSection
                  order={order}
                  orderItemId={selectedOrderItemId}
                  onClose={() => handleSelectOrderItem(undefined)}
                />
              )}

              <PaymentDetails order={order} />

              {order.billingDetails && (
                <Grid container spacing={2} sx={{ mt: -1 }}>
                  <Grid item xs={12}>
                    <SectionTitle title="Billing details" sx={{ mb: 0 }} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1">{`${order.billingDetails.firstName} ${order.billingDetails.lastName}`}</Typography>
                    {order.billingDetails.billingAddress && (
                      <Typography variant="body1">{`${order.billingDetails.billingAddress.addressLine1}, ${
                        order.billingDetails.billingAddress.addressLine2
                          ? order.billingDetails.billingAddress.addressLine2 + ', '
                          : ' '
                      }${order.billingDetails.billingAddress.city}, ${order.billingDetails.billingAddress.state}, ${
                        order?.billingDetails?.billingAddress?.postalCode
                      }, ${order?.billingDetails?.billingAddress?.country}`}</Typography>
                    )}
                    <Typography variant="body1">{order.billingDetails.email}</Typography>
                    <Typography variant="body1">{order.billingDetails.phone}</Typography>
                  </Grid>
                </Grid>
              )}

              {order?.orderItems[0]?.shippingInformation != null && (
                <Grid container spacing={2} sx={{ mt: -1 }}>
                  <Grid item xs={12}>
                    <SectionTitle title="Shipping details" sx={{ mb: 0 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Info
                      label="Ship to"
                      value={`${order?.orderItems[0]?.shippingInformation?.address?.addressLine1}, ${
                        order.orderItems[0].shippingInformation?.address.addressLine2
                          ? order.orderItems[0].shippingInformation?.address.addressLine2 + ', '
                          : ' '
                      }${order?.orderItems[0]?.shippingInformation?.address?.city}, ${
                        order?.orderItems[0]?.shippingInformation?.address?.state
                      }, ${order?.orderItems[0]?.shippingInformation?.address?.postalCode}, ${
                        order?.orderItems[0]?.shippingInformation?.address?.country
                      }`}
                    />
                  </Grid>
                </Grid>
              )}

              {!!order?.metadataKeys && order?.metadataKeys?.length > 0 && (
                <SalesOrderMetadata
                  metadata={metadata}
                  metadataKeys={order.metadataKeys}
                  handleSave={handleSaveMetadata}
                  entityId={order.orderNumber}
                />
              )}

              <CancellationSection
                order={order}
                testId={testId}
                handleCloseCancellationForm={handleCloseCancellationForm}
                onOrderCancellationFinished={handleOrderCancellationFinished}
                isCancellationOpen={isCancellationOpen}
              />
              <EditTravelersSection
                orderNumber={order.orderNumber}
                cruiseOrderItem={cruiseOrderItem}
                editTravelerSupportedEditOperation={editTravelerSupportedEditOperation}
                testId={testId}
                handleCloseEditTravelersForm={handleCloseEditTravelersForm}
                handleOnTravelersEditFinished={handleOnTravelersEditFinished}
                isEditTravelersOpen={isEditTravelersOpen}
              />
            </DialogContent>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};
