import { ReactNode } from 'react';
import { Control, FieldValues } from 'react-hook-form';

import { Box, Chip, LinearProgress, Typography } from '@mui/material';

import ControlledAutocomplete from 'components/_common/ControlledAutocomplete';
import { PaymentMethodOption } from 'components/views/paymentPlans/components/EditPaymentPlanDialog';

interface Props {
  isLoading?: boolean;
  options?: PaymentMethodOption[];
  name: string;
  control: Control;
  error?: boolean;
  helperText?: ReactNode;
  label?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  isAutoFocused?: boolean;
  disableClearable?: boolean;
  requiredMessage?: string;
  popperWidth?: string;
  disableDefaultTextFieldChange?: boolean;
  testId: string;
  onChange: (paymentMethodReference: string | undefined) => void;
  getOptionDisabled?: (option: any) => boolean;
}

const CardSelectorOption = ({ props, option }: any) => (
  <Box
    {...props}
    key={option.code}
    component="li"
    p={1}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    }}
  >
    <Box sx={{ flex: 1 }}>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
        {option.label}
      </Typography>
      <Typography variant="body1" color="#6A7383">
        {`${
          option?.expirationMonth < 10 ? '0' + option?.expirationMonth : option?.expirationMonth
        } / ${option?.expirationYear.toString().slice(-2)}`}
      </Typography>
    </Box>
    {option.isDefaultPaymentMethod && (
      <Box sx={{ display: 'flex' }}>
        <Chip
          sx={{
            maxHeight: '20px',
            backgroundColor: '#bbdffb',
            color: '#2196f3',
            borderRadius: '6px',
            fontWeight: 500,
          }}
          size="small"
          label="Preferred"
        />
      </Box>
    )}
  </Box>
);

export const PaymentPlanControlledCardSelector = ({
  control,
  name,
  label,
  isLoading,
  requiredMessage,
  error,
  popperWidth = '220px',
  helperText,
  disabled,
  options,
  testId,
  onChange,
  ...props
}: Props) =>
  isLoading ? (
    <LinearProgress sx={{ width: '188px', mt: '16px', mb: '17px' }} />
  ) : (
    <ControlledAutocomplete
      name={name}
      label={label}
      options={options}
      error={error}
      autoSelect={false}
      helperText={helperText}
      control={control as unknown as Control<FieldValues, object>}
      requiredMessage={requiredMessage}
      disableClearable
      popperWidth={popperWidth}
      renderOption={(props, option) => <CardSelectorOption props={props} option={option} />}
      onChange={(value) => {
        onChange(value);
      }}
      disabled={disabled}
      getOptionDisabled={(option) => {
        const currentDate = new Date();
        return (
          option.expirationYear < currentDate.getFullYear() ||
          (option.expirationYear === currentDate.getFullYear() && option.expirationMonth < currentDate.getMonth())
        );
      }}
      testId={testId}
      {...props}
    />
  );
