import Home from '@mui/icons-material/Home';
import { Box, Button, Typography } from '@mui/material';

export const Error = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: `100%`,
        marginTop: '35vh',
      }}
    >
      <Typography variant="h5">There was an error processing your request</Typography>
      <Button sx={{ mt: 2 }} color="secondary" aria-label="home" href="/" data-testid={`ErrorPageGoHomeButton`}>
        <Home />
      </Button>
    </Box>
  );
};
