import { RefObject } from 'react';

import { Box } from '@mui/material';

import { Typography } from 'styled';

import { PaymentProvider } from './components/PaymentProvider';

interface SalesOrderPaymentProps {
  formRef: RefObject<HTMLFormElement>;
  memberId: string;
}

export const SalesOrderPayment = ({ formRef, memberId }: SalesOrderPaymentProps) => {
  return (
    <Box sx={{ mt: 7 }}>
      <Typography variant="h5">Payment</Typography>
      <Box sx={{ mt: 2.5 }}>
        <PaymentProvider type="programSale" formRef={formRef} memberId={memberId} />
      </Box>
    </Box>
  );
};
