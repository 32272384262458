import { Fragment } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ReactComponent as CruisesIcon } from 'assets/icons/cruises.svg';
import { ReactComponent as DealsIcon } from 'assets/icons/deals.svg';
import { ReactComponent as ShopIcon } from 'assets/icons/shop-1.svg';
import { ReactComponent as StaysIcon } from 'assets/icons/stays.svg';
import { ReactComponent as VacationsIcon } from 'assets/icons/vacations.svg';
import { ReactComponent as WineIcon } from 'assets/icons/wine.svg';
import { useFormat } from 'components/hooks/useFormat';

type ItemInfoProps = {
  type: string;
  name: string;
  testId: string;
  onClick?: () => void;
  label?: string;
  activeFrom?: Date;
  expiresOn?: Date;
  disabled?: boolean;
};

export const ItemInfo = ({ type, name, testId, onClick, activeFrom, expiresOn, disabled, label }: ItemInfoProps) => {
  const { formatDate } = useFormat();

  return (
    <Fragment>
      <Box sx={{ display: 'flex', mb: 2.3, flexGrow: 1 }}>
        {getIconForType(type)}{' '}
        <Box>
          <Typography sx={{ mx: 1 }} variant="body2">
            {name}
          </Typography>
          {activeFrom && (
            <Typography sx={{ mx: 1 }} variant="body1" lineHeight="1.5">
              Active from: {formatDate(new Date(activeFrom), true, 'dd MMM yyyy')}
            </Typography>
          )}
          {expiresOn && (
            <Typography sx={{ mx: 1 }} variant="body1" lineHeight="1.5">
              Expires on: {formatDate(new Date(expiresOn), true, 'dd MMM yyyy')}
            </Typography>
          )}
        </Box>
      </Box>
      {onClick && (
        <Box>
          <Button
            sx={{
              textTransform: 'none',
              pt: 0,
              pb: 0,
              borderRadius: '16px',
              fontWeight: 'bold',
              lineHeight: '1.4rem',
              borderColor: '#ccc',
            }}
            variant="outlined"
            onClick={onClick}
            disabled={disabled}
            data-testid={`${testId}RedeemButton`}
            fullWidth
          >
            {label || 'Redeem'}
          </Button>
        </Box>
      )}
    </Fragment>
  );
};

function getIconForType(type: string) {
  let icon = <DealsIcon />;

  if (type === 'hotel') icon = <StaysIcon />;
  if (type === 'wine') icon = <WineIcon />;
  if (type === 'deal') icon = <ShopIcon />;
  if (type === 'discount') icon = <DealsIcon />;
  if (type === 'cruise') icon = <CruisesIcon />;
  if (type === 'vacation') icon = <VacationsIcon />;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        maxHeight: '22px',
        maxWidth: '16px',
        justifyContent: 'center',
        opacity: 0.6,
      }}
    >
      {icon}
    </Box>
  );
}
