import React, { MouseEventHandler } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, SxProps, Theme } from '@mui/material';

export interface ActionButtonProps {
  children: React.ReactNode;
  icon: React.ReactNode | 'edit';
  loading?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  size?: 'small' | 'medium' | 'large';
  tabIndex?: number;
  sx?: SxProps<Theme>;
  testId: string;
  onClick?: MouseEventHandler;
}

export const ActionButton = (props: ActionButtonProps) => {
  const { children, loading, disabled, icon, sx, size, type, tabIndex, testId, onClick } = props;

  let iconComponent = icon;
  switch (icon) {
    case 'edit':
      iconComponent = <EditIcon />;
  }

  return (
    <Button
      type={type}
      variant="contained"
      size={size}
      startIcon={loading ? <CircularProgress size="22px" data-testid="LoadingSpinner" /> : iconComponent}
      tabIndex={tabIndex}
      disabled={disabled || loading}
      disableRipple
      onClick={onClick}
      sx={sx}
      data-testid={`${testId}Button`}
    >
      {children}
    </Button>
  );
};

ActionButton.defaultProps = {
  size: 'large',
};
