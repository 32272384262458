import { ChangeEvent, useEffect } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { range } from 'lodash';
import debounce from 'lodash.debounce';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { CalculatedInstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CalculatedInstallmentPlan';
import { CalculatePaymentPlanRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CalculatePaymentPlanRequest';
import { CalculatePaymentPlanResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CalculatePaymentPlanResponse';
import { InstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InstallmentPlan';
import { InstallmentPlanSettings } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InstallmentPlanSettings';
import { Money } from '@one/api-models/lib/Money';

import { ApiError } from 'apiAccess/api-client';
import ControlledSelect from 'common/ControlledSelect';
import ControlledTextField from 'common/ControlledTextField';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import {
  PaymentOptions,
  selectCalculatedInstallmentPlan,
  selectInstallmentsPaymentPlan,
  selectPurchaseData,
  selectSelectedPaymentOption,
  selectSelectedPrograms,
  setCalculatedInstallmentPlan,
  setInstallmentsPaymentPlan,
  setSelectedPaymentOption,
} from 'slices/salesOrderDataSlice';
import { Typography } from 'styled';

export type PaymentPlan = {
  downPayment: number;
  paymentTerm: number;
  minDownPayment: number;
  maxDownPayment: number;
  termsAndConditions: boolean;
};

interface PaymentPlanProps {
  validatePaymentPlanForm: any;
  testId: string;
}

const validationSchema: yup.SchemaOf<PaymentPlan> = yup.object().shape(
  {
    downPayment: yup
      .number()
      .required('Down payment is required')
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(true)
      .test({
        name: 'minDownPaymentMin',
        test: function (value: number | undefined | null) {
          const { minDownPayment, maxDownPayment } = this.parent;
          if (!!value && value < minDownPayment) {
            return this.createError({
              path: 'downPayment',
              message: 'Amount smaller than minimum allowed',
            });
          }
          if (!!value && value > maxDownPayment) {
            return this.createError({
              path: 'downPayment',
              message: 'Amount exceeds total value',
            });
          }
          return true;
        },
      }),
    paymentTerm: yup.number().typeError('Invalid value').required('Term is required'),
    minDownPayment: yup.number().required(),
    maxDownPayment: yup.number().required(),
    termsAndConditions: yup
      .bool()
      .oneOf([true], 'Terms and conditions are required')
      .required('Terms and conditions are required'),
  },
  [
    ['downPayment', 'minDownPayment'],
    ['downPayment', 'maxDownPayment'],
  ],
);

export const PaymentPlanForm = ({ validatePaymentPlanForm, testId }: PaymentPlanProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { formatCurrency, formatDate, getRecurringIntervalTypeLabel, formatRecurringInterval } = useFormat();
  const { apiErrorHandler } = useToastMessage();
  const { api } = useApiHelpers();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const purchaseData = useSelector(selectPurchaseData);
  const installmentsPaymentPlan = useSelector(selectInstallmentsPaymentPlan);
  const calculatedInstallmentPlan = useSelector(selectCalculatedInstallmentPlan);
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);

  const installmentsPlanSettings = purchaseData?.paymentPlanConfig as InstallmentPlanSettings;

  const termOptions = range(
    installmentsPlanSettings?.minIntervalCount || 1,
    installmentsPlanSettings?.maxIntervalCount + 1,
  );

  const defaultValues: PaymentPlan = {
    downPayment: installmentsPlanSettings?.minDownPaymentAmount?.amount,
    paymentTerm: installmentsPlanSettings?.maxIntervalCount,
    minDownPayment: installmentsPlanSettings?.minDownPaymentAmount?.amount || 0,
    maxDownPayment: selectedPrograms[0]?.price?.amount || 0,
    termsAndConditions: false,
  };
  const {
    control,
    formState: { errors },
    getValues,
    register,
    trigger,
  } = useForm<PaymentPlan>({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });

  useEffect(() => {
    validatePaymentPlanForm.current = trigger;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const plan = {
      $type: InstallmentPlan.$Type,
      $Type: InstallmentPlan.$Type,
      paymentPlanConfigId: installmentsPlanSettings?.id,
      intervalCount: installmentsPlanSettings?.maxIntervalCount,
      recurringInterval: installmentsPlanSettings.recurringInterval,
      downPaymentAmounts: [
        {
          currency: installmentsPlanSettings?.minDownPaymentAmount?.currency,
          amount: installmentsPlanSettings?.minDownPaymentAmount?.amount,
          isEstimated: false,
        } as Money,
      ],
    } as InstallmentPlan;
    dispatch(setInstallmentsPaymentPlan(plan));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentsPlanSettings]);

  useEffect(() => {
    if (
      selectedPaymentOption === PaymentOptions.PaymentPlan &&
      selectedPrograms &&
      selectedPrograms.length > 0 &&
      installmentsPaymentPlan?.downPaymentAmounts[0] &&
      installmentsPaymentPlan?.downPaymentAmounts[0]?.amount >=
        installmentsPlanSettings?.minDownPaymentAmount?.amount &&
      installmentsPaymentPlan?.downPaymentAmounts[0]?.amount <= selectedPrograms[0]?.price?.amount
    ) {
      calculatePaymentPlanMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentOption, installmentsPaymentPlan]);

  const calculatePaymentPlanMutation = useMutation<CalculatePaymentPlanResponse, ApiError, void, unknown>(
    async () => {
      return await api.programSales.calculatePaymentPlan({
        programId: selectedPrograms[0]?.id,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        paymentPlan: installmentsPaymentPlan,
      } as CalculatePaymentPlanRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          dispatch(setCalculatedInstallmentPlan(value.calculatedPaymentPlan as CalculatedInstallmentPlan));
        }
      },
      onError: apiErrorHandler,
    },
  );

  const debouncedHandleInstallmentsChanges = debounce(function () {
    const plan: InstallmentPlan = {
      $type: InstallmentPlan.$Type,
      paymentPlanConfigId: installmentsPlanSettings.id,
      intervalCount: getValues().paymentTerm,
      recurringInterval: installmentsPlanSettings.recurringInterval,
      downPaymentAmounts: [
        {
          currency: installmentsPlanSettings?.minDownPaymentAmount.currency,
          amount: getValues().downPayment || 0,
          isEstimated: false,
        },
      ],
    };
    dispatch(setInstallmentsPaymentPlan(plan));
  }, 300);

  const handlePaymentOption = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(setSelectedPaymentOption(value as PaymentOptions));
  };

  const mapCurrency = (currency: string) => {
    switch (currency) {
      case 'USD':
        return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
      case 'GBP':
        return <CurrencyPoundIcon sx={{ fontSize: '1rem' }} />;
      default:
        return <AttachMoneyIcon sx={{ fontSize: '1rem' }} />;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      spacing={1}
      rowGap={1}
      sx={{ alignItems: 'center', mb: 4 }}
    >
      <Grid item xs={12}>
        <RadioGroup onChange={handlePaymentOption} value={selectedPaymentOption} defaultValue={PaymentOptions.OneTime}>
          <FormControlLabel
            value={PaymentOptions.OneTime}
            control={
              <Radio
                size="small"
                inputProps={{
                  //eslint-disable-next-line
                  //@ts-ignore
                  'data-testid': `${testId}OneTimeRadioInput`,
                }}
              />
            }
            label={<Typography variant="body2">Charge Customer in Full Now</Typography>}
          />
          <Typography variant="body1" sx={{ color: theme.palette.text.secondary, ml: 3.25 }}>
            One Credit card or Split payment on Multiple Cards
          </Typography>
          <FormControlLabel
            value={PaymentOptions.PaymentPlan}
            sx={{ mt: 2 }}
            control={
              <Radio
                size="small"
                inputProps={{
                  //eslint-disable-next-line
                  //@ts-ignore
                  'data-testid': `${testId}PaymentPlanRadioInput`,
                }}
              />
            }
            label={<Typography variant="body2">Payment Plans</Typography>}
          />
          <Typography variant="body1" sx={{ color: theme.palette.text.secondary, ml: 3.25 }}>
            Deposits + Monthly Installments
          </Typography>
          <FormControlLabel
            value={PaymentOptions.RequestPayment}
            disabled={true}
            sx={{ mt: 2 }}
            control={
              <Radio
                size="small"
                inputProps={{
                  //eslint-disable-next-line
                  //@ts-ignore
                  'data-testid': `${testId}RequestPaymentRadioInput`,
                }}
              />
            }
            label={
              <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>
                Request Payment
              </Typography>
            }
          />
          <Typography variant="body1" sx={{ color: theme.palette.text.disabled, ml: 3.25 }}>
            Create an invoice requesting payment by a specific date
          </Typography>
        </RadioGroup>
      </Grid>
      <Grid item xs={12} my={2}>
        <Divider />
      </Grid>
      {selectedPaymentOption === PaymentOptions.PaymentPlan && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">Schedule</Typography>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Typography variant="body2">Down Payment</Typography>
            <Typography variant="caption">{`minimum ${
              installmentsPlanSettings?.minDownPaymentPercentage
            }% (${formatCurrency(
              installmentsPlanSettings?.minDownPaymentAmount?.amount,
              installmentsPlanSettings?.minDownPaymentAmount?.currency,
            )})`}</Typography>
          </Grid>
          <Grid item xs={8}>
            <ControlledTextField
              control={control as unknown as Control<FieldValues, object>}
              name="downPayment"
              placeholder="0.00"
              type="number"
              sx={{ width: '200px' }}
              onChange={() => {
                debouncedHandleInstallmentsChanges();
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {mapCurrency(installmentsPlanSettings?.minDownPaymentAmount?.currency)}
                  </InputAdornment>
                ),
                inputProps: {
                  min: installmentsPlanSettings?.minDownPaymentAmount?.amount,
                  max: selectedPrograms[0]?.price.amount,
                },
              }}
              error={errors.downPayment?.message != null}
              helperText={errors.downPayment?.message}
              testId={`${testId}DownPayment`}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Term</Typography>
            <Typography variant="caption">
              {`up to ${formatRecurringInterval(
                installmentsPlanSettings?.maxIntervalCount,
                installmentsPlanSettings.recurringInterval,
                true,
              )}`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <ControlledSelect
              control={control as unknown as Control<FieldValues, object>}
              name="paymentTerm"
              sx={{ width: '100px' }}
              onChange={() => {
                debouncedHandleInstallmentsChanges();
              }}
              testId={`${testId}PaymentTerm`}
            >
              {termOptions.map((item) => (
                <MenuItem key={item} value={item} data-testid={`${testId}PaymentTermItem`}>
                  {item}
                </MenuItem>
              ))}
            </ControlledSelect>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Balance</Typography>
          </Grid>
          <Grid item xs={8}>
            {calculatePaymentPlanMutation.isLoading ? (
              <LinearProgress color="inherit" sx={{ width: '75px', ml: '12px' }} />
            ) : (
              <Typography variant="body1">
                {formatCurrency(calculatedInstallmentPlan?.balance.amount, calculatedInstallmentPlan?.balance.currency)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">
              {getRecurringIntervalTypeLabel(calculatedInstallmentPlan?.recurringInterval)} Payment
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {calculatePaymentPlanMutation.isLoading ? (
              <LinearProgress color="inherit" sx={{ width: '75px', ml: '12px' }} />
            ) : (
              <Typography variant="body1">
                {!calculatedInstallmentPlan?.installments[0] ? (
                  'N/A'
                ) : (
                  <>
                    {formatCurrency(
                      calculatedInstallmentPlan?.installments[0].amount.amount,
                      calculatedInstallmentPlan?.installments[0].amount.currency,
                      2,
                    )}
                    /{formatRecurringInterval(1, calculatedInstallmentPlan.recurringInterval)}
                  </>
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Next Payment</Typography>
          </Grid>
          <Grid item xs={8}>
            {calculatePaymentPlanMutation.isLoading ? (
              <LinearProgress color="inherit" sx={{ width: '75px', ml: '12px' }} />
            ) : (
              <Typography variant="body1">
                {!calculatedInstallmentPlan?.installments[0]
                  ? 'N/A'
                  : formatDate(calculatedInstallmentPlan?.installments[0].billingCycleBegin, true)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">End Term</Typography>
          </Grid>
          <Grid item xs={8}>
            {calculatePaymentPlanMutation.isLoading ? (
              <LinearProgress color="inherit" sx={{ width: '75px', ml: '12px' }} />
            ) : (
              <Typography variant="body1">
                {!calculatedInstallmentPlan?.installments[0]
                  ? 'N/A'
                  : formatDate(calculatedInstallmentPlan?.termEnd, true)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Terms & Conditions</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('termsAndConditions')}
                  inputProps={{
                    //eslint-disable-next-line
                    //@ts-ignore
                    'data-testid': `${testId}AgreeTermsCheckboxInput`,
                  }}
                />
              }
              label="Customer Agreed"
            />
            <>
              {errors.termsAndConditions != null && (
                <FormHelperText error={true}>{errors.termsAndConditions.message}</FormHelperText>
              )}
            </>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Divider />
          </Grid>
        </>
      )}
    </Grid>
  );
};
