import { ReactElement } from 'react';

import { Box, Divider, SxProps } from '@mui/material';

import { Typography } from 'styled';

interface SectionProps {
  title: string;
  children: ReactElement;
  showDivider?: boolean;
  sx?: SxProps;
}

export const Section = ({ title, children, showDivider = true, sx }: SectionProps) => {
  return (
    <Box sx={{ mt: 7, ...sx }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4">{title}</Typography>
        {showDivider && <Divider sx={{ my: 1 }} />}
      </Box>
      {children}
    </Box>
  );
};
