import { BrowserCacheLocation, Configuration, PopupRequest } from '@azure/msal-browser';

import { isTravelAgentPortal } from 'auth/auth';
import appConfig from 'config/appConfig';

const tenantId = appConfig.azureADTenantId;
let clientId = appConfig.azureADClientId;
let redirectUrl = appConfig.azureADRedirectUrl;
let scopes = ['User.Read', 'email'];
let authority = `${appConfig.azureADAuthority}/${tenantId}/`;
let knownAuthorities: string[] = [];

if (isTravelAgentPortal()) {
  clientId = appConfig.agentPortalADClientId;
  redirectUrl = window.location.href.split('/').slice(0, 3).join('/');
  scopes = ['openid', `${appConfig.agentPortalADAuthority}/tasks-api/tasks.read`];
  authority = `${appConfig.agentPortalADAuthority}/B2C_1_SignIn`;
  knownAuthorities = [`${appConfig.agentPortalADAuthority}`];
}

if (clientId == null) throw new Error('Missing Azure AD ClientId');

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId,
    redirectUri: redirectUrl,
    authority,
    knownAuthorities,
    postLogoutRedirectUri: redirectUrl,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

// scopes for id token to be used at MS Identity Platform endpoints
export const loginRequest: PopupRequest = {
  scopes: scopes,
};

// endpoints for MS Graph API services
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
