import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, InputAdornment, Paper, PaperProps, styled } from '@mui/material';
import { ListingData } from '@one/api-models/lib/Admin/Common/ListingData';
import { PredictiveSearchRequest } from '@one/api-models/lib/Admin/Members/Request/PredictiveSearchRequest';
import { MemberProfileItem } from '@one/api-models/lib/Admin/Members/Response/MemberProfileItem';
import { PredictiveSearchResponse } from '@one/api-models/lib/Admin/Members/Response/PredictiveSearchResponse';
import { SearchItemType } from '@one/api-models/lib/Admin/Members/SearchItemType';

import { ApiError } from 'apiAccess/api-client';
import { CustomerStatus } from 'components/_common/CustomerStatus';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useDebouncedValue } from 'components/hooks/useDebounce';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import appConfig from 'config/appConfig';
import { customerSpecificSearchBrands } from 'models/customers/constants';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import { Autocomplete, TextField, Typography } from 'styled';

export const SearchTextField = styled(TextField)(({ theme }) => ({
  'input::placeholder': {
    color: theme.palette.text.primary,
    opacity: 0.8,
  },
  '.Mui-focused': {
    background: theme.palette.grey[100],
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.MuiInputAdornment-root': {
    marginRight: 0,
  },

  '.MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      padding: '4px 8px',
    },
  },
}));

interface AppSearchAutocompleteProps {
  testId: string;
}

export const AppSearchAutocomplete = ({ testId }: AppSearchAutocompleteProps) => {
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const navigate = useNavigate();
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const [selectedOption, setSelectedOption] = useState<MemberProfileItem | undefined>(undefined);
  const [autocompleteOptions, setAutocompleteOptions] = useState<MemberProfileItem[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const inputValueDebounced = useDebouncedValue(inputValue, 300);
  const isLoading = useIsMutating({ mutationKey: 'predictiveSearchMutation' }) > 0;
  const allowBrandSpecific = activeBrand
    ? customerSpecificSearchBrands.includes(activeBrand?.key) && !!appConfig.isMCMSingleIdentityEnabled
    : false;

  const noResultsMessage = allowBrandSpecific
    ? 'Coming soon.'
    : inputValueDebounced && inputValueDebounced.length >= 2
    ? 'No results matching your criteria.'
    : 'Enter at least 2 characters to start searching.';

  useEffect(() => {
    setAutocompleteOptions([]);

    //Temporary disable search for "Princess" brands
    if (!allowBrandSpecific) {
      getSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueDebounced, activeBrand, activePartner]);

  const customerSearchMutation = useMutation<PredictiveSearchResponse, ApiError, PredictiveSearchRequest, unknown>(
    (request) => {
      return api.members.predictiveSearch({
        ...request,
      });
    },
    {
      mutationKey: 'predictiveSearchMutation',
      onSuccess: (value: PredictiveSearchResponse) => {
        if (value) {
          const members = (value.listingData as ListingData<MemberProfileItem>).items;
          setAutocompleteOptions(members);
        }
      },
      onError: (error) => {
        setAutocompleteOptions([]);
        addApiError(error);
      },
    },
  );

  const handleOnChange = (option: MemberProfileItem | null) => {
    setAutocompleteOptions([]);
    setInputValue('');
    if (option) {
      navigate(`/customers/${option.memberKey}`, { state: { refetch: true } });
    }
  };

  const getSuggestions = async () => {
    if (inputValueDebounced == null || inputValueDebounced === '' || inputValueDebounced.length < 2) return;

    customerSearchMutation.mutate({
      searchTerm: inputValueDebounced,
      itemTypes: [SearchItemType.MemberProfile],
      partnerKey: activePartner?.key ?? '',
      brandKey: activeBrand?.key ?? '',
      listHandling: { take: 10000, skip: 0, ascending: true },
    });
  };

  return (
    <Autocomplete
      disablePortal
      data-testid={`${testId}Search`}
      noOptionsText={noResultsMessage}
      loading={isLoading}
      options={autocompleteOptions || []}
      sx={{ width: { xs: 250, lg: 350 } }}
      onChange={(_, value) => handleOnChange(value)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue.trim());
        setAutocompleteOptions([]);
      }}
      clearOnBlur={false}
      value={selectedOption}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      filterOptions={(x) => x}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      groupBy={(option) => option.$type || ''}
      renderOption={(props, option) => <CustomSearchOption props={props} option={option} />}
      renderGroup={(params) => <CustomSearchGroup params={params} />}
      renderInput={(params) => (
        <SearchTextField
          {...params}
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: undefined,
          }}
          placeholder="Search..."
          autoComplete="off"
          onChange={(e) => {
            e.preventDefault();
          }}
        />
      )}
      PaperComponent={(props) => <FilterPopperComponent {...props} />}
    />
  );
};

const CustomSearchGroup = ({ params }: any) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle2" mx={2}>
        {params.group === MemberProfileItem.$type ? 'Customers' : 'Other'}
      </Typography>
      <Box my={1}>{params.children}</Box>
      <Divider />
    </Box>
  );
};

const CustomSearchOption = ({ props, option }: any) => {
  const { formatDate } = useFormat();
  const formattedDateOfBirth = formatDate(option.dateOfBirth, false, 'MMM dd, yyyy');

  return (
    <Box
      {...props}
      sx={{
        '&.MuiAutocomplete-option': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          py: 1,
        },
      }}
      key={option.memberKey}
    >
      <Box display="flex" justifyContent="space-between" width="100%" pb={0.25}>
        <Typography variant="subtitle1">{`${option.firstName} ${option.lastName}`}</Typography>
        <CustomerStatus status={option.accountStatus} />
      </Box>

      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" width="100%">
        <Typography variant="caption">{option.emailAddress}</Typography>
        <Typography variant="caption">{option.mobilePhoneNumber}</Typography>
      </Box>

      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" width="100%">
        <Typography variant="caption">{option.memberKey}</Typography>
        <Typography variant="caption">{formattedDateOfBirth}</Typography>
      </Box>
    </Box>
  );
};

const FilterPopperComponent = ({ ...props }: PaperProps) => {
  return (
    <Paper
      sx={{ mt: 1, minWidth: { xs: '100%', md: '600px' } }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <Box py={2}>{props.children}</Box>
    </Paper>
  );
};
