import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

import { IconButton } from 'styled';

interface TailorAppDockProps {
  testId: string;
}

export const TailorAppDock = ({ testId }: TailorAppDockProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        minWidth: '40px',
        height: '100vh',
        borderColor: theme.palette.divider,
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
        background: '#fff',
        position: 'fixed',
        right: 0,
        zIndex: 1,
      })}
    >
      <IconButton
        data-testid={`${testId}Add`}
        size="small"
        sx={{
          background: `linear-gradient(90deg, #0048E5 0%, #00D078 100%)`,
          color: '#fff',
        }}
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
