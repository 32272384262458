import { useNavigate } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface BackButtonProps {
  redirectUrl: string;
  color: string;
  testId: string;
}
export const BackButton = (props: BackButtonProps) => {
  const navigate = useNavigate();

  const { redirectUrl, color, testId } = props;

  return (
    <IconButton
      sx={{ p: 0, minWidth: 0, pr: 1, color: color }}
      onClick={() => navigate(redirectUrl)}
      data-testid={`${testId}BackButton`}
    >
      <ArrowBack />
    </IconButton>
  );
};
