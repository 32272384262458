import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

type Props = {
  open: boolean;
  message?: string | undefined;
  subMessage?: string | undefined;
  zIndex?: number | undefined;
  label?: string;
};

export const LoadingScreen = ({ open, message, subMessage, zIndex = 9999, label }: Props) => {
  return (
    <Backdrop open={open} sx={{ zIndex: zIndex, backgroundColor: 'rgba(245,245,245,0.8)' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="primary" aria-labelledby={label} aria-label={label} data-testid="LoadingScreen" />
        {message && (
          <Typography sx={{ fontSize: '1.125rem', mt: 2, color: (theme) => alpha(theme.palette.common.black, 0.84) }}>
            {message}
          </Typography>
        )}
        {subMessage && (
          <Typography variant="body2" sx={{ color: (theme) => alpha(theme.palette.common.black, 0.84) }}>
            {subMessage}
          </Typography>
        )}
      </Box>
    </Backdrop>
  );
};
