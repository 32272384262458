import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    padding: 0,
    '.MuiAutocomplete-input': {
      padding: '4px 8px',
    },
  },

  '.MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 12px)',
  },
  '.MuiInputAdornment-positionStart': {
    paddingLeft: '8px',
  },
})) as typeof MuiAutocomplete;
