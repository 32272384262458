import { AlternativeCurrencyPayment } from '@one/api-models/lib/Membership/Account/AlternativeCurrencyPayment';
import { PaymentOptionBase } from '@one/api-models/lib/Membership/Account/PaymentOptionBase';

import { PointsDisplay } from './PointsDisplay';

type Props = {
  options?: PaymentOptionBase[];
  fontSize?: string;
  textAlign?: 'right' | 'left' | undefined;
};

export const PaymentOptionsDisplay = ({ options, fontSize = '1rem', textAlign }: Props) => (
  <>
    {options?.map((option, index) => {
      if (option.$type === AlternativeCurrencyPayment.$type) {
        const currency = option as AlternativeCurrencyPayment;
        return (
          <PointsDisplay
            value={currency.value}
            code={currency.currency.code}
            name={currency.currency.name}
            fontSize={fontSize}
            textAlign={textAlign}
            key={index}
          />
        );
      }
      return null;
    })}
  </>
);
