import { Divider, Grid, Skeleton } from '@mui/material';

export const CustomerActionsSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={7} lg={12}>
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Divider sx={{ my: 2 }} />
        <Skeleton variant="rectangular" height={50} width="100%" />
        <Divider sx={{ my: 2 }} />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
      </Grid>
    </Grid>
  );
};
