import { Fragment, ReactElement, useMemo } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { AlternativeCurrencyPayment } from '@one/api-models/lib/Membership/Account/AlternativeCurrencyPayment';
import { CertificatePayment } from '@one/api-models/lib/Membership/Account/CertificatePayment';
import { Order } from '@one/api-models/lib/Membership/Account/Order';
import { PaymentOptionBase } from '@one/api-models/lib/Membership/Account/PaymentOptionBase';
import { DepositPlan } from '@one/api-models/lib/Membership/Account/PaymentPlan/DepositPlan';
import { InstallmentPlan } from '@one/api-models/lib/Membership/Account/PaymentPlan/InstallmentPlan';
import { Money } from '@one/api-models/lib/Money';

import { AlternativeCurrency } from 'components/_common/alternativeCurrency/AlternativeCurrency';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';

interface Props {
  order: Order;
}

interface PaymentItem {
  id: string;
  title: string | ReactElement;
  amount: string | null | ReactElement;
}

export const PaymentDetails = ({ order }: Props) => {
  const { formatCurrency: formatCurrencyFunc } = useFormat();

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const data: {
    items: PaymentItem[];
    installmentPaymentPlan: InstallmentPlan | undefined;
    depositPaymentPlan: DepositPlan | undefined;
  } = useMemo(() => {
    const items: PaymentItem[] = [];
    let installmentPaymentPlan: InstallmentPlan | undefined = undefined;
    let depositPaymentPlan: DepositPlan | undefined = undefined;

    if (order?.paymentPlan?.$type === InstallmentPlan.$Type) {
      installmentPaymentPlan = order?.paymentPlan as InstallmentPlan;
    } else if (order?.paymentPlan?.$type === DepositPlan.$Type) {
      depositPaymentPlan = order?.paymentPlan as DepositPlan;
    }

    if (!installmentPaymentPlan && !depositPaymentPlan) {
      items.push({
        id: 'payment',
        title: 'Total paid',
        // TODO: ONE-8152: add the payemnet provider and the reference
        // title: (
        //   <Box>
        //     <Typography>Payment provider: Stripe MOCK</Typography>
        //     <Typography variant="caption">Payment reference: 12345 MOCK</Typography>
        //   </Box>
        // ),
        amount: formatCurrency(order.totalPrice.amount, order.totalPrice.currency),
      });
    }

    order?.purchaseSummary?.totals?.paymentOptions?.forEach((paymentOption: PaymentOptionBase) => {
      const certificate = paymentOption as CertificatePayment;

      if (certificate?.displayName) {
        items.push({
          id: `certificate_${certificate.serialNumber}`,
          title: `${certificate.serialNumber ? `[${certificate.serialNumber}]` : ''} ${
            certificate.displayName
          } Certificate`,
          amount: '',
        });
      } else {
        const alternativeCurrency = paymentOption as AlternativeCurrencyPayment;
        if (alternativeCurrency != null) {
          items.push({
            id: `burned_${alternativeCurrency.currency.code}_${alternativeCurrency.value}`,
            title: 'Burned',
            amount: (
              <AlternativeCurrency
                data={{
                  alternativeCurrencyDefinition: alternativeCurrency.currency,
                  total: alternativeCurrency.value,
                }}
              />
            ),
          });
        }
      }
    });

    return { items, depositPaymentPlan, installmentPaymentPlan };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Payment',
      flex: 6,
      sortable: false,
      align: 'left',
      filterable: false,
      renderCell: (params: any) => <Fragment> {params.row.title}</Fragment>,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 2,
      sortable: false,
      align: 'left',
      filterable: false,
      renderCell: (params: any) => <Fragment> {params.row.amount}</Fragment>,
    },
  ];
  return (
    <Fragment>
      <SectionTitle title="Payment details" sx={{ mb: 0 }} />
      {data.items.length > 0 && <DataGrid rows={data.items} columns={columns} rowHeight={45} />}

      {data.installmentPaymentPlan && (
        <InstallmentPlanDetails paymentPlan={data.installmentPaymentPlan} purchaseTotal={order.totalPrice} />
      )}
      {data.depositPaymentPlan && (
        <DepositPlanDetails paymentPlan={data.depositPaymentPlan} purchaseTotal={order.totalPrice} />
      )}
    </Fragment>
  );
};

type InstallmentsDetailsProps = {
  paymentPlan: InstallmentPlan;
  purchaseTotal: Money;
};

const InstallmentPlanDetails = ({ paymentPlan, purchaseTotal }: InstallmentsDetailsProps) => {
  const {
    formatDate,
    formatCurrency: formatCurrencyFunc,
    formatRecurringInterval,
    formatRecurringInvoice,
    getRecurringIntervalTypeLabel,
  } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <SectionTitle title="Payment information" />
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1">Total Price</Typography>
            <Typography variant="body1"> {formatCurrency(purchaseTotal.amount, purchaseTotal.currency)}</Typography>
          </Grid>

          {paymentPlan.downPayment && (
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">Down Payment</Typography>
              <Typography variant="body1">
                {formatCurrency(paymentPlan.downPayment.amount, paymentPlan.downPayment.currency)}
              </Typography>
            </Grid>
          )}

          {paymentPlan.balance && (
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">Balance</Typography>
              <Typography variant="body1">
                {formatCurrency(paymentPlan.balance.amount, paymentPlan.balance.currency)}
              </Typography>
            </Grid>
          )}

          {paymentPlan.totalPaid && (
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">Total Paid</Typography>
              <Typography variant="body1">
                {formatCurrency(paymentPlan.totalPaid.amount, paymentPlan.totalPaid.currency)}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container item>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1">Term</Typography>
            <Typography variant="body1">
              {formatRecurringInterval(paymentPlan?.intervalCount, paymentPlan?.recurringInterval, true)}
            </Typography>
          </Grid>

          {paymentPlan?.nextPaymentAmount && (
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">{`${getRecurringIntervalTypeLabel(
                paymentPlan?.recurringInterval,
              )} Payment`}</Typography>
              <Typography variant="body1">
                {formatRecurringInvoice(
                  formatCurrency(paymentPlan?.nextPaymentAmount?.amount, paymentPlan?.nextPaymentAmount?.currency),
                  paymentPlan?.recurringInterval,
                )}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1">Next Payment</Typography>
            <Typography variant="body1">{formatDate(paymentPlan.nextPaymentDue, true, 'MMM dd, yyyy')}</Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1">End Term</Typography>
            <Typography variant="body1">{formatDate(paymentPlan.endDate, true, 'MMM dd, yyyy')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

type DepositDetailsProps = {
  paymentPlan: DepositPlan;
  purchaseTotal: Money;
};

const DepositPlanDetails = ({ paymentPlan, purchaseTotal }: DepositDetailsProps) => {
  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <SectionTitle title="Payment plan details" />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1">Total Price</Typography>
          <Typography variant="body1"> {formatCurrency(purchaseTotal.amount, purchaseTotal.currency)}</Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1">Down Payment</Typography>
          <Typography variant="body1">
            {formatCurrency(paymentPlan.downPayment.amount, paymentPlan.downPayment.currency)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1">Balance</Typography>
          <Typography variant="body1">
            {formatCurrency(paymentPlan.balance.amount, paymentPlan.balance.currency)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1">Balance due on</Typography>
          <Typography variant="body1">{formatDate(paymentPlan.balancePaymentDate, true, 'MMM dd, yyyy')}</Typography>
        </Grid>

        {paymentPlan.totalPaid && (
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1">Total Paid</Typography>
            <Typography variant="body1">
              {formatCurrency(paymentPlan.totalPaid.amount, paymentPlan.totalPaid.currency)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
