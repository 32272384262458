import { useState } from 'react';
import { useDrop } from 'react-dnd';

import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip, Stack, Typography } from '@mui/material';

import { ProductTag } from 'models/productTags/ProductTag';

export enum ProductTagViewTempStatus {
  Hover,
  Saving,
}

export interface ProductTagView extends ProductTag {
  tempStatus: ProductTagViewTempStatus;
}

interface SelectedChipListProps {
  assignedTags: ProductTag[];
  onAssignTag: (productTag: ProductTag, assignedTags: ProductTag[]) => void;
  onRemoveTag: (productTag: ProductTag) => void;
}

export const AssignedChipList = ({ assignedTags, onAssignTag, onRemoveTag }: SelectedChipListProps) => {
  const [hoverItem, setHoverItem] = useState<ProductTag | undefined>();

  const [collectedProps, drop] = useDrop(
    () => ({
      accept: 'DraggableTreeItem',
      drop: (_item: any, monitor) => {
        const { item } = _item;
        onAssignTag(item, assignedTags);
        return undefined;
      },
      hover: (_item: any, monitor) => {
        const { item } = _item;
        if (hoverItem !== item.tagKey && !assignedTags.find((x) => x.tagKey === item.tagKey)) setHoverItem(item);
        return undefined;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [assignedTags],
  );
  const { canDrop, isOver } = collectedProps;

  const isActive = canDrop && isOver;

  const tempTag: ProductTag | undefined =
    isActive && hoverItem && assignedTags.find((x) => x.tagKey === hoverItem.tagKey) === undefined
      ? ({ ...hoverItem, tempStatus: ProductTagViewTempStatus.Hover } as ProductTagView)
      : undefined;

  const displayAssignedTags = [...assignedTags, tempTag]
    .filter((x) => !!x)
    .sort((x: ProductTag | undefined, y: ProductTag | undefined) =>
      (x!.path.join('|') + x?.name).localeCompare(y!.path.join('|') + y?.name),
    );

  let lastGroup = '';

  return (
    <Box ref={drop} padding={1} marginRight={2} sx={{ minHeight: 400, backgroundColor: isActive ? '#fcfcfc' : '#fff' }}>
      <Stack direction="column" spacing={1.5}>
        {displayAssignedTags.map((tag) => {
          if (!tag) return null;
          const groupHeader =
            lastGroup === tag.path[0] ? null : (
              <Box paddingTop={1} paddingBottom={1}>
                <Typography variant="subtitle2">{tag.path[0]}</Typography>
              </Box>
            );
          lastGroup = tag.path[0];

          const isHover = (tag as ProductTagView).tempStatus === ProductTagViewTempStatus.Hover;

          return (
            <Box key={'grp' + tag.tagKey}>
              {groupHeader}
              <Stack direction="row" spacing={1}>
                {tag.path.slice(1).map((pathChipName) => (
                  <Chip
                    label={pathChipName}
                    key={`path-${tag.tagKey}-${pathChipName}`}
                    size="small"
                    style={
                      isHover
                        ? { backgroundColor: '#f4d2dc', color: '#fff' }
                        : { backgroundColor: '#F1F3F3', color: '#333' }
                    }
                  />
                ))}

                <Chip
                  label={<Box>{tag.name}</Box>}
                  deleteIcon={<ClearIcon style={{ color: 'black' }} />}
                  onDelete={(tag as ProductTagView).tempStatus || isHover ? undefined : () => onRemoveTag(tag)}
                  key={`item-${tag.tagKey}-${tag.name}`}
                  size="small"
                  style={
                    isHover
                      ? { backgroundColor: '#EBAABE', color: '#fff', fontWeight: 'bold' }
                      : { backgroundColor: '#E8F0FD', color: 'black' }
                  }
                />
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
