import { forwardRef, ReactElement, Ref } from 'react';

import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return (
    <Slide
      easing={{
        enter: 'linear',
        exit: 'linear',
      }}
      direction="left"
      ref={ref}
      {...props}
    />
  );
});
