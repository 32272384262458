import { AssetStatus } from '@one/api-models/lib/Membership/Account/AssetStatus';

export const AssetStatusLabel: { [key in AssetStatus]: string } = {
  [AssetStatus.Inactive]: 'Inactive',
  [AssetStatus.Active]: 'Active',
  [AssetStatus.NotRedeemed]: 'Not Redeemed',
  [AssetStatus.Redeemed]: 'Redeemed',
  [AssetStatus.Expired]: 'Expired',
  [AssetStatus.Deleted]: 'Deleted',
};
