import { CreateRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/CreateRequest';
import { GenerateCodesRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/GenerateCodesRequest';
import { ListRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/ListRequest';
import { RetrieveRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/RetrieveRequest';
import { UpdateRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/UpdateRequest';
import { CreateResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/CreateResponse';
import { GenerateCodesResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/GenerateCodesResponse';
import { ListResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/ListResponse';
import { RetrieveResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/RetrieveResponse';
import { UpdateResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/UpdateResponse';

import { IApiClient } from './api-client';

export interface IPromoCodeDefinitionAccess {
  load(criteria: ListRequest): Promise<ListResponse>;
  retrieve(request: RetrieveRequest): Promise<RetrieveResponse>;
  create(criteria: CreateRequest): Promise<CreateResponse>;
  update(request: UpdateRequest): Promise<UpdateResponse>;
  generateCodes(criteria: GenerateCodesRequest): Promise<GenerateCodesResponse>;
}

export class PromoCodeDefinitionAccess implements IPromoCodeDefinitionAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  load(request: ListRequest): Promise<ListResponse> {
    return this.apiClient.call('promocodedefinition/list', {
      ...request,
      $Type: ListRequest.$type,
      cultureCode: 'en-US',
    });
  }

  retrieve(request: RetrieveRequest): Promise<RetrieveResponse> {
    return this.apiClient.call('promocodedefinition/retrieve', {
      ...request,
      $Type: RetrieveRequest.$type,
      cultureCode: 'en-US',
    });
  }

  create(request: CreateRequest): Promise<CreateResponse> {
    return this.apiClient.call('promocodedefinition/create', {
      ...request,
      $Type: CreateRequest.$type,
      cultureCode: 'en-US',
    });
  }

  update(request: UpdateRequest): Promise<UpdateResponse> {
    return this.apiClient.call('promocodedefinition/update', {
      ...request,
      $Type: UpdateRequest.$type,
      cultureCode: 'en-US',
    });
  }

  generateCodes(request: GenerateCodesRequest): Promise<GenerateCodesResponse> {
    return this.apiClient.call('promocodedefinition/generatecodes', {
      ...request,
      $Type: GenerateCodesRequest.$type,
      cultureCode: 'en-US',
    });
  }
}
