import { Brand } from 'models/Brand';
import { CountryCode } from 'models/CountryCode';

export const getBrandCountryCode = (brand?: string) => {
  switch (brand) {
    case Brand.FutureCruisePackagesUk:
    case Brand.GuestBookingsUK:
      return CountryCode.UnitedKingdom;
    case Brand.FutureCruisePackagesCanada:
      return CountryCode.Canada;
    case Brand.GuestBookingsAMZ:
      return undefined;
    default:
      return CountryCode.UnitedStates;
  }
};
