import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  Typography,
} from '@mui/material';

import { FileDetails } from './FileDetails';
import { QueueListingItem } from './QueueListingItem';

export enum UploadStatus {
  new,
  uploading,
  completed,
  error,
}

export const QueueListing = ({
  files,
  onHide,
  status,
  testId,
}: {
  files: FileDetails[];
  onHide: () => void;
  status: UploadStatus;
  testId: string;
}) => {
  const [detailsExpended, setDetailsExpended] = useState(false);

  const filesCount = files.length;
  const globalProgressColor: 'primary' | 'error' | 'success' = 'primary';

  let summaryTitle = '';
  let disableCloseButton = true;
  let progress: JSX.Element | null = <LinearProgress variant="determinate" value={0} />;
  switch (status) {
    case UploadStatus.new:
      summaryTitle = filesCount === 1 ? files[0].name : `You've selected ${filesCount} files`;
      progress = null;
      disableCloseButton = false;
      break;
    case UploadStatus.uploading:
      summaryTitle = filesCount === 1 ? 'Uploading the file...' : `Uploading ${filesCount} files...`;
      progress = <LinearProgress variant="indeterminate" color={globalProgressColor} />;
      break;
    case UploadStatus.completed:
      summaryTitle =
        filesCount === 0
          ? ''
          : filesCount === 1
          ? 'File uploaded successfully'
          : `All ${filesCount} files completed successfully`;
      disableCloseButton = false;
      progress = <LinearProgress variant="determinate" value={100} color="success" />;
      break;
    case UploadStatus.error:
      summaryTitle = 'Upload failed';
      if (!detailsExpended) setDetailsExpended(true);
      disableCloseButton = false;
      progress = <LinearProgress variant="determinate" value={100} color="error" />;
      break;
  }

  return (
    <Accordion expanded={detailsExpended} onChange={() => setDetailsExpended(!detailsExpended)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ width: '100%', mr: 1.5 }}>
          <Grid container direction="row" alignItems="">
            <Grid item sx={{ flex: 1 }}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="body1">{summaryTitle}</Typography>
                </Grid>
              </Grid>
              <Box>{progress}</Box>
            </Grid>
            <Grid item sx={{ pl: 1.5, pt: 0.2 }}>
              <IconButton
                onClick={() => onHide()}
                disabled={disableCloseButton}
                data-testid={`${testId}ListItemCloseButton`}
              >
                <CloseIcon fontSize="small" sx={{ color: '#777' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <List dense sx={{ pt: 0 }}>
          {files.map((f, idx) => (
            <div key={`c-${f.name}`}>
              <Divider sx={{ mt: 0 }} key={`divider-${f.name}`} />
              <QueueListingItem file={f} />
            </div>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
