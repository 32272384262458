import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

interface TransactionsData {
  allBrandsEnabled: boolean;
}
const initialState: TransactionsData = {
  allBrandsEnabled: false,
};

const transactionsDataSlice = createSlice({
  name: 'transactionsData',
  initialState,
  reducers: {
    setAllBrandsEnabled: (state, action: PayloadAction<boolean>) => {
      state.allBrandsEnabled = action.payload;
    },
  },
});

export const { setAllBrandsEnabled } = transactionsDataSlice.actions;

export const selectAllBrandsEnabled = (state: RootState) => state.transactionsData.allBrandsEnabled;

export default transactionsDataSlice.reducer;
