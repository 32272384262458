import { UserData } from 'models/UserData';

import { AccessControlAccess, IAccessControlAccess } from './accessControlAccess';
import { AdminAccess, IAdminAccess } from './adminAccess';
import { AgentAccess, IAgentAccess } from './agentAccess';
import { ApiClient } from './api-client';
import { FileStorageAccess, IFileStorageAccess } from './fileStorageAccess';
import { IMembersAccess, MembersAccess } from './membersAccess';
import { IOrderAccess, OrderAccess } from './orderAccess';
import { IPaymentAccess, PaymentAccess } from './paymentAccess';
import { IProductsAccess, ProductsAccess } from './productsAccess';
import { IProgramSalesAccess, ProgramSalesAccess } from './programSalesAccess';
import { IPromoCodeAccess, PromoCodeAccess } from './promoCodeAccess';
import { IPromoCodeDefinitionAccess, PromoCodeDefinitionAccess } from './promoCodeDefinitionAccess';
import { IPromotionsAccess, PromotionsAccess } from './promotionsAccess';
import { IReportsAccess, ReportsAccess } from './reportsAccess';
import { ISalesAccess, SalesAccess } from './salesAccess';
import { ISingleIdentityPrincessAccess, SingleIdentityPrincessAccess } from './singleIedentityPrincessAccess';
import { ITagsAccess, TagsAccess } from './tagsAccess';
import { ITransactionAccess, TransactionAccess } from './transactionAccess';

export type ApiType = {
  admin: IAdminAccess;
  members: IMembersAccess;
  programSales: IProgramSalesAccess;
  singleIdentityPrincess: ISingleIdentityPrincessAccess;
  products: IProductsAccess;
  fileStorage: IFileStorageAccess;
  tags: ITagsAccess;
  reports: IReportsAccess;
  accessControl: IAccessControlAccess;
  agent: IAgentAccess;
  payment: IPaymentAccess;
  sales: ISalesAccess;
  transaction: ITransactionAccess;
  order: IOrderAccess;
  promoCodeDefinition: IPromoCodeDefinitionAccess;
  promotions: IPromotionsAccess;
  promoCode: IPromoCodeAccess;
};

export const createApi = (loadUser: () => UserData | null, requestToken: () => Promise<string | undefined>) => {
  const apiClient = new ApiClient(loadUser, requestToken);
  const api: ApiType = {
    admin: new AdminAccess(apiClient),
    members: new MembersAccess(apiClient),
    programSales: new ProgramSalesAccess(apiClient),
    singleIdentityPrincess: new SingleIdentityPrincessAccess(apiClient),
    products: new ProductsAccess(apiClient),
    tags: new TagsAccess(apiClient),
    reports: new ReportsAccess(apiClient),
    fileStorage: new FileStorageAccess(apiClient),
    accessControl: new AccessControlAccess(apiClient),
    agent: new AgentAccess(apiClient),
    payment: new PaymentAccess(apiClient),
    sales: new SalesAccess(apiClient),
    transaction: new TransactionAccess(apiClient),
    order: new OrderAccess(apiClient),
    promoCodeDefinition: new PromoCodeDefinitionAccess(apiClient),
    promoCode: new PromoCodeAccess(apiClient),
    promotions: new PromotionsAccess(apiClient),
  };

  return api;
};
