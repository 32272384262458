import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#000',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '-0.4px',
  fontWeight: theme.typography.fontWeightMedium,
}));
