import React, { KeyboardEvent, useState } from 'react';

import { InputBaseComponentProps } from '@mui/material/InputBase';

export const ExpiryInput: React.FC<InputBaseComponentProps> = ({
  id,
  className,
  delimiter = '/',
  placeholder,
  onChange,
  onFocus,
  onBlur,
}) => {
  const [maxLength] = useState(delimiter.length + 4);

  const correctInput = (event: KeyboardEvent<HTMLInputElement>): void => {
    const target = event.currentTarget;
    const value = target.value;
    const key = event.key;
    let typeKey: 'number' | 'specSymbol';
    const monthMin = '01';
    const monthMax = '12';
    const yearMin = new Date().getFullYear() - 2000;
    const yearMax = yearMin + 50;

    if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
      if (/(\d)/.test(key)) {
        typeKey = 'number';
      } else {
        typeKey = 'specSymbol';
      }

      if (value.length === 0 && Number(key) > 1) {
        target.value = '0' + key + delimiter;
        onChange && onChange(event);
        event.preventDefault();
      }

      if (value.length === 1 && value[0] !== '0' && Number(key) > 2) {
        target.value = monthMax + delimiter;
        onChange && onChange(event);
        event.preventDefault();
        return;
      }

      if (value.length === 1 && typeKey !== 'specSymbol') {
        target.value = target.value + key + delimiter;
        onChange && onChange(event);
        event.preventDefault();
      }

      if (value.length === 2 && typeKey !== 'specSymbol') {
        target.value = target.value + delimiter + key;
        onChange && onChange(event);
        event.preventDefault();
        return;
      }

      if (value === '0' && key === '0') {
        target.value = monthMin + delimiter;
        onChange && onChange(event);
        event.preventDefault();
        return;
      }

      if (target.value.length + 1 === maxLength) {
        const arr = target.value.split(delimiter);

        if (arr[0].length === 2 && Number(arr[1] + key) < yearMin) {
          target.value = arr[0] + delimiter + yearMin;
          onChange && onChange(event);
          event.preventDefault();
          return;
        }

        if (arr[0].length === 2 && Number(arr[1] + key) > yearMax) {
          target.value = arr[0] + delimiter + yearMax;
          onChange && onChange(event);
          event.preventDefault();
          return;
        }
      }
    } else {
      // Remove spaces if autocomplete
      event.currentTarget.value = event.currentTarget.value.replace(/\s/g, '');
      onChange && onChange(event);
      event.preventDefault();
      return;
    }
  };

  return (
    <input
      id={id}
      onKeyDown={correctInput}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      className={className}
      maxLength={maxLength}
      placeholder={placeholder}
      autoComplete="cc-exp"
      type="text"
    />
  );
};
