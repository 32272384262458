import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/styles';
import { BillingDetails } from '@one/api-models/lib/Admin/ProgramSales/Purchase/BillingDetails';

import { selectBillingDetails, setBillingDetails } from 'slices/salesOrderDataSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from 'styled';

import { BillingForm } from './components/BillingForm';

interface BillingDetailsDialogProps {
  open: boolean;
  validateOnOpen: boolean;
  handleClose: () => void;
  testId: string;
}

export const BillingDetailsDialog = ({ open, validateOnOpen, handleClose, testId }: BillingDetailsDialogProps) => {
  const dispatch = useDispatch();
  const billingDetails = useSelector(selectBillingDetails);
  const formRef = useRef<HTMLFormElement>(null);
  const theme = useTheme();

  const defaultValues: BillingDetails = {
    firstName: billingDetails?.firstName || '',
    lastName: billingDetails?.lastName || '',
    email: billingDetails?.email || '',
    streetAddress: billingDetails?.streetAddress || '',
    aptNumber: billingDetails?.aptNumber || '',
    city: billingDetails?.city || '',
    state: billingDetails?.state || '',
    stateCode: billingDetails?.stateCode || '',
    zipCode: billingDetails?.zipCode || '',
    country: billingDetails?.country || 'US',
  };

  const handleBillingDataChange = (data: BillingDetails) => {
    dispatch(setBillingDetails(data));
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '496px',
        },
      }}
    >
      <DialogTitle>
        Update Billing Details
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testId}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <BillingForm
          validateOnOpen={validateOnOpen}
          formRef={formRef}
          testId={testId}
          initialDetails={defaultValues}
          onBillingDataChanged={handleBillingDataChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} data-testid={`${testId}CancelButton`}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            formRef?.current?.requestSubmit();
          }}
          data-testid={`${testId}UpdateButton`}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
