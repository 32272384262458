import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

import { Typography } from '@mui/material';
import Link from '@mui/material/Link/Link';
import {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridPaginationModel,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';
import { LoadCriteria } from '@one/api-models/lib/Admin/Products/LoadCriteria';
import { LoadResponse } from '@one/api-models/lib/Admin/Products/LoadResponse';

import { ApiError } from 'apiAccess/api-client';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';

import { ProductType } from './ProductTypeConfig';

type Props = {
  productType: ProductType;
};

const defaultPageSize = 50;

export const ProductsTable = ({ productType }: Props) => {
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [filterValue, setFilterValue] = useState<GridFilterItem[]>();
  const [sortValue, setSortValue] = useState<GridSortItem[]>();
  const [rowCount, setRowCount] = useState<number>(0);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 300,
      filterable: false,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Link
          component={RouterLink}
          to={`/products/${params.row.productType ?? productType}/${params.id}?partitionKey=${params.row.partitionKey}`}
        >
          <Typography>{params.row.name}</Typography>
        </Link>
      ),
    },
    {
      field: 'productType',
      headerName: 'Type',
      width: 130,
      filterable: false,
      sortable: false,
      renderCell: (params: GridCellParams) => params.row.productType ?? productType,
    },
  ];

  const { data, isFetching, isPreviousData } = useQuery<LoadResponse, ApiError>(
    ['products', productType, page, pageSize, filterValue, sortValue],
    () => {
      const criteria = {
        tagIds: [],
        types: [`${productType}`],
        listingCriteria: {
          skip: page * pageSize,
          take: pageSize,
          sortField: sortValue && sortValue.length > 0 ? sortValue[0].field : undefined,
          isAscending: sortValue && sortValue.length > 0 ? sortValue[0].sort === 'asc' : undefined,
        },
        cultureCode: 'en-US',
        detailLevel: 0,

        $Type: LoadCriteria.$type,
      } as LoadCriteria;

      return api.products.load(criteria);
    },
    {
      enabled: true,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess(data) {
        setRowCount(
          data?.products?.hasMoreItems
            ? (page + 1) * pageSize + 1
            : page * pageSize + (data?.products?.items.length ?? 0),
        );
      },
      onError: (error: ApiError) => addApiError(error),
    },
  );

  const handleFilterByName = (model: GridFilterModel): void => {
    setFilterValue(model.items); // TODO: add filter by all columns?
  };

  const handleSort = (model: GridSortModel) => {
    setSortValue(model); // TODO: add filter by all columns?
  };

  const handlePaginationChanges = (data: GridPaginationModel) => {
    setPageSize(data.pageSize);
    setPage(data.page);
  };

  return (
    <DataGrid
      paginationMode="server"
      filterMode="server"
      sortingMode="server"
      onFilterModelChange={handleFilterByName}
      onSortModelChange={handleSort}
      onPaginationModelChange={handlePaginationChanges}
      loading={(isFetching && isPreviousData) || data == null}
      rows={data?.products?.items || []}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            page: page,
            pageSize: defaultPageSize,
          },
        },
      }}
      paginationModel={{
        page: page,
        pageSize: pageSize,
      }}
      rowCount={rowCount}
      hideFooter={rowCount <= pageSize}
    />
  );
};
