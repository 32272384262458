import { Chip } from '@mui/material';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';

type StatusSettings = {
  type: MemberStatus;
  name: string;
  backgroundColor: string;
  color: string;
};

const statusSettingsNew: StatusSettings[] = [
  { type: MemberStatus.Active, name: 'Active', backgroundColor: '#DDF6C7', color: '#2D681E' },
  {
    type: MemberStatus.Inactive,
    name: 'Inactive',
    backgroundColor: '#f5f5f5',
    color: '#424242',
  },
  {
    type: MemberStatus.PendingActivation,
    name: 'Pending Activation',
    backgroundColor: '#d1c4e9',
    color: '#4527a0',
  },
  {
    type: MemberStatus.Deleted,
    name: 'Deleted',
    backgroundColor: '#ffcdd2',
    color: '#c62828',
  },
  {
    type: MemberStatus.Suspended,
    name: 'Suspended',
    backgroundColor: '#ffcdd2',
    color: '#c62828',
  },
];

export const CustomerStatus = ({ status }: { status: MemberStatus }) => {
  const statusSetting = statusSettingsNew.find((el) => {
    return el.type === status;
  }) || {
    name: status,
    backgroundColor: '#f5f5f5',
    color: '#000',
  };
  return (
    <Chip
      sx={{
        maxHeight: '20px',
        backgroundColor: statusSetting.backgroundColor,
        color: statusSetting.color,
        borderRadius: '4px',
        fontWeight: 500,
        alignSelf: 'flex-start',
      }}
      size="small"
      label={statusSetting.name}
    />
  );
};
