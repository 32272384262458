import { Box, CircularProgress, Typography } from '@mui/material';

type Props = {
  message?: string | undefined;
  subMessage?: string | undefined;
  isContained?: boolean;
};
export const Loading = ({ message, subMessage, isContained }: Props) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <Box
      onClick={(e) => handleClick(e)}
      sx={{
        position: isContained ? 'absolute' : 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'rgba(245,245,245,0.8)',
        zIndex: '9000',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: isContained ? 'absolute' : 'fixed',
          zIndex: '9999',
        }}
      >
        <CircularProgress color="primary" data-testid="MainLoadingSpinner" />
        {message && <Typography sx={{ fontSize: '1.125rem', mt: 2, color: 'rgba(0,0,0,0.84)' }}>{message}</Typography>}
        {subMessage && (
          <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.84)' }}>
            {subMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
