import { Chip } from '@mui/material';

type StatusSettings = {
  type: string;
  name: string;
  backgroundColor: string;
  color: string,
};

const statusSettingsNew: StatusSettings[] = [
  { type: 'Active', name: 'Active', backgroundColor: '#DDF6C7', color: '#2D681E' },
  {
    type: 'Inactive',
    name: 'Inactive',
    backgroundColor: '#f5f5f5',
    color: '#424242',
  },
  {
    type: 'Imported',
    name: 'Imported',
    backgroundColor: '#ffcdd2',
    color: '#c62828',
  },
];

export const AgentStatus = ({ status }: { status: string }) => {
  const statusSetting = statusSettingsNew.find((el) => {
    return el.type === status;
  }) || {
    name: status,
    backgroundColor: '#f5f5f5',
    color: '#000',
  };
  return (
    <Chip
      sx={{
        maxHeight: '20px',
        backgroundColor: statusSetting.backgroundColor,
        color: statusSetting.color,
        borderRadius: '4px',
        fontWeight: 500,
      }}
      size="small"
      label={statusSetting.name}
    />
  );
};
