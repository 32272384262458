import { useSelector } from 'react-redux';

import { Box, Button, Grid, Typography } from '@mui/material';
import { AccountSummary } from '@one/api-models/lib/Membership/Account/AccountSummary';

import { SectionTitle } from 'components/_common/SectionTitle';
import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { selectCAStatesList, selectCountryList, selectUSStatesList } from 'slices/customersDataSlice';

interface Props {
  accountSummary: AccountSummary;
  testId: string;
  handleEditCustomer: () => void;
}

export const CustomerInfo = ({ accountSummary, testId, handleEditCustomer }: Props) => {
  const { hasPermission } = useAppHelpers();
  const hasManagePermission = hasPermission(['Customers:Manage']);

  const countryList = useSelector(selectCountryList) || [];
  const usStatesList = useSelector(selectUSStatesList) || [];
  const caStatesList = useSelector(selectCAStatesList) || [];

  const countries = Object.assign({}, ...countryList?.map((item: any) => ({ [item.code]: item.label })));
  const usStatesCode = Object.assign({}, ...usStatesList?.map((item: any) => ({ [item.code]: item.label })));
  const caStatesCode = Object.assign({}, ...caStatesList?.map((item: any) => ({ [item.code]: item.label })));

  const addressLine1 = `${accountSummary.profileSummary.address && `${accountSummary.profileSummary.address} `}${
    accountSummary.profileSummary.address2
  }`;
  const addressLine2 = `${accountSummary.profileSummary.city && `${accountSummary.profileSummary.city} `}${
    accountSummary.profileSummary.state &&
    (accountSummary.profileSummary.country === 'US' && usStatesCode && usStatesCode[accountSummary.profileSummary.state]
      ? `${usStatesCode[accountSummary.profileSummary.state]} `
      : accountSummary.profileSummary.country === 'CA' &&
        caStatesCode &&
        caStatesCode[accountSummary.profileSummary.state]
      ? `${caStatesCode[accountSummary.profileSummary.state]} `
      : `${accountSummary.profileSummary.state} `)
  }${accountSummary.profileSummary.zipCode && `${accountSummary.profileSummary.zipCode} `}${
    accountSummary.profileSummary.country && countries && countries[accountSummary.profileSummary.country]
  }`;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <SectionTitle title="Customer Information" />
        {hasManagePermission ? (
          <Button onClick={handleEditCustomer} variant="text" data-testid={`${testId}EditButton`}>
            Edit
          </Button>
        ) : null}
      </Box>

      <Grid container spacing={2} className="customer-info">
        <Grid item xs={12} sm={7} xl={6}>
          <Typography variant="subtitle1">First Name</Typography>
          <Typography variant="body1">{accountSummary.profileSummary.firstName}</Typography>
        </Grid>
        <Grid item xs={12} sm={5} xl={6}>
          <Typography variant="subtitle1">Last Name</Typography>
          <Typography variant="body1">{accountSummary.profileSummary.lastName}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} xl={6}>
          <Typography variant="subtitle1">E-mail address</Typography>
          <Typography variant="body1" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {accountSummary.profileSummary.email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} xl={6}>
          <Typography variant="subtitle1">Mobile number</Typography>
          <Typography variant="body1">{accountSummary.profileSummary.mobilePhone || '-'}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} xl={6}>
          <Typography variant="subtitle1">Address</Typography>
          <Typography variant="body1">{addressLine1 !== '' ? addressLine1 : '-'}</Typography>
          <Typography variant="body1">{addressLine2 !== '' ? addressLine2 : '-'}</Typography>
        </Grid>
        <Grid item xs={12} sm={5} xl={6}>
          <Typography variant="subtitle1">Lead Source</Typography>
          <Typography variant="body1">{accountSummary.profileSummary.holderAccountLeadSource || '-'}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} xl={6}>
          <Typography variant="subtitle1">ONE Member key</Typography>
          <Typography variant="body1">{accountSummary.memberKey}</Typography>
        </Grid>
        <Grid item xs={12} sm={5} xl={6}>
          <Typography variant="subtitle1">PARTNER Customer ID</Typography>
          <Typography variant="body1">{accountSummary.profileSummary.holderAccountExternalMemberKey || '-'}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} xl={6}>
          <Typography variant="subtitle1">PARTNER Loyalty ID</Typography>
          <Typography variant="body1">{accountSummary.profileSummary.holderAccountPartnerLoyaltyId || '-'}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
