import { kebabCase } from 'lodash';

import { Link } from '@mui/material';

interface Props {
  cruiseLineName: string;
}

export const CancellationPolicy = ({ cruiseLineName }: Props) => {
  const buildCruiseCancellationPolicyLink = (cruiseLineName: string) => {
    const docName = kebabCase(`${cruiseLineName}  cancellation policies`);
    return `https://onemembersclub.com/cruises/rules-and-restrictions/${docName}`;
  };

  return (
    <Link
      sx={{ mb: 2 }}
      target="_blank"
      rel="noopener noreferrer"
      href={buildCruiseCancellationPolicyLink(cruiseLineName)}
    >
      Cruise line's cancellation policy
    </Link>
  );
};
