import { SearchMemberRequest as SearchCriteria } from '@one/api-models/lib/Admin/Members/Request/SearchMemberRequest';
import { MemberResponse as Customer } from '@one/api-models/lib/Admin/Members/Response/MemberResponse';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ControlledAutocompleteOption } from 'components/_common/ControlledAutocomplete';

interface CustomersData {
  searchCriteria: SearchCriteria | undefined;
  customerList: Customer[] | undefined;
  selectedCustomer: Customer | undefined | null;

  countryList: ControlledAutocompleteOption[] | undefined;
  usStatesList: ControlledAutocompleteOption[] | undefined;
  caStatesList: ControlledAutocompleteOption[] | undefined;
}

const initialState: CustomersData = {
  searchCriteria: undefined,
  customerList: undefined,
  selectedCustomer: undefined,
  countryList: [],
  usStatesList: [],
  caStatesList: [],
};

const customersDataSlice = createSlice({
  name: 'customersData',
  initialState,
  reducers: {
    setSearchCriteria: (state, action: PayloadAction<SearchCriteria | undefined>) => {
      state.searchCriteria = action.payload;
    },
    setCustomerList: (state, action: PayloadAction<Customer[] | undefined>) => {
      state.customerList = action.payload;
    },
    setSelectedCustomer: (state, action: PayloadAction<Customer | undefined | null>) => {
      state.selectedCustomer = action.payload;
    },
    setCountryList: (state, action: PayloadAction<ControlledAutocompleteOption[] | undefined>) => {
      state.countryList = action.payload;
    },
    setUSStatesList: (state, action: PayloadAction<ControlledAutocompleteOption[] | undefined>) => {
      state.usStatesList = action.payload;
    },
    setCAStatesList: (state, action: PayloadAction<ControlledAutocompleteOption[] | undefined>) => {
      state.caStatesList = action.payload;
    },
  },
});

export const {
  setSearchCriteria,
  setCustomerList,
  setSelectedCustomer,
  setCountryList,
  setUSStatesList,
  setCAStatesList,
} = customersDataSlice.actions;

export const selectSearchCriteria = (state: RootState) => state.customersData.searchCriteria;
export const selectCustomerList = (state: RootState) => state.customersData.customerList;
export const selectSelectedCustomer = (state: RootState) => state.customersData.selectedCustomer;
export const selectCountryList = (state: RootState) => state.customersData.countryList;
export const selectUSStatesList = (state: RootState) => state.customersData.usStatesList;
export const selectCAStatesList = (state: RootState) => state.customersData.caStatesList;

export default customersDataSlice.reducer;
