import { PromoCodeDefinitionStatus } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinitionStatus';
import { PromoCodeDefinitionType } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinitionType';

export const promoCodeDefinitionTypeOptions = Object.keys(PromoCodeDefinitionType)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({
    code: PromoCodeDefinitionType[key as any] as any as PromoCodeDefinitionType,
    label: key.replace(/_/g, ' '),
  }));

export const promoCodeDefinitionStatusOptions = Object.keys(PromoCodeDefinitionStatus)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({ code: PromoCodeDefinitionStatus[key as any] as any as PromoCodeDefinitionStatus, label: key }));
