import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse as qsParse } from 'qs';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrowRight.svg';
import { SendFeedback } from 'common';
import { useStripeProgramSales } from 'components/hooks/useStripeProgramSales';
import { PaymentProviderType } from 'models/PaymentProvider';
import {
  resetSalesOrderDataSliceState,
  selectBillingDetails,
  selectIsCompleteWithNoPayment,
  selectMetadata,
  selectOrderConfirmation,
  selectPaymentProviderType,
  selectSelectedCustomer,
  selectSelectedPrograms,
} from 'slices/salesOrderDataSlice';
import { AppBar, Button, IconButton, Toolbar, Typography } from 'styled';

import { CreateSalesOrder } from './CreateSalesOrder';
import { SalesOrderComplete } from './SalesOrderComplete';
import { SalesOrderPreview } from './SalesOrderPreview';

const PAGE_HEADER_HEIGHT = '79px';

export const CreateSalesOrderView = () => {
  const testIdPrefix = 'SalesOrder';
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = qsParse(location.search, { ignoreQueryPrefix: true });
  const memberKey: string = queryParams?.memberKey as string;

  const isCompleteWithNoPayment = useSelector(selectIsCompleteWithNoPayment);
  const billingDetails = useSelector(selectBillingDetails);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const orderConfirmation = useSelector(selectOrderConfirmation);
  const paymentProviderType = useSelector(selectPaymentProviderType);
  const metadata = useSelector(selectMetadata);
  const [openBillingDetails, setOpenBillingDetails] = useState<boolean>(false);
  const [forceBillingValidation, setForceBillingValidation] = useState<boolean>(false);
  const paymentFormRef = useRef<HTMLFormElement>(null);
  const { performCompleteOrderWithoutPayment } = useStripeProgramSales();

  useEffect(() => {
    return () => {
      dispatch(resetSalesOrderDataSliceState(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    if (memberKey) {
      navigate(`/customers/${memberKey}`);
    } else {
      navigate(-1);
    }
  };

  const handleNewSaleForCustomer = () => {
    dispatch(resetSalesOrderDataSliceState(true));
  };

  const handleNewSale = () => {
    dispatch(resetSalesOrderDataSliceState(false));
  };

  const handleCloseBillingDetailsDialog = () => {
    setOpenBillingDetails(false);
    setForceBillingValidation(false);
  };

  const handleProcessPayment = () => {
    if (!billingDetails || !selectedCustomer || !selectedPrograms || selectedPrograms.length === 0) return;

    if (!!billingDetails && (!billingDetails?.country || !billingDetails.zipCode || !billingDetails.state)) {
      setForceBillingValidation(true);
      setOpenBillingDetails(true);
    } else {
      if (isCompleteWithNoPayment) {
        performCompleteOrderWithoutPayment(billingDetails, metadata);
      } else {
        paymentFormRef?.current?.requestSubmit();
      }
    }
  };

  return (
    <Box>
      <AppBar height={PAGE_HEADER_HEIGHT} sx={{ justifyContent: 'center' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', height: PAGE_HEADER_HEIGHT }}>
          <Box display="flex" alignItems="center" height="100%">
            <IconButton onClick={handleGoBack} disableRipple size="medium" data-testid={`${testIdPrefix}BackButton`}>
              <CloseIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
            <Divider
              orientation="vertical"
              sx={{
                mr: 2,
                height: '24px',
                borderColor: theme.palette.text.secondary,
                color: theme.palette.text.secondary,
              }}
            />
            <Typography variant={smallScreen ? 'body2' : 'h1'}>Create Sales Order</Typography>
          </Box>
          {!orderConfirmation && (
            <Box display="flex" alignItems="center" gap={1}>
              {!smallScreen && (
                <>
                  <SendFeedback testId={testIdPrefix} />
                  {/* <Button variant="outlined" disabled startIcon={<ArrowExportIcon />}>
                    Save for later
                  </Button> */}
                </>
              )}
              {PaymentProviderType.Shift4 !== paymentProviderType && (
                <Button
                  onClick={handleProcessPayment}
                  variant="contained"
                  startIcon={xsScreen ? undefined : <ArrowRightIcon />}
                  data-testid={`${testIdPrefix}ProcessPayment`}
                >
                  Process Payment
                </Button>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {orderConfirmation ? (
        <Box width="80%" margin="24px auto">
          <SalesOrderComplete
            confirmation={orderConfirmation}
            onNewSaleCallback={handleNewSale}
            onNewSaleForCustomerCallback={handleNewSaleForCustomer}
            testId="PurchaseComplete"
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexFlow: { xs: 'column', lg: 'row' },
            width: '100%',
            top: PAGE_HEADER_HEIGHT,
          }}
        >
          <Box sx={{ flexBasis: { xs: '100%', lg: '50%' } }}>
            <Box
              sx={{
                maxWidth: { xs: '100%', lg: '508px', xl: '608px' },
                mx: { xs: 3, sm: 5, lg: 'auto' },
                my: { xs: 3, lg: 5.5 },
              }}
            >
              <CreateSalesOrder
                paymentFormRef={paymentFormRef}
                openBillingDetails={openBillingDetails}
                requestBillingDetailsValidation={forceBillingValidation}
                openBillingDetailsDialog={() => setOpenBillingDetails(true)}
                closeBillingDetailsDialog={handleCloseBillingDetailsDialog}
              />
            </Box>
          </Box>
          <Box
            sx={{
              flexBasis: { xs: '100%', lg: '50%' },
              width: '100%',
              backgroundColor: '#F6F8FA',
              minHeight: '100vh',
              position: 'sticky',
              alignSelf: 'flex-start',
              top: 0,
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: '100%', lg: '508px', xl: '608px' },
                mx: { xs: 3, sm: 5, lg: 'auto' },
                my: { xs: 3, lg: 5.5 },
              }}
            >
              <SalesOrderPreview />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
