import { StoreRequestBase as FileStoreRequest } from '@one/api-models/lib/Admin/Files/StoreRequestBase';
import { StoreResponseBase as FileStoreResponse } from '@one/api-models/lib/Admin/Files/StoreResponseBase';
import { LoadCriteria as MediaLoadCriteria } from '@one/api-models/lib/Admin/Media/LoadCriteria';
import { LoadResponse as MediaLoadResponse } from '@one/api-models/lib/Admin/Media/LoadResponse';
import { StoreRequestBase as MediaStoreRequest } from '@one/api-models/lib/Admin/Media/StoreRequestBase';
import { StoreResponse as MediaStoreResponse } from '@one/api-models/lib/Admin/Media/StoreResponse';
import { EditRequest } from '@one/api-models/lib/Admin/Products/EditRequest';
import { EditResponse } from '@one/api-models/lib/Admin/Products/EditResponse';
import { LoadCriteria } from '@one/api-models/lib/Admin/Products/LoadCriteria';
import { LoadResponse } from '@one/api-models/lib/Admin/Products/LoadResponse';

import { IApiClient } from 'apiAccess/api-client';
import { AssignEditRequest } from 'models/productTags/AssignEditRequest';
import { EditRequest as TagsEditRequest } from 'models/productTags/EditRequest';
import { EditResponse as TagsEditResponse } from 'models/productTags/EditResponse';
import { LoadCriteria as TagsLoadCriteria } from 'models/productTags/LoadCriteria';
import { LoadInitialCriteria as TagsLoadInitialCriteria } from 'models/productTags/LoadInitialCriteria';
import { LoadResponse as TagsLoadResponse } from 'models/productTags/LoadResponse';
import { LoadSubtreeCriteria as TagsLoadSubtreeCriteria } from 'models/productTags/LoadSubtreeCriteria';
import { RemoveEditRequest } from 'models/productTags/RemoveEditRequest';
import { Tag } from 'models/productTags/Tag';

import { assignedTags, filterTreeTags, subtree, taggingTagTree } from './productTagMock';

export interface IProductsAccess {
  load(criteria: LoadCriteria): Promise<LoadResponse>;
  edit(request: EditRequest): PromiseLike<EditResponse>;

  tagsLoad(criteria: TagsLoadCriteria): Promise<TagsLoadResponse>;
  tagsEdit(request: TagsEditRequest): PromiseLike<TagsEditResponse>;

  mediaLoad(criteria: MediaLoadCriteria): Promise<MediaLoadResponse>;
  mediaStore(request: MediaStoreRequest): Promise<MediaStoreResponse>;
  filesStore(request: FileStoreRequest): Promise<FileStoreResponse>;
}

export class ProductsAccess implements IProductsAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  load(criteria: LoadCriteria): Promise<LoadResponse> {
    return this.apiClient.call('admin/products/load', {
      ...criteria,
      cultureCode: 'en-US',
    });
  }

  edit(request: EditRequest): PromiseLike<EditResponse> {
    return this.apiClient.call('admin/products/edit', {
      ...request,
      cultureCode: 'en-US',
    });
  }

  tagsLoad(criteria: TagsLoadCriteria): Promise<TagsLoadResponse> {
    //return this.apiClient.call('products/tags/load', criteria);

    if (criteria.$type === TagsLoadInitialCriteria.$Type) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            assignedTags: assignedTags,
            tags: subtree(),
          } as TagsLoadResponse);
        }, 1500);
      });
    }

    if (criteria.$type === TagsLoadSubtreeCriteria.$Type) {
      const subtreeCriteria = criteria as TagsLoadSubtreeCriteria;

      let responseTags: Tag[] = [];

      if (subtreeCriteria.tagKey) {
        responseTags = subtree(subtreeCriteria.tagKey);
      }

      if (subtreeCriteria.searchTerm) {
        //temp
        subtree('24-#-24o02dVVfEGlkyIiaVsiRw');

        const term = subtreeCriteria.searchTerm.toLocaleLowerCase();
        responseTags = filterTreeTags(term, taggingTagTree) || [];
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            tags: responseTags,
          } as TagsLoadResponse);
        }, 1500);
      });
    }

    throw new Error('Type not found.');
  }

  tagsEdit(request: TagsEditRequest): PromiseLike<TagsEditResponse> {
    if (request.$type === AssignEditRequest.$Type) {
    }
    if (request.$type === RemoveEditRequest.$Type) {
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({} as TagsEditResponse);
      }, 1500);
    });
    //return this.apiClient.call('admin/products/tags/edit', request);
  }

  mediaLoad(criteria: MediaLoadCriteria): Promise<MediaLoadResponse> {
    return this.apiClient.call('admin/products/media/load', criteria);
  }

  mediaStore(request: MediaStoreRequest): Promise<MediaStoreResponse> {
    return this.apiClient.call('admin/products/media/store', request);
  }

  filesStore(request: FileStoreRequest): Promise<FileStoreResponse> {
    return this.apiClient.call('admin/products/files/store', request);
  }
}
