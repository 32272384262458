import { Box, Typography } from '@mui/material';

import { SectionTitle } from 'components/_common/SectionTitle';

export const PaymentsSection = () => {
  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Payments" />
      <Typography variant="body1" sx={{ mb: 1 }}>
        Coming soon
      </Typography>
    </Box>
  );
};
