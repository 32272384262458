import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';

import { ReactComponent as CarIcon } from 'assets/icons/cars.svg';
import { ReactComponent as CruisesIcon } from 'assets/icons/cruises.svg';
import { ReactComponent as DealsIcon } from 'assets/icons/deals.svg';
import { ReactComponent as ResortsIcon } from 'assets/icons/resorts.svg';
import { ReactComponent as ShopIcon } from 'assets/icons/shop-1.svg';
import { ReactComponent as StaysIcon } from 'assets/icons/stays.svg';
import { ReactComponent as VacationsIcon } from 'assets/icons/vacations.svg';
import { ReactComponent as VillasIcon } from 'assets/icons/villas.svg';
import { ReactComponent as WineIcon } from 'assets/icons/wine.svg';
import { CertificateProductTypes } from 'models/customers/CertificateProductTypes';

interface CertificateTypeIconProp {
  type: string;
  iconFill?: string;
}

export const CertificateTypeIcon: React.FC<CertificateTypeIconProp> = ({
  type,
  iconFill = '#000000',
}: CertificateTypeIconProp) => {
  switch (type) {
    case CertificateProductTypes.Cruise:
    case CertificateProductTypes['Cruise.Ocean']:
      return <CruisesIcon fill={iconFill} />;
    case CertificateProductTypes['Cruise.River']:
      return <DirectionsBoatIcon sx={{ ml: '-3px', mb: '-1px', fontSize: '22px', fill: iconFill }} />;
    case CertificateProductTypes.Hotel:
      return <StaysIcon fill={iconFill} />;
    case CertificateProductTypes.Wine:
      return <WineIcon fill={iconFill} />;
    case CertificateProductTypes.Resort:
      return <ResortsIcon fill={iconFill} />;
    case CertificateProductTypes.Merchandise:
      return <ShopIcon fill={iconFill} />;
    case CertificateProductTypes.Villa:
      return <VillasIcon fill={iconFill} />;
    case CertificateProductTypes.Deal:
      return <ShopIcon fill={iconFill} />;
    case CertificateProductTypes.Discount:
      return <DealsIcon fill={iconFill} />;
    case CertificateProductTypes.Vacation:
      return <VacationsIcon fill={iconFill} />;
    case CertificateProductTypes.Car:
      return <CarIcon fill={iconFill} />;
    default:
      return <DealsIcon fill={iconFill} />;
  }
};
