import { DashboardResponse } from '@one/api-models/lib/Agency/Dashboard/AgencyAdvisor/DashboardResponse';
import { LoadCriteria } from '@one/api-models/lib/Agency/Team/LoadCriteria';
import { LoadResponse } from '@one/api-models/lib/Agency/Team/LoadResponse';
import { LoadCriteria as TransferLoadCriteria } from '@one/api-models/lib/Agency/Transfer/LoadCriteria';
import { LoadResponse as TransferLoadResponse } from '@one/api-models/lib/Agency/Transfer/LoadResponse';
import { TransferCriteria } from '@one/api-models/lib/Agency/Transfer/TransferCriteria';
import { TransferResponse } from '@one/api-models/lib/Agency/Transfer/TransferResponse';

import { IApiClient } from 'apiAccess/api-client';

export interface IAgentAccess {
  dashboard(): Promise<DashboardResponse>;
  loadTeam(request: LoadCriteria): Promise<LoadResponse>;
  loadTransferAssets(request: TransferLoadCriteria): Promise<TransferLoadResponse>;
  transferAssets(request: TransferCriteria): Promise<TransferResponse>;
}

export class AgentAccess implements IAgentAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  dashboard(): Promise<DashboardResponse> {
    return this.apiClient.call('agents/dashboard/load', {
      cultureCode: 'en-US',
    });
  }

  loadTeam(request: LoadCriteria): Promise<LoadResponse> {
    return this.apiClient.call('agents/team/load', {
      ...request,
      cultureCode: 'en-US',
    });
  }

  loadTransferAssets(request: TransferLoadCriteria): Promise<TransferLoadResponse> {
    return this.apiClient.call('agents/transfer/load', {
      ...request,
      cultureCode: 'en-US',
    });
  }

  transferAssets(request: TransferCriteria): Promise<TransferResponse> {
    return this.apiClient.call('agents/transfer', {
      ...request,
      cultureCode: 'en-US',
    });
  }
}
