import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { MemberResponse as Customer } from '@one/api-models/lib/Admin/Members/Response/MemberResponse';

import { AddCustomerDialog } from 'common';
import { PageHeader } from 'components/_common/PageHeader';
import { useCustomerSearch } from 'components/hooks/useCustomerSearch';
import { selectSearchCriteria, setSelectedCustomer } from 'slices/customersDataSlice';

import { LoadingScreen } from '../../_common/LoadingScreen';

import { AdditionalCustomerDetails } from './components/AdditionalCustomerDetails';
import { CustomerList } from './components/CustomerList';
import { CustomerSearchForm } from './components/SearchForm';

export const CustomersListView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoading,
    searchCriteriaChangedHandler,
    clearSearch,
    createCustomer,
    fullCustomersList,
    customerList,
    allowBrandSpecific,
    hasResults,
    showAdditionalCustomerDetails,
    additionalDetailsRequest,
    handleCloseAddCustomer,
  } = useCustomerSearch();

  const [addCustomerOpen, setAddCustomerOpen] = useState<boolean>(false);
  const searchCriteria = useSelector(selectSearchCriteria);
  const testIdPrefix = 'Customers';

  const handleCustomerSelection = (customer: Customer) => {
    //[Princess Fast Track] - to be removed after proper implementation with Workflow
    if (allowBrandSpecific && !customer.memberKey) {
      createCustomer(customer);
    } else {
      //Normal flow; This needs to stay after the "Princess Fast Track" is removed
      dispatch(setSelectedCustomer(customer));
      navigate(`/customers/${customer.memberKey}`, { state: { refetch: true } });
    }
  };

  return (
    <Box>
      <LoadingScreen open={isLoading} message={'Loading...'} />
      <PageHeader
        title="Customers"
        testId={testIdPrefix}
        titleAside={
          <Button
            type="submit"
            variant="contained"
            size="medium"
            disableRipple
            startIcon={<AddIcon />}
            onClick={() => setAddCustomerOpen(true)}
            data-testid={`${testIdPrefix}NewCustomerButton`}
          >
            New Customer
          </Button>
        }
      />
      <Paper sx={{ p: 3 }}>
        <CustomerSearchForm
          allowBrandSpecific={allowBrandSpecific}
          onSearchCriteriaChange={searchCriteriaChangedHandler}
          onFormClear={clearSearch}
          defaultSearchCriteria={searchCriteria}
          testId={testIdPrefix}
        />
        {!hasResults ? (
          <Box sx={{ p: 0, mt: 5 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <Typography variant="body2" sx={{ ml: 2, mb: 1 }}>
                  No customer matched your search.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <CustomerList
            items={allowBrandSpecific ? fullCustomersList : customerList}
            onItemSelect={(data: Customer) => handleCustomerSelection(data)}
            testId={testIdPrefix}
          />
        )}
      </Paper>
      {additionalDetailsRequest && (
        <AdditionalCustomerDetails
          isOpen={showAdditionalCustomerDetails}
          customer={additionalDetailsRequest}
          handleClose={handleCloseAddCustomer}
          testId={testIdPrefix}
        />
      )}
      <AddCustomerDialog open={addCustomerOpen} handleClose={() => setAddCustomerOpen(false)} />
    </Box>
  );
};
