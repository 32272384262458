import { Box } from '@mui/material';
import { ComponentType } from '@one/api-models/lib/Admin/Media/ComponentType';
import { ProductContentBase } from '@one/api-models/lib/Admin/Products/ProductContentBase';

import { MediaManager } from 'components/_common/MediaManager';

type Props = {
  product: ProductContentBase;
  isLoading: boolean;
  testId: string;
};

export const MediaTab = ({ product, testId }: Props) => {
  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <MediaManager
        entityId={product.id}
        partitionKey={product.partitionKey}
        componentType={ComponentType.Product}
        testId={testId}
      />
    </Box>
  );
};
