import { useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useIsMutating, useMutation } from 'react-query';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Button, Dialog, Grid, IconButton, MenuItem, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AlternativeCurrency } from '@one/api-models/lib/Agency/AlternativeCurrency';
import { MemberAccountSummary } from '@one/api-models/lib/Agency/MemberAccountSummary';
import { LoadCriteria } from '@one/api-models/lib/Agency/Transfer/LoadCriteria';
import { LoadResponse } from '@one/api-models/lib/Agency/Transfer/LoadResponse';
import { TransferCriteria } from '@one/api-models/lib/Agency/Transfer/TransferCriteria';
import { TransferResponse } from '@one/api-models/lib/Agency/Transfer/TransferResponse';

import { ApiError } from 'apiAccess/api-client';
import ControlledSelect from 'components/_common/ControlledSelect';
import ControlledTextField from 'components/_common/ControlledTextField';
import { Loading } from 'components/_common/Loading';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';

interface AssetsTransferProps {
  open: boolean;
  recipientAgentKey?: string;
  testId: string;
  handleOnClose: () => void;
}

interface TransferForm {
  transferValue: number | undefined;
  alternativeCurrency: string | undefined;
}

export const AssetsTransfer = ({ open, recipientAgentKey, testId, handleOnClose }: AssetsTransferProps) => {
  const { api } = useApiHelpers();
  const { formatNumber } = useFormat();
  const { apiErrorHandler, showMessage } = useToastMessage();
  const [sender, setSender] = useState<MemberAccountSummary | undefined>(undefined);
  const [recipient, setRecipient] = useState<MemberAccountSummary | undefined>(undefined);
  const [calculatedSenderAssets, setCalculatedSenderAssets] = useState<number>(0);
  const [calculatedRecipientAssets, setCalculatedRecipientAssets] = useState<number>(0);
  const [initialSenderAssets, setInitialSenderAssets] = useState<number>(0);
  const [initialRecipientAssets, setInitialRecipientAssets] = useState<number>(0);

  const [selectedAlternativeCurrency, setSelectedAlternativeCurrency] = useState<AlternativeCurrency | undefined>(
    undefined,
  );
  const isLoading = useIsMutating({ mutationKey: 'loadTransferDetails' }) > 0;
  const isTransferLoading = useIsMutating({ mutationKey: 'transferAssets' }) > 0;

  useEffect(() => {
    loadMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientAgentKey]);

  useEffect(() => {
    const senderSelectedAltCurrency = sender?.alternativeCurrencies?.find(
      (item) => item.code === selectedAlternativeCurrency?.code,
    );
    const recipientSelectedAltCurrency = recipient?.alternativeCurrencies?.find(
      (item) => item.code === selectedAlternativeCurrency?.code,
    );

    setInitialSenderAssets(senderSelectedAltCurrency?.amount || 0);
    setInitialRecipientAssets(recipientSelectedAltCurrency?.amount || 0);

    setCalculatedSenderAssets(senderSelectedAltCurrency?.amount || 0);
    setCalculatedRecipientAssets(recipientSelectedAltCurrency?.amount || 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAlternativeCurrency]);

  const defaultValues: TransferForm = {
    transferValue: 0,
    alternativeCurrency: sender?.alternativeCurrencies[0]?.code || '',
  };

  const validationSchema: yup.SchemaOf<TransferForm> = yup.object().shape({
    transferValue: yup
      .number()
      .transform((_, val) => (val !== '' ? Number(val) : undefined))
      .required('Transfer value is required')
      .min(1, 'Minimum transfer value is 1')
      .max(initialSenderAssets, `Maximum transfer value is ${initialSenderAssets}`),
    alternativeCurrency: yup
      .string()
      .trim()
      .test({
        name: 'alternativeCurrencyRequired',
        test: function (value: string | undefined | null) {
          if (!value && sender?.alternativeCurrencies && sender?.alternativeCurrencies?.length > 1) {
            return this.createError({
              path: 'alternativeCurrency',
              message: 'Asset type is required',
            });
          }
          return true;
        },
      }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<TransferForm>({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });

  const handleTransferSubmit = (data: TransferForm) => {
    transferMutation.mutateAsync({
      $Type: TransferCriteria.$type,
      recipientAgentKey: recipientAgentKey || '',
      altCurrencyCode: sender?.alternativeCurrencies[0].code || '',
      amount: data.transferValue || 0,
    });
  };

  const loadMutation = useMutation<LoadResponse, ApiError>(
    () =>
      api.agent.loadTransferAssets({
        $Type: LoadCriteria.$type,
        recipientAgentKey: recipientAgentKey,
      } as LoadCriteria),
    {
      mutationKey: 'loadTransferDetails',
      onSuccess: (value: LoadResponse) => {
        setSender(value.sender);
        setRecipient(value.recipient);
        setCalculatedSenderAssets(value.sender.alternativeCurrencies[0]?.amount);
        setCalculatedRecipientAssets(value.recipient.alternativeCurrencies[0]?.amount);
        setSelectedAlternativeCurrency(value.sender.alternativeCurrencies[0]);
      },
      onError: apiErrorHandler,
    },
  );

  const transferMutation = useMutation<TransferResponse, ApiError, TransferCriteria, unknown>(
    (request: TransferCriteria) => api.agent.transferAssets(request),
    {
      mutationKey: 'transferAssets',
      onSuccess: () => {
        showMessage('Transfer completed successfully.', 'success');
        handleOnClose();
      },
      onError: apiErrorHandler,
    },
  );

  const handleTransAssetChange = (value: string | undefined) => {
    if (!!value) {
      const transferValue = parseInt(value);
      setCalculatedSenderAssets(initialSenderAssets - transferValue);
      setCalculatedRecipientAssets(initialRecipientAssets + transferValue);
    } else {
      setCalculatedSenderAssets(initialSenderAssets);
      setCalculatedRecipientAssets(initialRecipientAssets);
    }
  };

  const handleSelectedAlternativeCurrency = (value: string | undefined) => {
    setSelectedAlternativeCurrency(sender?.alternativeCurrencies?.find((item) => item.code === value));
  };

  return (
    <Dialog fullScreen open={open} onClose={handleOnClose}>
      <AppBar position="relative" color="inherit">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleOnClose}
            aria-label="close"
            data-testid={`${testId}CloseButton`}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h1">
            Transfer Assets
          </Typography>
        </Toolbar>
      </AppBar>
      {isLoading || isTransferLoading ? <Loading /> : null}
      <form onSubmit={handleSubmit(handleTransferSubmit)} style={{ height: '100%' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid
            item
            container
            xs={12}
            lg={6}
            rowSpacing={1}
            columnSpacing={3}
            px={6}
            py={3}
            sx={{ display: 'flex', flexDirection: 'row', height: 'fit-content' }}
          >
            <Grid item xs={12} lg={6}>
              <SectionTitle title="From:" />
              <Typography variant="subtitle1" pt={1}>
                {sender?.name}
              </Typography>
              <Typography variant="body1">{sender?.email}</Typography>
              <ControlledSelect
                control={control as unknown as Control<FieldValues, object>}
                name="alternativeCurrency"
                fullWidth
                size="small"
                sx={{
                  maxWidth: '300px',
                  mt: 3,
                  display:
                    sender?.alternativeCurrencies && sender?.alternativeCurrencies?.length > 1 ? 'block' : 'none',
                }}
                onChange={handleSelectedAlternativeCurrency}
                error={errors?.alternativeCurrency?.message != null}
                helperText={errors?.alternativeCurrency?.message}
                testId={`${testId}AlternativeCurrency`}
              >
                {sender?.alternativeCurrencies.map((altCurrency: any) => (
                  <MenuItem key={altCurrency.code} value={altCurrency.code}>
                    {altCurrency.name}
                  </MenuItem>
                ))}
              </ControlledSelect>

              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                size="small"
                name="transferValue"
                type="number"
                fullWidth
                sx={{ maxWidth: '300px', mt: 3 }}
                inputProps={{
                  step: 10,
                }}
                label="Transfer Value"
                error={errors?.transferValue?.message != null}
                helperText={errors?.transferValue?.message}
                onChange={handleTransAssetChange}
                testId={`${testId}TransferValue`}
              />
              <Box display="flex" mt={1} ml={1}>
                <Typography variant="caption">
                  up to {formatNumber(initialSenderAssets)} {selectedAlternativeCurrency?.name || 'n/a'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ mt: { xs: 3, lg: 0 } }}>
              <SectionTitle title="To:" />
              <Typography variant="subtitle1" pt={1}>
                {recipient?.name}
              </Typography>
              <Typography variant="body1">{recipient?.email}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} lg={6} sx={{ backgroundColor: '#F6F8FA' }}>
            <Grid
              item
              container
              xs={12}
              rowSpacing={1}
              columnSpacing={3}
              sx={{ display: 'flex', flexDirection: 'row', height: 'fit-content' }}
              px={6}
              py={3}
            >
              <Grid item xs={12}>
                <SectionTitle title="Summary:" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">From:</Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle1">{sender?.name}</Typography>
                <Typography variant="body1">{sender?.email}</Typography>
              </Grid>
              <Grid item xs={12} lg={6} display="flex" justifyContent="flex-end">
                <Typography variant="body1" color="#6A7383">{`Available Balance: ${
                  formatNumber(calculatedSenderAssets) || 0
                } ${selectedAlternativeCurrency?.name || 'n/a'}`}</Typography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mt: { xs: 3 }, mb: { xs: 0, lg: 3 } }}>
                <Typography variant="subtitle2">Transfer Amount</Typography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ mt: { xs: 0, lg: 3 }, mb: 3 }} display="flex" justifyContent="flex-end">
                <Typography variant="body1" color="#6A7383">{`${formatNumber(getValues().transferValue) || 0} ${
                  selectedAlternativeCurrency?.name || 'n/a'
                }`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">To:</Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle1">{recipient?.name}</Typography>
                <Typography variant="body1">{recipient?.email}</Typography>
              </Grid>
              <Grid item xs={12} lg={6} display="flex" justifyContent="flex-end">
                <Typography variant="body1" color="#6A7383">{`New Balance: ${
                  formatNumber(calculatedRecipientAssets) || 0
                } ${selectedAlternativeCurrency?.name || 'n/a'}`}</Typography>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end" my={3}>
                <Button variant="contained" type="submit" data-testid={`${testId}MakeTransferButton`}>
                  Make Transfer
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
