import { forwardRef, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Autocomplete, TextField } from '@mui/material';
import { AutocompleteChangeReason, createFilterOptions } from '@mui/material/Autocomplete';

export interface ControlledAutocompleteOption {
  code: string;
  label: string;
}

interface ControlledAutocompleteProps {
  name: string;
  testId: string;
  options?: ControlledAutocompleteOption[];
  defaultValue?: ControlledAutocompleteOption;
  control: Control;
  error?: boolean;
  helperText?: ReactNode;
  label?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  isAutoFocused?: boolean;
  autoSelect?: boolean;
  disableClearable?: boolean;
  requiredMessage?: string;
  onChange?: (code?: string) => void;
  renderOption?: (props: any, option: any) => ReactNode;
  popperWidth?: string;
  disableDefaultTextFieldChange?: boolean;
  getOptionDisabled?: (option: any) => boolean;
}

const ControlledAutocomplete = forwardRef(
  (
    {
      name,
      testId,
      label,
      placeholder,
      options,
      control,
      error,
      helperText,
      disabled = false,
      requiredMessage,
      onChange,
      variant = 'outlined',
      size = 'small',
      isAutoFocused = false,
      disableClearable = false,
      autoSelect = true,
      renderOption,
      getOptionDisabled,
      popperWidth,
      disableDefaultTextFieldChange,
      defaultValue,
    }: ControlledAutocompleteProps,
    ref,
  ) => {
    const filterOptions = createFilterOptions<ControlledAutocompleteOption>({
      matchFrom: 'any',
    });
    return (
      <Controller
        control={control}
        name={name}
        rules={requiredMessage ? { required: { value: true, message: requiredMessage } } : undefined}
        render={({ field }) => (
          <Autocomplete
            ref={ref}
            autoSelect={autoSelect}
            fullWidth
            disableClearable={disableClearable}
            options={options || []}
            value={options?.find((c) => c.code === field.value) || null}
            defaultValue={defaultValue}
            getOptionLabel={(v: ControlledAutocompleteOption) => v.label}
            isOptionEqualToValue={(option: ControlledAutocompleteOption, value: ControlledAutocompleteOption) =>
              option.code === value?.code
            }
            onChange={(_, value: ControlledAutocompleteOption | null, reason: AutocompleteChangeReason) => {
              field.onChange(value?.code);
              if (onChange) onChange(value?.code);
              switch (reason) {
                case 'clear':
                  field.onChange('');
                  break;
                default:
                  break;
              }
            }}
            filterOptions={filterOptions}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                {...field}
                autoFocus={isAutoFocused}
                label={label}
                placeholder={placeholder}
                error={error}
                helperText={helperText}
                disabled={disabled}
                variant={variant}
                size={size}
                autoComplete="off"
                onChange={(e) => {
                  if (disableDefaultTextFieldChange) {
                    e.preventDefault();
                  }
                }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': `${testId}Input`,
                }}
              />
            )}
            componentsProps={{
              popper: {
                sx: {
                  minWidth: renderOption && popperWidth,
                },
              },
            }}
            renderOption={renderOption}
            getOptionDisabled={getOptionDisabled}
          />
        )}
      />
    );
  },
);

export default ControlledAutocomplete;
