import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

import { formatFileSize } from 'utils/formattingUtilities';

import { FileDetails } from './FileDetails';

export const QueueListingItem = ({ file }: { file: FileDetails }) => {
  let action;

  return (
    <ListItem>
      <ListItemIcon>
        <InsertDriveFileOutlinedIcon />
      </ListItemIcon>
      <ListItemText
        key={file.name}
        primary={
          <Grid container direction="row" justifyContent="space-between" alignItems="center" component="span">
            <Grid item component="span">
              {file.name}
            </Grid>
            <Grid item component="span">
              <Typography variant="caption">{formatFileSize(file.size)}</Typography>
            </Grid>
          </Grid>
        }
      ></ListItemText>
      {action}
    </ListItem>
  );
};
