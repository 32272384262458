import { useState } from 'react';

import { Box, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';
import { MetadataKey } from '@one/api-models/lib/Metadata/MetadataKey';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';
import { Typography } from 'styled';
import { ActionIconButton } from 'styled/IconButton';

import { EditMetadataDialog } from './EditMetadataDialog';

interface SalesOrderMetadataProps {
  metadata: Metadata[];
  metadataKeys: MetadataKey[];
  handleSave: (metadata: Metadata[]) => void;
  entityId?: string;
}
export const SalesOrderMetadata = ({ metadata, metadataKeys, entityId, handleSave }: SalesOrderMetadataProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'metadataKey',
      headerName: 'Key',
      flex: 1,
    },
    {
      field: 'metadataValue',
      headerName: 'Value',
      flex: 1,
    },
  ];

  return (
    <Box sx={{ mt: 7 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Metadata</Typography>
        <ActionIconButton onClick={() => setOpen(true)} data-testid="EditSalesOrderMetadataButton">
          <EditIcon />
        </ActionIconButton>
      </Box>
      {metadata.length > 0 ? (
        <Box
          sx={{
            mt: 2.5,
          }}
        >
          <DataGrid
            rows={metadata.map((item: Metadata) => ({ ...item, id: item.metadataKey }))}
            columns={columns}
            rowHeight={40}
            hideFooter={true}
          />
        </Box>
      ) : (
        <Box
          onClick={() => !entityId && setOpen(true)}
          sx={{
            mt: 2.5,
            height: 150,
            borderRadius: 8,
            border: `1px dashed ${theme.customPalette.input.border}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body1" color="#D5DBE1">
            No metadata
          </Typography>
        </Box>
      )}
      {open && (
        <EditMetadataDialog
          open={open}
          handleClose={() => setOpen(false)}
          handleSave={handleSave}
          testId="salesOrderMetadata"
          metadata={metadata}
          metadataKeys={metadataKeys}
          entityId={entityId}
        />
      )}
    </Box>
  );
};
