import { useRef, useState } from 'react';

import { Clear } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SearchIcon from '@mui/icons-material/Search';
import TreeView from '@mui/lab/TreeView';
import { Box, CircularProgress, IconButton, InputAdornment, LinearProgress, TextField } from '@mui/material';

import { ProductTag } from 'models/productTags/ProductTag';
import { Tag } from 'models/productTags/Tag';

import { StyledTreeItem } from './StyledTreeItem';

type Props = {
  tags: Tag[];
  deniedTagKeys: string[];
  onSearch: (term: string) => void;
  loading: boolean;
  testId: string;
  onSelect: (tag?: Tag, productTag?: ProductTag) => void;
};

let productTags = new Map<string, ProductTag>();

export function TaggingTree({ tags, deniedTagKeys, onSearch, loading, testId, onSelect }: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const textInput = useRef(null);

  const getItems = (node: Tag, path: string[], deniedTagKeys: string[]) => {
    if (node.descendants != null && Array.isArray(node.descendants) && node.descendants.length > 0) {
      const sorted = [...node.descendants].sort();
      return sorted.map((node) => renderTree(node, path, deniedTagKeys));
    }

    if (node.hasDescendants)
      return (
        <Box pt={2} pb={2}>
          <LinearProgress />
        </Box>
      );

    return undefined;
  };

  const renderTree = (node: Tag, path: string[], deniedTagKeys: string[]) => {
    const children = getItems(node, [...path, node.name], deniedTagKeys);
    const isDenied = deniedTagKeys.indexOf(node.key) > -1;

    let color = 'black';
    let branchIcon = null;
    let leafIcon = <LocalOfferIcon sx={{ color: 'gray' }} />;

    if (node.allowAssign) {
      //already assigned
      if (isDenied) {
        color = 'gray';
        branchIcon = <LocalOfferIcon sx={{ color: 'lightgray', fontSize: '12px', mr: 1 }} />;
        leafIcon = <LocalOfferIcon sx={{ color: 'lightgray' }} />;
      } else {
        branchIcon = <LocalOfferIcon sx={{ color: 'gray', fontSize: '12px', mr: 1 }} />;
      }
    }

    const productTag = { tagKey: node.key, name: node.name, path: path } as ProductTag;

    productTags.set(node.key, productTag);
    const isDraggable = !isDenied && node.allowAssign;

    return (
      <StyledTreeItem
        key={node.key}
        nodeId={node.key}
        label={
          <Box sx={{ color: color }}>
            {children && branchIcon}
            {node.name}
          </Box>
        }
        endIcon={leafIcon}
        isDraggable={isDraggable}
        itemData={productTag}
      >
        {children}
      </StyledTreeItem>
    );
  };

  productTags = new Map<string, ProductTag>();
  const tagItems = tags.map((subtreeTags: Tag) => renderTree(subtreeTags, [], deniedTagKeys));

  return (
    <>
      <TextField
        fullWidth
        label="Search"
        sx={{ mb: 2, mt: 3 }}
        onChange={(p) => {
          setSearchTerm(p.target.value);
        }}
        onKeyDown={(k) => {
          if (k.key === 'Enter') onSearch(searchTerm);
        }}
        size="small"
        inputRef={textInput}
        inputProps={{
          'data-testid': `${testId}SearchInput`,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={() => {
                  if (textInput !== null && textInput.current !== null) (textInput.current as any).value = '';
                  setSearchTerm('');
                  onSearch('');
                }}
                disabled={loading || searchTerm.length === 0}
                data-testid={`${testId}ClearButton`}
              >
                <Clear />
              </IconButton>

              {(!loading || (loading && !searchTerm)) && (
                <IconButton
                  onClick={() => onSearch(searchTerm)}
                  disabled={loading || (searchTerm.length < 3 && searchTerm.length > 0)}
                  data-testid={`${testId}SearchButton`}
                >
                  <SearchIcon />
                </IconButton>
              )}
              {loading && searchTerm && (
                <IconButton disabled={true} data-testid={`${testId}LoadingButton`}>
                  <CircularProgress size={24} data-testid="LoadingSpinner" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={<LocalOfferIcon sx={{ color: 'gray' }} />}
        onNodeSelect={(e: any, n: string) => {
          onSelect(getTag({ descendants: tags } as Tag, n), productTags.get(n));
        }}
        sx={{ maxHeight: 680, overflow: 'auto' }}
      >
        {tagItems}
      </TreeView>
    </>
  );
}

const getTag = (startTag: Tag, tagKey: string): Tag | undefined => {
  if (startTag.key === tagKey) {
    return startTag;
  }

  if (Array.isArray(startTag.descendants) && startTag.descendants.length > 0) {
    let found: Tag | undefined = undefined;
    for (let i = 0; i < startTag.descendants.length && !found; i += 1) {
      found = getTag(startTag.descendants[i], tagKey);
    }
    return found;
  }
  return undefined;
};
