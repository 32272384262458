import { AccessControlPermissions } from 'apiAccess/accessControlAccess';
import { useAppHelpers } from 'components/hooks/useAppHelpers';
import { Layout } from 'components/layout/Layout';
import { LayoutConfig } from 'components/layout/LayoutConfig';

import { AppNoPermissions } from './auth/AppNoPermissions';

interface Props {
  routePermissions?: AccessControlPermissions[];
  children: any;
  layout?: LayoutConfig;
}

const GuardedRoute: React.FC<Props> = ({ routePermissions, layout, children }: Props) => {
  const { hasPermission } = useAppHelpers();
  const hasRoutePermission = (permissions: AccessControlPermissions[]) => {
    return permissions !== undefined && hasPermission(permissions);
  };

  if (routePermissions != null && !hasRoutePermission(routePermissions)) {
    return <AppNoPermissions />;
  }
  return <Layout config={layout}>{children}</Layout>;
};

export default GuardedRoute;
