import { AppBarProps as MuiAppBarProps, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CustomWidth } from 'models/CustomWidth';

interface AppBarProps extends MuiAppBarProps {
  marginRight?: CustomWidth;
  height?: string;
}

export const AppBar = styled(Box, { shouldForwardProp: (prop) => prop !== 'height' })<AppBarProps>(
  ({ theme, height }) => ({
    height: height ?? '45px',
    position: 'sticky',
    top: 0,
    zIndex: 999,
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.divider,
    boxShadow: 'none',
    width: '100%',
    margin: 0,
    marginRight: '45px',
  }),
);
