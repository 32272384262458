export const groupBy = <T>(list: T[], key: string): { [key: string]: T[] } =>
  list.reduce((hash: { [key: string]: T[] }, obj: T) => {
    type ObjectKey = keyof typeof obj;
    const oKey = key as ObjectKey;
    const groupedKey = ((obj[oKey] as any) || 'undefined').toString();
    return {
      ...hash,
      [groupedKey]: (hash[groupedKey] || []).concat([obj]),
    };
  }, {});
