import { AssetRedeemRequest } from '@one/api-models/lib/Admin/Members/Asset/Request/AssetRedeemRequest';
import { AssetReinstateRequest } from '@one/api-models/lib/Admin/Members/Asset/Request/AssetReinstateRequest';
import { AssetRedeemResponse } from '@one/api-models/lib/Admin/Members/Asset/Response/AssetRedeemResponse';
import { AssetReinstateResponse } from '@one/api-models/lib/Admin/Members/Asset/Response/AssetReinstateResponse';
import { EditMemberRequest } from '@one/api-models/lib/Admin/Members/Request/EditMemberRequest';
import { LoadMemberRequest } from '@one/api-models/lib/Admin/Members/Request/LoadMemberRequest';
import { OnBehalfOfMemberRequest } from '@one/api-models/lib/Admin/Members/Request/OnBehalfOfMemberRequest';
import { PredictiveSearchRequest } from '@one/api-models/lib/Admin/Members/Request/PredictiveSearchRequest';
import { ResetMemberRequest } from '@one/api-models/lib/Admin/Members/Request/ResetMemberRequest';
import { SearchMemberRequest } from '@one/api-models/lib/Admin/Members/Request/SearchMemberRequest';
import { SendActivationRequest } from '@one/api-models/lib/Admin/Members/Request/SendActivationRequest';
import { SuspendMemberRequest } from '@one/api-models/lib/Admin/Members/Request/SuspendMemberRequest';
import { EditMemberResponse } from '@one/api-models/lib/Admin/Members/Response/EditMemberResponse';
import { OnBehalfOfMemberResponse } from '@one/api-models/lib/Admin/Members/Response/OnBehalfOfMemberResponse';
import { PredictiveSearchResponse } from '@one/api-models/lib/Admin/Members/Response/PredictiveSearchResponse';
import { ResetMemberResponse } from '@one/api-models/lib/Admin/Members/Response/ResetMemberResponse';
import { SearchMemberResponse } from '@one/api-models/lib/Admin/Members/Response/SearchMemberResponse';
import { SendActivationResponse } from '@one/api-models/lib/Admin/Members/Response/SendActivationResponse';
import { SuspendMemberResponse } from '@one/api-models/lib/Admin/Members/Response/SuspendMemberResponse';
import { BulkEnrollmentIngestRequest } from '@one/api-models/lib/Feed/Files/Member/Enrollment/BulkEnrollmentIngestRequest';
import { BulkEnrollmentIngestResponse } from '@one/api-models/lib/Feed/Files/Member/Enrollment/BulkEnrollmentIngestResponse';
import { SummaryLoadResponse } from '@one/api-models/lib/Membership/Account/SummaryLoadResponse';

import { IApiClient } from 'apiAccess/api-client';

export interface IMembersAccess {
  search(request: SearchMemberRequest): Promise<SearchMemberResponse>;
  predictiveSearch(request: PredictiveSearchRequest): Promise<PredictiveSearchResponse>;
  edit(request: EditMemberRequest): Promise<EditMemberResponse>;
  load(request: LoadMemberRequest): Promise<SummaryLoadResponse>;
  reset(request: ResetMemberRequest): Promise<ResetMemberResponse>;
  redeemAsset(request: AssetRedeemRequest): Promise<AssetRedeemResponse>;
  reinstateAsset(request: AssetReinstateRequest): Promise<AssetReinstateResponse>;
  onBehalfOf(request: OnBehalfOfMemberRequest): Promise<OnBehalfOfMemberResponse>;
  sendActivation(request: SendActivationRequest): Promise<SendActivationResponse>;
  suspendMember(request: SuspendMemberRequest): Promise<SuspendMemberResponse>;
  bulkEnrollment(request: BulkEnrollmentIngestRequest): Promise<BulkEnrollmentIngestResponse>;
}

export class MembersAccess implements IMembersAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  search(request: SearchMemberRequest): Promise<SearchMemberResponse> {
    return this.apiClient.call('members/search', {
      ...request,
      $Type: SearchMemberRequest.$type,
      cultureCode: 'en-US',
      listingCriteria: { take: 100 },
    } as SearchMemberRequest);
  }

  predictiveSearch(request: PredictiveSearchRequest): Promise<PredictiveSearchResponse> {
    return this.apiClient.call('members/search-suggestions', {
      ...request,
      $Type: PredictiveSearchRequest.$type,
      cultureCode: 'en-US',
    } as PredictiveSearchRequest);
  }

  edit(request: EditMemberRequest): Promise<EditMemberResponse> {
    return this.apiClient.call('members/edit', {
      ...request,
      $type: EditMemberRequest.$type,
      cultureCode: 'en-US',
    });
  }

  load(request: LoadMemberRequest): Promise<SummaryLoadResponse> {
    return this.apiClient.call('members/load', {
      ...request,
      $type: LoadMemberRequest.$type,
      cultureCode: 'en-US',
    });
  }

  reset(request: ResetMemberRequest): Promise<ResetMemberResponse> {
    return this.apiClient.call('members/reset', {
      ...request,
      $type: ResetMemberRequest.$type,
      cultureCode: 'en-US',
    });
  }

  redeemAsset(request: AssetRedeemRequest): Promise<AssetRedeemResponse> {
    return this.apiClient.call('members/assets/redeem', {
      ...request,
      $type: AssetRedeemRequest.$type,
      cultureCode: 'en-US',
    });
  }
  reinstateAsset(request: AssetReinstateRequest): Promise<AssetReinstateResponse> {
    return this.apiClient.call('members/assets/reinstate', {
      ...request,
      $type: AssetReinstateRequest.$type,
      cultureCode: 'en-US',
    });
  }

  onBehalfOf(request: OnBehalfOfMemberRequest): Promise<OnBehalfOfMemberResponse> {
    return this.apiClient.call('members/obo', {
      ...request,
      $type: OnBehalfOfMemberRequest.$type,
      cultureCode: 'en-US',
    });
  }

  sendActivation(request: SendActivationRequest): Promise<SendActivationResponse> {
    return this.apiClient.call('members/sendactivation', {
      ...request,
      $type: SendActivationRequest.$type,
      cultureCode: 'en-US',
    });
  }

  suspendMember(request: SuspendMemberRequest): Promise<SuspendMemberResponse> {
    return this.apiClient.call('members/suspend', {
      ...request,
      $type: SuspendMemberRequest.$type,
      cultureCode: 'en-US',
    });
  }

  bulkEnrollment(request: BulkEnrollmentIngestRequest): Promise<BulkEnrollmentIngestResponse> {
    return this.apiClient.call('admin/files/member/bulk_enrollment/process', request);
  }
}
