import { Divider, List, ListItem, ListItemText, Paper, Skeleton, Typography } from '@mui/material';
import { ProgramItemSummary } from '@one/api-models/lib/Admin/ProgramSales/Common/ProgramItemSummary';
import { ProgramSummary as ProgramSummaryModel } from '@one/api-models/lib/Admin/ProgramSales/Common/ProgramSummary';

import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';

interface ProgramSummaryProps {
  data: ProgramSummaryModel;
}

export const ProgramSummary = ({ data }: ProgramSummaryProps) => {
  const { formatDate } = useFormat();
  const itemsToSort = [...data.items];

  return (
    <Paper sx={{ px: 3, pt: 3, pb: 1 }}>
      <SectionTitle title="Program Details" />
      <Typography variant="button">{data.programName}</Typography>
      <Divider sx={{ pt: 2 }} />
      <List dense>
        {itemsToSort
          .sort((a: ProgramItemSummary, b: ProgramItemSummary) => a.orderIndex - b.orderIndex)
          .map((item) => (
            <ListItem
              key={item.description}
              sx={{ pl: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <ListItemText
                sx={{ mb: 0 }}
                primary={item.description}
                primaryTypographyProps={{ variant: 'subtitle1' }}
              />
              <ListItemText
                sx={{ mt: 0 }}
                primary={item.name}
                primaryTypographyProps={{ variant: 'body1' }}
                secondary={
                  item?.activeFrom ? `Active from: ${formatDate(new Date(item.activeFrom), true, 'dd MMM yyyy')}` : null
                }
                secondaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
          ))}
      </List>
    </Paper>
  );
};

export const ProgramSummarySkeleton = () => {
  return (
    <Paper sx={{ p: 3 }}>
      <Skeleton variant="rectangular" height={35} width={200} />
      <Skeleton variant="rectangular" height={180} sx={{ mt: 2 }} />
    </Paper>
  );
};
