import React from 'react';

import { Box, useTheme } from '@mui/material';

type Props = {
  showPreview?: boolean;
  children: React.ReactNode;
};

export const SideModalContainer = ({ children, showPreview }: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: showPreview ? 'flex' : 'block',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        maxHeight: 'inherit',
        overflow: 'auto',

        [theme.breakpoints.down('md')]: {
          display: 'block',
        },
      }}
    >
      {children}
    </Box>
  );
};
