import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Divider, MenuList, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as PanelArrowRightIcon } from 'assets/icons/panelArrowRight.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { useAuthWrapperHelpers } from 'components/hooks/useAuthWrapperHelpers';
import { selectUsername } from 'slices/userDataSlice';
import { Menu, MenuItem } from 'styled';

type Props = {
  testId: string;
};

export const SettingsMenu = ({ testId }: Props) => {
  const { logout } = useAuthWrapperHelpers();
  const theme = useTheme();
  const username = useSelector(selectUsername);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={handleMenu} size="small" sx={{ mr: 1 }} data-testid={testId}>
        <SettingsIcon stroke={theme.palette.text.primary} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem data-testid={`${testId}ProfileMenuUsernameItem`}>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>{username}</Typography>
        </MenuItem>
        <MenuList>
          <Divider sx={{ width: '100%' }}></Divider>
        </MenuList>
        <MenuItem onClick={logout} data-testid={`${testId}ProfileMenuLogoutItem`}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '25px', mr: 1 }}>
            <PanelArrowRightIcon />
          </Box>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
