import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { BillingDetails } from '@one/api-models/lib/Admin/ProgramSales/Purchase/BillingDetails';
import { CompleteOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderRequest';
import { CompleteOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderResponse';
import { InitOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderRequest';
import { InitOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderResponse';
import { InstallmentPlan } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InstallmentPlan';
import { PaymentSource } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentSource';
import { PaymentToken } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentToken';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';

import { ApiError } from 'apiAccess/api-client';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { PAYMENT_AVS_MISMATCH_ERROR } from 'components/views/paymentPlans/constants/CybersourcePayment';
import { selectActiveAgency, selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import {
  selectInstallmentsPaymentPlan,
  selectSelectedCustomer,
  selectSelectedPrograms,
  setIsLoadingOrderPayment,
  setOrderConfirmation,
  setOrderKey,
  setPaymentError,
} from 'slices/salesOrderDataSlice';

export const useCybersourceProgramSale = () => {
  const dispatch = useDispatch();
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const activeAgency = useSelector(selectActiveAgency);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const installmentsPaymentPlan = useSelector(selectInstallmentsPaymentPlan);

  const initOrderMutation = useMutation<
    InitOrderResponse,
    ApiError,
    {
      billingDetails: BillingDetails;
      installmentsPlan?: InstallmentPlan;
      token: string;
      paymentMethodExpiration: Date;
      metadata?: Metadata[];
    },
    unknown
  >(
    async ({ billingDetails, installmentsPlan, token, paymentMethodExpiration, metadata }) => {
      return await api.programSales.initOrder({
        billingDetails: billingDetails,
        paymentPlan: installmentsPlan
          ? {
              ...installmentsPlan,
            }
          : undefined,
        memberKey: selectedCustomer?.memberKey,
        programId: selectedPrograms[0]?.id,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        brandId: activeBrand ? parseInt(activeBrand?.key) : undefined,
        paymentMethodToken: token,
        paymentMethodExpiration,
        agencyKey: activeAgency?.key,
        metadata: metadata,
      } as InitOrderRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          dispatch(setOrderKey(value.orderNumber));
        }
      },
      onError: (error) => {
        if (error.errors != null && error.errors.length > 0) {
          dispatch(setPaymentError(error.errors.map((e) => e.message).join('\n')));
        } else {
          dispatch(setPaymentError(error.message));
        }
        apiErrorHandler(error, undefined, undefined, PAYMENT_AVS_MISMATCH_ERROR);
      },
    },
  );

  const performCompleteOrderWithPayment = async (
    token: string,
    paymentMethodExpiration: Date,
    billingDetails: BillingDetails | undefined,
    installmentsPlan?: InstallmentPlan,
    metadata?: Metadata[],
  ) => {
    if (!billingDetails) throw new Error('Failed to get billingDetails');

    dispatch(setPaymentError(undefined));

    const order = await initOrderMutation.mutateAsync({
      billingDetails,
      installmentsPlan,
      token,
      paymentMethodExpiration,
      metadata,
    });
    performCompleteOrder(order.orderNumber, order.paymentTokens[0]);
  };

  const completeOrderMutation = useMutation<
    CompleteOrderResponse,
    ApiError,
    { orderKey: string; transactionId: number; paymentIntent: string; tokenReference?: string },
    unknown
  >(
    async ({ orderKey, transactionId, paymentIntent, tokenReference }) => {
      const payment: PaymentSource = {
        amount:
          installmentsPaymentPlan && installmentsPaymentPlan?.downPaymentAmounts[0]
            ? installmentsPaymentPlan?.downPaymentAmounts[0]
            : selectedPrograms && selectedPrograms.length > 0
            ? selectedPrograms[0].price
            : { amount: 0, currency: 'USD', isEstimated: false },
        transactionId: transactionId,
        paymentIntentReference: paymentIntent,
        paymentMethodReference: tokenReference,
        isPaymentMethodDefault: true,
      };
      return await api.programSales.completeOrder({
        memberKey: selectedCustomer?.memberKey,
        orderNumber: orderKey,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        paymentSources: [payment],
        agencyKey: activeAgency?.key,
      } as unknown as CompleteOrderRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          dispatch(setOrderConfirmation(value));
          dispatch(setOrderKey(undefined));
        }
      },
      onError: (error) => {
        if (error.errors != null && error.errors.length > 0) {
          dispatch(setPaymentError(error.errors.map((e) => e.message).join('\n')));
        } else {
          dispatch(setPaymentError(error.message));
        }
        apiErrorHandler(error);
      },
    },
  );

  const performCompleteOrder = (orderKey: string, paymentToken: PaymentToken) => {
    completeOrderMutation.mutate({
      orderKey,
      transactionId: paymentToken?.transactionId,
      paymentIntent: paymentToken?.secret,
      tokenReference: paymentToken?.paymentMethodGatewayReference,
    });
  };

  useEffect(() => {
    dispatch(setIsLoadingOrderPayment(initOrderMutation.isLoading || completeOrderMutation.isLoading));
  }, [dispatch, initOrderMutation.isLoading, completeOrderMutation.isLoading]);

  return {
    performCompleteOrderWithPayment,
  };
};
