import { Toolbar as MuiToolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    minHeight: '45px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
