import { FC, forwardRef, useImperativeHandle, useState } from 'react';

import { LineWeight, Padding } from '@mui/icons-material';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';

export const StripeInput: FC<InputBaseComponentProps> = forwardRef((props, ref) => {
  const {
    component: Component,
    'aria-invalid': ariaInvalid,
    'aria-describedby': ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    options,
    ...other
  } = props;
  const theme = useTheme();
  const [mountNode, setMountNode] = useState<typeof Component | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode],
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.025em',
            fontFamily: 'monospace',
            '::placeholder': {
              color: alpha(theme.palette.text.primary, 0.42),
            },
          },
          invalid: {
            color: theme.palette.text.primary,
          },
        },
      }}
      {...other}
    />
  );
});
