import { Control, DeepRequired, FieldErrorsImpl, FieldValues } from 'react-hook-form';

import { Box, Grid, Typography } from '@mui/material';
import { DepositPlan } from '@one/api-models/lib/Admin/PaymentPlan/DepositPlan';
import { InstallmentPlan } from '@one/api-models/lib/Admin/PaymentPlan/InstallmentPlan';
import { PaymentPlanBase } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanBase';

import { SectionTitle } from 'components/_common/SectionTitle';
import {
  PaymentMethodOption,
  UpdatePaymentPlanForm,
} from 'components/views/paymentPlans/components/EditPaymentPlanDialog';

import { PaymentPlanControlledCardSelector } from './PaymentPlanControlledCardSelector';

interface Props {
  paymentPlanDetails: PaymentPlanBase | DepositPlan | InstallmentPlan | undefined;
  isFetchingPaymentMethodList?: boolean;
  control: Control<UpdatePaymentPlanForm, object>;
  paymentMethodListOptions?: PaymentMethodOption[];
  allowPreferredPaymentMethod: boolean;
  errors: FieldErrorsImpl<DeepRequired<UpdatePaymentPlanForm>>;
  testId: string;
  handleChangePaymentMethod: (paymentMethodReference: string | undefined) => void;
}

export const PaymentPlanOrderSection = ({
  paymentPlanDetails,
  isFetchingPaymentMethodList,
  control,
  paymentMethodListOptions,
  allowPreferredPaymentMethod,
  errors,
  testId,
  handleChangePaymentMethod,
}: Props) => {
  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Order" variant="h4" />

      {!!paymentPlanDetails && (
        <Grid container spacing={2} rowSpacing={2}>
          {paymentPlanDetails?.orderNumber && (
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2" color="#6A7383">
                  Order Number
                </Typography>
                <Typography variant="subtitle1">{paymentPlanDetails?.orderNumber}</Typography>
              </Box>
            </Grid>
          )}
          {paymentPlanDetails?.externalReference && (
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2" color="#6A7383">
                  Payment Gateway Reference
                </Typography>
                <Typography variant="subtitle1">{paymentPlanDetails?.externalReference}</Typography>
              </Box>
            </Grid>
          )}
          {allowPreferredPaymentMethod && (
            <Grid item xs={6} md={7} lg={5}>
              <PaymentPlanControlledCardSelector
                isLoading={isFetchingPaymentMethodList}
                control={control as unknown as Control<FieldValues, object>}
                name="paymentMethodReference"
                label="Preferred Payment Method"
                options={paymentMethodListOptions}
                error={errors.paymentMethodReference != null}
                helperText={errors.paymentMethodReference?.message}
                requiredMessage="Payment method is required"
                disableClearable
                popperWidth="300px"
                onChange={(value) => {
                  handleChangePaymentMethod(value);
                }}
                getOptionDisabled={(option) => {
                  const currentDate = new Date();
                  return (
                    option.expirationYear < currentDate.getFullYear() ||
                    (option.expirationYear === currentDate.getFullYear() &&
                      option.expirationMonth < currentDate.getMonth())
                  );
                }}
                testId={testId}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};
