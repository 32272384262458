import { DepositPlan } from '@one/api-models/lib/Admin/PaymentPlan/DepositPlan';
import { InstallmentPlan } from '@one/api-models/lib/Admin/PaymentPlan/InstallmentPlan';
import { CalculatedDepositPlan } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculatedDepositPlan';
import { CalculatedInstallmentPlan } from '@one/api-models/lib/Sales/Payment/PaymentPlan/CalculatedInstallmentPlan';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

interface PaymentData {
  calculatedPaymentPlan: CalculatedDepositPlan | CalculatedInstallmentPlan | undefined;
  updatedPaymentPlan: DepositPlan | InstallmentPlan | undefined;
  createPaymentMethodError: string | undefined;
  isLoadingCalculatePaymentPlan: boolean;
  isLoadingUpdatePaymentPlan: boolean;
  isLoadingCreatePaymentMethod: boolean;
}

const initialState: PaymentData = {
  calculatedPaymentPlan: undefined,
  updatedPaymentPlan: undefined,
  createPaymentMethodError: undefined,
  isLoadingCalculatePaymentPlan: false,
  isLoadingUpdatePaymentPlan: false,
  isLoadingCreatePaymentMethod: false,
};

const paymentDataSlice = createSlice({
  name: 'paymentData',
  initialState,
  reducers: {
    setCalculatedPaymentPlan: (
      state,
      action: PayloadAction<CalculatedDepositPlan | CalculatedInstallmentPlan | undefined>,
    ) => {
      state.calculatedPaymentPlan = action.payload;
    },
    setUpdatedPaymentPlan: (state, action: PayloadAction<DepositPlan | InstallmentPlan | undefined>) => {
      state.updatedPaymentPlan = action.payload;
    },
    setCreatePaymentMethodError: (state, action: PayloadAction<string | undefined>) => {
      state.createPaymentMethodError = action.payload;
    },
    setIsLoadingCalculatePaymentPlan: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCalculatePaymentPlan = action.payload;
    },
    setIsLoadingUpdatePaymentPlan: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUpdatePaymentPlan = action.payload;
    },
    setIsLoadingCreatePaymentMethod: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCreatePaymentMethod = action.payload;
    },
    resetPaymentDataSliceState: (state) => {
      state.calculatedPaymentPlan = undefined;
      state.updatedPaymentPlan = undefined;
      state.createPaymentMethodError = undefined;
    },
  },
});

export const {
  setCalculatedPaymentPlan,
  setUpdatedPaymentPlan,
  setCreatePaymentMethodError,
  setIsLoadingCalculatePaymentPlan,
  setIsLoadingUpdatePaymentPlan,
  setIsLoadingCreatePaymentMethod,
  resetPaymentDataSliceState,
} = paymentDataSlice.actions;

export const selectCalculatedPaymentPlan = (state: RootState) => state.paymentData.calculatedPaymentPlan;
export const selectUpdatedPaymentPlan = (state: RootState) => state.paymentData.updatedPaymentPlan;
export const selectCreatePaymentMethodError = (state: RootState) => state.paymentData.createPaymentMethodError;
export const selectIsLoadingCalculatePaymentPlan = (state: RootState) =>
  state.paymentData.isLoadingCalculatePaymentPlan;
export const selectIsLoadingUpdatePaymentPlan = (state: RootState) => state.paymentData.isLoadingUpdatePaymentPlan;
export const selectIsLoadingCreatePaymentMethod = (state: RootState) => state.paymentData.isLoadingCreatePaymentMethod;

export default paymentDataSlice.reducer;
