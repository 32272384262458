import React from 'react';

import { AccessControlPermissions } from 'apiAccess/accessControlAccess';
import { useAppHelpers } from 'components/hooks/useAppHelpers';

type Props = {
  children: React.ReactNode;
  permission: AccessControlPermissions[];
  invalidPermissionComponent?: React.ReactNode | undefined;
};

export const AccessControlGuard = ({ children, permission, invalidPermissionComponent }: Props) => {
  const { hasPermission } = useAppHelpers();

  return <> {hasPermission(permission) ? children : invalidPermissionComponent} </>;
};
