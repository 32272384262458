import { Control, FieldValues } from 'react-hook-form';

import { Grid } from '@mui/material';
import { Supplier } from '@one/api-models/lib/Admin/Products/Supplier';

import ControlledTextField from 'components/_common/ControlledTextField';

import { FormProps } from '../DetailsTab/DetailsBase';

import { FieldBox } from './FieldBox';
import { SupplierReferences } from './SupplierReferences';

export interface DetailsUpdateBase {
  name: string;
  displayName: string;
}

interface ProductBaseFieldsProps {
  formProps: FormProps;
  isLoading: boolean;
  suppliers: Supplier[];
  testId: string;
}

export const ProductBaseFields = ({ formProps, isLoading, suppliers, testId }: ProductBaseFieldsProps) => {
  const { errors, control } = formProps;

  return (
    <FieldBox>
      <Grid container direction="column" alignItems="stretch">
        <Grid item xs={12} sx={{ mb: 3 }}>
          <ControlledTextField
            control={control as unknown as Control<FieldValues, object>}
            name="name"
            fullWidth
            label="Name"
            isAutoFocused
            disabled={isLoading}
            error={errors?.name?.message != null}
            helperText={errors?.name?.message}
            testId={`${testId}Name`}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <ControlledTextField
            control={control as unknown as Control<FieldValues, object>}
            name="displayName"
            fullWidth
            label="Display Name"
            disabled={isLoading}
            error={errors?.displayName?.message != null}
            helperText={errors?.displayName?.message}
            testId={`${testId}DisplayName`}
          />
        </Grid>
        <Grid item container>
          <Grid item xs={12} sx={{ p: 1, pt: 0 }}>
            <SupplierReferences formProps={formProps} suppliers={suppliers} isLoading={isLoading} testId={testId} />
          </Grid>
        </Grid>
      </Grid>
    </FieldBox>
  );
};
