import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Accordion = styled(MuiAccordion)(({ theme }) => ({}));

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  justifyContent: 'flex-start',
  minHeight: 'auto',

  '&.Mui-expanded': {
    minHeight: 'auto',
    margin: '0 0 24px 0',
  },

  '.MuiAccordionSummary-content': {
    flex: 'unset',
    margin: 0,

    '&.Mui-expanded': {
      margin: '0',
    },
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));
