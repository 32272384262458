import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Button, Grid } from '@mui/material';
import { CybersourceGatewayConfiguration } from '@one/api-models/lib/Sales/Payment/CybersourceGatewayConfiguration';

import { useCybersourceProgramSale } from 'components/hooks/useCybersourceProgramSale';
import {
  PaymentOptions,
  selectBillingDetails,
  selectInstallmentsPaymentPlan,
  selectIsCompleteWithNoPayment,
  selectIsLoadingOrderPayment,
  selectMetadata,
  selectPaymentError,
  selectPurchaseData,
  selectSelectedPaymentOption,
  setPaymentError,
} from 'slices/salesOrderDataSlice';

import { CybersourceForm, CybersourceFormRefHandle } from './components/CybersourceForm';
import { PaymentBackdropLoading } from './components/PaymentBackdropLoading';
import { PaymentPlanForm } from './components/PaymentPlanForm';
import { PaymentSkeleton } from './components/PaymentSkeleton';

interface PaymentDetailsCybersourceProps {
  paymentGatewayConfiguration: CybersourceGatewayConfiguration;
  isLoadingConfiguration: boolean;
  refetchConfiguration: () => void;
}

export const PaymentDetailsCybersource: FC<PaymentDetailsCybersourceProps> = ({
  paymentGatewayConfiguration,
  refetchConfiguration,
  isLoadingConfiguration,
}) => {
  const dispatch = useDispatch();

  const { performCompleteOrderWithPayment } = useCybersourceProgramSale();
  const isLoading = useSelector(selectIsLoadingOrderPayment);
  const isCompleteWithNoPayment = useSelector(selectIsCompleteWithNoPayment);
  const paymentError = useSelector(selectPaymentError);
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);
  const billingDetails = useSelector(selectBillingDetails);
  const purchaseData = useSelector(selectPurchaseData);
  const metadata = useSelector(selectMetadata);
  const installmentsPaymentPlan = useSelector(selectInstallmentsPaymentPlan);

  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  const testIdPrefix = 'PaymentDetailsCybersource';

  const cybersourceFormRef = useRef<CybersourceFormRefHandle>(null);

  const validatePaymentPlanForm = useRef(() => {
    return false;
  });

  // Ref required for input attached events
  const selectedPaymentOptiondRef = useRef(selectedPaymentOption);

  // Update the ref whenever selectedPaymentMethod changes
  useEffect(() => {
    selectedPaymentOptiondRef.current = selectedPaymentOption;
  }, [selectedPaymentOption]);

  useEffect(() => {
    if (!isLoading) {
      setIsSubmittingPayment(false);
    }
  }, [isLoading]);

  const paymentSubmitHandler = async (token: string, paymentMethodExpiration: Date) => {
    dispatch(setPaymentError(undefined));

    if (selectedPaymentOption === PaymentOptions.OneTime) {
      performCompleteOrderWithPayment(token, paymentMethodExpiration, billingDetails, undefined, metadata);
    } else if (selectedPaymentOption === PaymentOptions.PaymentPlan) {
      let isPaymentEnabled = false;

      if (!!validatePaymentPlanForm && !!validatePaymentPlanForm.current) {
        isPaymentEnabled = await validatePaymentPlanForm.current();
      }

      if (isPaymentEnabled) {
        performCompleteOrderWithPayment(
          token,
          paymentMethodExpiration,
          billingDetails,
          installmentsPaymentPlan,
          metadata,
        );
      } else {
        setIsSubmittingPayment(false);
      }
    }
  };

  const updatePaymentData = () => {
    setIsSubmittingPayment(false);
    refetchConfiguration();
  };

  const submitForm = async () => {
    let isAdditionalFormValid = true;
    if (
      selectedPaymentOptiondRef.current === PaymentOptions.PaymentPlan &&
      !!validatePaymentPlanForm &&
      !!validatePaymentPlanForm.current
    ) {
      isAdditionalFormValid = await validatePaymentPlanForm.current();
    }

    if (cybersourceFormRef.current) {
      cybersourceFormRef.current.handleFormSubmit(!isAdditionalFormValid);
    }
  };

  return (
    <>
      {!isLoadingConfiguration || isSubmittingPayment ? (
        <>
          {!!purchaseData?.paymentPlanConfig && (
            <Grid item xs={12} key="pf">
              <PaymentPlanForm validatePaymentPlanForm={validatePaymentPlanForm} testId={testIdPrefix} />
            </Grid>
          )}
          <Grid item xs={12} md={10} lg={8}>
            <CybersourceForm
              title="Payment"
              ref={cybersourceFormRef}
              paymentGatewayConfiguration={paymentGatewayConfiguration}
              updatePaymentData={updatePaymentData}
              submitPayment={paymentSubmitHandler}
              isSubmittingPayment={isSubmittingPayment}
              setIsSubmittingPayment={setIsSubmittingPayment}
              submitForm={submitForm}
              error={paymentError}
              testId={testIdPrefix}
            />
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              disabled={isSubmittingPayment}
              sx={{ mt: 2 }}
              variant="contained"
              type="submit"
              size="large"
              onClick={submitForm}
              startIcon={<AttachMoneyIcon />}
              data-testid={`${testIdPrefix}ConfirmAndPayButton`}
            >
              Confirm and pay
            </Button>
          </Grid>
        </>
      ) : (
        <PaymentSkeleton />
      )}

      <PaymentBackdropLoading
        isCompleteWithNoPayment={isCompleteWithNoPayment}
        isLoading={isLoading}
        isLoadingConfiguration={isLoadingConfiguration}
        isSubmittingPayment={isSubmittingPayment}
      />
    </>
  );
};
