import CloseIcon from '@mui/icons-material/Close';
import { Box, Breakpoint, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';

export const ImagePreviewPopup = ({
  imageSrc,
  imageWidth,
  testId,
  onClose,
}: {
  imageSrc?: string;
  imageWidth?: number;
  testId: string;
  onClose: () => void;
}) => {
  const isAutoSize = !imageWidth;

  let maxWidth: Breakpoint | false = (imageWidth || 0) < 100 ? 'xs' : 'lg';

  if ((imageWidth && imageWidth > 1000) || isAutoSize) {
    maxWidth = false;
  }

  const displayImageWidth = isAutoSize ? undefined : imageWidth;

  return (
    <Dialog open={!!imageSrc} onClose={onClose} fullWidth={false} maxWidth={maxWidth}>
      <DialogTitle variant="h6">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {isAutoSize ? <Box>Preview</Box> : <Box>Image Preview - {imageWidth}px</Box>}

          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            size="small"
            disableRipple
            data-testid={`${testId}CloseButton`}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center' }}>
          <img src={imageSrc} alt="" width={displayImageWidth} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
