import { Dialog as MuiDialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    borderRadius: 8,
  },
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(182, 192, 205, 0.7)',
  },
}));
