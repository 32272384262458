import { useEffect, useRef, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { Country, formatPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, useTheme } from '@mui/material';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';
import { EditMemberRequest } from '@one/api-models/lib/Admin/Members/Request/EditMemberRequest';
import { EditMemberResponse } from '@one/api-models/lib/Admin/Members/Response/EditMemberResponse';

import { ApiError } from 'apiAccess/api-client';
import { SelectedAddress } from 'components/_common/AddressAutocomplete';
import { ControlledAutocompleteOption } from 'components/_common/ControlledAutocomplete';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { Brand } from 'models/Brand';
import { CountryCode } from 'models/CountryCode';
import { LEAD_SOURCES, SALUTATIONS } from 'models/customers/constants';
import { Customer } from 'models/customers/Customer';
import { selectActiveBrand } from 'slices/applicationDataSlice';
import { selectCAStatesList, selectCountryList, selectUSStatesList } from 'slices/customersDataSlice';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SectionTitle,
} from 'styled';
import { getBrandCountryCode } from 'utils/getBrandCountryCode';
import { isPhoneNumberValid } from 'utils/validatePhoneNumber';

import { AddressAutocomplete } from '../../../../common/AddressAutocomplete';
import ControlledAutocomplete from '../../../../common/ControlledAutocomplete';
import ControlledDatePicker from '../../../../common/ControlledDatePicker';
import { ControlledInternationalCountryCallingCodesSelect } from '../../../../common/ControlledInternationalCountryCallingCodesSelect';
import { ControlledPhoneNumberInput } from '../../../../common/ControlledPhoneNumberInput';
import ControlledTextField from '../../../../common/ControlledTextField';

type CustomerDetailsForm = {
  salutation?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  internationalCountryCallingCode?: string;
  dateOfBirth?: Date | null;
  partnerCustomerId?: string;
  partnerLoyaltyId?: string;
  leadSource?: string;
  city?: string;
  address?: string;
  address2?: string;
  stateCode?: string;
  zipCode?: string;
  countryCode?: string;
  notes?: string;
};

interface AddCustomerDialogProps {
  open: boolean;
  callback?: (customer: Customer) => void;
  handleClose: () => void;
}

export const AddCustomerDialog = ({ open, handleClose, callback }: AddCustomerDialogProps) => {
  const { api } = useApiHelpers();
  const { apiErrorHandler, addMessage } = useToastMessage();
  const navigate = useNavigate();
  const theme = useTheme();
  const { currentZonedDateToISODate } = useFormat();

  const [brandKey, setBrandKey] = useState<string | undefined>();
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>('US');

  const activeBrand = useSelector(selectActiveBrand);
  const countryList = useSelector(selectCountryList);
  const usStatesList = useSelector(selectUSStatesList);
  const caStatesList = useSelector(selectCAStatesList);
  const testIdPrefix = 'CreateCustomerForm';

  const validationSchema: yup.SchemaOf<CustomerDetailsForm> = yup.object().shape({
    salutation: yup.string().trim(),
    firstName: yup.string().trim().required('First Name is required.').matches(/.{2,}/, {
      excludeEmptyString: true,
      message: 'Use at least 2 characters.',
    }),
    lastName: yup.string().trim().required('Last Name is required.').matches(/.{2,}/, {
      excludeEmptyString: true,
      message: 'Use at least 2 characters.',
    }),
    email: yup.string().trim().required('Email is required.').email('Must be a valid email.'),
    phoneNumber: yup
      .string()
      .trim()
      .required('Phone number is required.')
      .test('phoneNumber', 'Phone number is not valid.', (value) => isPhoneNumberValid(value))
      .nullable(),
    internationalCountryCallingCode: yup.string().trim().required('Country calling code is required.'),
    dateOfBirth: yup
      .date()
      .nullable()
      .typeError('Invalid Date Format.')
      .test({
        name: 'requiredBirthDate',
        test: function (value: Date | undefined | null) {
          const { allowBrandSpecific } = this.parent;
          if (!value && allowBrandSpecific) {
            return this.createError({
              path: 'dateOfBirth',
              message: 'Date of birth is required.',
            });
          }
          return true;
        },
      }),
    address: yup.string().trim(),
    address2: yup.string().trim(),
    city: yup.string().trim(),
    stateCode: yup.string().trim(),
    zipCode:
      activeBrand?.key === Brand.GuestBookingsAMZ
        ? yup.string().trim()
        : yup
            .string()
            .trim()
            .when('countryCode', {
              is: 'CA',
              then: yup.string().matches(/^[a-zA-Z]\d[a-zA-Z](\s?\d[a-zA-Z]\d)?$/, 'Zip Code is invalid'),
              otherwise: yup
                .string()
                .trim()
                .when('countryCode', {
                  is: 'US',
                  then: yup
                    .string()
                    .required('Zip Code is required')
                    .matches(/^\d{5}(?:-\d{4})?$/, 'Zip Code is invalid'),
                }),
            }),
    countryCode: yup.string().trim(),
    partnerCustomerId: yup.string().trim(),
    partnerLoyaltyId: yup.string().trim(),
    leadSource: yup.string().trim(),
    notes: yup.string().trim(),
  });

  const defaultValues: CustomerDetailsForm = {
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    internationalCountryCallingCode: 'US',
    phoneNumber: '',
    dateOfBirth: null,
    partnerCustomerId: '',
    partnerLoyaltyId: '',
    leadSource: '',
    countryCode: '',
    city: '',
    address: '',
    address2: '',
    stateCode: '',
    zipCode: '',
    notes: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    trigger,
  } = useForm<CustomerDetailsForm>({ mode: 'onBlur', defaultValues, resolver: yupResolver(validationSchema) });
  const internationalCountryCallingCode = watch('internationalCountryCallingCode');
  const countryCode = watch('countryCode');
  const isSelectedCountryStateCodeRequired = countryCode === 'US' || countryCode === 'CA';
  const fromRef = useRef<HTMLFormElement>(null);

  // #region handling Google Place Autocomplete
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddress | null | undefined>(null);
  const [phonePlaceholder, setPhonePlaceholder] = useState<string>(
    formatPhoneNumber(`+${getCountryCallingCode((internationalCountryCallingCode as Country) || 'US')}2025553412`),
  );

  useEffect(() => {
    setPhonePlaceholder(
      formatPhoneNumber(`+${getCountryCallingCode((internationalCountryCallingCode as Country) || 'US')}2025553412`),
    );
  }, [internationalCountryCallingCode]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (selectedAddress) {
      setValue('address', selectedAddress.street);
      setValue('city', selectedAddress.city);
      setValue('countryCode', selectedAddress.countryCode);
      setValue('zipCode', selectedAddress.zipCode);

      if (selectedAddress.countryCode === 'US' || selectedAddress.countryCode === 'CA') {
        setValue('stateCode', selectedAddress.stateCode);
      } else {
        setValue('stateCode', selectedAddress.state);
      }

      // trigger validation for all the set fields to avoid showing redundant validaiton messages
      trigger('address');
      trigger('city');
      trigger('countryCode');
      trigger('zipCode');
      trigger('stateCode');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress, setValue, trigger]);

  useEffect(() => {
    // set countryCode value one time based on activeBrand
    if (activeBrand?.key) {
      const brandCountryCode = getBrandCountryCode(activeBrand.key);

      setSelectedCountry(brandCountryCode);
      setBrandKey(activeBrand.key);
      if (brandKey !== Brand.GuestBookingsAMZ) setValue('internationalCountryCallingCode', brandCountryCode);
      else setValue('internationalCountryCallingCode', CountryCode.UnitedStates);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBrand, brandKey]);

  const createCustomerMutation = useMutation<EditMemberResponse, ApiError, EditMemberRequest, unknown>(
    (request) => {
      return api.members.edit(request);
    },
    {
      onSuccess: (value: EditMemberResponse) => {
        reset(defaultValues);
        addMessage({
          label: 'Customer successfully added',
          severity: 'success',
        });
        if (value?.memberKey) {
          if (callback) {
            callback({
              memberKey: value.memberKey,
              firstName: value.firstName,
              lastName: value.lastName,
              email: value.emailAddress,
              accountStatus: MemberStatus.PendingActivation,
            });
          } else {
            handleClose();
            navigate(`/customers/${value.memberKey}`);
          }
        }
      },
      onError: apiErrorHandler,
    },
  );

  const handleCustomerSubmit = (data: CustomerDetailsForm) => {
    const request: EditMemberRequest = {
      $Type: EditMemberRequest.$type,
      memberKey: '',
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.email,
      phoneNumber: data.phoneNumber,
      dateOfBirth: currentZonedDateToISODate(data.dateOfBirth),
      brandKey: activeBrand?.key ?? '',
      status: MemberStatus.Inactive,
      zipCode: data.zipCode,
      state: data.stateCode,
      city: data.city,
      address: data.address,
      address2: data.address2,
      country: data.countryCode,
      externalMemberKey: data.partnerCustomerId,
      partnerLoyaltyId: data.partnerLoyaltyId,
      leadSource: data.leadSource,
      notes: data.notes,
      salutation: data.salutation,
    };
    createCustomerMutation.mutate(request);
  };

  const handleCountryChange = (value: string | undefined) => {
    setSelectedCountry(value);
    setValue('stateCode', '');

    // trigger validation for state field
    trigger('stateCode');
  };

  const salutations = SALUTATIONS.map((salutation) => ({ code: salutation.value, label: salutation.label })).sort(
    (a: ControlledAutocompleteOption, b: ControlledAutocompleteOption) => a.label.localeCompare(b.label),
  );

  const leadSources = LEAD_SOURCES.map((leadSource) => ({ code: leadSource.value, label: leadSource.label })).sort(
    (a: ControlledAutocompleteOption, b: ControlledAutocompleteOption) => a.label.localeCompare(b.label),
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '496px',
        },
      }}
    >
      <DialogTitle>
        Add Customer
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testIdPrefix}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(handleCustomerSubmit)} ref={fromRef}>
          <Grid container rowSpacing={3} columnSpacing={1}>
            <Grid item xs={12} mt={2}>
              <SectionTitle>Account Information</SectionTitle>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6} md={3}>
                <ControlledAutocomplete
                  isAutoFocused
                  name="salutation"
                  label="Salutation"
                  placeholder="..."
                  options={salutations || []}
                  error={errors.salutation != null}
                  helperText={errors.salutation?.message}
                  control={control as unknown as Control<FieldValues, object>}
                  testId={`${testIdPrefix}Salutation`}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="firstName"
                fullWidth
                label="First Name"
                placeholder="Alex"
                error={errors?.firstName?.message != null}
                helperText={errors?.firstName?.message}
                testId={`${testIdPrefix}FirstName`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="lastName"
                fullWidth
                label="Last Name"
                placeholder="Smith"
                error={errors?.lastName?.message != null}
                helperText={errors?.lastName?.message}
                testId={`${testIdPrefix}LastName`}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledDatePicker
                control={control as unknown as Control<FieldValues, object>}
                name="dateOfBirth"
                label="Date of Birth"
                placeholder="mm/dd/yyyy"
                disableFuture
                openTo="year"
                error={errors?.dateOfBirth?.message != null}
                helperText={errors?.dateOfBirth?.message}
                fullWidth
                testId={`${testIdPrefix}DateOfBirth`}
              />
            </Grid>

            <Grid item xs={4} md={3}>
              <ControlledInternationalCountryCallingCodesSelect
                control={control as unknown as Control<FieldValues, object>}
                name="internationalCountryCallingCode"
                label="Country"
                fullWidth
                error={errors?.internationalCountryCallingCode?.message != null}
                helperText={errors?.internationalCountryCallingCode?.message}
                country={selectedCountry}
                testId={testIdPrefix}
              />
            </Grid>
            <Grid item xs={8} md={9}>
              <ControlledPhoneNumberInput
                control={control as unknown as Control<FieldValues, object>}
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                placeholder={phonePlaceholder}
                error={errors?.phoneNumber?.message != null}
                helperText={errors?.phoneNumber?.message}
                country={internationalCountryCallingCode || selectedCountry}
                defaultCountry="US"
                testId={testIdPrefix}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                control={control as unknown as Control<FieldValues, object>}
                name="email"
                fullWidth
                label="Email"
                placeholder="alex.smith@onecompany.com"
                error={errors?.email?.message != null}
                helperText={errors?.email?.message}
                testId={`${testIdPrefix}Email`}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />} sx={{ px: 0 }}>
                  <SectionTitle>Billing Details</SectionTitle>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                  <Grid container xs={12} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12} md={8}>
                      <AddressAutocomplete
                        name="address"
                        label="Street Address"
                        error={errors?.address?.message != null}
                        helperText={errors?.address?.message}
                        setSelectedAddress={setSelectedAddress}
                        control={control as unknown as Control<FieldValues, object>}
                        testId={testIdPrefix}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ControlledTextField
                        control={control as unknown as Control<FieldValues, object>}
                        name="address2"
                        fullWidth
                        label="Apt or suite number"
                        error={errors?.address2?.message != null}
                        helperText={errors?.address2?.message}
                        testId={`${testIdPrefix}AptNumber`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ControlledAutocomplete
                        name="countryCode"
                        label="Country"
                        options={countryList || []}
                        error={errors.countryCode != null}
                        helperText={errors.countryCode?.message}
                        control={control as unknown as Control<FieldValues, object>}
                        onChange={handleCountryChange}
                        testId={`${testIdPrefix}Country`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {!isSelectedCountryStateCodeRequired && (
                        <ControlledTextField
                          control={control as unknown as Control<FieldValues, object>}
                          name="stateCode"
                          fullWidth
                          label="State/Region"
                          error={errors?.stateCode?.message != null}
                          helperText={errors?.stateCode?.message}
                          testId={`${testIdPrefix}State`}
                        />
                      )}
                      {isSelectedCountryStateCodeRequired && (
                        <ControlledAutocomplete
                          name="stateCode"
                          label="State"
                          options={countryCode === 'US' ? usStatesList : countryCode === 'CA' ? caStatesList : []}
                          error={errors.stateCode != null}
                          helperText={errors.stateCode?.message}
                          control={control as unknown as Control<FieldValues, object>}
                          testId={`${testIdPrefix}State`}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        control={control as unknown as Control<FieldValues, object>}
                        name="city"
                        fullWidth
                        label="City"
                        error={errors?.city?.message != null}
                        helperText={errors?.city?.message}
                        testId={`${testIdPrefix}City`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        control={control as unknown as Control<FieldValues, object>}
                        name="zipCode"
                        fullWidth
                        label="Zip Code"
                        error={errors?.zipCode?.message != null}
                        helperText={errors?.zipCode?.message}
                        testId={`${testIdPrefix}ZipCode`}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} mt={2}>
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />} sx={{ px: 0 }}>
                  <SectionTitle>Partner Information</SectionTitle>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                  <Grid container xs={12} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        control={control as unknown as Control<FieldValues, object>}
                        name="partnerCustomerId"
                        fullWidth
                        label="PARTNER Customer ID"
                        description="(Partner Reference)"
                        error={errors?.partnerCustomerId?.message != null}
                        helperText={errors?.partnerCustomerId?.message}
                        testId={`${testIdPrefix}PartnerCustomerId`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        control={control as unknown as Control<FieldValues, object>}
                        name="partnerLoyaltyId"
                        fullWidth
                        label="PARTNER Loyalty ID"
                        description="(Partner Reference)"
                        error={errors?.partnerLoyaltyId?.message != null}
                        helperText={errors?.partnerLoyaltyId?.message}
                        testId={`${testIdPrefix}PartnerLoyaltyId`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ControlledAutocomplete
                        name="leadSource"
                        label="Lead Source"
                        placeholder="..."
                        options={leadSources || []}
                        error={errors.leadSource != null}
                        helperText={errors.leadSource?.message}
                        control={control as unknown as Control<FieldValues, object>}
                        testId={`${testIdPrefix}LeadSource`}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} my={2}>
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />} sx={{ px: 0 }}>
                  <SectionTitle>Notes</SectionTitle>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control as unknown as Control<FieldValues, object>}
                      name="notes"
                      fullWidth
                      multiline
                      minRows="5"
                      label="Customer Notes"
                      error={errors?.notes?.message != null}
                      helperText={errors?.notes?.message}
                      testId={`${testIdPrefix}Notes`}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} data-testid={`${testIdPrefix}CancelButton`}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            fromRef?.current?.requestSubmit();
          }}
          disabled={createCustomerMutation.isLoading}
          data-testid={`${testIdPrefix}AddButton`}
        >
          Add Customer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
