import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Button, CircularProgress, IconButton, Link, TableCell, TableRow, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ImageRecord } from '@one/api-models/lib/Admin/Media/ImageRecord';
import { ImageVariant } from '@one/api-models/lib/Admin/Media/ImageVariant';

import { GeneratingImageVariant } from './GeneratingImageVariant';
import { InlineTextEditor } from './InlineTextEditor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.light,
    },
    setPrimaryButton: {
      color: theme.palette.success.light,
    },

    primaryButton: {
      color: theme.palette.success.main,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText,
      },
    },
    variantsButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[800],
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  }),
);

export const MediaListingRow = ({
  item,
  testId,
  onSetImagePreview,
  onCaptionChanged,
  onNoteChanged,
  onDelete,
  onSetPrimary,
  onGenerateVariants,
}: {
  item: ImageRecord;
  testId: string;
  onSetImagePreview: (photoVariant: ImageVariant | undefined) => void;
  onCaptionChanged: (newValue: string) => void;
  onNoteChanged: (newValue: string) => void;
  onDelete: () => void;
  onSetPrimary: () => void;
  onGenerateVariants: () => void;
}) => {
  const classes = useStyles();

  const isGeneratingVariants =
    item.variants && item.variants.length === 1 && (item.variants[0] as GeneratingImageVariant).generating;

  const variants = isGeneratingVariants ? (
    <CircularProgress data-testid="LoadingSpinner" />
  ) : (
    <>
      {(item.variants || [])
        .sort((x, y) => x.width - y.width)
        .map((x: ImageVariant, idx: number) => (
          <Box key={x.width} component="span">
            {idx > 0 ? ',  ' : ''}
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onSetImagePreview(x);
              }}
              style={{ whiteSpace: 'nowrap' }}
              data-testid={`${testId}WidthLink`}
            >
              {x.width} px
            </Link>
          </Box>
        ))}
    </>
  );

  return (
    <TableRow>
      <TableCell sx={{ width: 0, p: 0, verticalAlign: 'top' }}>
        <Box sx={{ width: 0 }}>
          {item.allowDelete && (
            <IconButton
              title="Delete"
              className={classes.deleteButton}
              sx={{ mt: 1, ml: 1 }}
              onClick={() => onDelete()}
              data-testid={`${testId}DeleteButton`}
            >
              <DeleteOutlineIcon />
            </IconButton>
          )}
        </Box>
      </TableCell>
      <TableCell>{item.id}</TableCell>
      <TableCell>
        <Link
          href="#"
          onClick={(e) => {
            onSetImagePreview({ url: item.url, width: item.width } as ImageVariant);
            e.preventDefault();
          }}
          data-testid={`${testId}PreviewImageButton`}
        >
          <img src={item.url} width="200" style={{ width: '200px', zoom: 0 }} alt="Preview" />
        </Link>
      </TableCell>
      <TableCell>
        <InlineTextEditor value={item.caption} onChange={onCaptionChanged} width={100} testId={testId} />
      </TableCell>
      <TableCell>{item.supplier}</TableCell>
      <TableCell>{item.author}</TableCell>
      <TableCell>
        <Box component="div" sx={{ textAlign: 'center' }}>
          {variants}
          {item.allowGenerateVariants && (
            <Button
              variant="contained"
              className={classes.variantsButton}
              sx={{ mt: 2 }}
              onClick={() => onGenerateVariants()}
              disabled={isGeneratingVariants}
              data-testid={`${testId}GenerateVariantsButton`}
            >
              Generate all variants
            </Button>
          )}
        </Box>
      </TableCell>
      <TableCell>{item.status}</TableCell>
      <TableCell>
        <InlineTextEditor value={item.notes} onChange={onNoteChanged} width={120} testId={testId} />
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        {item.isPrimary && (
          <Box>
            <StarIcon className={classes.primaryButton} />
          </Box>
        )}
        {!item.isPrimary && (
          <Box>
            <IconButton
              size="medium"
              className={classes.setPrimaryButton}
              title="Set as primary"
              onClick={() => onSetPrimary()}
              disabled={!item.allowSetPrimary}
              data-testid={`${testId}StarButton`}
            >
              <StarBorderIcon />
            </IconButton>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};
