import { useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { AssetStatus } from '@one/api-models/lib/Admin/Members/Common/AssetStatus';
import { AlternativeCurrencySummary } from '@one/api-models/lib/Admin/Orders/Common/AlternativeCurrencySummary';
import { CalculateCancellationOutcomeResponse } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeResponse';
import { CancelOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/CancelOrderRequest';
import { ItemCancellationOutcome } from '@one/api-models/lib/Admin/Orders/Common/ItemCancellationOutcome';

import { AlternativeCurrenciesIcon } from 'components/_common/alternativeCurrency/AlternativeCurrenciesIcon';
import { ConfirmationDialog } from 'components/_common/ConfirmationDialog';
import { useFormat } from 'components/hooks/useFormat';
import AlternativeCurrencyForm from 'models/customers/profile/AlternativeCurrencyForm';
import CertificateForm from 'models/customers/profile/CertificateForm';
import OrderCancellationForm from 'models/customers/profile/OrderCancellationForm';
import OrderCancellationItemForm from 'models/customers/profile/OrderCancellationItemForm';

import { CancellationItem } from './CancellationItem';
import { CancellationSummary } from './CancellationSummary';

interface Props {
  orderNumber: string;
  data: CalculateCancellationOutcomeResponse;
  alwaysCancelAtSupplier?: boolean;
  testId: string;
  onCloseForm: () => void;
  onSubmitForm: (request: CancelOrderRequest) => void;
}

export const CancellationForm = ({
  data,
  orderNumber,
  alwaysCancelAtSupplier,
  testId,
  onCloseForm,
  onSubmitForm,
}: Props) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState<boolean>(false);

  const { formatCurrencySymbol, formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const altCurrencyValidationSchema: yup.SchemaOf<AlternativeCurrencyForm> = yup.object().shape({
    value: yup.number().required(),
    maxValue: yup.number().required(),
    name: yup.string().required(),
    code: yup.string().required(),
  });

  const certificateValidationSchema: yup.SchemaOf<CertificateForm> = yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    serialNumber: yup.string().required(),
    shouldReissue: yup.boolean().required(),
  });

  const itemValidationSchema: yup.SchemaOf<OrderCancellationItemForm> = yup.object().shape({
    orderItemId: yup.number().required(),
    supplierCancellationFee: yup.string().required(),
    isCancellableAtSupplier: yup.boolean().required(),
    cancelAtSupplier: yup.boolean().when('isCancellableAtSupplier', {
      is: true,
      then: yup.boolean().required(),
    }),
    burn: yup.array().of(altCurrencyValidationSchema),
    earn: yup.array().of(altCurrencyValidationSchema),
    certificates: yup.array().of(certificateValidationSchema),
  });

  const validationSchema: yup.SchemaOf<OrderCancellationForm> = yup.object().shape({
    orderNumber: yup.string().required(),
    items: yup.array().of(itemValidationSchema),
    amountToBeRefunded: yup.number().required(),
  });

  const defaultValues = {
    orderNumber: orderNumber,
    items: data.itemsCancellationOutcome.map((outcome: ItemCancellationOutcome) => ({
      ...outcome,
      cancelAtSupplier: false,
      supplierCancellationFee:
        formatCurrency(outcome.supplierCancellationFee?.amount, outcome.supplierCancellationFee?.currency) ?? 'N/A',
      burn: outcome.burnedAltCurrencyToReissue.map((b: AlternativeCurrencySummary) => ({
        ...b,
        maxValue: b.value,
      })),
      earn: outcome.earnedAltCurrencyToRevoke.map((e: AlternativeCurrencySummary) => ({
        ...e,
        maxValue: e.value,
      })),
      certificates: [],
    })),
    amountToBeRefunded: data.amountPaid.amount,
  };

  const methods = useForm<OrderCancellationForm>({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = methods;

  const values: OrderCancellationForm = watch();

  const handleSubmitCancellation = () => {
    setIsConfirmationOpen(true);
  };

  const handleCloseForm = (): void => {
    if (isDirty) setIsCloseConfirmationOpen(true);
    else onCloseForm();
  };

  const handleAbort = (isConfirmed: boolean): void => {
    if (isConfirmed) onCloseForm();
    else setIsCloseConfirmationOpen(false);
  };

  const handleCancellation = (isConfirmed: boolean): void => {
    if (isConfirmed) {
      const refundAmount = values.amountToBeRefunded != null && data.amountPaid != null ? values.amountToBeRefunded : 0;
      const request: CancelOrderRequest = {
        orderNumber: values.orderNumber,
        amountToBeRefunded: { ...data.amountPaid, amount: refundAmount },
        items: values.items.map((item) => ({
          orderItemId: item.orderItemId,
          cancelAtSupplier: alwaysCancelAtSupplier || item.cancelAtSupplier === true,
          burnedAltCurrencyToReissue: item.burn.map((burn) => ({
            value: burn.value,
            code: burn.code,
            name: burn.name,
          })),
          earnedAltCurrencyToRevoke: item.earn.map((earn) => ({
            value: earn.value,
            code: earn.code,
            name: earn.name,
          })),
          certificatesToReinstate: item.certificates
            .filter((certificate) => certificate.shouldReissue)
            .map((certificate) => ({
              id: certificate.id,
              serialNumber: certificate.serialNumber,
              name: certificate.name,
              product: '',
              status: AssetStatus.Active,
            })),
        })),
      };
      onSubmitForm(request);
    }

    setIsConfirmationOpen(false);
  };

  const { fields } = useFieldArray({
    control,
    name: 'items',
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitCancellation)} noValidate>
        <Typography variant="h5" mt={3} mb={1}>
          Cancellation information
        </Typography>

        {data.amountPaid && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CancellationItem
                label="Amount to be refunded"
                name="amountToBeRefunded"
                icon={formatCurrencySymbol(data.amountPaid.currency)}
                minValue={0}
                maxValue={data.amountPaid.amount}
                testId={testId}
              />
            </Grid>
          </Grid>
        )}
        {fields.map((field, index) => (
          <Grid key={field.id} container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <Typography variant="subtitle1">Cancellation fee to supplier</Typography>
              <Typography variant="body1">{field.supplierCancellationFee}</Typography>
            </Grid>

            {field.burn.map((burn: AlternativeCurrencyForm, burnIndex: number) => (
              <Grid key={burn.code} item xs={12}>
                <CancellationItem
                  label={`${burn.name} to be reissued`}
                  name={`items.${index}.burn.${burnIndex}.value`}
                  icon={<AlternativeCurrenciesIcon size="0.9rem" alternativeCurrency={burn.code} />}
                  minValue={0}
                  maxValue={burn.value}
                  testId={testId}
                />
              </Grid>
            ))}

            {field.earn.map((earn: AlternativeCurrencyForm, earnIndex: number) => (
              <Grid key={earn.code} item xs={12}>
                <CancellationItem
                  label={`${earn.name} to be revoked`}
                  name={`items.${index}.earn.${earnIndex}.value`}
                  icon={<AlternativeCurrenciesIcon size="0.9rem" alternativeCurrency={earn.code} />}
                  minValue={0}
                  maxValue={earn.value}
                  testId={testId}
                />
              </Grid>
            ))}

            {field.certificates.map((certificate: CertificateForm, certificateIndex: number) => (
              <Grid key={certificate.serialNumber} item xs={12}>
                <Typography variant="subtitle1">
                  Certificate: {certificate.name} ({certificate.serialNumber})
                </Typography>
                {/* The option to issue a new certificate was disabled */}
                {/* ONE-5465 */}
                {/* <FormControlLabel
                  control={
                    <Controller
                      name={`items.${index}.certificates.${certificateIndex}.shouldReissue`}
                      control={control}
                      render={({ field: props }) => <Checkbox {...props} checked={props.value} />}
                    />
                  }
                  label="Issue new certificate"
                /> */}
              </Grid>
            ))}

            {!alwaysCancelAtSupplier && (
              <Grid item xs={12} sx={{ my: 1 }}>
                {field.isCancellableAtSupplier ? (
                  <FormControlLabel
                    control={
                      <Controller
                        name={`items.${index}.cancelAtSupplier`}
                        control={control}
                        render={({ field: props }) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            inputProps={{
                              //eslint-disable-next-line
                              //@ts-ignore
                              'data-testid': `${testId}CancelAtSupplierCheckboxInput`,
                            }}
                          />
                        )}
                      />
                    }
                    label="Cancel at supplier"
                  />
                ) : (
                  <Typography
                    sx={{ display: 'flex', alignContent: 'center' }}
                    color={(theme) => theme.palette.action.disabled}
                  >
                    <CheckBoxOutlineBlankIcon color="disabled" sx={{ mr: 1 }} />
                    This order has to be manually canceled at supplier.
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Box>
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={handleCloseForm}
              data-testid={`${testId}DoNotCancelButton`}
            >
              Do not cancel order
            </Button>
            <Button variant="contained" type="submit" data-testid={`${testId}CancelButton`}>
              Cancel order
            </Button>
          </Box>
        </Box>

        <ConfirmationDialog
          open={isConfirmationOpen}
          isLoading={false}
          title="Cancel order"
          question={
            <CancellationSummary data={values} outcome={data} alwaysCancelAtSupplier={alwaysCancelAtSupplier} />
          }
          confirmButton="Cancel order"
          abortButton="Close"
          onClose={handleCancellation}
          testId={`${testId}CancelOrderDialog`}
        />

        <ConfirmationDialog
          open={isCloseConfirmationOpen}
          isLoading={false}
          title="Abort cancel"
          question={
            <>
              Are you sure you want to abort the cancellation?
              <br />
              All changes will be lost.
            </>
          }
          confirmButton="Abort cancellation"
          abortButton="Continue"
          onClose={handleAbort}
          testId={`${testId}AbortCancelDialog`}
        />
      </form>
    </FormProvider>
  );
};
