import { Box } from '@mui/material';
import { PaymentPlanSummary } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanSummary';

import { SectionTitle } from 'components/_common/SectionTitle';
import { PaymentPlansList } from 'components/views/paymentPlans/PaymentPlansList';

interface PaymentPlansSectionProps {
  isFetchingPaymentPlanList: boolean;
  paymentPlans: PaymentPlanSummary[];
  handleUpdatePaymentPlanSuccess: () => void;
  memberId: string;
  testId: string;
}

export const PaymentPlansSection = (props: PaymentPlansSectionProps) => {
  const { isFetchingPaymentPlanList, paymentPlans, handleUpdatePaymentPlanSuccess, memberId, testId } = props;
  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Payment Plans" />
      <PaymentPlansList
        isFetchingPaymentPlanList={isFetchingPaymentPlanList}
        paymentPlans={paymentPlans}
        handleUpdatePaymentPlanSuccess={handleUpdatePaymentPlanSuccess}
        memberId={memberId}
        testId={testId}
      />
    </Box>
  );
};
