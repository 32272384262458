import { Box } from '@mui/material';

import { Typography } from 'styled';

type Props = {
  title: string;
  variant?:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2';
  sx?: object;
  icon?: React.ReactNode;
};

export const SectionTitle = ({ title, variant = 'h5', sx, icon }: Props) => {
  return (
    <Box display="flex">
      {icon && icon}
      <Typography variant={variant} sx={{ mb: 2, ...sx }}>
        {title}
      </Typography>
    </Box>
  );
};
