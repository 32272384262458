import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  uploadDropZone: {
    border: '2px dashed #ddd',
    fontSize: '25px',
    padding: 15,
    backgroundColor: '#fafafa',
    textAlign: 'center',

    '& .uploadTextColor': {
      color: '#bbb',
    },
  },

  uploadDropZoneHover: {
    border: '2px dashed ' + theme.palette.success.main,
    '& .uploadTextColor': {
      color: theme.palette.success.light,
    },
  },
  uploadDropZoneRejected: {
    border: '2px dashed ' + theme.palette.error.main,
    '& .uploadTextColor': {
      color: theme.palette.error.light,
    },
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  successColor: {
    color: theme.palette.success.light,
  },
})
);
