import { LoadCriteria } from "./LoadCriteria";

export class LoadSubtreeCriteria extends LoadCriteria {
  static $Type = "LoadSubtreeCriteria";

  constructor() {
    super();
    this.productId = '';
  }

  tagKey?: string;
  searchTerm?: string;
  productId: string;
}
