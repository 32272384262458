import { useMemo } from 'react';

import { Alert, Box, Divider, Typography } from '@mui/material';
import { DepositPlan } from '@one/api-models/lib/Admin/PaymentPlan/DepositPlan';
import { InstallmentPlan } from '@one/api-models/lib/Admin/PaymentPlan/InstallmentPlan';
import { PaymentPlanBase } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanBase';

import { Loading } from 'components/_common/Loading';
import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';
import { PaymentMethodOption } from 'components/views/paymentPlans/components/EditPaymentPlanDialog';

import { SideModalPreviewWrapper } from '../sideModal/SideModalPreviewWrapper';

interface Props {
  paymentPlanDetails: PaymentPlanBase | DepositPlan | InstallmentPlan | undefined;
  previewData: PaymentPlanBase | DepositPlan | InstallmentPlan | undefined;
  adhocPaymentAmount: string | undefined;
  isLoading?: boolean;
  isInstallmentPlan?: boolean;
  allowPreferredPaymentMethod: boolean;
  selectedPaymentMethod: PaymentMethodOption | undefined;
  selectedAdhocPaymentMethod: PaymentMethodOption | undefined;
}

export const PaymentPlanSummary = (props: Props) => {
  const {
    paymentPlanDetails,
    previewData,
    adhocPaymentAmount,
    isLoading,
    isInstallmentPlan,
    allowPreferredPaymentMethod,
    selectedPaymentMethod,
    selectedAdhocPaymentMethod,
  } = props;
  const {
    formatDate,
    formatCurrency: formatCurrencyFunc,
    formatRecurringInterval,
    formatRecurringInvoice,
    getRecurringIntervalTypeLabel,
  } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const initialRecurringInterval = useMemo(() => {
    if (paymentPlanDetails) {
      return {
        recurringIntervalType:
          'recurringInterval' in paymentPlanDetails ? paymentPlanDetails?.recurringInterval : undefined,
        recurringIntervalCount: 'intervalCount' in paymentPlanDetails ? paymentPlanDetails?.intervalCount : undefined,
      };
    }
  }, [paymentPlanDetails]);

  const previewRecurringInterval = useMemo(() => {
    if (previewData) {
      return {
        recurringIntervalType: 'recurringInterval' in previewData ? previewData?.recurringInterval : undefined,
        recurringIntervalCount: 'intervalCount' in previewData ? previewData?.intervalCount : undefined,
      };
    }
  }, [previewData]);

  const hasPaymentPlanEndDateChanged = useMemo(() => {
    if (previewData?.paymentPlanEndDate && paymentPlanDetails?.paymentPlanEndDate) {
      const formattedPreviewDate = formatDate(new Date(previewData?.paymentPlanEndDate), true, 'MM/dd/yyyy');
      const formattedInitialDate = formatDate(new Date(paymentPlanDetails?.paymentPlanEndDate), true, 'MM/dd/yyyy');
      return formattedPreviewDate !== formattedInitialDate;
    }
    return false;
  }, [previewData, paymentPlanDetails, formatDate]);

  const hasNextPaymentDueDateChanged = useMemo(() => {
    if (previewData?.nextPaymentDueDate && paymentPlanDetails?.nextPaymentDueDate) {
      const formattedPreviewDate = formatDate(new Date(previewData?.nextPaymentDueDate), true, 'MMM dd');
      const formattedInitialDate = formatDate(new Date(paymentPlanDetails?.nextPaymentDueDate), true, 'MMM dd');
      return formattedPreviewDate !== formattedInitialDate;
    }
    return false;
  }, [previewData, paymentPlanDetails, formatDate]);

  return (
    <SideModalPreviewWrapper>
      <Box mb={4} px={2}>
        <SectionTitle title="Summary" variant="h4" />
      </Box>

      {isLoading ? (
        <Loading isContained />
      ) : (
        <>
          {paymentPlanDetails && (
            <Box mb={5}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2} px={2}>
                <Typography variant="subtitle1">Product Price:</Typography>
                <Typography variant="subtitle1">
                  {formatCurrency(paymentPlanDetails?.total?.amount, paymentPlanDetails?.total?.currency)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1} px={2}>
                <Typography variant="subtitle1">Paid:</Typography>
                <Typography variant="subtitle1">
                  {formatCurrency(paymentPlanDetails?.totalPaid?.amount, paymentPlanDetails?.totalPaid?.currency)}
                </Typography>
              </Box>
              <Divider sx={{ mb: 1 }} />
              <Box display="flex" flexDirection="row" justifyContent="space-between" mb={3} px={2}>
                <Typography variant="subtitle1">Balance:</Typography>
                <Typography variant="subtitle1">
                  {formatCurrency(paymentPlanDetails?.balance?.amount, paymentPlanDetails?.balance?.currency)}
                </Typography>
              </Box>

              <SectionTitle title="Payment Plan" variant="subtitle2" sx={{ mb: 1, px: 2 }} />
              {!!initialRecurringInterval?.recurringIntervalCount && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1} px={2}>
                  <Typography variant="subtitle1">Term:</Typography>
                  <Typography variant="subtitle1">
                    {formatRecurringInterval(
                      initialRecurringInterval?.recurringIntervalCount,
                      initialRecurringInterval?.recurringIntervalType,
                      true,
                    )}
                  </Typography>
                </Box>
              )}
              {isInstallmentPlan && paymentPlanDetails?.nextPaymentAmount && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1} px={2}>
                  <Typography variant="subtitle1">{`${getRecurringIntervalTypeLabel(
                    initialRecurringInterval?.recurringIntervalType,
                  )} Payment:`}</Typography>
                  <Typography variant="subtitle1">
                    {formatRecurringInvoice(
                      formatCurrency(
                        paymentPlanDetails?.nextPaymentAmount?.amount,
                        paymentPlanDetails?.nextPaymentAmount?.currency,
                      ),
                      initialRecurringInterval?.recurringIntervalType,
                    )}
                  </Typography>
                </Box>
              )}
              <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1} px={2}>
                <Typography variant="subtitle1">Term End Date:</Typography>
                <Typography variant="subtitle1">
                  {paymentPlanDetails?.paymentPlanEndDate &&
                    formatDate(new Date(paymentPlanDetails?.paymentPlanEndDate), true, 'MM/dd/yyyy')}
                </Typography>
              </Box>
              {paymentPlanDetails?.nextPaymentAmount && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1} px={2}>
                  <Typography variant="subtitle1">Next Invoice Date:</Typography>
                  <Typography variant="subtitle1">
                    {paymentPlanDetails?.nextPaymentDueDate &&
                      formatDate(new Date(paymentPlanDetails?.nextPaymentDueDate), true, 'MMM dd')}
                  </Typography>
                </Box>
              )}
              {paymentPlanDetails?.cardLast4Digits && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1} px={2}>
                  <Typography variant="subtitle1">Preferred card:</Typography>
                  <Typography variant="subtitle1">{`**** **** **** ${paymentPlanDetails?.cardLast4Digits}`}</Typography>
                </Box>
              )}
            </Box>
          )}
          {previewData && (
            <Box>
              <Box mb={5} px={6}>
                <Alert severity="warning">Changes after update:</Alert>
              </Box>

              <Box mb={5}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={previewData?.totalPaid?.amount !== paymentPlanDetails?.totalPaid?.amount ? 1 : 2}
                  px={2}
                >
                  <Typography variant="subtitle1">Product Price:</Typography>
                  <Typography variant="subtitle1">
                    {formatCurrency(previewData?.total?.amount, previewData?.total?.currency)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb={1}
                  pt={previewData?.totalPaid?.amount !== paymentPlanDetails?.totalPaid?.amount ? 1 : 0}
                  px={2}
                  sx={{
                    backgroundColor: (theme) => {
                      if (previewData?.totalPaid?.amount !== paymentPlanDetails?.totalPaid?.amount)
                        return theme.paymentPlan.chargeFeedback;
                    },
                  }}
                >
                  <Typography variant="subtitle1">Paid:</Typography>
                  <Typography variant="subtitle1">
                    {formatCurrency(previewData?.totalPaid?.amount, previewData?.totalPaid?.currency)}
                  </Typography>
                </Box>
                {adhocPaymentAmount && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pb={1}
                    pt={1}
                    px={2}
                    sx={{
                      backgroundColor: (theme) => {
                        if (parseFloat(adhocPaymentAmount) > 0) return theme.paymentPlan.chargeFeedback;
                      },
                    }}
                  >
                    <Typography variant="subtitle1">New Payments:</Typography>
                    <Typography variant="subtitle1">
                      {formatCurrency(parseFloat(adhocPaymentAmount), previewData?.total?.currency)}
                    </Typography>
                  </Box>
                )}
                <Divider />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb={1}
                  pt={1}
                  px={2}
                  sx={{
                    backgroundColor: (theme) => {
                      if (previewData?.balance?.amount !== paymentPlanDetails?.balance?.amount)
                        return theme.paymentPlan.chargeFeedback;
                    },
                  }}
                >
                  <Typography variant="subtitle1">Balance:</Typography>
                  <Typography variant="subtitle1">
                    {formatCurrency(previewData?.balance?.amount, previewData?.balance?.currency)}
                  </Typography>
                </Box>
                {selectedAdhocPaymentMethod && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pb={1}
                    px={2}
                    mb={2}
                    sx={{
                      backgroundColor: (theme) => {
                        if (
                          selectedAdhocPaymentMethod &&
                          Number(selectedAdhocPaymentMethod.code) !== paymentPlanDetails?.paymentMethodId
                        )
                          return theme.paymentPlan.chargeFeedback;
                      },
                    }}
                  >
                    <Typography variant="subtitle1">Card charged:</Typography>
                    <Typography variant="subtitle1">{selectedAdhocPaymentMethod.label}</Typography>
                  </Box>
                )}

                <SectionTitle
                  title="Payment Plan"
                  variant="subtitle2"
                  sx={{
                    mb:
                      previewRecurringInterval?.recurringIntervalCount !==
                      initialRecurringInterval?.recurringIntervalCount
                        ? 0.5
                        : 1,
                    px: 2,
                  }}
                />
                {!!previewRecurringInterval?.recurringIntervalCount && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pb={0.5}
                    pt={0.5}
                    px={2}
                    sx={{
                      backgroundColor: (theme) => {
                        if (
                          previewRecurringInterval?.recurringIntervalCount !==
                          initialRecurringInterval?.recurringIntervalCount
                        )
                          return theme.paymentPlan.chargeFeedback;
                      },
                    }}
                  >
                    <Typography variant="subtitle1">Term:</Typography>
                    <Typography variant="subtitle1">
                      {formatRecurringInterval(
                        previewRecurringInterval?.recurringIntervalCount,
                        previewRecurringInterval?.recurringIntervalType,
                        true,
                      )}
                    </Typography>
                  </Box>
                )}
                {isInstallmentPlan && previewData?.nextPaymentAmount && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pb={0.5}
                    pt={0.5}
                    px={2}
                    sx={{
                      backgroundColor: (theme) => {
                        if (previewData?.nextPaymentAmount?.amount !== paymentPlanDetails?.nextPaymentAmount?.amount)
                          return theme.paymentPlan.chargeFeedback;
                      },
                    }}
                  >
                    <Typography variant="subtitle1">{`${getRecurringIntervalTypeLabel(
                      previewRecurringInterval?.recurringIntervalType,
                    )} Payment:`}</Typography>
                    <Typography variant="subtitle1">
                      {formatRecurringInvoice(
                        formatCurrency(
                          previewData?.nextPaymentAmount?.amount,
                          previewData?.nextPaymentAmount?.currency,
                        ),
                        previewRecurringInterval?.recurringIntervalType,
                      )}
                    </Typography>
                  </Box>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb={0.5}
                  pt={0.5}
                  px={2}
                  sx={{
                    backgroundColor: (theme) => {
                      if (hasPaymentPlanEndDateChanged) return theme.paymentPlan.chargeFeedback;
                    },
                  }}
                >
                  <Typography variant="subtitle1">Term End Date:</Typography>
                  <Typography variant="subtitle1">
                    {previewData?.paymentPlanEndDate &&
                      formatDate(new Date(previewData?.paymentPlanEndDate), true, 'MM/dd/yyyy')}
                  </Typography>
                </Box>
                {previewData?.nextPaymentAmount && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pb={0.5}
                    pt={0.5}
                    px={2}
                    sx={{
                      backgroundColor: (theme) => {
                        if (hasNextPaymentDueDateChanged) return theme.paymentPlan.chargeFeedback;
                      },
                    }}
                  >
                    <Typography variant="subtitle1">Next Invoice Date:</Typography>
                    <Typography variant="subtitle1">
                      {previewData?.nextPaymentDueDate &&
                        formatDate(new Date(previewData?.nextPaymentDueDate), true, 'MMM dd')}
                    </Typography>
                  </Box>
                )}
                {(selectedPaymentMethod || paymentPlanDetails?.cardLast4Digits) && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pb={1}
                    pt={0.5}
                    px={2}
                    sx={{
                      backgroundColor: (theme) => {
                        if (
                          selectedPaymentMethod &&
                          Number(selectedPaymentMethod.code) !== paymentPlanDetails?.paymentMethodId
                        )
                          return theme.paymentPlan.chargeFeedback;
                      },
                    }}
                  >
                    <Typography variant="subtitle1">Preferred card:</Typography>
                    <Typography variant="subtitle1">
                      {selectedPaymentMethod?.label || `**** **** **** ${paymentPlanDetails?.cardLast4Digits}`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {!previewData &&
            Number(selectedPaymentMethod?.code) !== paymentPlanDetails?.paymentMethodId &&
            allowPreferredPaymentMethod && (
              <>
                <Box mb={5} px={6}>
                  <Alert severity="warning">Changes after update:</Alert>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb={1}
                  pt={1}
                  px={2}
                  sx={{
                    backgroundColor: (theme) => theme.paymentPlan.chargeFeedback,
                  }}
                >
                  <Typography variant="subtitle1">Preferred card:</Typography>
                  <Typography variant="subtitle1">
                    {selectedPaymentMethod?.label || `**** **** **** ${paymentPlanDetails?.cardLast4Digits}`}
                  </Typography>
                </Box>
              </>
            )}
        </>
      )}
    </SideModalPreviewWrapper>
  );
};
