import { Grid, Typography } from '@mui/material';
import { CalculateCancellationOutcomeResponse } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeResponse';

import { AlternativeCurrency } from 'components/_common/alternativeCurrency/AlternativeCurrency';
import { useFormat } from 'components/hooks/useFormat';

interface Props {
  data: CalculateCancellationOutcomeResponse;
}

export const CancellationOutcome = ({ data }: Props) => {
  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };
  return (
    <>
      <Grid container sx={{ my: 2 }}>
        {data.amountPaid && (
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1">Amount paid</Typography>
            <Typography variant="body1">{formatCurrency(data.amountPaid.amount, data.amountPaid.currency)}</Typography>
          </Grid>
        )}
        {data.itemsCancellationOutcome.map((item) => (
          <>
            {item.burnedAltCurrencyToReissue.map((burn) => (
              <Grid item xs={12} sm={3} key={`${item.orderItemId}_${burn.code}`}>
                <Typography variant="subtitle1">Burned</Typography>
                <Typography variant="body1">
                  <AlternativeCurrency data={{ alternativeCurrencyDefinition: { ...burn }, total: burn.value }} />
                </Typography>
              </Grid>
            ))}

            {item.earnedAltCurrencyToRevoke.map((earn) => (
              <Grid item xs={12} sm={3} key={`${item.orderItemId}_${earn.code}`}>
                <Typography variant="subtitle1">Earned</Typography>
                <Typography variant="body1">
                  <AlternativeCurrency data={{ alternativeCurrencyDefinition: { ...earn }, total: earn.value }} />
                </Typography>
              </Grid>
            ))}

            {item.certificatesToReinstate.map((certificate) => (
              <Grid item xs={12} sm={3} key={`${item.orderItemId}_${certificate.serialNumber}`}>
                <Typography variant="subtitle1">Certificate</Typography>
                <Typography variant="body1">
                  {certificate.name} ({certificate.serialNumber})
                </Typography>
              </Grid>
            ))}
          </>
        ))}
      </Grid>
    </>
  );
};
