import { LoadCriteria as ChooseProgramLoadCriteria } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/LoadCriteria';
import { LoadResponse as ChooseProgramLoadResponse } from '@one/api-models/lib/Admin/ProgramSales/ChooseProgram/LoadResponse';
import { CalculatePaymentPlanRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CalculatePaymentPlanRequest';
import { CalculatePaymentPlanResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CalculatePaymentPlanResponse';
import { CompleteOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderRequest';
import { CompleteOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderResponse';
import { InitOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderRequest';
import { InitOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderResponse';
import { LoadCriteria as PurchaseLoadCriteria } from '@one/api-models/lib/Admin/ProgramSales/Purchase/LoadCriteria';
import { LoadResponse as PurchaseLoadResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/LoadResponse';

import { IApiClient } from 'apiAccess/api-client';

export interface IProgramSalesAccess {
  chooseProgramLoad(data: ChooseProgramLoadCriteria): Promise<ChooseProgramLoadResponse>;
  purchaseDetailsLoad(criteria: PurchaseLoadCriteria): Promise<PurchaseLoadResponse>;
  calculatePaymentPlan(request: CalculatePaymentPlanRequest): Promise<CalculatePaymentPlanResponse>;
  initOrder(criteria: InitOrderRequest): Promise<InitOrderResponse>;
  completeOrder(criteria: CompleteOrderRequest): Promise<CompleteOrderResponse>;
}

export class ProgramSalesAccess implements IProgramSalesAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  chooseProgramLoad(criteria: ChooseProgramLoadCriteria): Promise<ChooseProgramLoadResponse> {
    return this.apiClient.call('sales/programSales/chooseProgram/load', {
      ...criteria,
      $Type: ChooseProgramLoadCriteria.$type,
      cultureCode: 'en-US',
    } as ChooseProgramLoadCriteria);
  }

  purchaseDetailsLoad(criteria: PurchaseLoadCriteria): Promise<PurchaseLoadResponse> {
    return this.apiClient.call('sales/programSales/purchase/load', {
      ...criteria,
      $Type: PurchaseLoadCriteria.$type,
      cultureCode: 'en-US',
    } as PurchaseLoadCriteria);
  }

  calculatePaymentPlan(criteria: CalculatePaymentPlanRequest): Promise<CalculatePaymentPlanResponse> {
    return this.apiClient.call('sales/programSales/purchase/paymentplan/calculate', {
      ...criteria,
      $Type: CalculatePaymentPlanRequest.$type,
      cultureCode: 'en-US',
    } as CalculatePaymentPlanRequest);
  }

  initOrder(criteria: InitOrderRequest): Promise<InitOrderResponse> {
    return this.apiClient.call('sales/programSales/purchase/initOrder', {
      ...criteria,
      $Type: InitOrderRequest.$type,
      cultureCode: 'en-US',
    } as InitOrderRequest);
  }

  completeOrder(criteria: CompleteOrderRequest): Promise<CompleteOrderResponse> {
    return this.apiClient.call('sales/programSales/purchase/completeOrder', {
      ...criteria,
      $Type: CompleteOrderRequest.$type,
      cultureCode: 'en-US',
    } as CompleteOrderRequest);
  }
}
