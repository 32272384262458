import { Box, Skeleton } from '@mui/material';
import { GridColDef, GridOverlay, GridPaginationModel } from '@mui/x-data-grid';
import { LoadResponse } from '@one/api-models/lib/Admin/Reports/FinalizedOrders/LoadResponse';

import { CustomPagination } from 'components/_common/DataGrid/CustomPagination';
import { DataGrid } from 'components/_common/DataGrid/DataGrid';

const columns: GridColDef[] = [
  {
    field: 'purchaseId',
    headerName: 'Purchase ID',
    sortable: false,
    filterable: false,
    width: 200,
  },
  {
    field: 'purchaseStatus',
    headerName: 'Purchase Status',
    sortable: false,
    filterable: false,
    width: 130,
  },
  {
    field: 'partner',
    headerName: 'Partner',
    sortable: false,
    filterable: false,
    width: 120,
  },
  {
    field: 'brand',
    headerName: 'Brand',
    sortable: false,
    filterable: false,
    width: 200,
  },
  {
    field: 'customerFirstName',
    headerName: 'First Name',
    sortable: false,
    filterable: false,
    width: 160,
  },
  {
    field: 'customerLastName',
    headerName: 'Last Name',
    sortable: false,
    filterable: false,
    width: 150,
  },
  {
    field: 'product',
    headerName: 'Product',
    sortable: false,
    filterable: false,
    width: 110,
  },
  {
    field: 'productPurchased',
    headerName: 'Product Purchased',
    sortable: false,
    filterable: false,
    width: 250,
  },
  {
    field: 'paymentAmount',
    headerName: 'Payment Amount',
    sortable: false,
    filterable: false,
    align: 'right',
    width: 100,
  },
  {
    field: 'supplierName',
    headerName: 'Supplier',
    sortable: false,
    filterable: false,
    width: 110,
  },
  {
    field: 'purchaseDate',
    headerName: 'Date (Purchase)',
    sortable: false,
    filterable: false,
    width: 100,
  },
  {
    field: 'supplierNet',
    headerName: 'Net (Supplier)',
    sortable: false,
    filterable: false,
    align: 'right',
    width: 100,
  },
  {
    field: 'supplierRetail',
    headerName: 'Retail (Supplier)',
    sortable: false,
    filterable: false,
    align: 'right',
    width: 100,
  },
  {
    field: 'availableGrossMargin',
    headerName: 'Available Gross Margin',
    sortable: false,
    filterable: false,
    align: 'right',
    width: 100,
  },
  {
    field: 'tax',
    headerName: 'Tax',
    sortable: false,
    filterable: false,
    align: 'right',
    width: 100,
  },
  {
    field: 'certificateName',
    headerName: 'Certificate Name',
    sortable: false,
    filterable: false,
    width: 200,
  },
  {
    field: 'certificateSerialNumber',
    headerName: 'CertificateID',
    sortable: false,
    filterable: false,
    width: 140,
  },
  {
    field: 'certificateInternalCostValue',
    headerName: 'Internal Cost (Certificate)',
    sortable: false,
    filterable: false,
    align: 'right',
    width: 100,
  },
  {
    field: 'certificateInternalCostCurrency',
    headerName: 'Internal Cost Currency (Certificate)',
    sortable: false,
    filterable: false,
    width: 100,
  },
  {
    field: 'certificateType',
    headerName: 'Type (Certificate)',
    sortable: false,
    filterable: false,
    width: 100,
  },
  {
    field: 'certificatePaymentAmount',
    headerName: 'Payment Amount (Certificate)',
    sortable: false,
    filterable: false,
    align: 'right',
    width: 100,
  },
];

declare interface SalesReportListingProps {
  data: LoadResponse | undefined;
  isFetching: boolean;
  showSkeleton: boolean;
  handlePaginationChanges: (data: GridPaginationModel) => void;
  isPreviousData: boolean;
  page: number;
  pageSize: number;
  isEmptySalesReport: boolean | null;
}

export const SalesReportListing = ({
  data,
  isFetching,
  showSkeleton,
  handlePaginationChanges,
  isPreviousData,
  page,
  pageSize,
  isEmptySalesReport,
}: SalesReportListingProps) => {
  return !showSkeleton ? (
    <Box sx={{ pb: 2, overflow: 'scroll' }}>
      <DataGrid
        paginationMode="server"
        onPaginationModelChange={handlePaginationChanges}
        loading={(isFetching && isPreviousData) || data == null}
        rows={
          data?.listingData.items.map((x: any) => {
            const id = `${x.purchaseId}-${x.product}-${x.productPurchased}`;
            return { id: id, ...x };
          }) || []
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              page: page,
              pageSize: pageSize,
            },
          },
        }}
        rowCount={data?.listingData?.itemCount || 0}
        disablePaginationButtons={isEmptySalesReport}
        components={{
          LoadingOverlay: () => <GridOverlay sx={{ backgroundColor: 'white', opacity: 0.5 }} />,
          Pagination: CustomPagination,
        }}
      />
    </Box>
  ) : (
    <Box>
      <Skeleton variant="rectangular" height={600} />
    </Box>
  );
};
