import { Box, Typography } from '@mui/material';

import { ReactComponent as Logo } from 'assets/icons/backOfficeLogo.svg';

export const BackOfficeLogo = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        py: 2,
      }}
    >
      <Logo width={35} />
      <Typography sx={{ ml: 1, fontSize: '0.875rem' }}>Backoffice</Typography>
    </Box>
  );
};
