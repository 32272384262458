import { Control, FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { MemberStatus } from '@one/api-models/lib/Admin/Members/MemberStatus';
import { EditMemberRequest } from '@one/api-models/lib/Admin/Members/Request/EditMemberRequest';
import { EditMemberResponse } from '@one/api-models/lib/Admin/Members/Response/EditMemberResponse';
import { AccountSummary } from '@one/api-models/lib/Membership/Account/AccountSummary';

import { ApiError } from 'apiAccess/api-client';
import ControlledTextField from 'components/_common/ControlledTextField';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useToastMessage } from 'components/hooks/useToastMessage';
import { selectActiveBrand } from 'slices/applicationDataSlice';

interface AddNoteDialogProps {
  handleClose: () => void;
  open: boolean;
  accountSummary?: AccountSummary;
  testId: string;
}

export const AddNoteDialog = ({ handleClose, open, accountSummary, testId }: AddNoteDialogProps) => {
  const { api } = useApiHelpers();
  const activeBrand = useSelector(selectActiveBrand);
  const { apiErrorHandler, addMessage } = useToastMessage();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<{ notes: string }>({
    defaultValues: {
      notes: accountSummary?.profileSummary.holderAccountNotes,
    },
  });

  //Temporary solution. Everything related to edit customer will be removed in V2
  const editCustomerMutation = useMutation<EditMemberResponse, ApiError, EditMemberRequest, unknown>(
    (request) => {
      return api.members.edit(request);
    },
    {
      onSuccess: (value: EditMemberResponse) => {
        addMessage({
          label: 'Notes updated successfully.',
          severity: 'success',
        });
        if (value?.memberKey) {
          navigate(`/customers/${value.memberKey}`, { state: { refetch: true } });
        }
        handleClose();
      },
      onError: apiErrorHandler,
    },
  );

  const handleNotesSubmit = (data: any) => {
    const request: EditMemberRequest = {
      $Type: EditMemberRequest.$type,
      memberKey: accountSummary?.memberKey ?? '',
      profileId: accountSummary?.profileSummary.id,
      brandKey: activeBrand?.key ?? '',
      status: accountSummary?.memberStatus || MemberStatus.Inactive,
      salutation: accountSummary?.profileSummary.salutation ?? '',
      firstName: accountSummary?.profileSummary.firstName ?? '',
      lastName: accountSummary?.profileSummary.lastName ?? '',
      emailAddress: accountSummary?.profileSummary.email ?? '',
      dateOfBirth: accountSummary?.profileSummary.dateOfBirth,
      phoneNumber: accountSummary?.profileSummary.mobilePhone,
      country: accountSummary?.profileSummary.country ?? 'US',
      address: accountSummary?.profileSummary.address ?? '',
      address2: accountSummary?.profileSummary.address2 ?? '',
      city: accountSummary?.profileSummary.city ?? '',
      state: accountSummary?.profileSummary.state ?? '',
      zipCode: accountSummary?.profileSummary.zipCode ?? '',
      externalMemberKey: accountSummary?.profileSummary.holderAccountExternalMemberKey ?? '',
      partnerLoyaltyId: accountSummary?.profileSummary.holderAccountPartnerLoyaltyId ?? '',
      notes: data.notes ?? '',
      leadSource: accountSummary?.profileSummary.holderAccountLeadSource ?? '',
    };
    editCustomerMutation.mutate(request);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle variant="h6" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box>Add Note</Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="small"
          disableRipple
          data-testid={`${testId}CloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(handleNotesSubmit)}>
        <DialogContent>
          <ControlledTextField
            control={control as unknown as Control<FieldValues, object>}
            name="notes"
            fullWidth
            multiline
            minRows="5"
            label="Notes"
            placeholder="Notes"
            testId={`${testId}Input`}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={handleClose}
            disabled={editCustomerMutation.isLoading}
            variant="outlined"
            data-testid={`${testId}CancelButton`}
            disableRipple
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={editCustomerMutation.isLoading}
            disableRipple
            variant="contained"
            type="submit"
            size="small"
            loading={editCustomerMutation.isLoading}
            data-testid={`${testId}SaveChangesButton`}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
