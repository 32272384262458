import { useRef, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

export interface RowActionType {
  label: string;
  actionHandle: (data: any) => void;
}
interface RowActionProps {
  cellParam: GridRenderCellParams;
  actions: RowActionType[];
  testId: string;
}
export const RowAction = (props: RowActionProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        id={`composition-button`}
        aria-controls={open ? `composition-menu` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        data-testid={`${props.testId}CompositionButton`}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'right bottom' : 'left bottom',
            }}
          >
            <Paper elevation={5}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id={`composition-menu`}
                  aria-labelledby={`composition-button`}
                  onKeyDown={handleListKeyDown}
                >
                  {props.actions.map((actionItem, index) => (
                    <MenuItem
                      key={`menu-option-${index}`}
                      onClick={() => actionItem.actionHandle(props.cellParam.row)}
                      data-testid={`${props.testId}CompositionMenuItem`}
                    >
                      {actionItem.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
