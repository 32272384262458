import { useSelector } from 'react-redux';

import { Brand } from '@one/api-models/lib/Admin/Common/Brand';
import { Partner } from '@one/api-models/lib/Admin/Common/Partner';

import { selectActivePartner, selectPartners } from 'slices/applicationDataSlice';

import { useAppHelpers } from './useAppHelpers';

export const useSwitchActiveBrand = () => {
  const activePartner = useSelector(selectActivePartner);
  const partners = useSelector(selectPartners);
  const { setActivePartner } = useAppHelpers();

  const switchActiveBrand = (id?: string) => {
    if (id) {
      const splitOrderNumber = id.split('-');

      const partnerKey = splitOrderNumber[0];
      const brandKey = splitOrderNumber[1];

      let partner: Partner | undefined;
      let brand: Brand | undefined;

      if (activePartner?.key !== partnerKey) {
        partner = partners?.find((e: Partner) => e.key === partnerKey);
        brand = partner?.brands?.find((e: Brand) => e.key === brandKey);
        setActivePartner(partner, brand);
      }
    }
  };

  return { switchActiveBrand };
};
