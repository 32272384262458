import { Box } from '@mui/material';

import { TextField, Typography } from 'styled';
// Mocked component
export const SalesOrderMemo = () => {
  return (
    <Box sx={{ mt: 7 }}>
      <Typography variant="h5">Memo</Typography>
      <Box sx={{ mt: 2.5 }}>
        <Typography variant="body2">Add Customer Note</Typography>
        <Typography variant="body1">This will appear in the Confirmation Email</Typography>
        <Box width={{ xs: '100%', sm: '70%', mt: 3 }}>
          <TextField placeholder="Note" multiline minRows="5" fullWidth sx={{ mt: 1 }} />
        </Box>
      </Box>
    </Box>
  );
};
