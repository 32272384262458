import { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Supplier } from '@one/api-models/lib/Admin/Products/Supplier';
import { SupplierReference } from '@one/api-models/lib/Admin/Products/SupplierReference';

import { SectionTitle } from 'components/_common/SectionTitle';
import { groupBy } from 'utils/functions';

import { FormProps } from '../DetailsTab/DetailsBase';

import { SupplierReferenceFields } from './SupplierReferenceFields';

export interface DetailsUpdateBase {
  name: string;
  displayName: string;
}

interface Props {
  formProps: FormProps;
  isLoading: boolean;
  suppliers: Supplier[];
  testId: string;
}

export const SupplierReferences = ({ formProps, isLoading, suppliers, testId }: Props) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleEdit = (): void => {
    setIsEditMode(!isEditMode);
  };

  const { getValues } = formProps;

  const supplierReferences = getValues('supplierReferences') as SupplierReference[];

  const groupedReferences = groupBy<SupplierReference>(supplierReferences, 'supplierIdentifier');

  const getSupplierName = (identifier: string): string =>
    suppliers.find((s: Supplier) => s.identifier === identifier)?.name ?? identifier;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionTitle title="Supplier Reference" />
        <Tooltip title="Edit Supplier References" onClick={handleEdit}>
          <IconButton aria-label="edit" size="small" data-testid={`${testId}${isEditMode ? 'Edit' : 'Clear'}Button`}>
            {isEditMode ? <ClearIcon /> : <EditIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        {isEditMode ? (
          <Grid container>
            {suppliers.map((supplier: Supplier) => (
              <Grid item xs={12} md={6} key={supplier.identifier}>
                <Typography variant="subtitle2" sx={{ mt: 2 }}>
                  {supplier?.name}
                </Typography>
                <SupplierReferenceFields
                  formProps={formProps}
                  supplierIdentifier={supplier.identifier}
                  isLoading={isLoading}
                  testId={testId}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box>
            {supplierReferences.length === 0 ? (
              <Typography variant="body2">There are no references for this product.</Typography>
            ) : (
              <Grid container>
                {Object.keys(groupedReferences).map((key: string) => (
                  <Grid item xs={12} md={6} key={key}>
                    <Typography variant="subtitle2" sx={{ mt: 1 }}>
                      {getSupplierName(key)}
                    </Typography>
                    {groupedReferences[key].map((ref: SupplierReference) => (
                      <Chip
                        key={`${ref.supplierIdentifier}-${ref.reference}`}
                        label={ref.reference}
                        size="small"
                        sx={{ ml: 1, mt: 0.5 }}
                      />
                    ))}
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
