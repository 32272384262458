import { forwardRef, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Country, Labels } from 'react-phone-number-input';
import { getCountries, getCountryCallingCode, isSupportedCountry } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';

import { Box, FormHelperText, MenuItem, TextField } from '@mui/material';

import { FlagIcon } from 'common/FlagIcon';

import 'react-phone-number-input/style.css';

type ControlledInternationalCountryCallingCodesSelectProps = {
  name: string;
  control: Control;
  onChange?: (value?: string) => void;
  error?: boolean;
  helperText?: ReactNode;
  label?: ReactNode;
  labels?: Labels;
  locales?: string;
  fullWidth?: boolean;
  country?: string;
  disabled?: boolean;
  isAutoFocused?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  placeholder?: string;
  testId: string;
};

type CountryOption = {
  label: string | undefined;
  value: Country;
};

const getSortedCountries = (labels: any, locales: string): CountryOption[] =>
  getCountries()
    .map((country) => ({ label: labels[country], value: country }))
    .sort((c1, c2) => c1?.label?.localeCompare(c2?.label, locales));

export const ControlledInternationalCountryCallingCodesSelect = forwardRef(
  (
    {
      country,
      labels = en,
      locales = 'en',
      control,
      name,
      error,
      label,
      helperText,
      variant = 'outlined',
      size = 'small',
      testId,
      onChange,
      ...props
    }: ControlledInternationalCountryCallingCodesSelectProps,
    ref,
  ) => {
    return (
      <>
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            const countryValue = (field.value || country) as Country;
            return (
              <TextField
                {...props}
                variant={variant}
                size={size}
                error={error}
                inputRef={ref}
                inputProps={{
                  tabIndex: -1,
                  'data-testid': `${testId}CountryCodeInput`,
                }}
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: '50%', maxWidth: '84%' },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  },
                  renderValue: () => {
                    return isSupportedCountry(countryValue) ? (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Box
                          sx={{
                            flex: '0 0 26px',
                            flexDirection: 'column',
                            alignItems: 'center',
                            margin: '2px 4px 0px 0px',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                          }}
                        >
                          <Box sx={{ position: 'relative', width: '100%', paddingBottom: 'calc(100% * 2 / 3)' }}>
                            <Box
                              sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                boxShadow: '0 0 0 1px white',
                              }}
                            >
                              <FlagIcon countryValue={countryValue} />
                            </Box>
                          </Box>
                        </Box>
                        <Box component="span" sx={{ width: '46%' }}>
                          +{getCountryCallingCode(countryValue)}
                        </Box>
                      </Box>
                    ) : (
                      <Box component="span">Not Supported</Box>
                    );
                  },
                }}
                select
                label={label}
                value={countryValue}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  if (onChange) onChange(event.target.value);
                }}
              >
                {getSortedCountries(labels, locales).map((country) => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label} +{getCountryCallingCode(country.value)}
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </>
    );
  },
);
