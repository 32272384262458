import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    border: 0,
    '& .MuiDataGrid-columnHeaders': {
      textTransform: 'uppercase',
      fontSize: '11px',
      fontWeight: '500',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'rgb(246,248,250)',
    },
    '& .MuiDataGrid-columnHeader': {
      padding: '0px 4px',
    },
    '& .MuiDataGrid-columnHeader.noPL': {
      paddingLeft: '0px',
    },
    '& .MuiDataGrid-columnHeader.pb1': {
      paddingBottom: '8px',
    },
    '& .MuiDataGrid-main': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiDataGrid-footerContainer': {
      border: 'none',
    },
    '& .MuiDataGrid-cell': {
      outline: 'none',
      padding: '16px 4px',
      fontWeight: 400,
    },
    '& .MuiDataGrid-cell.align-top': {
      alignItems: 'flex-start',
    },
    '& .MuiDataGrid-cell.noPL': {
      paddingLeft: '0px',
    },
  },
});
export const dataGridStyle = makeStyles({
  root: {
    border: 0,
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '0.6px solid #D8DEE4',
      borderTop: '0.6px solid #D8DEE4',
      borderColor: '#D8DEE4',
      minHeight: '36px !important',
      maxHeight: '36px !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '12px',
      lineHeight: '20px',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '0.6px solid #D8DEE4',
      minHeight: '37px !important',
      maxHeight: '37px !important',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      minHeight: '37px !important',
      maxHeight: '37px !important',
      color: '#596171',
      height: '37px !important',
      '&:last-child .MuiDataGrid-cell': {
        borderBottom: '0.6px solid #D8DEE4',
      },
    },
    '& .MuiDataGrid-selectedRowCount ': {
      minWidth: '150px',
      marginRight: 0,
    },
    '& .MuiDataGrid-checkboxInput': {
      color: '#D8DEE4',
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-root:focus': {
      outline: 'none',
    },
  },
});
