import { Location, matchPath } from 'react-router-dom';

import { AppRoute, routes } from 'Routes';

export const getMatchRoute = (location: Location): AppRoute | undefined => {
  for (const r of routes) {
    if (matchPath(r.path, location.pathname)) {
      return r;
    } else {
      const matchedPath = r.insideElements?.find((sr) => !!matchPath(sr.path, location.pathname));
      if (matchedPath) {
        return matchedPath;
      }
    }
  }
  return undefined;
};
