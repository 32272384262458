import { StoreFileItem } from 'models/fileManagement/StoreFileItem';
import { StoreRequest } from 'models/fileManagement/StoreRequest';
import { StoreResponse } from 'models/fileManagement/StoreResponse';

import { IApiClient } from './api-client';

export interface IFileStorageAccess {
  store(request: StoreRequest): PromiseLike<StoreResponse>;
}

export class FileStorageAccess implements IFileStorageAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  store(request: StoreRequest): PromiseLike<StoreResponse> {
    return new Promise((resolve) => {
      resolve({
        storedFiles: request.files.map((x, idx) => ({ ...x, url: URL.createObjectURL(x.fileData) } as StoreFileItem)),
      } as StoreResponse);
    });
  }
}
