import { forwardRef, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

import {
  FilledInputProps,
  InputBaseComponentProps,
  InputLabelProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from '@mui/material';
import { SxProps } from '@mui/system';

interface ControlledTextFieldProps {
  name: string;
  control: Control;
  error?: boolean;
  helperText?: ReactNode;
  label?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  isAutoFocused?: boolean;
  multiline?: boolean;
  minRows?: string;
  maxRows?: string;
  type?: string;
  sx?: SxProps;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  InputLabelProps?: Partial<InputLabelProps>;
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
  inputProps?: InputBaseComponentProps;
  testId: string;
  onChange?: (value?: string) => void;
  onBlur?: () => void;
}

const ControlledTextField = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      control,
      error,
      helperText,
      disabled = false,
      fullWidth,
      isAutoFocused = false,
      multiline,
      minRows,
      maxRows,
      InputProps,
      inputProps,
      InputLabelProps,
      type,
      testId,
      size = 'small',
      variant = 'outlined',
      sx,
      onChange,
      onBlur,
    }: ControlledTextFieldProps,
    ref,
  ) => {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField
            {...field}
            value={field.value || field.value === 0 ? field.value : ''}
            inputRef={ref}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            inputProps={{
              ...inputProps,
              'data-testid': `${testId}TextInput`,
            }}
            onChange={(ev) => {
              field.onChange(ev.target.value);
              if (onChange) onChange(ev.target.value);
            }}
            onBlur={() => {
              field.onBlur();
              if (onBlur) onBlur();
            }}
            type={type}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            disabled={disabled}
            autoFocus={isAutoFocused}
            sx={sx}
            size={size}
            variant={variant}
          />
        )}
      />
    );
  },
);

export default ControlledTextField;
