import { CalculateCancellationOutcomeRequest } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeRequest';
import { CalculateCancellationOutcomeResponse } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeResponse';
import { CancelOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/CancelOrderRequest';
import { CancelOrderResponse } from '@one/api-models/lib/Admin/Orders/Common/CancelOrderResponse';
import { EditOrderRequest } from '@one/api-models/lib/Admin/Orders/Common/EditOrderRequest';
import { EditOrderResponse } from '@one/api-models/lib/Admin/Orders/Common/EditOrderResponse';

import { IApiClient } from 'apiAccess/api-client';

export interface ISalesAccess {
  cancelOrder(request: CancelOrderRequest): Promise<CancelOrderResponse>;
  calculateCancellationOutcome(
    request: CalculateCancellationOutcomeRequest,
  ): Promise<CalculateCancellationOutcomeResponse>;
  editOrder(request: EditOrderRequest): Promise<EditOrderResponse>;
}

export class SalesAccess implements ISalesAccess {
  private apiClient: IApiClient;

  constructor(apiClient: IApiClient) {
    this.apiClient = apiClient;
  }

  editOrder(request: EditOrderRequest): Promise<EditOrderResponse> {
    return this.apiClient.call('sales/order/edit', {
      ...request,
      $type: EditOrderRequest.$type,
      cultureCode: 'en-US',
    });
  }

  cancelOrder(request: CancelOrderRequest): Promise<CancelOrderResponse> {
    return this.apiClient.call('sales/order/cancel', {
      ...request,
      $type: CancelOrderRequest.$type,
      cultureCode: 'en-US',
    });
  }

  calculateCancellationOutcome(
    request: CalculateCancellationOutcomeRequest,
  ): Promise<CalculateCancellationOutcomeResponse> {
    return this.apiClient.call('sales/order/calculatecancellationoutcome', {
      ...request,
      $type: CalculateCancellationOutcomeRequest.$type,
      cultureCode: 'en-US',
    });
  }
}
