import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElements,
} from '@stripe/stripe-js';

export const validateStripeCard = (elements: StripeElements) => {
  const cardElement: StripeCardNumberElement = elements?.getElement(CardNumberElement) as StripeCardNumberElement;
  const emptyCardNumber = (cardElement as any)?._empty;
  const invalidCardNumber = (cardElement as any)?._invalid;

  const expDateElement: StripeCardExpiryElement = elements?.getElement(CardExpiryElement) as StripeCardExpiryElement;
  const emptyExpDate = (expDateElement as any)?._empty;
  const invalidExpDate = (expDateElement as any)?._invalid;

  const cvcElement: StripeCardCvcElement = elements?.getElement(CardCvcElement) as StripeCardCvcElement;
  const emptyCvc = (cvcElement as any)?._empty;
  const invalidCvc = (cvcElement as any)?._invalid;

  const creditCardErrors = {
    cardNumberError: emptyCardNumber
      ? 'Card number is required'
      : invalidCardNumber
      ? 'Your card number is invalid.'
      : undefined,
    cardExpiryError: emptyExpDate
      ? 'Expiry date is required'
      : invalidExpDate
      ? 'Your card expiration date is invalid.'
      : undefined,
    cardCvcError: emptyCvc ? 'CVC is required' : invalidCvc ? 'Your CVC is invalid.' : undefined,
  };

  return {
    cardElement,
    creditCardErrors,
  };
};
