import React, { useMemo } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import { ContentType } from '@one/api-models/lib/Membership/Account/ContentType';
import { CabinCategorySummary } from '@one/api-models/lib/Membership/Account/Travel/Cruise/CabinCategorySummary';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Membership/Account/Travel/Cruise/CruiseOrderItemSummary';
import { ItineraryDay } from '@one/api-models/lib/Membership/Account/Travel/Cruise/ItineraryDay';
import { Traveler } from '@one/api-models/lib/Membership/Account/Travel/Cruise/Traveler';
import { InformationItem } from '@one/api-models/lib/Membership/Account/Travel/InformationItem';
import { InformationSection } from '@one/api-models/lib/Membership/Account/Travel/InformationSection';

import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';

import { Info } from './Info';

interface Props {
  orderItem: CruiseOrderItemSummary;
}

export const CruiseOrderItemDetails = ({ orderItem }: Props) => {
  const { productSummary } = orderItem;
  const { formatDate: _formatDate } = useFormat();
  const formatDate = (date: string | Date | null | undefined, format = 'dd MMM yyyy'): string => {
    if (!date) return 'N/A';
    return _formatDate(new Date(date!), true, format) ?? 'N/A';
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <SectionTitle title="Overview" variant="h6" />
        </Grid>
        <Grid item xs={6} md={3}>
          <Info
            label="Departure"
            value={
              <>
                {productSummary.departurePort.name}
                <Typography component="span" sx={{ display: 'block' }}>
                  {formatDate(productSummary.departureDate, 'ccc, dd MMM yyyy')}
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Info
            label="Arrival"
            value={
              <>
                {productSummary.arrivalPort.name}
                <Typography component="span" sx={{ display: 'block' }}>
                  {formatDate(productSummary.arrivalDate, 'ccc, dd MMM yyyy')}
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Info
            label="Duration"
            value={
              <>
                <Typography component="span" sx={{ display: 'block' }}>
                  {productSummary.durationDays} nights
                </Typography>
                <Itinerary orderItem={orderItem} />
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Info label="Cruise line" value={productSummary.cruiseLine.name} />
        </Grid>
        <Grid item xs={6} md={2}>
          <Info label="Ship" value={productSummary.ship.name} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} mt={2}>
          <SectionTitle title="Cabin details" variant="h6" />
        </Grid>
        <Grid item xs={6} md={2}>
          <Info label="Type" value={productSummary.cabinCategory.type} />
        </Grid>
        <Grid item xs={6} md={2}>
          <Info label="Category" value={<CabinCategory data={productSummary.cabinCategory} />} />
        </Grid>
        <Grid item xs={6} md={2}>
          <Info label="Number" value={`#${productSummary.cabinNumber}`} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Info
            label="Rate"
            value={
              <>
                {productSummary.rate.name}
                <Typography component="span" sx={{ display: 'block' }}>
                  ({productSummary.rate.supplierReference})
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Info label="Dining option" value={productSummary.diningPreference} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} mt={2}>
          <SectionTitle title="Guests" variant="h6" />
        </Grid>
        <Grid item xs={3} md={2}>
          <Info label="Adults" value={orderItem.numberOfAdults} direction="row" />
          <Info label="Children" value={orderItem.numberOfChildren} direction="row" />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        {orderItem.travelers.map((traveler: Traveler, index: number) => (
          <Grid item xs={12} md={6} key={`traveler_${index}`}>
            <SectionTitle title={`Traveler ${index + 1}`} variant="h6" />
            <Box ml={1}>
              <Info
                label="Name"
                value={`${traveler.title ? `${traveler.title} ` : ''}${traveler.firstName} ${traveler.lastName}`}
              />
              {traveler.citizenshipCountryCode && <Info label="Citizenship" value={traveler.citizenshipCountryCode} />}
              {traveler.dateOfBirth && <Info label="Date of birth" value={formatDate(traveler.dateOfBirth)} />}
              {traveler.email && <Info label="Email" value={traveler.email} />}
              {traveler.phone && <Info label="Phone" value={traveler.phone} />}
              {traveler.pastPassengerNumber && <Info label="Loyalty no" value={traveler.pastPassengerNumber} />}
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const CabinCategory = (props: { data: CabinCategorySummary }) => {
  const { data } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleOpenDetails = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDetails = (): void => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  const cabinCategoryAmenities: string[] = useMemo(() => {
    let desc: string[] = [];
    const contents = data.contents.find(
      (c: InformationSection) => c.type === ContentType.CabinCategoryAmenities,
    )?.items;

    if (contents != null) {
      contents.forEach((description: InformationItem) => {
        try {
          const items = JSON.parse(description.value);
          desc = desc.concat(items);
        } catch (e) {
          desc.push(description.value);
        }
      });
    }
    return desc;
  }, [data.contents]);

  const hasMoreDetails = useMemo(
    () =>
      data.bedConfiguration != null ||
      cabinCategoryAmenities.length > 0 ||
      data.deck != null ||
      data.location != null ||
      data.occupancyDetails != null,
    [data, cabinCategoryAmenities],
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} component="span">
      {data.name}
      {hasMoreDetails && (
        <>
          <IconButton sx={{ ml: 1 }} size="small" aria-describedby="cabin-details" onClick={handleOpenDetails}>
            <InfoOutlined />
          </IconButton>

          <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleCloseDetails}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography sx={{ p: 2 }}>
              {data.deck && <Info label="Deck" value={data.deck} />}
              {data.location && <Info label="Location" value={data.location} />}
              {data.occupancyDetails && (
                <Info label="Occupancy" value={`Up to ${data.occupancyDetails.maxAllowedTotal} guests`} />
              )}
              {data.bedConfiguration && <Info label="Bed" value={data.bedConfiguration} />}
              {cabinCategoryAmenities.length > 0 && (
                <Info
                  label="Amenities"
                  value={
                    <ul style={{ margin: 0 }}>
                      {cabinCategoryAmenities.map((s: string, i: number) => (
                        <li key={`description-${i}`}>
                          <Typography variant="body2" component={Box} sx={{ whiteSpace: 'pre-line' }}>
                            {s}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  }
                />
              )}
            </Typography>
          </Popover>
        </>
      )}
    </Box>
  );
};

const Itinerary = ({ orderItem }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleOpenDetails = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDetails = (): void => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);
  return (
    <>
      <Button size="small" aria-describedby="cabin-details" onClick={handleOpenDetails}>
        See itinerary
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseDetails}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 1 }}>
          <List
            sx={{
              li: {
                pb: 0,
                pt: 0,
              },
            }}
          >
            {orderItem.productSummary.itinerary.days.map((day: ItineraryDay) => (
              <ListItem alignItems="flex-start" key={day.dayIndex}>
                <ListItemText
                  primary={`Day ${day.dayIndex + 1}`}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                  secondary={
                    <>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>
                        {day.title}
                      </Typography>
                      <Grid container>
                        {day.arrivalTime && (
                          <Grid item>
                            <Typography variant="caption">Arrival</Typography>
                            <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                              {day.arrivalTime}
                            </Typography>
                          </Grid>
                        )}
                        {day.departureTime && (
                          <Grid item component={Box} pl={day.arrivalTime ? 2 : 0}>
                            <Typography variant="caption">Departure</Typography>
                            <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                              {day.departureTime}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {day.highlights && (
                        <Typography variant="body1" component={Box} mt={1}>
                          {day.highlights}
                        </Typography>
                      )}
                    </>
                  }
                  secondaryTypographyProps={{
                    style: { opacity: 1 },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Typography>
      </Popover>
    </>
  );
};
