import { Link as RouterLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs, Link } from '@mui/material';

import { AppRoute, routes } from '../../Routes';

export const RouterBreadcrumbs = () => {
  const insideRoutes = routes.filter((r) => r.insideElements != null).map((r) => r.insideElements!);
  const allRoutes = routes.concat(...insideRoutes).filter((r) => r.breadcrumb != null);
  const breadcrumbs = useBreadcrumbs(allRoutes, { disableDefaults: true });
  const testIdPrefix = 'Breadcrumb';

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      {breadcrumbs.map(({ match, breadcrumb }, idx) => {
        const matchRoute = match?.route as AppRoute;

        return (
          <span key={match.pathname}>
            {idx < breadcrumbs.length - 1 && (
              <Link
                component={RouterLink}
                to={match.pathname}
                sx={
                  matchRoute?.isAppRoot
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                      }
                    : {}
                }
                data-testid={`${testIdPrefix}Link`}
                underline="hover"
              >
                {matchRoute?.isAppRoot ? <HomeIcon fontSize="small" /> : breadcrumb}
              </Link>
            )}
            {idx === breadcrumbs.length - 1 && <>{breadcrumb} </>}
          </span>
        );
      })}
    </Breadcrumbs>
  );
};
