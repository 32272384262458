export enum CertificateProductTypes {
  Shopping = 'Shopping',
  Wine = 'Wine',
  Hotel = 'Hotel',
  Resort = 'Resort',
  Villa = 'Villa',
  Car = 'Car',
  Cruise = 'Cruise',
  'Cruise.Ocean' = 'Cruise.Ocean',
  'Cruise.River' = 'Cruise.River',
  Discount = 'Discount',
  Deal = 'Deal',
  Vacation = 'Vacation',
  Merchandise = 'Merchandise',
}

export enum CertificateCruiseProductTypes {
  Cruise,
  'Cruise.Ocean',
  'Cruise.River',
}

export enum CertificateAccommodationProductTypes {
  Hotel,
  Resort,
  Villa,
}
