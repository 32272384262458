import React, { useState } from 'react';

import { Button, Typography, TypographyProps } from '@mui/material';

interface Props {
  text: string;
  maxLength: number;
  testId: string;
  typographyProps?: TypographyProps;
  moreText?: string;
  lessText?: string;
}

export const ReadMore: React.FC<Props> = ({ text, maxLength, typographyProps, moreText, lessText, testId }: Props) => {
  const [showFullText, setShowFullText] = useState(false);

  let isFullText = true;
  let textToShow = text;

  if (text && text.length > maxLength) {
    textToShow = `${text.substr(0, maxLength)}... `;
    isFullText = false;
  }

  return (
    <>
      <Typography {...typographyProps}>
        {showFullText ? text : textToShow}
        {!isFullText && (
          <Button
            color="primary"
            onClick={() => setShowFullText(!showFullText)}
            className="read-more"
            sx={{ py: 0 }}
            data-testid={`${testId}ReadMoreButton`}
          >
            {showFullText ? lessText : moreText}
          </Button>
        )}
      </Typography>
    </>
  );
};

ReadMore.defaultProps = {
  lessText: 'Read less',
  moreText: 'Read more',
};
